import { emitter } from "../../components/utils";
import instance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

const pollConnectionStatus = (platform) => async (dispatch) => {
  console.log("running poll connection status");
  const res = await instance.post("/user/check-connection-status", {
    platform,
  });
  console.log(res, platform);
  const { connectionStatus, isOtpRequired } = res?.data;
  if (isOtpRequired) {
    dispatch({
      type: "CONNECTION_OTP_REQUIREMENT",
      payload: platform,
    });
  }

  if (connectionStatus === "connected") {
    // emitter.emit(
    //     `connectionStatusFullfilled/${platform}`,
    //     connectionStatus
    // );
    dispatch({
      type: "UPDATE_PLATFORM_STATUS",
      payload: {
        platform,
        status: "connected",
      },
    });
    toast.success("Credential saved successfully!");
  } else if (connectionStatus === "failed") {
    dispatch({
      type: "UPDATE_PLATFORM_STATUS",
      payload: {
        platform,
        status: "failed",
      },
    });
  } else if (connectionStatus === "connecting") {
    setTimeout(() => {
      dispatch(pollConnectionStatus(platform));
    }, 5000);
  }
};

export const SaveCredentials = (platform, data) => async (dispatch) => {
  try {
    console.log("running save credentials");
    const { status } = await instance.post("/user/save-credential", {
      platform,
      data,
    });
    console.log(status, "CHECK STATUS");
    if (status === 200) {
      dispatch(pollConnectionStatus(platform));
    } else {
      emitter.emit(`connectionStatusFullfilled/${platform}`, "failed");
      toast.error("Credential saving failed!");
      return false;
    }
  } catch (error) {
    emitter.emit(`connectionStatusFullfilled/${platform}`, "failed");
    console.log(error, "Error in create listing");
    toast.error("Something went wrong!");
    return false;
  }
};

export const SaveShopifyCredentials = async (credentials) => {
  try {
    const { status } = await instance.post("/shopify/credentials", credentials);
    console.log(status, "STATUS");
    if (status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const CheckCredentialsStatus = (platform) => async (dispatch) => {
  try {
  } catch (error) {}
};

export const sendOtpForPlatformVerification =
  (platform, otp) => async (dispatch) => {
    try {
      dispatch({
        type: "CONNECTION_OTP_REQUIREMENT",
        payload: null,
      });
      let sendOtp = otp;
      if (!sendOtp) {
        sendOtp = "123456";
      }
      const { status } = await instance.post("/user/save-otp-connection", {
        platform,
        otp: sendOtp,
      });
      if (status === 200) {
        if (!otp) {
          toast.success("connection will get cancelled");
          return;
        }
        toast.success("Otp sent successfully");
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      toast.error("Some thing went wrond!");
    }
  };
