import { combineReducers } from 'redux';

import auth from './auth';
import wants from './wants';
import tasks from './tasks';
import staging from './staging'
import inventory from './inventory';
export default combineReducers({
    auth: auth,
    wants: wants,
    tasks: tasks,
    staging: staging,
    inventory: inventory,
})