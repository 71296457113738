import React from "react";
import Select from "react-select";
import {
  departmentOptions,
  categories,
  categoriesEverythingElse,
  sourceOptions,
  styleOptions,
  conditionOptions,
  ageOptions,
  colorOptions,
  countryOptions,
} from "./DepopDropdowns";
import BrandOptions from "./DepopBrands";
import brandOptions from "./DepopBrands";
import { Ring } from "@uiball/loaders";
import SizeOptions from "./DepopSizes";

console.log(SizeOptions);

const sizingTypeOptions = [
  { value: "US", label: "US" },
  { value: "UK", label: "UK" },
  { value: "EUR", label: "EUR" },
];

const shippingTypeOptions = [
  {
    value: "other",
    label:
      "Other, (Set a shipping price when listing. When you sell, ship however you like using a tracked method.)",
  },
  {
    value: "depop",
    label:
      "Depop Shipping (via USPS), (You’ll receive a pre-paid shipping label and a QR code when you sell. Use either of these to ship at your nearest USPS Post Office.)",
  },
];

const parcelSizeOptions = [
  { value: "XXS", label: "XXS ($4.49)" },
  { value: "XS", label: "XS ($5.49)" },
  { value: "S", label: "S ($6.29)" },
  { value: "M", label: "M ($7.99)" },
  { value: "L", label: "L ($10.99)" },
  { value: "XL", label: "XL ($13.49)" },
];

const worldWideShippingOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

function depop({
  data,
  changeDepopValueHandler,
  dataFetched,
  saveDataHandler,
}) {
  const values = {
    description: "",
    department: "",
    category: "",
    brand: "",
    condition: "",
    price: "",
    source: "",
    style: "",
    age: "",
    color: "",
    country: "",
    sizingType: "",
    size: "",
    shippingType: "",
    shippingPrice: "",
    packageSize: "",
    parcelSize: "",
  };
  let categoriesOptions = categories;
  let selectedBrand = null;
  let actualSizeOptions = [];
  if (data && data.depop && data.generalInfo) {
    values.description =
      "description" in data.depop
        ? data.depop.description
        : data.generalInfo.description;
    values.department = data.depop.department;
    values.category = data.depop.category;
    values.brand = data.depop.brand;
    values.condition = data.depop.condition;
    values.source = data.depop.source;
    values.style = data.depop.style;
    values.age = data.depop.age;
    values.color =
      "color" in data.depop ? data.depop.color : data.generalInfo.color;
    values.country = data.depop.country;
    values.price =
      "price" in data.depop ? data.depop.price : data.generalInfo.price;
    values.sizingType = data.depop.sizingType;
    values.shippingType = data.depop.shippingType || "other";
    values.shippingPrice = data.depop.shippingPrice;
    values.parcelSize = data.depop.parcelSize || "XS";
    if (!values.sizingType || values.sizingType == "") {
      values.sizingType = "US";
    }
    values.size = data.depop.size;

    if (values.department == "Everything else") {
      categoriesOptions = categoriesEverythingElse;
    }

    for (let i = 0; i < BrandOptions.length; i++) {
      if (BrandOptions[i].value == data.depop.brand) {
        selectedBrand = BrandOptions[i];
        break;
      }
    }
    if (!selectedBrand) {
      selectedBrand = {
        value: "Other",
        label: "Other",
      };
    }

    if (
      values.department &&
      values.category &&
      values.sizingType &&
      values.sizingType != "" &&
      values.category != "" &&
      values.department != ""
    ) {
      if (
        values.department === "Kids" ||
        values.department === "Everything else"
      ) {
        actualSizeOptions = [{ value: "One Size", label: "One Size" }];
      } else {
        actualSizeOptions =
          SizeOptions[values.department][values.category][values.sizingType];
      }
    }
  }
  return (
    <>
      <div className="grid grid-cols-12 gap-x-3">
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Description
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Descriptionof the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <textarea
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="description"
              value={values.description}
              onChange={(e) => {
                changeDepopValueHandler("description", e.target.value);
              }}
              onBlur={saveDataHandler}
              placeholder="Enter description"
              // onBlur={onBlur}
              required
            />
          </div>
          {values.description == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Department
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the Department of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={departmentOptions}
              placeholder="Select department"
              value={
                values.department &&
                departmentOptions.find((option) => {
                  return option.value === values.department;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("department", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.department == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the Category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={categoriesOptions}
              placeholder="Select category"
              value={
                values.category &&
                categoriesOptions.find((option) => {
                  return option.value === values.category;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("category", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.category == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Sizing Type
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the sizing type of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={sizingTypeOptions}
              placeholder="Select sizing type"
              value={
                values.sizingType &&
                sizingTypeOptions.find((option) => {
                  return option.value === values.sizingType;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("sizingType", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.sizingType == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Size
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the size of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={actualSizeOptions}
              placeholder="Select size"
              value={
                values.size &&
                actualSizeOptions.find((option) => {
                  return (
                    option.value.toLowerCase() === values.size.toLowerCase()
                  );
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("size", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.size == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Brand
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the Brand of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={brandOptions}
              placeholder="Select brand"
              value={selectedBrand}
              onChange={(selectedOption) => {
                changeDepopValueHandler("brand", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.brand == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Condition
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the Condition of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={conditionOptions}
              placeholder="Select brand"
              value={
                values.condition &&
                conditionOptions.find((option) => {
                  return option.value === values.condition;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("condition", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.condition == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Source
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the Source of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={sourceOptions}
              placeholder="Select brand"
              value={
                values.source &&
                sourceOptions.find((option) => {
                  return option.value === values.source;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("source", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.source == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Age
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the age of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={ageOptions}
              placeholder="Select brand"
              value={
                values.age &&
                ageOptions.find((option) => {
                  return option.value === values.age;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("age", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.age == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Style
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the style of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={styleOptions}
              placeholder="Select brand"
              value={
                values.style &&
                styleOptions.find((option) => {
                  return option.value === values.style;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("style", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.style == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Color
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the color of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={colorOptions}
              placeholder="Select color"
              value={
                values.color &&
                colorOptions.find((option) => {
                  return option.value === values.color;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("color", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.color == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Country
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the country
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={countryOptions}
              placeholder="Select color"
              value={
                values.country &&
                countryOptions.find((option) => {
                  return option.value === values.country;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("country", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.country == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Price of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="description"
              value={values.price}
              onChange={(e) => {
                changeDepopValueHandler("price", e.target.value);
              }}
              onBlur={saveDataHandler}
              placeholder="Enter price"
              // onBlur={onBlur}
              required
            />
          </div>
          {values.price == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Shipping Type
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Please select the shipping type
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={shippingTypeOptions}
              placeholder="Please select shipping type"
              value={
                values.shippingType &&
                shippingTypeOptions.find((option) => {
                  return option.value === values.shippingType;
                })
              }
              onChange={(selectedOption) => {
                changeDepopValueHandler("shippingType", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.price == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.shippingType == "other" && (
          <div
            className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Shipping Price
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Shipping price of the product
              </label>
            </div>
            <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
                name="description"
                value={values.shippingPrice}
                onChange={(e) => {
                  changeDepopValueHandler("shippingPrice", e.target.value);
                }}
                onBlur={saveDataHandler}
                placeholder="Enter shipping price"
                // onBlur={onBlur}
                required
              />
            </div>
            {values.price == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
        {values.shippingType == "depop" && (
          <div
            className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Parcel Size
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Parcel size of the product
              </label>
            </div>
            <div className="w-3/5 my-auto relative">
              <Select
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                options={parcelSizeOptions}
                placeholder="Please select parcel size"
                value={
                  values.parcelSize &&
                  parcelSizeOptions.find((option) => {
                    return option.value === values.parcelSize;
                  })
                }
                onChange={(selectedOption) => {
                  changeDepopValueHandler("parcelSize", selectedOption.value);
                }}
                onBlur={saveDataHandler}
                // onChange={onChange}
              />
            </div>
            {values.price == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default depop;
