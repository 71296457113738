import React, { useState, useEffect } from "react";
import {
  PlatformConStatus,
  RecentListing,
  ConnectionModal,
  Chart,
} from "../components/Dashboard";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { connect } from "react-redux";
import { getUserInfo } from "../redux/actions/auth";
import ShopifyConnectionModal from "../components/Dashboard/ShopifyConnectionModal";
import platformList from "../Utils/platformList";

export const Dashboard = (props) => {
  const {
    connectedPlatforms,
    getUserInfo,
    connectionStatus,
    setConnectionStatus,
    connectingStatus,
    setConnectingStatus,
  } = props;
  const navigate = useNavigate();

  const [modalStatus, setModalStatus] = useState({
    status: false,
    platform: null,
  });

  console.log(modalStatus);

  useEffect(() => {
    if (connectedPlatforms) {
      connectedPlatforms.forEach((item) => {
        setConnectionStatus((prev) => ({
          ...prev,
          [item.platform]: item.status,
        }));
      });
    }
  }, [connectedPlatforms]);

  // useEffect(() => {
  //   if (!modalStatus.status) {
  //     getUserInfo()
  //   }
  // }, [modalStatus.status])

  //animation
  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className="py-4 w-full fade-in overflow-y-auto min-h-screen relative">
      <div className="flex-1 px-7  w-full flex items-center justify-center sm:items-stretch sm:justify-between  pb-[5.9px]">
        <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
          <p className="title fade-in">Inventory</p>
        </div>
        <div className="flex item-center gap-x-5">
          <button
            onClick={() => {
              navigate("staging");
            }}
            className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
          >
            <BiPlus className="h-4 w-4 mt-1 text-white" />
            Add product
          </button>
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.15px] mt-3" />

      <div className="px-7 py-5 w-full">
        <div className="">
          <p className="text-lg text-gray-800 font-semibold text-Inter">
            Connect your stores
          </p>
          <p className="text-sm text-secondarySupport text-Inter">
            Manage your team members and their account permissions here.
          </p>
        </div>

        <div className="mt-5 flex item-center gap-x-4">
          {platformList.map((item, index) => {
            // console.log(item);
            return (
              <PlatformConStatus
                item={item}
                key={index}
                connectionStatus={connectionStatus}
                setConnectionStatus={setConnectionStatus}
                setModalStatus={setModalStatus}
                connectingStatus={connectingStatus}
                setConnectingStatus={setConnectingStatus}
              />
            );
          })}
        </div>
      </div>
      <div>
        <Chart />
      </div>
      <div>
        <RecentListing />
      </div>

      {modalStatus.platform !== "shopify" && modalStatus.status && (
        <ConnectionModal
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          connectingStatus={connectingStatus}
          setConnectingStatus={setConnectingStatus}
        />
      )}
      {(modalStatus.platform === "shopify" ||
        modalStatus.platform === "alliwant") &&
        modalStatus.status && (
          <ShopifyConnectionModal
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            connectingStatus={connectingStatus}
            setConnectingStatus={setConnectingStatus}
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  connectedPlatforms: state.auth.connectedPlatforms,
});

const mapDispatchToProps = { getUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
