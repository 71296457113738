import React from "react";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js"; // Import TimeScale
import Chart from "chart.js/auto";
import { connect } from "react-redux";

// Register TimeScale
Chart.register(CategoryScale);
const month = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];
function countListingsMonthly(inventoryData, year) {
    const monthlyCounts = {};
    const startDate = new Date(`${year}-01-01`);
    const endDate = new Date(`${year}-12-31`);

    for (
        let date = new Date(startDate);
        date <= endDate;
        date.setMonth(date.getMonth() + 1)
    ) {
        const monthYear = `${date.getFullYear()}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}`;
        monthlyCounts[month[+monthYear.split("-")[1] - 1]] = 0;
    }

    inventoryData.forEach((inventory) => {
        const createdAt = new Date(inventory.createdAt);
        if (createdAt.getFullYear() === year) {
            const monthYear = `${createdAt.getFullYear()}-${String(
                createdAt.getMonth() + 1
            ).padStart(2, "0")}`;
            const listedCount =
                inventory.listings.filter((listing) => {
                    const tasks = listing.task;
                    if (tasks?.length > 0) {
                        const latestTask = tasks[tasks.length - 1];
                        console.log("Latest task", "latestTask", latestTask);
                        if (
                            (latestTask.status === "completed" &&
                                latestTask.actionType === "create") ||
                            (latestTask.status === "failed" &&
                                latestTask.actionType === "delete")
                        ) {
                            return true;
                        }
                    }
                    return false;
                }).length > 0;
            monthlyCounts[month[+monthYear.split("-")[1] - 1]] += listedCount;
        }
    });
    console.log(monthlyCounts);
    console.log("monthlyCounts", monthlyCounts);
    return monthlyCounts;
}

function countDraftMonthly(inventoryData, year) {
    const monthlyCounts = {};
    const startDate = new Date(`${year}-01-01`);
    const endDate = new Date(`${year}-12-31`);

    for (
        let date = new Date(startDate);
        date <= endDate;
        date.setMonth(date.getMonth() + 1)
    ) {
        const monthYear = `${date.getFullYear()}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}`;
        monthlyCounts[month[+monthYear.split("-")[1] - 1]] = 0;
    }

    inventoryData.forEach((inventory) => {
        const createdAt = new Date(inventory.createdAt);
        if (createdAt.getFullYear() === year) {
            const monthYear = `${createdAt.getFullYear()}-${String(
                createdAt.getMonth() + 1
            ).padStart(2, "0")}`;
            const listedCount =
                inventory.listings.filter((listing) => {
                    const tasks = listing.task;
                    if (tasks?.length > 0) {
                        const latestTask = tasks[tasks.length - 1];
                        console.log("Latest task", "latestTask", latestTask);
                        if (
                            (latestTask.status === "completed" &&
                                latestTask.actionType === "create") ||
                            (latestTask.status === "failed" &&
                                latestTask.actionType === "delete")
                        ) {
                            return true;
                        }
                    }
                    return false;
                }).length === 0;
            monthlyCounts[month[+monthYear.split("-")[1] - 1]] += listedCount;
        }
    });
    console.log(monthlyCounts);
    return monthlyCounts;
}
function countListingMonthly(inventoryData, year) {
    const monthlyCounts = {};
    const startDate = new Date(`${year}-01-01`);
    const endDate = new Date(`${year}-12-31`);

    for (
        let date = new Date(startDate);
        date <= endDate;
        date.setMonth(date.getMonth() + 1)
    ) {
        const monthYear = `${date.getFullYear()}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}`;
        monthlyCounts[month[+monthYear.split("-")[1] - 1]] = 0;
    }

    inventoryData.forEach((inventory) => {
        const createdAt = new Date(inventory.createdAt);
        if (createdAt.getFullYear() === year) {
            const monthYear = `${createdAt.getFullYear()}-${String(
                createdAt.getMonth() + 1
            ).padStart(2, "0")}`;
            const listedCount = inventory.listings.filter((listing) => {
                const tasks = listing.task;
                if (tasks?.length > 0) {
                    const latestTask = tasks[tasks.length - 1];
                    console.log("Latest task", "latestTask", latestTask);
                    if (
                        (latestTask.status === "completed" &&
                            latestTask.actionType === "create") ||
                        (latestTask.status === "failed" &&
                            latestTask.actionType === "delete")
                    ) {
                        return true;
                    }
                }
                return false;
            }).length;
            monthlyCounts[month[+monthYear.split("-")[1] - 1]] += listedCount;
        }
    });
    console.log(monthlyCounts);
    return monthlyCounts;
}
function countUnListedMonthly(inventoryData, year) {
    const monthlyCounts = {};
    const startDate = new Date(`${year}-01-01`);
    const endDate = new Date(`${year}-12-31`);
    for (
        let date = new Date(startDate);
        date <= endDate;
        date.setMonth(date.getMonth() + 1)
    ) {
        const monthYear = `${date.getFullYear()}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}`;
        monthlyCounts[month[+monthYear.split("-")[1] - 1]] = 0;
    }

    inventoryData.forEach((inventory) => {
        const createdAt = new Date(inventory.createdAt);
        if (createdAt.getFullYear() === year) {
            const monthYear = `${createdAt.getFullYear()}-${String(
                createdAt.getMonth() + 1
            ).padStart(2, "0")}`;
            const listedCount = inventory.listings.filter((listing) => {
                const tasks = listing.task;
                if (tasks?.length > 0) {
                    const latestTask = tasks[tasks.length - 1];
                    console.log("Latest task", "latestTask", latestTask);
                    if (
                        (latestTask.status === "completed" &&
                            latestTask.actionType === "create") ||
                        (latestTask.status === "failed" &&
                            latestTask.actionType === "delete")
                    ) {
                        return true;
                    }
                }
                return false;
            }).length;
            monthlyCounts[month[+monthYear.split("-")[1] - 1]] += listedCount;
        }
    });
    console.log(monthlyCounts);
    return monthlyCounts;
}

const LineChart = (props) => {
    const { inventoryData } = props;
    const [selectedYear, setSelectedYear] = React.useState(
        new Date().getFullYear()
    );
    const [formattedData, setFormattedData] = React.useState({
        labels: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
        ],
        datasets: [
            {
                label: "Active",
                borderColor: "rgba(75, 192, 192, 1)",
                fill: false,
                hidden: false,
            },
            {
                label: "Draft",
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
                hidden: true,
            },
            {
                label: "Listings",
                borderColor: "rgba(255, 206, 86, 1)",
                fill: false,
                hidden: true,
            },
            {
                label: "Unlistings",
                borderColor: "rgba(54, 162, 235, 1)",
                fill: false,
                hidden: true,
            },
        ],
    });

    React.useEffect(() => {
        if (inventoryData?.length > 0) {
            setFormattedData({
                labels: [
                    "Jan",
                    "Feb",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "Aug",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                datasets: [
                    {
                        label: "Active",
                        data: countListingsMonthly(inventoryData, selectedYear),
                        borderColor: "rgba(75, 192, 192, 1)",
                        fill: false,
                        hidden: false,
                    },
                    {
                        label: "Draft",
                        data: countDraftMonthly(inventoryData, selectedYear),
                        borderColor: "rgba(255, 99, 132, 1)",
                        fill: false,
                        hidden: true,
                    },
                    {
                        label: "Listings",
                        data: countListingMonthly(inventoryData, selectedYear),
                        borderColor: "rgba(255, 206, 86, 1)",
                        fill: false,
                        hidden: true,
                    },
                    {
                        label: "Unlistings",
                        data: countUnListedMonthly(inventoryData, selectedYear),
                        borderColor: "rgba(54, 162, 235, 1)",
                        fill: false,
                        hidden: true,
                    },
                ],
            });
        }
    }, [inventoryData, selectedYear]);
    const options = {
        maintainAspectRatio: false,
        scales: {
            //   x: {
            //     type: 'time',
            //   },
            y: {
                beginAtZero: true,
                type: "linear",
                ticks: {
                    stepSize: 1,
                    callback: function (value) {
                        return Number.isInteger(value) ? value : "";
                    },
                },
            },
        },
    };
    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    return (
        <div className="w-full px-8 fade-in animate-fade-in mb-4">
            <div className="w-full px-5 pt-3 pb-1.5 border-[1px] border-[#EAECF0] rounded-lg relative">
                <div class="flex item-center justify-between ml-4 pb-2">
                    <div class="">
                        <p class="text-lg text-secondary font-semibold text-Inter">
                            Yearly inventory chart
                        </p>
                        <p class="text-sm text-secondarySupport text-Inter">
                            List of all your previous listings by year.
                        </p>
                    </div>
                </div>
                <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    className="ml-4 absolute absolute top-4 right-10"
                >
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                </select>

                <div className="p-2 mt-4 mb-4">
                    <Line data={formattedData} options={options} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    inventoryData: state.inventory.inventoryData,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(LineChart);
