import React from "react";
import { Ring } from "@uiball/loaders";

function shopify({
  data,
  changeShopifyValueHandler,
  dataFetched,
  saveDataHandler,
}) {
  const values = {
    title: "",
    description: "",
    tags: "",
    category: "",
    size: "",
    color: "",
    price: "",
    brand: "",
  };

  if (data && data.generalInfo && data.shopify) {
    values.title =
      "title" in data.shopify ? data.shopify.title : data.generalInfo.title;
    values.description =
      "description" in data.shopify
        ? data.shopify.description
        : data.generalInfo.description;
    values.tags =
      "tags" in data.shopify ? data.shopify.tags : data.generalInfo.tags;
    values.category = data.shopify.category;
    values.size = data.shopify.size;
    values.color =
      "color" in data.shopify ? data.shopify.color : data.generalInfo.color;
    values.price =
      "price" in data.shopify ? data.shopify.price : data.generalInfo.price;
    values.brand = data.shopify.brand;
    values.size = data.shopify.size;
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-x-3">
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Title
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Name of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.title}
              required
              onChange={(e) => {
                changeShopifyValueHandler("title", e.target.value);
              }}
            />
          </div>
          {values.title == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Description
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Description of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <textarea
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.description}
              required
              onChange={(e) => {
                changeShopifyValueHandler("description", e.target.value);
              }}
            />
          </div>
          {values.description == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Tags
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Tags of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.tags}
              required
              onChange={(e) => {
                changeShopifyValueHandler("tags", e.target.value);
              }}
            />
          </div>
          {values.tags == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Category of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.category}
              required
              onChange={(e) => {
                changeShopifyValueHandler("category", e.target.value);
              }}
            />
          </div>
          {values.category == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Color
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Color of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.color}
              required
              onChange={(e) => {
                changeShopifyValueHandler("color", e.target.value);
              }}
            />
          </div>
          {values.color == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Price of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.price}
              required
              onChange={(e) => {
                changeShopifyValueHandler("price", e.target.value);
              }}
            />
          </div>
          {values.price == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Brand
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Brand of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.brand}
              required
              onChange={(e) => {
                changeShopifyValueHandler("brand", e.target.value);
              }}
            />
          </div>
          {values.brand == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Size
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Size of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.size}
              required
              onChange={(e) => {
                changeShopifyValueHandler("size", e.target.value);
              }}
            />
          </div>
          {values.size == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default shopify;
