import instance from "./AxiosInstance/authenticated";
import { toast } from 'sonner'

export const getAllTask = () => async (dispatch) => {
    try {
        const { data, status } = await instance.get("/task/get-all-task");
        if (status === 200) {
            dispatch({
                type: 'GET_ALL_TASKS', payload: data.data
            })
            // toast.success("Credential saved successfully!")
            return true;
        }
    } catch (error) {
        console.log(error, "Error in create listing");
        // toast.error("Something went wrong!")
        return false;
    }
};