import React, { useState, useEffect } from "react";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { useLocation } from "react-router-dom";
import platformList from "../../Utils/platformList";
import Kidizen from "../../components/staging/PlatformForms/kidizen";
import Depop from "../../components/staging/PlatformForms/depop";
import Poshmark from "../../components/staging/PlatformForms/poshmark";
import Shopify from "../../components/staging/PlatformForms/shopify";
import Alliwant from "../../components/staging/PlatformForms/Alliwant";
import Mercari from "../../components/staging/PlatformForms/mercari";
import { toast } from "sonner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import PlatformPostModal from "../../components/PlatformPostModal";
import { Modal } from "../../components/Modal";
import { useDropzone } from "react-dropzone";
import { Ring } from "@uiball/loaders";
import AutenticatedInsatnce from "../../redux/actions/AxiosInstance/authenticated";
import { useNavigate } from "react-router-dom";
import kidizen from "../../components/staging/PlatformForms/kidizen";

function SecondStaging() {
  const [currentSelectedPlatform, setcurrentSelectedPlatform] =
    useState("alliwant");
  const location = useLocation();
  const [inventoryData, setInventoryData] = useState({});
  const [images, setImages] = useState({
    kidizen: [],
    depop: [],
    poshmark: [],
    shopify: [],
    mercari: [],
    general: [],
    alliwant: [],
  });
  const [kdizenDataFetched, setKidizenDataFetched] = useState(false);
  const [depopDataFetched, setDepopDataFetched] = useState(false);
  const [poshmarkDataFetched, setPoshmarkDataFetched] = useState(false);
  const [shopifyDataFetched, setShopifyDataFetched] = useState(false);
  const [mercariDataFetched, setMercariDataFetched] = useState(false);
  const [alliwantDataFetched, setAlliwantDataFetched] = useState(false);
  const [platformPostModalStatus, setPlatformPostModalStatus] = useState(false);
  const [imageUploadingModal, setImageUploadingModal] = useState(false);
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const navigate = useNavigate();

  const [readyToPublish, setReadyToPublish] = useState({
    kidizen: false,
    depop: false,
    poshmark: false,
    shopify: false,
    mercari: false,
    alliwant: false,
  });
  const [published, setPublished] = useState({
    kidizen: false,
    poshmark: false,
    mercari: false,
    depop: false,
    shopify: false,
    alliwant: false,
  });

  const inventoryId = location.pathname.split("/")[2];

  const onDrop = async (acceptedFiles) => {
    try {
      if (acceptedFiles.length === 0) {
        toast.error("no images selected");
        return;
      }
      if (acceptedFiles.length + images[currentSelectedPlatform].length > 4) {
        toast.error("maximum 4 images are allowed");
        return;
      }

      setImageUploadingModal(true);

      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("inventoryId", inventoryId);
      formData.append("platform", currentSelectedPlatform);

      const response = await AutenticatedInsatnce.post(
        "/inventory/uploadPlatformImage",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      console.log(response.data);

      setImages(response.data.images);

      setImageUploadingModal(false);
    } catch (err) {
      setImageUploadingModal(false);
      toast.error(err.response.data.message || "Something went wrong");
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true, // Allow multiple file uploads
  });

  const deleteImageHandler = async (image) => {
    try {
      setDeleteImageModal(true);
      const response = await authenticatedInstance.post(
        "/inventory/deletePlatformImage",
        {
          inventoryId,
          platform: currentSelectedPlatform,
          image: image,
        }
      );
      setDeleteImageModal(false);
      toast.success(
        "Image deleted successfully for platform" + currentSelectedPlatform
      );
      setImages(response.data.images);
    } catch (err) {
      setDeleteImageModal(false);
      toast.error(err.response.data.message || "unable to delete image");
    }
  };

  const fecthData = async () => {
    try {
      let response = await authenticatedInstance.get(
        `/inventory/getInventory/${inventoryId}`
      );
      // console.log(response.data.data);
      const data = response.data.data;

      if (data.kidizen) {
        setKidizenDataFetched(true);
      }
      if (data.depop) {
        setDepopDataFetched(true);
      }
      if (data.poshmark) {
        setPoshmarkDataFetched(true);
      }
      if (data.shopify) {
        setShopifyDataFetched(true);
      }
      if (data.mercari) {
        setMercariDataFetched(true);
      }
      if (data.alliwant) {
        setAlliwantDataFetched(true);
      }
      console.log(data);
      setInventoryData(data);
      setImages(response.data.images);

      return { status: true, result: response.data.data };
    } catch (e) {
      toast.error("Error fetching inventory data");
      return { status: false, result: null };
    }
  };

  useEffect(() => {
    const fetchPublishedPlatforms = async () => {
      try {
        let response = await authenticatedInstance.get(
          `/inventory/getPublishedPlatforms/${inventoryId}`
        );

        const publishedPlatforms = response.data.data;
        for (let key in publishedPlatforms) {
          if (!publishedPlatforms[key]) {
            setcurrentSelectedPlatform(key);
            break;
          }
        }

        setPublished(publishedPlatforms);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPublishedPlatforms();
  }, []);

  useEffect(() => {
    fecthData();
    let interval = setInterval(async () => {
      try {
        let { result, status } = await fecthData();
        if (!status) {
          clearInterval(interval);
        }
        if (
          result.kidizen &&
          result.depop &&
          result.poshmark &&
          result.shopify &&
          result.mercari &&
          result.generalInfo &&
          result.alliwant
        ) {
          clearInterval(interval);
        }
      } catch (err) {
        toast.error("Error fetching inventory data");
        clearInterval(interval);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const validationHandler = (data) => {
    let platformStatuses = {
      kidizen: false,
      depop: false,
      poshmark: false,
      shopify: false,
      mercari: false,
      alliwant: false,
    };
    let keysNeedsToBeFilled = {
      kidizen: [],
      depop: [],
      poshmark: [],
      shopify: [],
      mercari: [],
      alliwant: [],
    };
    // console.log(data);
    if (data.kidizen) {
      let isKidizenReady = true;
      for (let key in data.kidizen) {
        if (!data.kidizen[key] || data.kidizen[key] === "") {
          isKidizenReady = false;
          keysNeedsToBeFilled.kidizen.push(key);
        }
        if (key == "description" && data.kidizen[key]) {
          if (data.kidizen[key].split(" ").length < 5) {
            isKidizenReady = false;
            keysNeedsToBeFilled.kidizen.push(key);
          }
        }
      }
      platformStatuses.kidizen = isKidizenReady;
    }
    if (data.depop) {
      let isDepopReady = true;
      for (let key in data.depop) {
        if (key != "shippingPrice") {
          if (!data.depop[key] || data.depop[key] === "") {
            isDepopReady = false;
            keysNeedsToBeFilled.depop.push(key);
          }
          if (key == "description" && data.depop[key]) {
            if (data.depop[key].split(" ").length < 5) {
              isDepopReady = false;
              keysNeedsToBeFilled.depop.push(key);
            }
          }
        }
      }
      platformStatuses.depop = isDepopReady;
    }
    if (data.poshmark) {
      let isPoshmarkReady = true;
      for (let key in data.poshmark) {
        if (!data.poshmark[key] || data.poshmark[key] === "") {
          isPoshmarkReady = false;
          keysNeedsToBeFilled.poshmark.push(key);
        }
        if (key == "description" && data.poshmark[key]) {
          if (data.poshmark[key].split(" ").length < 5) {
            isPoshmarkReady = false;
            keysNeedsToBeFilled.poshmark.push(key);
          }
        }
      }
      platformStatuses.poshmark = isPoshmarkReady;
    }
    if (data.shopify) {
      let isShopifyReady = true;
      for (let key in data.shopify) {
        if (!data.shopify[key] || data.shopify[key] === "") {
          isShopifyReady = false;
          keysNeedsToBeFilled.shopify.push(key);
        }
        if (key == "description" && data.shopify[key]) {
          if (data.shopify[key].split(" ").length < 5) {
            isShopifyReady = false;
            keysNeedsToBeFilled.shopify.push(key);
          }
        }
      }
      platformStatuses.shopify = isShopifyReady;
    }
    if (data.mercari) {
      let isMercariReady = true;
      for (let key in data.mercari) {
        if (!data.mercari[key] || data.mercari[key] === "") {
          if (key != "minimumPrice") {
            console.log(key, "line 311");
            isMercariReady = false;
            keysNeedsToBeFilled.mercari.push(key);
          }
        }
        if (key == "description" && data.mercari[key]) {
          if (data.mercari[key].split(" ").length < 5) {
            isMercariReady = false;
            keysNeedsToBeFilled.mercari.push(key);
          }
        }
      }
      if (data.mercari["smartPricing"] == "on") {
        if (!data.mercari["minimumPrice"]) {
          isMercariReady = false;
          keysNeedsToBeFilled.mercari.push("Minimum Price");
        } else if (data.mercari["minimumPrice"] && data.mercari["price"]) {
          if (
            parseInt(data.mercari["minimumPrice"]) >
            parseInt(data.mercari["price"])
          ) {
            isMercariReady = false;
            keysNeedsToBeFilled.mercari.push(
              "Price should be greater than Minimum Price"
            );
          }
        }
      }
      platformStatuses.mercari = isMercariReady;
    }
    if (data.alliwant) {
      let isAlliwantReady = true;
      console.log(data.alliwant.quantity, "validation");
      if (!data.alliwant.quantity || data.alliwant.quantity === "0") {
        keysNeedsToBeFilled.alliwant.push("quantity");
        isAlliwantReady = false;
      }
      for (let key in data.alliwant) {
        if (
          key != "pay_shipping_by" &&
          key != "shipping_type" &&
          key != "packagesize" &&
          key != "shipping_carrier" &&
          key != "is_brand_filter" &&
          key != "is_size_filter" &&
          key != "is_color_filter" &&
          key != "sizegroup" &&
          key != "size" &&
          key != "brand" &&
          key != "color" &&
          key != "pickup_distance" &&
          key != "negotiable_percentage" &&
          key != "quantity"
        ) {
          if (!data.alliwant[key] || data.alliwant[key] === "") {
            isAlliwantReady = false;
            keysNeedsToBeFilled.alliwant.push(key);
          }
          if (key == "description" && data.alliwant[key]) {
            if (data.alliwant[key].split(" ").length < 5) {
              isAlliwantReady = false;
              keysNeedsToBeFilled.alliwant.push(key);
            }
          }
        }
      }
      if (data.alliwant["is_brand_filter"] == "1") {
        if (!data.alliwant["brand"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Brand");
        }
      }

      if (data.alliwant["is_size_filter"] == "1") {
        if (!data.alliwant["size"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Size");
        }
      }

      if (data.alliwant["is_color_filter"] == "1") {
        if (!data.alliwant["color"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Color");
        }
      }

      if (data.alliwant["is_negotiable"] == "yes") {
        if (!data.alliwant["negotiable_percentage"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Negotiable Percentage");
        }
      }

      if (
        data.alliwant["deliveryMethod"] != "1" &&
        data.alliwant["itemType"] == "2"
      ) {
        if (!data.alliwant["pay_shipping_by"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Pay Shipping By");
        }
        if (!data.alliwant["shipping_type"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Shipping Type");
        }
      }
      if (
        data.alliwant["deliveryMethod"] != "1" &&
        data.alliwant["shipping_type"] == "1" &&
        data.alliwant["itemType"] == "2"
      ) {
        if (!data.alliwant["packagesize"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Package Size");
        }
        if (!data.alliwant["shipping_carrier"]) {
          isAlliwantReady = false;
          keysNeedsToBeFilled.alliwant.push("Shipping Carrier");
        }
      }

      platformStatuses.alliwant = isAlliwantReady;
    }
    if (!data.generalInfo) {
      for (let key in platformStatuses) {
        platformStatuses[key] = false;
      }
    } else {
      let isGeneralReady = true;
      for (let key in data.generalInfo) {
        if (!data.generalInfo[key] || data.generalInfo[key] === "") {
          isGeneralReady = false;
        }
        if (key == "description" && data.generalInfo[key]) {
          if (data.generalInfo[key].split(" ").length < 5) {
            isGeneralReady = false;
            // check for all platforms
            if (
              data.kidizen &&
              !data.kidizen.description &&
              !published.kidizen
            ) {
              keysNeedsToBeFilled.kidizen.push(
                "description (at least 5 words)"
              );
            }
            if (data.depop && !data.depop.description && !published.depop) {
              keysNeedsToBeFilled.depop.push("description (at least 5 words)");
            }
            if (
              data.poshmark &&
              !data.poshmark.description &&
              !published.poshmark
            ) {
              keysNeedsToBeFilled.poshmark.push(
                "description (at least 5 words)"
              );
            }
            if (
              data.shopify &&
              !data.shopify.description &&
              !published.shopify
            ) {
              keysNeedsToBeFilled.shopify.push(
                "description (at least 5 words)"
              );
            }
            if (
              data.mercari &&
              !data.mercari.description &&
              !published.mercari
            ) {
              keysNeedsToBeFilled.mercari.push(
                "description (at least 5 words)"
              );
            }
            if (
              data.alliwant &&
              !data.alliwant.description &&
              !published.alliwant
            ) {
              keysNeedsToBeFilled.alliwant.push(
                "description (at least 5 words)"
              );
            }
            // keysNeedsToBeFilled.kidizen.push(key);
          }
        }
      }
      if (!isGeneralReady) {
        for (let key in platformStatuses) {
          platformStatuses[key] = false;
        }
      }
    }
    return {
      platformStatuses: { ...platformStatuses },
      keysNeedsToBeFilled: { ...keysNeedsToBeFilled },
    };
  };

  useEffect(() => {
    // console.log("checking ready to publish");
    setReadyToPublish((prev) => {
      return validationHandler({ ...inventoryData }).platformStatuses;
    });
  }, [inventoryData]);

  const changeKidizenValueHandler = (field, val) => {
    if (kdizenDataFetched) {
      setInventoryData((prev) => {
        let newKidizen = { ...prev.kidizen };
        newKidizen[field] = val;
        if (field === "category") {
          newKidizen["subcategory"] = null;
          newKidizen["gender"] = null;
          newKidizen["size"] = null;
        }
        return {
          ...prev,
          kidizen: {
            ...newKidizen,
          },
        };
      });
    }
  };

  const changeDepopValueHandler = (field, val) => {
    if (depopDataFetched) {
      console.log(field, val);
      setInventoryData((prv) => {
        let newDepop = { ...prv.depop };
        newDepop[field] = val;
        if (field == "department") {
          newDepop["category"] = null;
          newDepop["size"] = null;
        }
        if (field == "category") {
          newDepop["size"] = null;
        }
        if (field == "sizingType") {
          newDepop["size"] = null;
        }
        return {
          ...prv,
          depop: {
            ...newDepop,
          },
        };
      });
    }
  };

  const changePoshmarkValueHandler = (field, val) => {
    if (poshmarkDataFetched) {
      setInventoryData((prv) => {
        let newPoshmark = { ...prv.poshmark };
        newPoshmark[field] = val;
        if (field === "department") {
          newPoshmark["category"] = null;
          newPoshmark["size"] = null;
        }
        if (field === "sizingType") {
          newPoshmark["size"] = null;
        }
        if (field === "category") {
          newPoshmark["size"] = null;
        }
        return {
          ...prv,
          poshmark: {
            ...newPoshmark,
          },
        };
      });
    }
  };

  const changeMercaiValueHandler = (field, val) => {
    // console.log(field, val);
    if (mercariDataFetched) {
      setInventoryData((prv) => {
        let newMercari = { ...prv.mercari };
        newMercari[field] = val;
        return {
          ...prv,
          mercari: {
            ...newMercari,
          },
        };
      });
    }
  };

  const changeShopifyValueHandler = (field, val) => {
    if (shopifyDataFetched) {
      setInventoryData((prv) => {
        let newShopify = { ...prv.shopify };
        newShopify[field] = val;
        return {
          ...prv,
          shopify: {
            ...newShopify,
          },
        };
      });
    }
  };

  const changeAlliwantValueHandler = (field, val) => {
    if (alliwantDataFetched) {
      console.log(field, val);
      if (field === "negotiable_percentage") {
        // remove starting 0 from the value
        if (val.length > 1 && val[0] === "0") {
          val = val.slice(1);
        }
      }
      setInventoryData((prv) => {
        let newAlliwant = { ...prv.alliwant };
        newAlliwant[field] = val;
        if (field === "category") {
          newAlliwant["subcategory"] = null;
          newAlliwant["childsubcategory"] = null;
          newAlliwant["is_brand_filter"] = "0";
          newAlliwant["is_size_filter"] = "0";
          newAlliwant["is_color_filter"] = "0";
          newAlliwant["size"] = null;
          newAlliwant["sizegroup"] = null;
        }
        if (field === "subcategory") {
          newAlliwant["childsubcategory"] = null;
          newAlliwant["is_brand_filter"] = "0";
          newAlliwant["is_size_filter"] = "0";
          newAlliwant["is_color_filter"] = "0";
          newAlliwant["size"] = null;
          newAlliwant["sizegroup"] = null;
        }
        if (field === "deliveryMethod") {
          newAlliwant["pay_shipping_by"] = null;
          newAlliwant["shipping_type"] = null;
        }
        if (field === "shipping_type") {
          newAlliwant["packagesize"] = null;
          newAlliwant["shipping_carrier"] = null;
        }
        if (field === "packagesize") {
          newAlliwant["shipping_carrier"] = null;
        }
        if (field === "childsubcategory") {
          newAlliwant["size"] = null;
          newAlliwant["sizegroup"] = null;
        }
        return {
          ...prv,
          alliwant: {
            ...newAlliwant,
          },
        };
      });
    }
  };

  const saveDataHandler = async () => {
    try {
      const { platformStatuses } = validationHandler({
        ...inventoryData,
      });

      if (
        !platformStatuses.kidizen ||
        !platformStatuses.depop ||
        !platformStatuses.poshmark ||
        !platformStatuses.shopify ||
        !platformStatuses.mercari ||
        !platformStatuses.alliwant
      ) {
        return;
      }

      // console.log("saving data", inventoryData);

      let response = await authenticatedInstance.post("/inventory/updateData", {
        inventoryId,
        data: inventoryData,
      });
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getEmptyValues = (platform) => {
    let { keysNeedsToBeFilled } = validationHandler(inventoryData);
    return keysNeedsToBeFilled[platform].join(", ");
  };

  const showPlatformSatuses = () => {
    for (let key in readyToPublish) {
      if (!readyToPublish[key] && !published[key]) {
        return true;
      }
    }
    return false;
  };

  let PlatformForm = <Kidizen />;
  switch (currentSelectedPlatform) {
    case "kidizen":
      PlatformForm = (
        <Kidizen
          changeKidizenValueHandler={changeKidizenValueHandler}
          images={images.kidizen}
          data={inventoryData}
          dataFetched={kdizenDataFetched}
          saveDataHandler={saveDataHandler}
        />
      );
      break;
    case "depop":
      PlatformForm = (
        <Depop
          images={images.depop}
          data={inventoryData}
          changeDepopValueHandler={changeDepopValueHandler}
          dataFetched={depopDataFetched}
          saveDataHandler={saveDataHandler}
        />
      );
      break;
    case "poshmark":
      PlatformForm = (
        <Poshmark
          images={images.poshmark}
          data={inventoryData}
          changePoshmarkValueHandler={changePoshmarkValueHandler}
          dataFetched={poshmarkDataFetched}
          saveDataHandler={saveDataHandler}
        />
      );
      break;
    case "shopify":
      PlatformForm = (
        <Shopify
          images={images.shopify}
          data={inventoryData}
          dataFetched={shopifyDataFetched}
          saveDataHandler={saveDataHandler}
          changeShopifyValueHandler={changeShopifyValueHandler}
        />
      );
      break;
    case "mercari":
      PlatformForm = (
        <Mercari
          changeMercaiValueHandler={changeMercaiValueHandler}
          images={images.mercari}
          data={inventoryData}
          dataFetched={mercariDataFetched}
          saveDataHandler={saveDataHandler}
        />
      );
      break;
    case "alliwant":
      PlatformForm = (
        <Alliwant
          dataFetched={alliwantDataFetched}
          changeAlliwantValueHandler={changeAlliwantValueHandler}
          data={inventoryData}
          saveDataHandler={saveDataHandler}
        ></Alliwant>
      );
      break;
    default:
      PlatformForm = (
        <Kidizen
          changeKidizenValueHandler={changeKidizenValueHandler}
          images={images.kidizen}
          data={inventoryData}
          dataFetched={kdizenDataFetched}
          saveDataHandler={saveDataHandler}
        />
      );
  }

  return (
    <div className="py-4 w-full fade-in overflow-y-auto min-h-screen relative">
      <div className="flex-1 px-7 w-full flex items-center justify-center sm:items-stretch sm:justify-between pb-[5.9px]">
        <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
          <p className="title fade-in">Staging</p>
        </div>
        <ol class="flex items-center justify-center flex-1 space-x-2  text-sm font-medium text-center text-gray-500  rounded-lg shadow-sm sm:text-base sm:space-x-4">
          <li class="flex items-center text-blue-600 dark:text-blue-500">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#FF4405"
                d="M5.66667 13.1663H12.3333M8.18141 1.30297L2.52949 5.6989C2.15168 5.99276 1.96278 6.13968 1.82669 6.32368C1.70614 6.48667 1.61633 6.67029 1.56169 6.86551C1.5 7.0859 1.5 7.32521 1.5 7.80384V13.833C1.5 14.7664 1.5 15.2331 1.68166 15.5896C1.84144 15.9032 2.09641 16.1582 2.41002 16.318C2.76654 16.4996 3.23325 16.4996 4.16667 16.4996H13.8333C14.7668 16.4996 15.2335 16.4996 15.59 16.318C15.9036 16.1582 16.1586 15.9032 16.3183 15.5896C16.5 15.2331 16.5 14.7664 16.5 13.833V7.80384C16.5 7.32521 16.5 7.0859 16.4383 6.86551C16.3837 6.67029 16.2939 6.48667 16.1733 6.32368C16.0372 6.13968 15.8483 5.99276 15.4705 5.69891L9.8186 1.30297C9.5258 1.07526 9.3794 0.961399 9.2178 0.917629C9.0752 0.879019 8.92484 0.879019 8.78221 0.917629C8.62057 0.961399 8.47418 1.07526 8.18141 1.30297Z"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              class="w-3 h-3 ml-2 sm:ml-4"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L5 5L1 1"
                stroke="#FF4405"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
          <li class="flex items-center text-primary">Staging</li>
        </ol>
        <div className="flex item-center gap-x-5">
          <button
            onClick={() => {
              if (
                published.kidizen ||
                published.depop ||
                published.poshmark ||
                published.shopify ||
                published.mercari
              ) {
                toast.error("cannot update general info of listed product");
                return;
              }

              navigate(`/staging/${inventoryId}`);
            }}
            className="btn-secondary flex item-center gap-x-1.5 text-Inter"
          >
            General Info
          </button>

          <button
            onClick={() => {
              saveDataHandler();
              setPlatformPostModalStatus(true);
            }}
            className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter cursor-pointer"
          >
            Next
          </button>
        </div>
      </div>
      <hr className="bg-gray-600 h-[1.15px] mt-3" />
      <div className="pl-5 flex">
        <div className="w-4/5 space-y-6 px-8">
          <div className="px-8">
            <div className="mt-2  flex justify-center item-center gap-x-4">
              {platformList.map((item, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      // if (published[item.title]) {
                      //   toast.success(
                      //     "The product is already published on this platform"
                      //   );
                      //   return;
                      // }
                      setcurrentSelectedPlatform(item.title);
                    }}
                    className={` px-2.5 py-1.5 
                                        ${
                                          currentSelectedPlatform ===
                                            item.title &&
                                          `border-b-4 border-primary bg-gray-50`
                                        } 
                                        ${
                                          published[item.title] &&
                                          "bg-green-50 border-2 border-green-300 shadow-md rounded-md"
                                        } }`}
                  >
                    <p className={`text-sm font-[500] text-center capitalize `}>
                      {published[item.title] && `Listed`}
                    </p>
                    {item.logo}
                    <p
                      className={`text-md font-[500] mt-2 text-center capitalize
                                         ${
                                           currentSelectedPlatform ===
                                             item.title && `text-primary`
                                         } 
                                        `}
                    >
                      {!published[item.title] && readyToPublish[item.title]
                        ? `✔️ ${item.title}`
                        : item.title}
                    </p>
                  </button>
                );
              })}
            </div>
            <hr className="bg-gray-100 h-[1.15px] mt-3" />
          </div>
          <div>
            <div className="space-y-6 py-5 px-8 fade-in">
              <div className="grid grid-cols-12 gap-x-3">
                <div className="flex items-center w-full col-span-11">
                  <div className="w-2/5">
                    <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                      Image
                    </p>
                    <label className="text-Inter text-gray-600 text-sm leading-snug">
                      Upload image of the product.
                    </label>
                  </div>
                  <div className="flex items-center text-center w-3/5 py-3">
                    <div className="flex flex-wrap gap-3">
                      {images[currentSelectedPlatform].map((img) => {
                        return (
                          <div
                            key={img.public_id}
                            className="relative w-32 h-32"
                          >
                            <img
                              src={img.url}
                              alt=""
                              className="object-cover w-full h-full rounded-lg"
                            />
                            <div
                              className="absolute top-0 right-0 p-1 cursor-pointer bg-white rounded-bl-lg"
                              onClick={(e) => {
                                e.preventDefault();
                                deleteImageHandler(img);
                              }}
                            >
                              <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.14412 6.4805C7.23218 6.56856 7.28166 6.688 7.28166 6.81253C7.28166 6.93707 7.23218 7.0565 7.14412 7.14456C7.05606 7.23262 6.93663 7.2821 6.81209 7.2821C6.68756 7.2821 6.56812 7.23262 6.48006 7.14456L3.99998 4.6637L1.51912 7.14378C1.43106 7.23184 1.31163 7.28131 1.18709 7.28131C1.06256 7.28131 0.943121 7.23184 0.855061 7.14378C0.767001 7.05572 0.717529 6.93629 0.717529 6.81175C0.717529 6.68721 0.767001 6.56778 0.855061 6.47972L3.33592 3.99964L0.855842 1.51878C0.767782 1.43072 0.718311 1.31129 0.718311 1.18675C0.718311 1.06221 0.767782 0.94278 0.855842 0.854719C0.943903 0.766659 1.06334 0.717187 1.18787 0.717187C1.31241 0.717187 1.43184 0.766659 1.51991 0.854719L3.99998 3.33558L6.48084 0.854329C6.5689 0.766268 6.68834 0.716797 6.81287 0.716797C6.93741 0.716797 7.05684 0.766268 7.14491 0.854329C7.23296 0.942389 7.28244 1.06182 7.28244 1.18636C7.28244 1.3109 7.23296 1.43033 7.14491 1.51839L4.66405 3.99964L7.14412 6.4805Z"
                                  fill="#E31B54"
                                />
                              </svg>
                            </div>
                          </div>
                        );
                      })}
                      {images[currentSelectedPlatform].length < 4 && (
                        <label
                          {...getRootProps()}
                          className="flex items-center justify-center w-32 h-32 border-2 border-slate-400 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
                        >
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            Add More
                          </p>
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {PlatformForm}
            </div>
          </div>
        </div>

        {showPlatformSatuses() && (
          <aside className="fixed -right-2 w-56 mt-10 rounded-lg bg-primarySupport px-2 py-2">
            <div className="group">
              <div className="flex items-center gap-x-2 cursor-pointer">
                <AiOutlineInfoCircle className="h-4 w-4" />
                <p className="text-Inter text-gray-800 font-[500] text-sm">
                  Platform Status
                </p>
              </div>
              <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible absolute bg-gray-800 text-white text-sm p-2 rounded-md mt-2">
                Columns need to be filled
              </div>
            </div>
            {platformList?.map((platform, idx) => {
              const result = getEmptyValues(platform?.title);
              if (result && result != "") {
                return (
                  <>
                    <hr key={idx} className="bg-slalte-200 h-[1.15px] mt-3" />
                    {
                      <div className="flex items-start gap-x-4 py-2">
                        <div className="px-2">{platform?.logo}</div>
                        <p className="text-xs flex-1 text-gray-700 font-[500]">
                          {result}
                        </p>
                      </div>
                    }
                  </>
                );
              }
              return false;
            })}
          </aside>
        )}
      </div>
      {platformPostModalStatus && (
        <PlatformPostModal
          setPlatformPostModalStatus={setPlatformPostModalStatus}
          readyToPublishPlatforms={readyToPublish}
          published={published}
          inventoryId={inventoryId}
        />
      )}

      {imageUploadingModal && (
        <Modal width={"w-1/4"}>
          <div className=" flex flex-col gap-2 justify-center items-center">
            <Ring speed={1} size={30} color="orange" />
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Uploading Images
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              Please wait while we upload your images.
            </p>
          </div>
        </Modal>
      )}
      {deleteImageModal && (
        <Modal width={"w-1/4"}>
          <div className=" flex flex-col gap-2 justify-center items-center">
            <Ring speed={1} size={30} color="orange" />
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Deleting Image
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              Please wait while we delete your images.
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default SecondStaging;
