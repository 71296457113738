import departments from "./PoshmarkDepartmets";

let sizes = {};
let countries = ["us", "au", "eu", "uk"];
for (let department of departments) {
  sizes[department.display] = {};

  for (let category of department.categories) {
    sizes[department.display][category.display] = {};
    for (let i = 0; i < 4; i++) {
      let val = countries[i];
      sizes[department.display][category.display][val] = [];

      for (let k = 0; k < category.size_sets.length; k++) {
        let sizeSet = category.size_sets[k];
        sizes[department.display][category.display][val].push({
          label: sizeSet.name,
          options: [],
        });

        for (let j = 0; j < sizeSet.size_systems[val].sizes.length; j++) {
          sizes[department.display][category.display][val][k].options.push({
            value: sizeSet.size_systems[val].sizes[j].display,
            label: sizeSet.size_systems[val].sizes[j].display,
            group: sizeSet.name,
          });
        }

        let options =
          sizes[department.display][category.display][val][k].options;
        let OneSizePresent = false;
        for (let option of options) {
          if (
            option.value === "One Size" ||
            option.value === "OneSize" ||
            option.value === "one size"
          ) {
            OneSizePresent = true;
            break;
          }
        }
        if (!OneSizePresent) {
          sizes[department.display][category.display][val][k].options.push({
            value: "One Size",
            label: "One Size",
            group: sizeSet.name,
          });
        }
      }
    }
  }
}

export default sizes;
