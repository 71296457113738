const initState = {
    addProductDetails: [],
    allTasks: [],
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'ADD_PRODUCT_DETAILS':
            return {
                ...state,
                addProductDetails: action.payload
            }
        case 'GET_ALL_TASKS':
            return {
                ...state,
                allTasks: action.payload
            }

        default:
            return state;

    }
}
