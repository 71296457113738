import { applyMiddleware, compose, createStore, } from "redux";
import rootReducer from "./reducers";
import thunk from 'redux-thunk'; 

const loadDevTools = () =>
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

const middleware = [thunk];

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middleware), loadDevTools())
);