import instance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const getAllWants = () => async (dispatch) => {
  try {
    const { data, status } = await instance.get("/wants/getAll");
    if (status === 200) {
      dispatch({ type: "GET_ALL_WANTS", payload: data });
    }
  } catch (error) {
    console.log(error, "Error in get settings");
    toast.error("Something went wrong!");
  }
};

export const createWants = (info) => async (dispatch) => {
  try {
    const { data, status } = await instance.post("/wants/create", info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (status === 201) {
      dispatch({ type: "CREATE_WANTS", payload: data });
      toast.success("Wants Created successfully!");
    }
  } catch (error) {
    console.log(error, "Error in create wants");
    toast.error("Something went wrong!");
  }
};

export const deleteWants = (id) => async (dispatch) => {
  try {
    const { data, status } = await instance.delete("/wants/deleteWant", {
      data: { id: id },
    });
    if (status === 200) {
      dispatch({ type: "DELETE_WANTS", payload: data });
      toast.success("Wants Deleted successfully!");
    }
  } catch (error) {
    console.log(error, "Error in delete wants");
    toast.error("Something went wrong!");
  }
};

export const updateWants = (info) => async (dispatch) => {
  try {
    // console.log(info);
    const { data, status } = await instance.post("/wants/updateWant", {
      info: info,
    });
    if (status === 200) {
      dispatch({ type: "UPDATE_WANTS", payload: data });
      toast.success("Wants Updated successfully!");
    }
  } catch (error) {
    console.log(error, "Error in update wants");
    toast.error("Something went wrong!");
  }
};
