import React from 'react'
import { connect } from 'react-redux'

export const SelectOption = (props) => {

  const {
    title,
    description,
    name,
    value,
    onChange,
    options,
    setSelectedDropdownId,
    selectedDropdownId,
    dropdownId,
    onBlur,
    required,
    className
  } = props

  return (
    <div className={`flex items-center w-full ${className}`}>
      <div className='w-2/5'>
        <div className='flex gap-1 item-center'>
          <p className='text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug'>{title}</p>
          {required && <p className='text-red-500'>*</p>}
        </div>
        <label className='text-Inter text-gray-600 text-sm leading-snug'>{description}</label>
      </div>
      <div className='w-3/5 my-auto relative'>
        <button
          type="button"
          onClick={() => {
            if (selectedDropdownId) {
              if (selectedDropdownId === dropdownId) {
                setSelectedDropdownId(null);
              } else {
                setSelectedDropdownId(dropdownId);
              }
            } else {
              setSelectedDropdownId(dropdownId);
            }
          }}
          className="flex w-full items-center justify-between rounded-lg bg-white p-2 ring-1 ring-gray-300"
        >
          <span className={`text-Inter text-sm font-[400] px-1 ${value ? `text-slate-800` : `text-slate-400`}`}>
            {value || "Select option"}
          </span>
        </button>
        {selectedDropdownId === dropdownId && (
          <ul
            className="p-1 z-20 absolute mt-2 w-full right-0 h-[auto] rounded bg-white ring-1 ring-gray-300 max-h-60 overflow-y-auto scrollbar-thin scrollbar-thumb-orange-400 scrollbar-track-orange-200 scrollbar-thumb-orange-400">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedDropdownId(null);
                  onChange(option);
                }}
                className="cursor-pointer text-Inter text-sm font-[400] select-none p-2 hover:bg-slate-100"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>

  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOption)