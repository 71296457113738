import React, { useState } from "react";
import { Modal } from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import instance from "../../redux/actions/AxiosInstance/unAuthenticated";

function ForgotPassword() {
  const navigate = useNavigate();
  const [sendModalStatus, setSendModalStatus] = useState(false);
  const [email, setEmail] = useState("");
  const sendEmail = async () => {
    try {
      const { data, status } = await instance.post("/user/forgot-password", {
        email: email,
      });
      if (status === 200) {
        if(sendModalStatus){
          
        }
        setSendModalStatus(true);
      }
    } catch (error) {
      console.log(error, "Error in get settings");
    }
  };

  return (
    <>
      <div className="grid place-content-center items-center h-screen">
        <div className="px-3 w-full max-w-md">
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] mt-4">Forgot password?</p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2">No worries, we’ll send you reset instructions.</p>
          <div className="mt-5">
            <label htmlFor="email" className="text-[#4E5674] font-[500] text-sm font-Inter">
              Your email
            </label>
            <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none outline-none w-full"
                type="text"
                name="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div className="flex justify-center items-center gap-x-4 mt-5">
            <button
              onClick={sendEmail}
              type="submit"
              className="text-[12px] text-sm w-full cursor-pointer font-semibold font-Inter px-5 rounded-lg py-2 text-white bg-[#2970FF] shadow-navButton hover:shadow-navButton border-[1px] border-[#2970FF] ">
              Recover password
            </button>
          </div>
          <p className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Back to log in
          </p>
        </div>
      </div>

      {sendModalStatus && (
        <Modal>
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] ">Check your email</p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2 w-1/2 mx-auto">
            We sent a password reset link to <span className="font-[500]">{email ? email : "colin@example.com"}</span>
          </p>

          <button
            onClick={() => {
              navigate("/reset-password");
            }}
            type="submit"
            className="font-semibold w-2/3 mx-auto font-Inter text-sm mt-8 mb-2 block  rounded-lg transition duration-200 ease-in bg-[#2970FF] hover:bg-[#2970FF] py-2.5 text-white">
            Open email app
          </button>

          <p className="font-Inter text-center text-sm text-[#475467] mt-4  mx-auto">
            Didn’t receive the email?{" "}
            <span className="font-[500] text-[#6941C6] cursor-pointer" onClick={sendEmail}>
              Click to resend
            </span>
          </p>

          <p
            onClick={() => {
              navigate("/signin");
            }}
            className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-4 flex items-center justify-center gap-x-3">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Back to log in
          </p>
        </Modal>
      )}
    </>
  );
}

export default ForgotPassword;
