import React, { useState, useEffect } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Ring } from "@uiball/loaders";
import {
  categories,
  subcategories,
  childsubcategories,
  colorOptions,
  itemTypes,
  conditionOptions,
  deliveryMethods,
} from "./AlliwantDropdowns";
import AuthenticatedInstance from "../../../redux/actions/AxiosInstance/authenticated";
import debounce from "debounce-promise";
import AddressModal from "./AddressModal";
import { toast } from "sonner";

let payShippingByOptions = [
  { value: "1", label: "Buyer" },
  { value: "2", label: "Seller" },
];

let shippingTypeOptions = [
  { value: "1", label: "Pre-paid label" },
  { value: "2", label: "Ship on my own" },
];

let isNegotiableOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

let packageSizeOptions = [
  {
    value: "pkg_89520e91fb197dd7e616d3385b55aac6",
    label: 'Lightweight (No side over 21")',
  },
  {
    value: "pkg_a0b694cf8e16d33e8177fa924bbf8d7f",
    label: 'X-Small 9"x6"x3"',
  },
  {
    value: "pkg_fc0b3d3a68d05e6d2233ceefd8860a71",
    label: 'Small 12"x9"x6"',
  },
  {
    value: "pkg_bb68a796e20b5dbf2c06aec80ebf4605",
    label: 'Medium 14"x10"x6"',
  },
  {
    value: "pkg_6bd2ae88654b9a3836b00ede2464ad66",
    label: 'Large 18"x12"x8"',
  },
  {
    value: "pkg_47f88da99a59878f9a6a06d4abf8ac79",
    label: 'X-Large 22"x18"x16"',
  },
];

function Alliwant({
  data,
  dataFetched,
  saveDataHandler,
  changeAlliwantValueHandler,
}) {
  console.log(data);
  const [shippingCarrierOptions, setShippingCarrierOptions] = useState([]);
  const [shippingCarrierOptionsLoading, setShippingCarrierOptionsLoading] =
    useState(false);
  const [brandDefaultOptions, setBrandDefaultOptions] = useState([]);
  const [initialBrandOptionsLoading, setInitialBrandOptionsLoading] =
    useState(false);
  const [sizeOptionsLoading, setSizeOptionsLoading] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressOptionsLoading, setAddressOptionsLoading] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addAddressLoading, setAddAddressLoading] = useState(false);

  const fecthShippingCarrierOptions = async (packageSizeId) => {
    try {
      setShippingCarrierOptions([]);
      setShippingCarrierOptionsLoading(true);
      const response = await AuthenticatedInstance.post(
        "/alliwant/options/shippingCarrier",
        {
          packageSizeId: packageSizeId,
        }
      );
      console.log(response.data);
      setShippingCarrierOptionsLoading(false);
      setShippingCarrierOptions(response.data);
    } catch (e) {
      setShippingCarrierOptionsLoading(false);
      setShippingCarrierOptions([]);
      console.log(e);
    }
  };

  const fetchSizeOptions = async (childsubcatid) => {
    try {
      console.log(childsubcatid);
      setSizeOptions([]);
      setSizeOptionsLoading(true);
      const response = await AuthenticatedInstance.post(
        "/alliwant/options/size",
        {
          childsubcatid: childsubcatid,
        }
      );
      console.log(response.data, "size options");
      setSizeOptionsLoading(false);
      setSizeOptions(response.data);
    } catch (e) {
      setSizeOptionsLoading(false);
      setSizeOptions([]);
      console.log(e);
    }
  };

  const fetchAddressOptions = async () => {
    try {
      setAddressOptionsLoading(true);

      const response = await AuthenticatedInstance.post(
        "/alliwant/options/address",
        {
          childsubcatid: values.childsubcat,
        }
      );
      setAddressOptionsLoading(false);
      setAddressOptions(response.data.options);
      setDefaultAddress(response.data.defaultAddress);
      console.log(response.data);
    } catch (e) {
      setAddressOptionsLoading(false);
      console.log(e);
    }
  };

  const saveAddressHandler = async (newAddress) => {
    try {
      for (let key in newAddress) {
        if (newAddress[key] === "") {
          toast.error("Please fill all the fields");
          return;
        }
      }

      console.log(newAddress);

      setAddAddressLoading(true);

      const response = await AuthenticatedInstance.post(
        "/alliwant/addAddress",
        newAddress
      );

      console.log(response.data);

      setAddAddressLoading(false);

      setShowAddressModal(false);

      fetchAddressOptions();

      toast.success("Address added successfully");
    } catch (e) {
      setAddAddressLoading(false);
      toast.error("Address is not valid");
    }
  };
  //   console.log(data, dataFetched, saveDataHandler, changeAlliwantValueHandler);
  const values = {
    title: "",
    description: "",
    category: "",
    subCategory: "",
    childsubcat: "",
    price: "",
    color: "",
    condition: "",
    itemType: "",
    deliveryMethod: "",
    pay_shipping_by: "",
    shipping_type: "",
    packagesize: "",
    shipping_carrier: "",
    is_brand_filter: "",
    is_color_filter: "",
    is_size_filter: "",
    brand: "",
    brand_label: "",
    size: "",
    sizegroup: "",
    address: "",
    expiration_days: "",
    pickup_distance: "",
    is_negotiable: "no",
    negotiable_percentage: "",
    quantity: "",
  };

  let subCategoryOptions = [];
  let childSubCatOptions = [];

  if (data && data.alliwant && data.generalInfo) {
    console.log(data.alliwant);
    values.title =
      "title" in data.alliwant ? data.alliwant.title : data.generalInfo.title;
    values.description =
      "description" in data.alliwant
        ? data.alliwant.description
        : data.generalInfo.description;

    values.category = data.alliwant.category;
    values.subCategory = data.alliwant.subcategory;
    values.childsubcat = data.alliwant.childsubcategory;
    values.quantity = data.alliwant.quantity;

    values.price =
      "price" in data.alliwant ? data.alliwant.price : data.generalInfo.price;

    values.condition = data.alliwant.condition;
    values.itemType = data.alliwant.itemType;
    values.deliveryMethod = data.alliwant.deliveryMethod;

    if (values.deliveryMethod && values.deliveryMethod != "1") {
      values.pay_shipping_by = data.alliwant.pay_shipping_by;
      values.shipping_type = data.alliwant.shipping_type;
    }
    if (values.deliveryMethod != "1" && values.shipping_type == "1") {
      values.packagesize = data.alliwant.packagesize;
      values.shipping_carrier = data.alliwant.shipping_carrier;
    }

    values.is_brand_filter = data.alliwant.is_brand_filter;
    values.is_size_filter = data.alliwant.is_size_filter;
    values.is_color_filter = data.alliwant.is_color_filter;

    values.color = data.alliwant.color;
    values.brand = data.alliwant.brand;
    values.brand_label = data.alliwant.brand_label;
    values.size = data.alliwant.size;
    values.sizegroup = data.alliwant.sizegroup;

    values.is_negotiable = data.alliwant.is_negotiable || "no";
    values.negotiable_percentage = data.alliwant.negotiable_percentage;

    subCategoryOptions = subcategories[values.category];

    childSubCatOptions =
      childsubcategories[values.category][values.subCategory];
    console.log(values.category, values.subCategory);
    console.log(childSubCatOptions, "childSubCatOptions");

    values.address = data.alliwant.address;
    values.expiration_days = data.alliwant.expiration_days;
    values.is_accept_similar = data.alliwant.is_accept_similar;
    values.pickup_distance = data.alliwant.pickup_distance;
  }

  useEffect(() => {
    if (
      dataFetched &&
      values.deliveryMethod != "1" &&
      values.shipping_type == "1" &&
      values.packagesize
    ) {
      fecthShippingCarrierOptions(values.packagesize);
    }
    if (dataFetched && values.childsubcat) {
      fetchSizeOptions(values.childsubcat);
    }
  }, [dataFetched]);

  const getBrands = debounce(async (value) => {
    if (!value) {
      return Promise.resolve({ options: [] });
    }
    setInitialBrandOptionsLoading(true);
    console.log(value);
    // const response = await AuthenticatedInstance.get(
    //   "/alliwant/options/brand",
    //   {
    //     params: {
    //       childsubcatid: values.childsubcat,
    //       searchterm: value,
    //     },
    //   }
    // );
    const response = await AuthenticatedInstance.post(
      "/alliwant/options/brand",
      {
        childsubcatid: values.childsubcat,
        searchterm: value,
      }
    );
    setBrandDefaultOptions(response.data);
    setInitialBrandOptionsLoading(false);
    console.log(response.data);

    return response.data;
  }, 1000);

  useEffect(() => {
    getBrands(values.brand_label);
    fetchAddressOptions();
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 gap-x-3">
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Title
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Name of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.title}
              required
              onChange={(e) => {
                changeAlliwantValueHandler("title", e.target.value);
              }}
            />
          </div>
          {values.title == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Description
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Description of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <textarea
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product description"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.description}
              required
              onChange={(e) => {
                changeAlliwantValueHandler("description", e.target.value);
              }}
            />
          </div>
          {values.description == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={categories}
              placeholder="Select category"
              value={
                values.category &&
                categories.find((option) => {
                  return option.value === values.category;
                })
              }
              onChange={(selectedOption) => {
                changeAlliwantValueHandler("category", selectedOption.value);
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.category == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Sub Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the sub category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={subCategoryOptions}
              placeholder="Select sub category"
              value={
                values.subCategory &&
                subCategoryOptions.find((option) => {
                  return option.value === values.subCategory;
                })
              }
              onChange={(selectedOption) => {
                changeAlliwantValueHandler("subcategory", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.subCategory == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Child Sub Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the child sub category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={childSubCatOptions}
              placeholder="Select sub category"
              value={
                values.childsubcat &&
                childSubCatOptions.find((option) => {
                  return option.value === values.childsubcat;
                })
              }
              onChange={(selectedOption) => {
                console.log(selectedOption);
                changeAlliwantValueHandler(
                  "childsubcategory",
                  selectedOption.value
                );
                changeAlliwantValueHandler(
                  "is_brand_filter",
                  selectedOption.is_brand_filter
                );
                changeAlliwantValueHandler(
                  "is_size_filter",
                  selectedOption.is_size_filter
                );
                changeAlliwantValueHandler(
                  "is_color_filter",
                  selectedOption.is_color_filter
                );
                if (selectedOption.is_size_filter == "1") {
                  fetchSizeOptions(selectedOption.value);
                }
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.childsubcat == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Price of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="number"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.price}
              required
              onChange={(e) => {
                changeAlliwantValueHandler("price", e.target.value);
              }}
            />
          </div>
          {values.price == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Quantity
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Quantity of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="number"
              name="quantity"
              // onChange={onChange}
              placeholder="Enter product quantity"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.quantity}
              required
              onChange={(e) => {
                changeAlliwantValueHandler("quantity", e.target.value);
              }}
            />
          </div>
          {values.quantity == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.itemType == "1" && (
          <div
            className={`flex items-center w-full col-span-12 gap-5 space-y-5`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Expiration Days
                </p>
                {/* <p className="font-bold text-red-500">*</p> */}
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Days after which the product will expire
              </label>
            </div>
            <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                type="number"
                name="title"
                // onChange={onChange}
                placeholder="Enter product title"
                onBlur={(e) => {
                  saveDataHandler();
                }}
                value={values.expiration_days}
                required
                onChange={(e) => {
                  changeAlliwantValueHandler("expiration_days", e.target.value);
                }}
              />
            </div>
          </div>
        )}
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Address
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the address
            </label>
          </div>
          <div className="w-3/5 my-auto relative flex flex-row gap-6">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={addressOptions}
              placeholder="Select address"
              value={
                values.address
                  ? addressOptions.find((option) => {
                      return option.value === values.address;
                    })
                  : addressOptions.find((option) => {
                      return option.value === defaultAddress;
                    })
              }
              isLoading={addressOptionsLoading}
              onChange={(selectedOption) => {
                changeAlliwantValueHandler("address", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
            <button
              onClick={() => {
                setShowAddressModal(true);
              }}
              className="btn-secondary"
            >
              Add
            </button>
          </div>

          {values.childsubcat == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.is_brand_filter == "1" && (
          <div
            className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Brand
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Select brand of the product.
              </label>
            </div>
            <div className="w-3/5 my-auto relative">
              <AsyncSelect
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                // options={colorOptions}
                loadOptions={getBrands}
                cacheOptions
                placeholder="Select brand"
                onChange={(selectedOption) => {
                  changeAlliwantValueHandler("brand", selectedOption.value);
                  changeAlliwantValueHandler(
                    "brand_label",
                    selectedOption.label
                  );
                }}
                value={{ value: values.brand, label: values.brand_label }}
                onBlur={(e) => {
                  saveDataHandler();
                }}
                isLoading={initialBrandOptionsLoading}
                defaultOptions={brandDefaultOptions}
                // onChange={onChange}
              />
            </div>
          </div>
        )}
        {values.is_color_filter == "1" && (
          <div
            className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Color
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Select the color of the product.
              </label>
            </div>
            <div className="w-3/5 my-auto relative">
              <Select
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                options={colorOptions}
                placeholder="Select color"
                value={
                  values.color &&
                  colorOptions.find((option) => {
                    return option.value === values.color;
                  })
                }
                onChange={(selectedOption) => {
                  changeAlliwantValueHandler("color", selectedOption.value);
                }}
                onBlur={(e) => {
                  saveDataHandler();
                }}
                // onChange={onChange}
              />
            </div>
            {values.color == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
        {values.is_size_filter == "1" && (
          <div
            className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Size
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Select the size of the product.
              </label>
            </div>
            <div className="w-3/5 my-auto relative">
              <Select
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                options={sizeOptions}
                placeholder="Select size"
                value={
                  values.size &&
                  sizeOptions.find((option) => {
                    return option.value === values.size;
                  })
                }
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  changeAlliwantValueHandler("size", selectedOption.value);
                  changeAlliwantValueHandler(
                    "sizegroup",
                    selectedOption.groupId
                  );
                }}
                onBlur={(e) => {
                  saveDataHandler();
                }}
                isLoading={sizeOptionsLoading}
                // onChange={onChange}
              />
            </div>
            {values.color == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Is Negotiable
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select whether the price is negotiable or not.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={isNegotiableOptions}
              placeholder="Select color"
              value={
                values.is_negotiable &&
                isNegotiableOptions.find((option) => {
                  return option.value === values.is_negotiable;
                })
              }
              onChange={(selectedOption) => {
                changeAlliwantValueHandler(
                  "is_negotiable",
                  selectedOption.value
                );
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.color == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.is_negotiable == "yes" && (
          <div
            className={`flex items-center w-full col-span-12 gap-5 space-y-5`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Negotiable Percentage
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Enter the negotiable percentage
              </label>
            </div>
            <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                type="text"
                name="title"
                // onChange={onChange}
                placeholder="Enter product negotiable percentage"
                onBlur={(e) => {
                  saveDataHandler();
                }}
                value={values.negotiable_percentage}
                required
                onChange={(e) => {
                  changeAlliwantValueHandler(
                    "negotiable_percentage",
                    e.target.value
                  );
                }}
              />
            </div>
            {values.title == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Condition
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select condition of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={conditionOptions}
              placeholder="Select condition"
              value={
                values.condition &&
                conditionOptions.find((option) => {
                  return option.value === values.condition;
                })
              }
              onChange={(selectedOption) => {
                changeAlliwantValueHandler("condition", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.itemType == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-12 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Delivery Method
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select delivery method of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={deliveryMethods}
              placeholder="Select delivery method"
              value={
                values.deliveryMethod &&
                deliveryMethods.find((option) => {
                  return option.value === values.deliveryMethod;
                })
              }
              onChange={(selectedOption) => {
                changeAlliwantValueHandler(
                  "deliveryMethod",
                  selectedOption.value
                );
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.deliveryMethod == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.deliveryMethod == "1" && (
          <div
            className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Pick Up Distance
                </p>
                <p className="font-bold text-red-500"></p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Enter the pickup distance
              </label>
            </div>
            <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                type="number"
                name="title"
                // onChange={onChange}
                placeholder="Enter pickup distance"
                onBlur={(e) => {
                  saveDataHandler();
                }}
                value={values.pickup_distance}
                required
                onChange={(e) => {
                  changeAlliwantValueHandler("pickup_distance", e.target.value);
                }}
              />
            </div>
            {values.pickup_distance == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
        {values.deliveryMethod &&
          values.deliveryMethod != "1" &&
          values.itemType == "2" && (
            <div
              className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Pay Shipping By
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Who will pay for the shipping?
                </label>
              </div>
              <div className="w-3/5 my-auto relative">
                <Select
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                  options={payShippingByOptions}
                  placeholder="Who will pay for the shipping?"
                  value={
                    values.pay_shipping_by &&
                    payShippingByOptions.find((option) => {
                      return option.value === values.pay_shipping_by;
                    })
                  }
                  onChange={(selectedOption) => {
                    changeAlliwantValueHandler(
                      "pay_shipping_by",
                      selectedOption.value
                    );
                  }}
                  onBlur={saveDataHandler}
                />
              </div>
            </div>
          )}
        {values.deliveryMethod &&
          values.deliveryMethod != "1" &&
          values.itemType == "2" && (
            <div
              className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Shipping Type
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  How do you want to ship ?
                </label>
              </div>
              <div className="w-3/5 my-auto relative">
                <Select
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                  options={shippingTypeOptions}
                  placeholder="Who will pay for the shipping?"
                  value={
                    values.shipping_type &&
                    shippingTypeOptions.find((option) => {
                      return option.value === values.shipping_type;
                    })
                  }
                  onChange={(selectedOption) => {
                    changeAlliwantValueHandler(
                      "shipping_type",
                      selectedOption.value
                    );
                  }}
                  onBlur={saveDataHandler}
                />
              </div>
            </div>
          )}
        {values.deliveryMethod &&
          values.deliveryMethod != "1" &&
          values.shipping_type == "1" &&
          values.itemType == "2" && (
            <div
              className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Package Size
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Select the size of the package
                </label>
              </div>
              <div className="w-3/5 my-auto relative">
                <Select
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                  options={packageSizeOptions}
                  placeholder="Who will pay for the shipping?"
                  value={
                    values.packagesize &&
                    packageSizeOptions.find((option) => {
                      return option.value === values.packagesize;
                    })
                  }
                  onChange={(selectedOption) => {
                    fecthShippingCarrierOptions(selectedOption.value);
                    changeAlliwantValueHandler(
                      "packagesize",
                      selectedOption.value
                    );
                  }}
                  onBlur={saveDataHandler}
                />
              </div>
            </div>
          )}
        {values.deliveryMethod &&
          values.deliveryMethod != "1" &&
          values.shipping_type == "1" &&
          values.itemType == "2" && (
            <div
              className={`flex items-center w-full col-span-12 gap-5 space-y-7`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Shipping Carrier
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Select the shipping carrier
                </label>
              </div>
              <div className="w-3/5 my-auto relative">
                <Select
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                  options={shippingCarrierOptions}
                  isLoading={shippingCarrierOptionsLoading}
                  noOptionsMessage="loadin..."
                  placeholder="Select shipping carrier"
                  value={
                    values.shipping_carrier &&
                    shippingCarrierOptions.find((option) => {
                      return option.value === values.shipping_carrier;
                    })
                  }
                  onChange={(selectedOption) => {
                    changeAlliwantValueHandler(
                      "shipping_carrier",
                      selectedOption.value
                    );
                  }}
                  onBlur={saveDataHandler}
                />
              </div>
            </div>
          )}
      </div>
      {showAddressModal && (
        <AddressModal
          cancel={() => {
            setShowAddressModal(false);
          }}
          saveAddress={(newAddress) => {
            saveAddressHandler(newAddress);
          }}
          loading={addAddressLoading}
        ></AddressModal>
      )}
    </>
  );
}

export default Alliwant;
