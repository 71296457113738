import instance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const getAllInventory = () => async (dispatch) => {
    try {
        const { data, status } = await instance.get("/inventory");
        // console.log(data);
        if (status === 200) {
            dispatch({
                type: "SET_INVENTORY_DATA",
                payload: data.inventory,
            });
            return true;
        }
    } catch (error) {
        toast.error("Something went wrong!");
    }
};

// const getImageMetaDataStatus = (inventoryId) => async (dispatch) => {
//     try {
//         const { data, status } = await instance.get(`/inventory/image-meta-data-status?inventoryId=${inventoryId}`);
//         if(status !== 200){
//             throw new Error("Error in fetching using AI")
//         }
//         dispatch({
//             type: "UPDATE_INVENTORY_DATA",
//             pay
//         })
//         // if (!connectionStatus?.status || !connectionStatus?.data || connectionStatus?.data?.status === 'failed') {
//     } catch (error) {
//         toast.error(error.message)
//     }
// }
// export const createATask = (inventoryId, platforms, actionType) => async (dispatch) => {
//     try {
//         const {data, status} = await instance.post("/staging/create-a-task", {
//             inventoryId: inventoryId,
//             selectedPlatforms: platforms,
//             actionType: actionType
//         })
//         if(status === 200) {
//             dispatch()
//         }
//     } catch (error) {
//         toast.error("Something went wrong!")
//     }
// }

export const deleteInventory = (inventoryId) => async (dispatch) => {
    try {
        if (!inventoryId) {
            throw new Error("Invalid inventoryId");
        }
        const { data, status } = await instance.delete(
            "/inventory?inventoryId=" + inventoryId
        );
        if (status === 200) {
            return true;
        }
    } catch (error) {
        console.log(error.message);
        toast.error("Trash removal failed");
    } finally {
        dispatch({ type: "RESET_STAGING_DETAILS" });
    }
};

export const deleteDraftInventory = (inventoryId) => async (dispatch) => {
    try {
        console.log("deleting action");
        const { data, status } = await instance.post(
            "/inventory/delete-draft",
            {
                inventoryId: inventoryId,
            }
        );
        dispatch({
            type: "SET_INVENTORY_DATA",
            payload: data.inventoryData,
        });
        console.log(data);
    } catch (error) {
        console.log(error.message);
        toast.error("deletion of inventory failed");
    } finally {
        dispatch({ type: "RESET_STAGING_DETAILS" });
    }
};
