import instance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const saveToInventory = (props) => async (dispatch) => {
    try {
        const { data, status } = await instance.post(
            "/staging/save-to-inventory",
            { data: props }
        );
        if (status === 200) {
            dispatch({
                type: "SET_INVENTORY_ID",
                payload: data.inventory.id,
            });

            return {
                status: true,
                data: data.inventory.id,
            };
        }
    } catch (error) {
        console.log(error, "when saving to inventory");
        return {
            status: false,
            data: null,
        };
    }
};

export const updateInventory = (inventoryId, info) => async (dispatch) => {
    try {
        const { data, status } = await instance.post("/inventory/update", {
            inventoryId: inventoryId,
            data: info,
        });
        if (status === 200) {
            dispatch({
                type: "UPDATE_INVENTORY",
                payload: data.inventory,
            });

            return {
                status: true,
            };
        }
    } catch (error) {
        console.log(error, "when updating inventory");
        return {
            status: false,
        };
    }
};

export const updateListing =
    (listingId, inventoryId, data) => async (dispatch) => {
        try {
            const { data: res, status } = await instance.post(
                "/staging/update-listing",
                { listingId, data }
            );
            if (status === 200) {
                dispatch({
                    type: "UPDATE_LISTING",
                    payload: {
                        data: res.listing.data,
                        listingId: Number(listingId),
                        inventoryId: parseInt(inventoryId),
                    },
                });

                return {
                    status: true,
                    data: res.listing,
                };
            }
        } catch (error) {
            console.log(error, "when updating listing");
            return {
                status: false,
                data: null,
            };
        }
    };

export const createATask =
    (inventoryId, selectedPlatforms, actionType = "create") =>
    async (dispatch) => {
        try {
            const { data, status } = await instance.post(
                "/staging/create-a-task",
                { inventoryId, selectedPlatforms, actionType }
            );
            if (status === 200) {
                dispatch({
                    type: "CREATE_A_TASK",
                    payload: data?.task?.id,
                });

                return {
                    status: true,
                    data: data?.task?.id,
                };
            }
        } catch (error) {
            console.log(error, "when creating a task");
            toast.error("Error creating a task");
            return {
                status: false,
                data: null,
            };
        }
    };

export const uploadImages = (fileFormData) => async (dispatch) => {
    try {
        const { data, status } = await instance.post(
            "/inventory/upload-image",
            fileFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        if (status === 200) {
            return {
                status: true,
                data: data,
            };
        }
    } catch (error) {
        console.log(error, "when uploading images");
        return {
            status: false,
            data: null,
        };
    }
};

export const handleImageMetaData =
    (images, inventoryId) => async (dispatch) => {
        try {
            const response = await instance.post(`/image/metadata`, {
                imageUrl: images,
                inventoryId: inventoryId,
            });
            const responseData = await response.data;
            if (responseData.status) {
                return {
                    status: true,
                    data: responseData.inventoryId,
                };
            }
            dispatch({
                type: "SET_IMAGE_META_DATA_DETECTOR_STATUS",
                payload: "fail",
            });
            return {
                status: false,
                data: null,
            };
        } catch (error) {
            console.log(error, "when handling image meta data");
            return {
                status: false,
                data: null,
            };
        }
    };

export const pollImageMetaDataStatus = (inventoryId) => async (dispatch) => {
    try {
        const res = await instance.get(
            `/inventory/image-meta-data-status?inventoryId=${inventoryId}`
        );
        const connectionStatus = res?.data;
        console.log(connectionStatus);
        if (
            !connectionStatus?.status ||
            !connectionStatus?.data ||
            connectionStatus?.data?.status === "failed"
        ) {
            return {
                status: false,
                data: null,
            };
        } else if (connectionStatus?.data?.status === "processing") {
            return {
                status: false,
                data: null,
            };
        } else if (connectionStatus?.data?.status === "success") {
            dispatch({
                type: "SAVE_IMAGE_META_DATA",
                payload: connectionStatus?.data?.data,
            });
            dispatch({
                type: "SET_IMAGE_META_DATA_DETECTOR_STATUS",
                payload: "success",
            });
            return {
                status: true,
                data: connectionStatus?.data,
            };
        }
    } catch (error) {
        console.log(error, "when polling image meta data status");
        return {
            status: false,
            data: null,
        };
    }
};
