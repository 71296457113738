import React from 'react'
import { connect } from 'react-redux'

export const Input = (props) => {

    const {
        title,
        description,
        type,
        name,
        value,
        onChange,
        placeholder,
        onBlur,
        children = null,
        required = false,
        className
    } = props


    return (
        <div className={`flex items-center w-full ${className}`}>
            <div className='w-2/5'>
                <div className='flex gap-1'>
                    <p className='text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug'>{title}</p>
                    {required && <p className='font-bold text-red-500'>*</p>}
                </div>
                <label className='text-Inter text-gray-600 text-sm leading-snug'>{description}</label>
            </div>
            <div className='w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3'>
                {
                    type === 'textarea' ?
                        <textarea
                            className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            onBlur={onBlur}
                            required
                        /> :
                        <input
                            className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                            type={type}
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            onBlur={onBlur}
                            required
                        />
                }
            </div>
            {children}
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Input)