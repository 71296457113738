import fuzzy from 'fuzzy';
import instance from '../redux/actions/AxiosInstance/authenticated';

const EventEmitter = require('events');
function convertDateTimeFormat(inputDateTime) {
    const inputDate = new Date(inputDateTime);
    const now = new Date();
    const Yesterday = new Date(now);
    Yesterday.setDate(Yesterday.getDate() - 1);

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };

    if (inputDate.toDateString() === now.toDateString()) {
        return "Today, " + inputDate.toLocaleTimeString(undefined, timeOptions);
    } else if (inputDate.toDateString() === Yesterday.toDateString()) {
        return "Yesterday," + inputDate.toLocaleTimeString(undefined, timeOptions);
    } else {
        return inputDate.toLocaleDateString(undefined, dateOptions) + ", " + inputDate.toLocaleTimeString(undefined, timeOptions);
    }
}
const emitter = new EventEmitter();


async function pollImageMetaDataStatus(inventoryId) {
    const res = await instance.get(`/inventory/image-meta-data-status?inventoryId=${inventoryId}`);
    const connectionStatus = res?.data;
    console.log(connectionStatus)
    if (!connectionStatus?.status || !connectionStatus?.data || connectionStatus?.data?.status === 'failed') {
        emitter.emit(`imageMetaDataStatusFullfilled/${inventoryId}`, false);
    }  else if (connectionStatus?.data?.status === 'processing'){
        setTimeout(() => {
            pollImageMetaDataStatus(inventoryId);
        }, 5000);
    }else if (connectionStatus?.data?.status === 'success') {
        emitter.emit(`imageMetaDataStatusFullfilled/${inventoryId}`, connectionStatus?.data);
    }
}

const handleImageMetaData = async (images, processAllImages, inventoryId) => {
    console.log(images)
    if(images?.length === 0){
        return false;
    }
    if(!processAllImages){
        images = images[0];
    }
    
    const response = await instance.post(`/image/metadata`, {
        imageUrl: images,
        inventoryId: inventoryId
    })
    const responseData = await response.data;
    if(responseData.status){
        setTimeout(() => {
            pollImageMetaDataStatus(responseData.inventoryId);
        }, 20000);
    }
    return responseData;
}

  
  const getNearestWord = (word, database) => {
    const fuzzyWords = fuzzy.filter(word, database)
    return fuzzyWords.length === 0 ? undefined : fuzzyWords[0].string
  };
  


export {
    convertDateTimeFormat,
    handleImageMetaData,
    getNearestWord,
    emitter
}