import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "sonner";
import authenticatedInstance from "../redux/actions/AxiosInstance/authenticated";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Modal } from "../components/Modal";
import ConfirmationModal from "../components/ConfirmationModal";
import { Ring } from "@uiball/loaders";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tippy from "@tippyjs/react";

function ImportedProduct() {
  let [product, setProduct] = useState(null);
  let [loading, setLoading] = useState(true);
  const [dleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [addToInventoryModal, setAddToInventoryModal] = useState(false);
  const [addToInventoryLoading, setAddToInventoryLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const fetchProduct = async (id) => {
    try {
      const response = await authenticatedInstance.get(
        `/import/fetchProduct/${id}`
      );

      // console.log(response.data);

      setProduct(response.data);
      setLoading(false);
    } catch (err) {
      toast.error("Error fetching product");
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = location.pathname.split("/")[2];
    fetchProduct(id);
  }, []);

  const addProductToInventory = async () => {
    try {
      setAddToInventoryModal(false);
      setAddToInventoryLoading(true);
      const response = await authenticatedInstance.post(
        "/import/addToInventory",
        {
          id: product.id,
        }
      );
      setAddToInventoryLoading(false);
      setProduct(response.data);
      toast.success("Product added to inventory successfully");
    } catch (e) {
      setAddToInventoryLoading(false);
      toast.error("Error adding product to inventory");
      console.log(e);
    }
  };

  const deleteProduct = async () => {
    try {
      const response = await authenticatedInstance.post("/import/delete", {
        id: product.id,
      });
      // console.log(response.data);
      setDeleteConfirmationModal(false);

      navigate("/import");

      toast.success("Product deleted successfully");

      // fetchProducts();
    } catch (e) {
      setDeleteConfirmationModal(false);
      toast.error(e.response.data.message);
      console.log(e);
    }
  };

  let content;

  if (loading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Ring color="orange"></Ring>
      </div>
    );
  } else {
    content = (
      <>
        <div
          className="flex-1 px-10 flex pb-[10px] mt-5 rounded-lg p-4 mx-auto pt-[10px] justify-between items-center"
          style={{ width: "90%" }}
        >
          <div>
            <div>
              Platform :{" "}
              <span className="font-bold capitalize">{product.platform}</span>
            </div>
            <div className="mt-3">
              Status :{" "}
              <span className="font-bold capitalize">{product.status}</span>
            </div>
          </div>
          <div className="flex gap-10 items-center justify-center h-[50px]">
            <div className="cursor-pointer">
              <Tippy content="back" placement="bottom">
                <ArrowBackIosIcon
                  onClick={() => {
                    navigate("/import");
                  }}
                  className="cursor-pointer outline-none"
                ></ArrowBackIosIcon>
              </Tippy>
            </div>
            {!product.inventoryId && (
              <button
                className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
                onClick={() => {
                  setAddToInventoryModal(true);
                }}
              >
                Add To Inventory
              </button>
            )}
            {!product.inventoryId && (
              <div className="cursor-pointer">
                <Tippy content="delete" placement="bottom">
                  <DeleteIcon
                    className="cursor-pointer outline-none"
                    onClick={() => {
                      setDeleteConfirmationModal(true);
                    }}
                  />
                </Tippy>
              </div>
            )}
          </div>
        </div>
        <div className="pl-5 flex mt-10">
          <div className="w-4/5 space-y-6 px-8 mx-auto">
            <div className="space-y-6 py-5 px-8 fade-in">
              <div className="grid grid-cols-12 gap-x-3">
                <div className="flex items-center w-full col-span-11">
                  <div className="w-2/5">
                    <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                      Image
                    </p>
                    <label className="text-Inter text-gray-600 text-sm leading-snug">
                      image of the product.
                    </label>
                  </div>
                  <div className="flex items-center text-center w-3/5 py-3">
                    <div className="flex flex-wrap gap-3">
                      <div className="relative w-32 h-32">
                        <img
                          src={product.images[0].url}
                          alt="product"
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-x-3 ">
                {product.data.title && (
                  <div
                    className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
                  >
                    <div className="w-2/5">
                      <div className="flex gap-1">
                        <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                          Title
                        </p>
                        <p className="font-bold text-red-500">*</p>
                      </div>
                      <label className="text-Inter text-gray-600 text-sm leading-snug">
                        Name of the product
                      </label>
                    </div>
                    <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                      <input
                        className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                        type="text"
                        name="title"
                        // onChange={onChange}
                        placeholder="Enter product title"
                        value={product.data.title}
                        required
                        readonly
                      />
                    </div>
                  </div>
                )}
                {product.data.description && (
                  <div
                    className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
                  >
                    <div className="w-2/5">
                      <div className="flex gap-1">
                        <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                          Description
                        </p>
                        <p className="font-bold text-red-500">*</p>
                      </div>
                      <label className="text-Inter text-gray-600 text-sm leading-snug">
                        Description of the product
                      </label>
                    </div>
                    <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                      <textarea
                        className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                        type="text"
                        name="title"
                        // onChange={onChange}
                        placeholder="Enter product title"
                        value={product.data.description}
                        required
                        readonly
                      />
                    </div>
                  </div>
                )}
                {product.data.color && (
                  <div
                    className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
                  >
                    <div className="w-2/5">
                      <div className="flex gap-1">
                        <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                          Color
                        </p>
                        <p className="font-bold text-red-500">*</p>
                      </div>
                      <label className="text-Inter text-gray-600 text-sm leading-snug">
                        Color of the product
                      </label>
                    </div>
                    <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                      <input
                        className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                        type="text"
                        name="title"
                        // onChange={onChange}
                        placeholder="Enter product title"
                        value={product.data.color}
                        required
                        readonly
                      />
                    </div>
                  </div>
                )}
                {product.data.brand && (
                  <div
                    className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
                  >
                    <div className="w-2/5">
                      <div className="flex gap-1">
                        <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                          Brand
                        </p>
                        <p className="font-bold text-red-500">*</p>
                      </div>
                      <label className="text-Inter text-gray-600 text-sm leading-snug">
                        Brand of the product
                      </label>
                    </div>
                    <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                      <input
                        className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                        type="text"
                        name="title"
                        // onChange={onChange}
                        placeholder="Enter product title"
                        value={product.data.brand}
                        required
                        readonly
                      />
                    </div>
                  </div>
                )}
                {product.data.tags && (
                  <div
                    className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
                  >
                    <div className="w-2/5">
                      <div className="flex gap-1">
                        <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                          Tags
                        </p>
                        <p className="font-bold text-red-500">*</p>
                      </div>
                      <label className="text-Inter text-gray-600 text-sm leading-snug">
                        Tags of the product
                      </label>
                    </div>
                    <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                      <input
                        className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                        type="text"
                        name="title"
                        // onChange={onChange}
                        placeholder="Enter product title"
                        value={product.data.tags}
                        required
                        readonly
                      />
                    </div>
                  </div>
                )}
                {product.data.price && (
                  <div
                    className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
                  >
                    <div className="w-2/5">
                      <div className="flex gap-1">
                        <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                          Price
                        </p>
                        <p className="font-bold text-red-500">*</p>
                      </div>
                      <label className="text-Inter text-gray-600 text-sm leading-snug">
                        Price of the product
                      </label>
                    </div>
                    <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                      <input
                        className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                        type="text"
                        name="title"
                        // onChange={onChange}
                        placeholder="Enter product title"
                        value={product.data.price}
                        required
                        readonly
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {dleteConfirmationModal && (
          <ConfirmationModal
            heading={"Delete Import Product Confirmation"}
            message={
              "Are you sure you want to delete this product from impprts"
            }
            onCancel={() => {
              setDeleteConfirmationModal(false);
            }}
            onConfirm={() => {
              deleteProduct();
            }}
          ></ConfirmationModal>
        )}
        {addToInventoryModal && (
          <ConfirmationModal
            heading={"Add Product to Inventory"}
            message={"Are you sure you want to add this product to inventory ?"}
            onCancel={() => {
              setAddToInventoryModal(false);
            }}
            onConfirm={() => {
              addProductToInventory();
            }}
          ></ConfirmationModal>
        )}
        {addToInventoryLoading && (
          <Modal width={"w-1/4"}>
            <div className=" flex flex-col gap-2 justify-center items-center">
              <Ring speed={1} size={30} color="orange" />
              <p className="text-lg text-secondary font-semibold text-Inter mt-3">
                Adding Product to Inventory
              </p>
              <p className="text-sm text-secondarySupport text-Inter">
                Please wait while we add product to inventory.
              </p>
            </div>
          </Modal>
        )}
      </>
    );
  }

  return (
    <div className="py-4 fade-in w-full overflow-y-auto min-h-screen relative">
      {content}
    </div>
  );
}

export default ImportedProduct;
