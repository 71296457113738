import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Modal } from "../../components/Modal";
import { toast } from "sonner";
import { Ring } from "@uiball/loaders";
import AutenticatedInsatnce from "../../redux/actions/AxiosInstance/authenticated";
import { Input, SelectOption } from "../../components/FormComponent";
import Select from "react-select";
import { colorOptions } from "../../components/staging/PlatformForms/DepopDropdowns";
import { useLocation } from "react-router-dom";
import { brands } from "../../components/staging/PlatformForms/KidizenDropdowns";

// import { set } from "../../../../api/routers/inventoryRoute";

export default function FirstStangingNew() {
  const [loading, setLoading] = useState(false);
  const [imageUploadingModal, setImageUploadingModal] = useState(false);
  const [images, setImages] = useState([]);
  const [inventoryId, setInventoryId] = useState(null);
  const [aiGeneralInfoStatus, setAiGeneralInfoStatus] = useState(false);
  const [generalInfo, setGeneralInfo] = useState({
    title: "",
    description: "",
    brand: "",
    tags: "",
    color: "",
    price: "",
  });
  const [autofillImageId, setAutofillImageId] = useState(null);
  const [imageDeleteModal, setImageDeleteModal] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    let inventoryIdParams = location.pathname.split("/")[2];

    let fetchInitialData = async () => {
      try {
        let response = await AutenticatedInsatnce.get(
          `/inventory/getInventory/${inventoryIdParams}`
        );
        setInventoryId(inventoryIdParams);
        setGeneralInfo(response.data.data.generalInfo);
        setAutofillImageId(response.data.autofillImageId);
        setImages(response.data.images.general);
        console.log(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (inventoryIdParams) {
      console.log(inventoryIdParams);
      fetchInitialData();
    }
  }, []);

  const onDrop = async (acceptedFiles) => {
    try {
      if (acceptedFiles.length === 0) {
        toast.error("no images selected");
        return;
      }
      if (acceptedFiles.length + images.length > 4) {
        toast.error("maximum 4 images are allowed");
        return;
      }

      setImageUploadingModal(true);

      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      if (inventoryId) {
        formData.append("inventoryId", inventoryId);
      }

      const response = await AutenticatedInsatnce.post(
        "/inventory/upload-image-new",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      console.log(response.data);
      setImages((prvImgs) => {
        return [...prvImgs, ...response.data.images];
      });

      if (!inventoryId) {
        setInventoryId(response.data.inventoryId);
        setAiGeneralInfoStatus(true);
        setAutofillImageId(response.data.autofillImageId);
      }
      setImageUploadingModal(false);
      toast.success("Images uploaded successfully");
    } catch (err) {
      setImageUploadingModal(false);
      toast.error(err.response.data.message || "Something went wrong");
    }
  };

  const updateGneralInfo = (newGeneralInfo) => {
    setGeneralInfo((prvData) => {
      let newData = { ...prvData };
      for (let key in newData) {
        if (!newData[key] || newData[key] === "") {
          newData[key] = newGeneralInfo[key];
        }
      }
      return {
        ...newData,
      };
    });
  };

  const changeValueHandler = (field, value) => {
    setGeneralInfo((prvData) => {
      let newData = { ...prvData };
      newData[field] = value;
      return {
        ...newData,
      };
    });
  };

  useEffect(() => {
    let interval;
    if (aiGeneralInfoStatus && inventoryId) {
      console.log("hi there");
      interval = setInterval(async () => {
        let response = await AutenticatedInsatnce.post(
          "/inventory/generalInfo",
          {
            inventoryId: inventoryId,
          }
        );
        console.log(response.data);
        if (response.data.status === "completed") {
          clearInterval(interval);
          setAiGeneralInfoStatus(false);
          updateGneralInfo(response.data.data);
        }
      }, 2000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [aiGeneralInfoStatus]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true, // Allow multiple file uploads
  });

  const handleGeneralImageDelete = async (image) => {
    try {
      setImageDeleteModal(true);
      const response = await AutenticatedInsatnce.post(
        "/inventory/deleteGeneralImage",
        {
          inventoryId: inventoryId,
          image: image,
        }
      );
      console.log(response.data);
      setImages((prvImgs) => {
        return prvImgs.filter((img) => img.public_id !== image.public_id);
      });
      setImageDeleteModal(false);
      toast.success("Image deleted successfully");
    } catch (e) {
      setImageDeleteModal(false);
      toast.error(e.response.data.message || "Unable to delete image");
      console.log(e);
    }
  };

  const handleSubmit = async () => {
    try {
      // validate the values
      let validationPass = true;

      for (let key in generalInfo) {
        if (!generalInfo[key] || generalInfo[key] === "") {
          validationPass = false;
        }
        if (key == "price") {
          if (parseInt(generalInfo[key]) <= 0) {
            validationPass = false;
          }
        }
        if (key === "description") {
          if (generalInfo[key].split(" ").length < 5) {
            validationPass = false;
          }
        }
      }

      if (!validationPass) {
        toast.error("Please fill all the required fields correctly");
        return;
      }
      setLoading(true);

      const response = await AutenticatedInsatnce.post(
        "/inventory/updateGeneralInfo",
        {
          inventoryId: inventoryId,
          generalInfo: generalInfo,
        }
      );

      setLoading(false);

      toast.success("Inventory updated successfully");

      navigate(`/inventoryDetails/${inventoryId}`);

      // update inventory general info
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onChange = (e) => {};

  const onBlur = (e) => {};

  return (
    <>
      <div className="py-4 w-full fade-in overflow-y-auto min-h-screen relative">
        <div className="flex-1 px-7  w-full flex items-center justify-center sm:items-stretch sm:justify-between pb-[5.9px]">
          <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
            <p className="title fade-in tracking-wide">Staging</p>
          </div>

          <ol class="flex items-center justify-center flex-1 space-x-2  text-sm font-medium text-center text-gray-500  rounded-lg shadow-sm sm:text-base sm:space-x-4">
            <li class="flex items-center text-blue-600 dark:text-blue-500">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.66667 13.1663H12.3333M8.18141 1.30297L2.52949 5.6989C2.15168 5.99276 1.96278 6.13968 1.82669 6.32368C1.70614 6.48667 1.61633 6.67029 1.56169 6.86551C1.5 7.0859 1.5 7.32521 1.5 7.80384V13.833C1.5 14.7664 1.5 15.2331 1.68166 15.5896C1.84144 15.9032 2.09641 16.1582 2.41002 16.318C2.76654 16.4996 3.23325 16.4996 4.16667 16.4996H13.8333C14.7668 16.4996 15.2335 16.4996 15.59 16.318C15.9036 16.1582 16.1586 15.9032 16.3183 15.5896C16.5 15.2331 16.5 14.7664 16.5 13.833V7.80384C16.5 7.32521 16.5 7.0859 16.4383 6.86551C16.3837 6.67029 16.2939 6.48667 16.1733 6.32368C16.0372 6.13968 15.8483 5.99276 15.4705 5.69891L9.8186 1.30297C9.5258 1.07526 9.3794 0.961399 9.2178 0.917629C9.0752 0.879019 8.92484 0.879019 8.78221 0.917629C8.62057 0.961399 8.47418 1.07526 8.18141 1.30297Z"
                  stroke="#FF4405"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                class="w-5 h-5 ml-2 sm:ml-4"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 12L10 8L6 4"
                  stroke="#667085"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </li>
            <li class="flex items-center">Staging</li>
          </ol>

          {/* cancel and save button div */}
          <div className="flex item-center gap-x-5">
            <button
              onClick={async () => {
                // console.log(inventoryId, "This is inventory Id");
                // if (
                //     !inventoryData?.find(
                //         (inventory) => inventory.id === inventoryId
                //     )?.title
                // ) {
                //     await deleteInventory(inventoryId);
                // }
                navigate("dashboard");
              }}
              className="btn-secondary flex item-center gap-x-1.5 text-Inter"
            >
              Cancel
            </button>
            {loading ? (
              <button
                disabled
                type="button"
                className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
              >
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
              >
                Save As Draft & Next
              </button>
            )}
          </div>
        </div>
        <hr className="bg-gray-600 h-[1.15px] mt-3" />
        <div className="space-y-6 w-2/3 mx-auto py-5 px-4">
          <div className="grid grid-cols-12 gap-x-7">
            <div className="flex items-center w-full col-span-11">
              <div className="w-2/5">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Upload Images
                </p>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Please ensure that the first image uploaded is the main
                  product image, as it will be used to fetch product details.
                  Subsequent images should contain labels to accurately fetch
                  the price and brand information using our AI technology.
                </label>
              </div>
              <div className="flex items-center text-center w-3/5 py-3 relative">
                {images.length == 0 ? (
                  <label
                    // htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-40 border-2 border-slate-400 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
                  >
                    <div
                      {...getRootProps()}
                      className="flex flex-col items-center justify-center pt-5 pb-6"
                    >
                      <svg
                        width="40"
                        height="40"
                        className="bg-[#F9FAFB] rounded-full p-2.5"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66666 12.3333L10 9M10 9L13.3333 12.3333M10 9V16.5M16.6667 12.9524C17.6846 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2184 2.73736 10.212 1.5 7.91666 1.5C4.46488 1.5 1.66666 4.29822 1.66666 7.75C1.66666 9.47175 2.36287 11.0309 3.48912 12.1613"
                          stroke="#475467"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold text-primary">
                          Click to upload
                        </span>{" "}
                        or drag and drop (max 4 images)
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        PNG, JPG (MAX. 5MB per image)
                      </p>
                    </div>
                  </label>
                ) : (
                  <div className="flex flex-wrap gap-3">
                    {images.map((img, indx) => {
                      return (
                        <div key={img.public_id} className="relative w-32 h-32">
                          <img
                            src={img.url}
                            alt=""
                            className="object-cover w-full h-full rounded-lg"
                          />
                          <div
                            className="absolute top-0 right-0 p-1 cursor-pointer bg-white rounded-bl-lg"
                            onClick={(e) => {
                              e.preventDefault();

                              if (
                                autofillImageId &&
                                img.public_id === autofillImageId
                              ) {
                                toast.error(
                                  "You can't delete the image used for autofilling data"
                                );
                                return;
                              }
                              handleGeneralImageDelete(img);
                            }}
                          >
                            <svg
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.14412 6.4805C7.23218 6.56856 7.28166 6.688 7.28166 6.81253C7.28166 6.93707 7.23218 7.0565 7.14412 7.14456C7.05606 7.23262 6.93663 7.2821 6.81209 7.2821C6.68756 7.2821 6.56812 7.23262 6.48006 7.14456L3.99998 4.6637L1.51912 7.14378C1.43106 7.23184 1.31163 7.28131 1.18709 7.28131C1.06256 7.28131 0.943121 7.23184 0.855061 7.14378C0.767001 7.05572 0.717529 6.93629 0.717529 6.81175C0.717529 6.68721 0.767001 6.56778 0.855061 6.47972L3.33592 3.99964L0.855842 1.51878C0.767782 1.43072 0.718311 1.31129 0.718311 1.18675C0.718311 1.06221 0.767782 0.94278 0.855842 0.854719C0.943903 0.766659 1.06334 0.717187 1.18787 0.717187C1.31241 0.717187 1.43184 0.766659 1.51991 0.854719L3.99998 3.33558L6.48084 0.854329C6.5689 0.766268 6.68834 0.716797 6.81287 0.716797C6.93741 0.716797 7.05684 0.766268 7.14491 0.854329C7.23296 0.942389 7.28244 1.06182 7.28244 1.18636C7.28244 1.3109 7.23296 1.43033 7.14491 1.51839L4.66405 3.99964L7.14412 6.4805Z"
                                fill="#E31B54"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                    {images.length < 4 && (
                      <label
                        {...getRootProps()}
                        className="flex items-center justify-center w-32 h-32 border-2 border-slate-400 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
                      >
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Add More
                        </p>
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-3">
            <div
              className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Title
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Name of the product
                </label>
              </div>
              <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                <input
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                  type="text"
                  name="title"
                  // onChange={onChange}
                  placeholder="Enter product title"
                  // onBlur={onBlur}
                  value={generalInfo.title}
                  required
                  onChange={(e) => {
                    changeValueHandler("title", e.target.value);
                  }}
                />
              </div>
              {generalInfo.title == "" && aiGeneralInfoStatus && (
                <Ring
                  color="orange"
                  size={20}
                  speed={1}
                  className="h-[15px] w-[15px] col-span-1"
                />
              )}
            </div>

            <div
              className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Description
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Descriptionof the product at least 5 words
                </label>
              </div>
              <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                <textarea
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
                  name="description"
                  value={generalInfo.description}
                  onChange={(e) => {
                    changeValueHandler("description", e.target.value);
                  }}
                  placeholder="Enter description"
                  // onBlur={onBlur}
                  required
                />
              </div>
              {generalInfo.description == "" && aiGeneralInfoStatus && (
                <Ring
                  color="orange"
                  size={20}
                  speed={1}
                  className="h-[15px] w-[15px] col-span-1"
                />
              )}
            </div>
            <div
              className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Brand
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Brand of the product
                </label>
              </div>
              <div className="w-3/5 my-auto relative">
                <Select
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                  options={brands}
                  placeholder="Select brand"
                  value={
                    generalInfo.brand &&
                    brands.find((option) => {
                      return (
                        option.value.toLocaleLowerCase() ===
                        generalInfo.brand.toLocaleLowerCase()
                      );
                    })
                  }
                  onChange={(selectedOption) => {
                    changeValueHandler("brand", selectedOption.value);
                  }}
                  // onChange={onChange}
                />
              </div>
              {generalInfo.brand == "" && aiGeneralInfoStatus && (
                <Ring
                  color="orange"
                  size={20}
                  speed={1}
                  className="h-[15px] w-[15px] col-span-1"
                />
              )}
            </div>
            <div
              className={`flex items-center w-full col-span-11 gap-5 space-y-7`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Color
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Select the color of the product.
                </label>
              </div>
              <div className="w-3/5 my-auto relative">
                <Select
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                  options={colorOptions}
                  placeholder="Select color"
                  value={
                    generalInfo.color &&
                    colorOptions.find((option) => {
                      return option.value === generalInfo.color;
                    })
                  }
                  onChange={(selectedOption) => {
                    changeValueHandler("color", selectedOption.value);
                  }}
                  // onChange={onChange}
                />
              </div>
              {generalInfo.color == "" && aiGeneralInfoStatus && (
                <Ring
                  color="orange"
                  size={20}
                  speed={1}
                  className="h-[15px] w-[15px] col-span-1"
                />
              )}
            </div>
            <div
              className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Price
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Price of the product
                </label>
              </div>
              <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                <input
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                  type="number"
                  name="price"
                  // onChange={onChange}
                  placeholder="Enter product title"
                  // onBlur={onBlur}
                  value={generalInfo.price}
                  required
                  onChange={(e) => {
                    changeValueHandler("price", e.target.value);
                  }}
                />
              </div>
              {generalInfo.price == "" && aiGeneralInfoStatus && (
                <Ring
                  color="orange"
                  size={20}
                  speed={1}
                  className="h-[15px] w-[15px] col-span-1"
                />
              )}
            </div>
            <div
              className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
            >
              <div className="w-2/5">
                <div className="flex gap-1">
                  <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                    Tags
                  </p>
                  <p className="font-bold text-red-500">*</p>
                </div>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Tags of the product
                </label>
              </div>
              <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
                <input
                  className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                  type="text"
                  name="title"
                  // onChange={onChange}
                  placeholder="Enter product tags"
                  // onBlur={onBlur}
                  value={generalInfo.tags}
                  required
                  onChange={(e) => {
                    changeValueHandler("tags", e.target.value);
                  }}
                />
              </div>
              {aiGeneralInfoStatus && (
                <Ring
                  color="orange"
                  size={20}
                  speed={1}
                  className="h-[15px] w-[15px] col-span-1"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {imageUploadingModal && (
        <Modal width={"w-1/4"}>
          <div className=" flex flex-col gap-2 justify-center items-center">
            <Ring speed={1} size={30} color="orange" />
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Uploading Images
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              Please wait while we upload your images.
            </p>
          </div>
        </Modal>
      )}
      {imageDeleteModal && (
        <Modal width={"w-1/4"}>
          <div className=" flex flex-col gap-2 justify-center items-center">
            <Ring speed={1} size={30} color="orange" />
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Deleting Image
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              Please wait while we delete your image.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
