import React from "react";
import { ImCancelCircle, ImTerminal } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { toast } from "sonner";
import { Ring } from "@uiball/loaders";
import { connect, useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import ConfirmationModal from "../ConfirmationModal";
import AuthenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
// import PlatformInfoModal from "./PlatformInfoModal";
function PlatformConStatus({
  item,
  key,
  connectionStatus,
  setConnectionStatus,
  setModalStatus,
  connectingStatus,
  setConnectingStatus,
  connectedPlatforms,
  isOtpRequired,
}) {
  const [isOtpVerificationModalAppeared, setOtpVerificationModalAppearance] =
    React.useState(false);
  const [showPlatInfoModal, setShowPlatInfoModal] = React.useState(false);
  const [discconectConfirmation, setDisconnectConfirmation] =
    React.useState(false);
  React.useEffect(() => {
    if (isOtpRequired) {
      setOtpVerificationModalAppearance(true);
    }
  }, [isOtpRequired]);

  const dispatch = useDispatch();
  const disconnectPlatformHandler = async () => {
    try {
      const response = await AuthenticatedInstance.post(
        "/user/disconnect-platform",
        {
          platform: item.title,
        }
      );
      console.log(response.data);
      toast.success("Platform disconnected successfully");
      setDisconnectConfirmation(false);
      dispatch({
        type: "SET_PLATFORMS",
        payload: response.data.platforms,
      });
    } catch (err) {
      setDisconnectConfirmation(false);
      toast.error("Failed to disconnect platform");
    }
  };

  return (
    <>
      <div
        key={key}
        className="w-fit py-2.5 px-5 border-[1px] cursor-pointer border-slate-200 rounded-md shadow-sm"
      >
        <div className="flex items-center gap-x-1">
          {connectedPlatforms.find(
            (platform) =>
              platform.platform === item.title &&
              platform.status === "connected"
          ) && (
            <>
              <TiTick className="h-5 w-5 text-green-600" />
              <p className="text-xs font-[500] text-green-600">Connected</p>
            </>
          )}

          {connectedPlatforms.find(
            (platform) =>
              platform.platform === item.title &&
              platform.status === "connecting"
          ) && (
            <>
              {/* <ImCancelCircle className='h-3 w-3 text-red-600' /> */}
              <div className="flex items-center justify-center gap-2 mx-auto">
                <Ring
                  color="orange"
                  size={16}
                  speed={0.7}
                  className="h-[15px] w-[15px] ml-4"
                />
                <p className="text-xs font-[500] text-orange-600">Connecting</p>
              </div>
            </>
          )}
          {!connectedPlatforms.find(
            (platform) => platform.platform === item.title
          ) && (
            <>
              <ImCancelCircle className="h-5 w-5 text-red-600" />
              <p className="text-xs font-[500] text-red-600 ml-2">
                Not connected
              </p>
            </>
          )}
          {connectedPlatforms.find(
            (platform) =>
              platform.platform === item.title && platform.status === "failed"
          ) && (
            <>
              <ImCancelCircle className="h-5 w-5 text-red-600" />
              <p className="text-xs font-[500] text-red-600">
                Connection Failed
              </p>
            </>
          )}
        </div>
        {item.logo}

        <p className="text-md text-gray-600 font-[500] mt-2 text-center capitalize">
          {item.label}
          {item.title !== "shopify" && item.title !== "alliwant" && (
            <Tippy content={item?.requirements?.join(". ")} arrow={true}>
              <InfoIcon
                style={{
                  fontSize: "1rem",
                  marginLeft: "0.5rem",
                  color: "#6B7280",
                  cursor: "pointer",
                }}
              ></InfoIcon>
            </Tippy>
          )}
        </p>

        {item.title === "mercari" &&
          !isOtpVerificationModalAppeared &&
          connectedPlatforms.find(
            (platform) =>
              platform.platform === "mercari" &&
              platform.status === "connecting"
          ) && (
            <p className="text-xs font-bold w-[130px] text-center leading-tight text-gray-600">
              Modal may appear for verification of otp
            </p>
          )}

        {connectedPlatforms.find(
          (platform) =>
            platform.platform === item.title && platform.status === "connected"
        ) && (
          <>
            <p
              onClick={() => {
                setDisconnectConfirmation(true);
              }}
              className="text-md text-blue-600 font-[500] text-center leading-tight"
            >
              Disconnect
            </p>
          </>
        )}

        {!connectedPlatforms.find(
          (platform) =>
            platform.platform === item.title &&
            ["connecting", "connected"].includes(platform.status)
        ) && (
          <p
            onClick={() => {
              console.log("clicking p");
              if (
                [
                  "kidizen",
                  "mercari",
                  "poshmark",
                  "depop",
                  "shopify",
                  "alliwant",
                ].includes(item.title)
              ) {
                console.log("opening model");
                setModalStatus({
                  status: true,
                  platform: item.title,
                });
              } else {
                toast.error(
                  "We only support Kidizen, Mercari, Shopify, Poshmark and Depop"
                );
              }
            }}
            className="text-md text-blue-600 font-[500] text-center leading-tight"
          >
            {connectedPlatforms.find(
              (platform) =>
                platform.platform === item.title && platform.status === "failed"
            )
              ? "Retry connect"
              : "Connect"}
          </p>
        )}
      </div>
      {discconectConfirmation && (
        <ConfirmationModal
          heading={`Disconnect ${item.title}`}
          message={`Are you sure you want to disconnect ${item.title}?`}
          onConfirm={() => {
            disconnectPlatformHandler();
          }}
          onCancel={() => {
            setDisconnectConfirmation(false);
          }}
        ></ConfirmationModal>
      )}
      {/* {showPlatInfoModal && (
                <PlatformInfoModal
                    item={item}
                    onConfirm={() => {
                        setShowPlatInfoModal(false);
                    }}
                ></PlatformInfoModal>
            )} */}
    </>
  );
}

const mapStateToProps = (state) => ({
  connectedPlatforms: state.auth.connectedPlatforms,
  isOtpRequired: state.auth.isOtpRequired,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformConStatus);
