import { useLocation } from "react-router-dom";
import Inventory from "./Inventory";
import Sidebar from "../components/Sidebar";
import Wants from "./Wants";
import { getUserInfo } from "../redux/actions/auth";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import Tasks from "./Tasks";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OtpVerificationForm from "./OtpVerificationForm";
import FirstStagingNew from "./Staging/FirstStangingNew";
import SecondStaging from "./Staging/SecondStaging";
import Import from "./Import";
import ImportedProduct from "./ImportedProduct";
export const Index = (props) => {
  const { getUserInfo, isOtpRequired } = props;

  const location = useLocation();
  const currentRoute = location.pathname.slice(1);

  const [sidebarClosingStatus, setSidebarClosingStatus] = React.useState(false);
  const [connectionStatus, setConnectionStatus] = useState({
    poshmark: false,
    shopify: false,
    ebay: false,
    mercari: false,
    etsy: false,
    kidizen: false,
    depop: false,
  });
  const [connectingStatus, setConnectingStatus] = useState({
    poshmark: false,
    shopify: false,
    ebay: false,
    mercari: false,
    etsy: false,
    kidizen: false,
    depop: false,
  });
  useEffect(() => {
    getUserInfo();
    // setInterval(() => {
    //     getUserInfo();
    // }, 10 * 1000);
    // getUserInfo();
  }, []);

  return (
    <div className="flex">
      <Sidebar
        sidebarClosingStatus={sidebarClosingStatus}
        setSidebarClosingStatus={setSidebarClosingStatus}
      />
      <div
        className={`w-full  ${
          sidebarClosingStatus ? `ml-[240px]` : `ml-[80px]`
        }`}
      >
        <div className="w-full overflow-y-auto min-h-screen relative">
          {currentRoute === "dashboard" && (
            <Inventory
              connectingStatus={connectingStatus}
              connectionStatus={connectionStatus}
              setConnectingStatus={setConnectingStatus}
              setConnectionStatus={setConnectionStatus}
            />
          )}
          {currentRoute === "settings" && <Settings />}
          {currentRoute === "tasks" && <Tasks />}
          {currentRoute === "inventory" && (
            <Dashboard
              connectingStatus={connectingStatus}
              connectionStatus={connectionStatus}
              setConnectingStatus={setConnectingStatus}
              setConnectionStatus={setConnectionStatus}
            />
          )}
          {currentRoute === "import" && <Import />}
          {currentRoute === "wants" && <Wants />}
          {currentRoute.includes("inventoryDetails") && <SecondStaging />}
          {currentRoute.includes("staging") && <FirstStagingNew />}
          {currentRoute.includes("importedProduct") && <ImportedProduct />}
        </div>
      </div>
      {<OtpVerificationForm />}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { getUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
