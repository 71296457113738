import React, { useEffect, useState } from "react";
import authenticatedInstance from "../redux/actions/AxiosInstance/authenticated";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BsFacebook } from "react-icons/bs";
import { SiShopify, SiEbay, SiEtsy } from "react-icons/si";
import Tippy from "@tippyjs/react";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { LuUploadCloud } from "react-icons/lu";

import ConfirmationModal from "../components/ConfirmationModal";
import { Ring } from "@uiball/loaders";
import { toast } from "sonner";
import { Modal } from "../components/Modal";
import { useNavigate } from "react-router-dom";
import ImportModal from "../components/ImportModal";
import platformLogos from "../Utils/platformLogos";
import Tooltip from "@mui/material/Tooltip";
import Select from "react-select";
import { MdClose } from "react-icons/md";

function Import() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [dleteConfirmationModal, setDeleteConfirmationModal] = useState({
    open: false,
    id: null,
  });
  const [addToInventoryModal, setAddToInventoryModal] = useState({
    open: false,
    id: null,
  });
  const [addToInventoryBulkModal, setAddToInventoryBulkModal] = useState(false);
  const [deleteBulkModal, setDeleteBulkModal] = useState(false);
  const [addToInventoryLoading, setAddToInventoryLoading] = useState(false);

  const [showImportModal, setShowImportModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const [showInfoModal, setShowInfoModal] = useState({
    status: false,
    product: null,
  });

  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      setInitialLoading(true);
      const response = await authenticatedInstance.get("/import/fetchAll");
      console.log(response.data);
      setProducts(response.data);
      setFilteredProducts(response.data);
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setFilteredProducts([...products]);
    setFilterValue("");
    setFilteredPlatforms([]);
  }, [products]);

  useEffect(() => {
    let timeout;
    const handleChange = () => {
      const filteredData = products.filter((product) => {
        const title = product.data.title || "";
        const description = product.data.description || "";

        let shouldReturn = true;
        if (
          filterValue == "" ||
          (title && title.toLowerCase().includes(filterValue.toLowerCase())) ||
          (!title &&
            description &&
            description.toLowerCase().includes(filterValue.toLowerCase()))
        ) {
          shouldReturn = shouldReturn && true;
        } else {
          shouldReturn = shouldReturn && false;
        }

        if (filteredPlatforms.length > 0) {
          shouldReturn =
            shouldReturn &&
            filteredPlatforms.includes(product.platform.toLowerCase());
        }

        // console.log(shouldReturn);

        return shouldReturn;
      });

      setFilteredProducts([...filteredData]);
    };
    timeout = setTimeout(() => {
      handleChange();
    });

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [filterValue, filteredPlatforms]);

  const deleteProduct = async () => {
    try {
      const response = await authenticatedInstance.post("/import/delete", {
        id: dleteConfirmationModal.id,
      });
      // console.log(response.data);
      setDeleteConfirmationModal({
        open: false,
        id: null,
      });

      setProducts((prv) => {
        return prv.filter(
          (product) => product.id !== dleteConfirmationModal.id
        );
      });

      toast.success("Product deleted successfully");

      // fetchProducts();
    } catch (e) {
      setDeleteConfirmationModal({
        open: false,
        id: null,
      });
      toast.error(e.response.data.message);
      console.log(e);
    }
  };

  const addProductToInventory = async () => {
    try {
      setAddToInventoryModal({
        open: false,
        id: null,
      });
      setAddToInventoryLoading(true);
      const response = await authenticatedInstance.post(
        "/import/addToInventory",
        {
          id: addToInventoryModal.id,
        }
      );
      setAddToInventoryLoading(false);
      console.log(response.data);
      setProducts((prv) => {
        return prv.map((product) => {
          if (product.id === addToInventoryModal.id) {
            return response.data;
          }
          return product;
        });
      });
      toast.success("Product added to inventory successfully");
    } catch (e) {
      setAddToInventoryLoading(false);
      toast.error(
        e.response.data.message || "Error adding product to inventory"
      );
      console.log(e);
    }
  };

  const addProductToInventoryBulk = async () => {
    try {
      setAddToInventoryBulkModal(false);
      setAddToInventoryLoading(true);
      const response = await authenticatedInstance.post(
        "/import/addToInventoryBulk",
        {
          ids: selectedRows,
        }
      );

      console.log(response.data.addedProducts);
      setAddToInventoryLoading(false);

      setSelectedRows([]);

      toast.success("Products will be added to inventory and wants");

      navigate("/tasks");
    } catch (e) {
      setAddToInventoryLoading(false);
      toast.error(
        e.response.data.message || "Error adding product to inventory"
      );
      console.log(e);
    }
  };

  const deleteBulk = async () => {
    try {
      setDeleteBulkModal(false);
      const response = await authenticatedInstance.post("/import/deleteBulk", {
        ids: selectedRows,
      });
      console.log(response.data);
      setSelectedRows([]);
      setProducts((prv) => {
        return prv.filter(
          (product) => !response.data.deletedIds.includes(product.id)
        );
      });
      toast.success("Products deleted successfully");
    } catch (e) {
      setDeleteBulkModal(false);
      toast.error(e.response.data.message);
      console.log(e);
    }
  };

  const getType = (params) => {
    if (
      params.row &&
      params.row.alliwantData &&
      params.row.alliwantData.itemType == "1"
    ) {
      return "Wants";
    } else {
      return "Sale";
    }
  };

  const columns = [
    {
      field: "Name",
      headerName: "Name",

      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            <img
              className="w-8 h-8 rounded-full mr-2 bg-cover object-cover"
              src={
                params?.row && params.row.images && params.row.images.length > 0
                  ? params.row.images[0].url
                  : "/images/Avatar.png"
              }
            />
            <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate capitalize">
              {params.row && params.row.data && (params.row.data.title || "-")}
            </div>
          </div>
        );
      },
    },
    {
      field: "Price",
      headerName: "Price",
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000] capitalize">
            ${params.row && params.row.data && (params.row.data.price || "-")}
          </div>
        );
      },
    },

    {
      field: "Platform",
      headerName: "Platform",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        switch (params.row && params.row?.platform) {
          // switch ('Mercari') {
          case "poshmark":
            return (
              <Tippy content={params.row?.platform} arrow={true}>
                <div className="">
                  <svg
                    version="1.2"
                    className="h-5 w-16 mx-auto text-gray-600 mt-2"
                    baseProfile="tiny-ps"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1446 1621"
                  >
                    <path
                      id="Layer"
                      class="shp0"
                      d="M976.02 0.37C1230.96 -4.26 1441.08 199.42 1445.72 452.48C1451.9 708.62 1249.5 920.01 993.02 926.18L993.02 754.91C1147.52 745.65 1268.04 617.58 1268.04 463.28C1268.04 302.8 1138.25 171.65 976.02 171.65C815.34 171.65 684.01 301.26 684.01 463.28L684.01 1191.58L512.5 1191.58L512.5 463.28C512.5 208.68 719.54 0.37 976.02 0.37L976.02 0.37ZM756.62 429.33L928.13 429.33L928.13 1157.64C928.13 1406.06 730.36 1611.28 481.6 1620.54C225.12 1629.8 10.36 1430.75 1.09 1174.61C-8.18 918.47 191.13 703.99 447.61 694.73L447.61 866.01C293.11 875.26 172.59 1003.33 172.59 1157.64C172.59 1318.11 302.38 1449.27 464.61 1449.27C625.29 1449.27 756.62 1319.65 756.62 1157.64L756.62 429.33Z"
                    />
                  </svg>
                </div>
              </Tippy>
            );
          case "shopify":
            return (
              <Tippy content={params.row?.platform} arrow={true}>
                <div className="p-5">
                  <SiShopify className="h-8 w-8 text-gray-600 mt-2" />
                </div>
              </Tippy>
            );

          case "mercari":
            return (
              <Tippy content={params.row?.platform} arrow={true}>
                <div className="">
                  <svg
                    className="h-8 w-8 mx-auto text-gray-600 mt-2"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.79492 36.25V12.4925L18.6733 36.25L30.5524 12.4925V36.25M27.7499 8.0525L28.9033 3.75L33.2066 4.9025L32.0533 9.20583L27.7499 8.0525Z"
                      stroke="#3F3F46"
                      stroke-width="0.9375"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </Tippy>
            );

          case "kidizen":
            return (
              <Tippy content={params.row?.platform} arrow={true}>
                <div className="">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-9 w-auto p-2.5 text-gray-600"
                    viewBox="0 0 481.000000 105.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,105.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M930 1033 c-39 -20 -70 -72 -70 -119 0 -71 86 -143 155 -130 78 15
         129 97 105 170 -24 74 -121 114 -190 79z"
                      />
                      <path
                        d="M2192 1034 c-78 -40 -90 -160 -22 -218 45 -37 90 -43 144 -17 71 35
         96 113 56 178 -38 63 -116 88 -178 57z"
                      />
                      <path d="M0 485 l0 -455 120 0 120 0 0 455 0 455 -120 0 -120 0 0 -455z" />
                      <path
                        d="M1780 786 l0 -155 -51 34 c-66 45 -118 58 -204 53 -106 -7 -190 -55
         -250 -142 -95 -140 -81 -344 33 -465 68 -72 144 -105 247 -105 70 -1 87 3 134
         29 30 16 59 38 65 47 17 28 26 21 26 -22 l0 -40 115 0 115 0 0 460 0 460 -115
         0 -115 0 0 -154z m-80 -281 c94 -49 114 -179 38 -252 -55 -53 -117 -66 -182
         -39 -121 51 -134 214 -23 285 39 25 124 28 167 6z"
                      />
                      <path d="M418 823 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
                      <path d="M344 788 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
                      <path
                        d="M710 765 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25 -28
         25 -3 0 5 -11 18 -25z"
                      />
                      <path d="M311 724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
                      <path
                        d="M3527 719 c-80 -12 -138 -39 -196 -94 -69 -64 -94 -126 -99 -245 -3
         -79 0 -104 18 -152 27 -73 93 -147 163 -181 138 -67 341 -55 448 27 38 29 99
         116 99 142 0 5 -50 8 -110 7 -102 -1 -113 -3 -141 -27 -96 -81 -249 -14 -249
         109 l0 25 260 0 261 0 -6 53 c-26 213 -132 323 -330 340 -33 2 -86 1 -118 -4z
         m161 -159 c29 -18 72 -73 72 -92 0 -5 -65 -8 -145 -8 -95 0 -145 4 -145 10 0
         25 33 72 63 90 42 26 112 26 155 0z"
                      />
                      <path
                        d="M4489 720 c-59 -10 -85 -23 -131 -68 l-38 -36 0 47 0 47 -115 0 -115
         0 0 -340 0 -340 115 0 115 0 0 205 c0 223 4 243 56 284 30 23 97 28 137 10 13
         -6 32 -25 43 -42 17 -29 19 -54 19 -244 l0 -213 118 0 117 0 0 235 c0 133 -5
         256 -11 286 -26 121 -160 194 -310 169z"
                      />
                      <path d="M890 365 l0 -335 120 0 120 0 0 335 0 335 -120 0 -120 0 0 -335z" />
                      <path d="M2150 365 l0 -335 120 0 120 0 0 335 0 335 -120 0 -120 0 0 -335z" />
                      <path
                        d="M2510 605 l0 -94 146 -3 145 -3 -160 -234 c-89 -129 -161 -236 -161
         -238 0 -2 155 -3 345 -3 l345 0 0 95 0 94 -149 3 -149 3 159 233 c88 128 159
         235 159 237 0 3 -153 5 -340 5 l-340 0 0 -95z"
                      />
                      <path d="M311 674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
                      <path d="M736 623 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
                      <path d="M350 596 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
                      <path
                        d="M709 583 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
         -17z"
                      />
                      <path
                        d="M659 543 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
         -17z"
                      />
                      <path d="M390 546 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
                      <path d="M609 503 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
                      <path d="M420 506 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
                      <path d="M559 463 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
                      <path d="M460 456 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
                      <path
                        d="M519 433 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
         -17z"
                      />
                      <path
                        d="M404 330 c-49 -40 -93 -75 -98 -80 -4 -4 25 -56 65 -114 l73 -106
         153 0 153 0 -116 172 c-65 95 -122 179 -129 186 -9 10 -29 -2 -101 -58z"
                      />
                    </g>
                  </svg>
                </div>
              </Tippy>
            );
          case "depop":
            return (
              <Tippy content="Depop" arrow={true}>
                <svg
                  className="h-15 w-20 mt-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1 -1 1296 402"
                >
                  <path d="M-1-1h582v402H-1z" fill="none" />
                  <path
                    d="M0 167.9c0-54.5 38.884-88 101.06-88h64.373V0H246v251H100.76C38.883 251 0 218.8 0 167.9zm85-2.7c0 20.9 12.653 31.8 36.96 31.8H165v-64h-42.64C98.05 133 85 144 85 165.2zm682-.1c0 54.5-38.884 88-101.06 88h-64.373V333H521V82h145.24C728.117 82 767 114.2 767 165.1zm-85 2.7c0-20.9-12.668-31.8-37.007-31.8H602v64h42.693c24.34 0 37.307-11 37.307-32.2zm613-2.7c0 54.5-38.884 88-101.06 88h-64.373V333H1049V82h145.24c61.876 0 100.76 32.2 100.76 83.1zm-85 2.7c0-20.9-12.668-31.8-37.007-31.8H1130v64h42.693c24.34 0 37.307-11 37.307-32.2zm-419-1.2c0-60.996 48.8-93.6 116-93.6s116 32.604 116 93.6c0 60.993-48.8 94.4-116 94.4-67.2-.1-116-33.407-116-94.4zm153-.95c0-29.733-14.9-44.65-37.5-44.65S869 135.917 869 165.65c0 29.332 14.9 45.35 37.5 45.35 22.7-.1 37.5-16.018 37.5-45.35zm-450 4.262C494 108.918 454.77 72 383.898 72 315.82 72 272 108.918 272 166.702 272 225.19 315.422 260 387.79 260c45.52 0 82.253-17.054 101.618-44.743l-57.696-21.067c-9.982 10.935-25.055 16.954-40.926 16.954-23.857 0-40.427-9.63-44.72-25.983-.3-1.102-.5-2.106-.698-3.31H494v-11.938zm-148-25.913c1.902-19.814 15.62-32 39.45-32 23.832 0 37.55 12.88 37.55 32z"
                    fill="#ff2300"
                  />
                </svg>
              </Tippy>
            );

          case "alliwant":
            return (
              <Tippy content="All I Want" arrow={true}>
                <img
                  width="30"
                  style={{
                    // marginTop: "15px",
                    marginLeft: "10px",
                    // marginRight: "auto",
                  }}
                  src={require("../Assets/alliwantlogo.png")}
                ></img>
              </Tippy>
            );
          default:
            return (
              <div className="cursor-pointer font-[400] font-Inter text-[13.5px] text-[#000000de]">
                {params.row && params.row.platform}
              </div>
            );
        }
      },
    },
    {
      field: "Type",
      headerName: "Type",

      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate capitalize">
              {getType(params)}
            </div>
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        switch (params.row && params.row?.status) {
          case "listed":
            return (
              <Tippy content={params.row?.status} arrow={true}>
                <div className="flex items-center bg-[#ECFDF3] py-1 px-2.5 rounded gap-x-1">
                  <p className="bg-green-600 rounded-full !h-2 !w-2"></p>
                  <p className="cursor-pointer font-[500] font-Inter text-xs text-[#027A48]">
                    Listed
                  </p>
                </div>
              </Tippy>
            );
          default:
            return (
              <Tippy content={params.row?.status} arrow={true}>
                <div className="flex items-center bg-[#FEF3F2] py-1 px-2.5 rounded gap-x-1">
                  <p className="bg-red-600 rounded-full !h-2 !w-2"></p>
                  <p className="cursor-pointer font-[500] font-Inter text-xs text-[#D92D20]">
                    Sold
                  </p>
                </div>
              </Tippy>
            );
        }
      },
    },

    {
      field: "Icons",
      headerName: "Actions",
      flex: 1.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate capitalize flex gap-3.5 justify-center items-center w-full">
            {/* {console.log(params.row)} */}

            <Tippy content="details" arrow={true}>
              <InfoIcon
                className="cursor-pointer outline-none"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (params.row.id) {
                    setShowInfoModal({
                      status: true,
                      product: params.row,
                    });
                  }
                }}
              ></InfoIcon>
            </Tippy>
            {params.row && !params.row.inventoryId && !params.row.wantsId && (
              <Tippy
                content={
                  getType(params) == "Wants"
                    ? "Add to Wants"
                    : "Add to Inventory"
                }
                arrow={true}
              >
                <AddIcon
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (params.row.id) {
                      setAddToInventoryModal({
                        open: true,
                        id: params.row.id,
                      });
                    }
                  }}
                  className="cursor-pointer outline-none"
                ></AddIcon>
              </Tippy>
            )}

            <Tippy content="delete" arrow={true}>
              <DeleteIcon
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (params.row.id) {
                    setDeleteConfirmationModal({
                      open: true,
                      id: params.row.id,
                    });
                  }
                }}
                className="cursor-pointer outline-none"
              ></DeleteIcon>
            </Tippy>
          </div>
        );
      },
    },
  ];

  return (
    <div className="py-4 fade-in w-full overflow-y-auto min-h-screen relative">
      <div className="flex-1 px-7  w-full flex items-center justify-center sm:items-stretch sm:justify-between pb-[5.9px]">
        <div className="flex justify-between sm:block gap-x-1 sm:gap-x-0 items-center">
          <p className="title fade-in">Import</p>
        </div>
        <div className="flex item-center gap-x-5">
          <div className="group relative">
            <button
              onClick={() => {
                console.log("hi");
                setShowImportModal(true);
              }}
              className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
            >
              <LuUploadCloud className="h-4 w-4 mt-1 text-white" />
              Import
            </button>
          </div>
        </div>
      </div>
      <hr className="bg-gray-200 h-[1.5px] mt-3" />

      <div className="mt-8">
        <div>
          <div className="w-full px-8 fade-in shadow-sm">
            <div className="w-full px-5 pt-3 pb-1.5 border-[1px] border-[#EAECF0] rounded-lg">
              <div className="flex item-center justify-between align-middle">
                <div className="">
                  <p className="text-lg text-secondary  text-Inter">
                    Imported Products
                  </p>
                  <p className="text-sm text-secondarySupport text-Inter">
                    List of all the imported products will appear here.
                  </p>
                </div>
                <div className="flex items-center">
                  <div className="mr-2">
                    <Select
                      className="border-none min-w-[200px] pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                      options={[
                        { value: "alliwant", label: "All I Want" },
                        { value: "kidizen", label: "Kidizen" },
                        { value: "depop", label: "Depop" },
                        { value: "poshmark", label: "Poshmark" },
                        { value: "mercari", label: "Mercari" },
                        { value: "shopify", label: "Shopify" },
                      ]}
                      isMulti
                      placeholder="Select platforms"
                      // value={
                      //   values.category &&
                      //   categoriesOptions.find((option) => {
                      //     return option.value === values.category;
                      //   })
                      // }
                      onChange={(selectedOptions) => {
                        setFilteredPlatforms(() => {
                          return selectedOptions.map((option) => {
                            return option.value;
                          });
                        });
                        console.log(selectedOptions);
                      }}
                      // onBlur={saveDataHandler}
                      // onChange={onChange}
                    />
                  </div>
                  <div className="mr-5 flex items-center border-gray-200 border-[2px] px-1.5 py-1.5 rounded-lg">
                    <input
                      // ref={inputRef}
                      type="text"
                      className="w-[200px] bg-white text-gray-600 outline-none"
                      placeholder="search title..."
                      // value={filterValue}
                      onChange={(event) => {
                        setFilterValue(event.target.value);
                      }}
                      value={filterValue}
                    ></input>
                    {filterValue.length > 0 && (
                      <span>
                        <MdClose
                          onClick={() => {
                            setFilterValue("");
                          }}
                          color="rgb(141 142 145)"
                          className="cursor-pointer"
                        ></MdClose>
                      </span>
                    )}
                  </div>
                </div>
                {selectedRows.length > 0 && (
                  <div className="flex gap-5 mx-20">
                    <button
                      onClick={() => {
                        setAddToInventoryBulkModal(true);
                      }}
                      className="btn-primary flex item-center text-Inter justify-center align-middle"
                      style={{
                        height: "40px",
                      }}
                    >
                      Add Products To Inventory
                    </button>
                    <Tippy content="Delete" arrow={true}>
                      <button
                        onClick={() => {
                          setDeleteBulkModal(true);
                        }}
                        style={{
                          height: "40px",
                        }}
                      >
                        <DeleteIcon style={{ color: "red" }}></DeleteIcon>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>

              {initialLoading && (
                <div
                  style={{ height: 600, width: "97%" }}
                  className="flex justify-center items-center"
                >
                  <Ring color="orange" speed={2}></Ring>
                </div>
              )}
              {!initialLoading && (
                <div style={{ height: 600, width: "97%" }} className=" mt-5">
                  <DataGrid
                    checkboxSelection
                    sx={{
                      overflowX: "scroll",
                      border: 0,
                      "& .css-17jjc08-MuiDataGrid-footerContainer": {
                        borderTop: 0,
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "500",
                        fontSize: "13px",
                        lineHeight: "18px",
                        fontFamily: "Inter",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.15rem",
                      },
                      "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none",
                      },
                      "& .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                      },
                      "& .MuiDataGrid-selectedRowCount": {
                        visibility: "hidden",
                      },
                    }}
                    rows={filteredProducts}
                    columns={columns}
                    getRowId={(row) => row.id}
                    onRowClick={(row) => {
                      setSelectedRows([]);
                      setShowInfoModal({
                        status: true,
                        product: row.row,
                      });
                    }}
                    rowSelectionModel={[...selectedRows]}
                    onRowSelectionModelChange={(rows) => {
                      setSelectedRows([...rows]);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {dleteConfirmationModal.open && (
        <ConfirmationModal
          heading={"Delete Import Product Confirmation"}
          message={"Are you sure you want to delete this product from impprts"}
          onCancel={() => {
            setDeleteConfirmationModal({
              open: false,
              id: null,
            });
          }}
          onConfirm={() => {
            deleteProduct();
          }}
        ></ConfirmationModal>
      )}
      {deleteBulkModal && (
        <ConfirmationModal
          heading={"Delete Selected Products Confirmation"}
          message={"Are you sure you want to delete selected products ?"}
          onCancel={() => {
            setDeleteBulkModal(false);
          }}
          onConfirm={() => {
            deleteBulk();
          }}
        ></ConfirmationModal>
      )}

      {addToInventoryModal.open && (
        <ConfirmationModal
          heading={"Add Product to Inventory"}
          message={"Are you sure you want to add this product to inventory ?"}
          onCancel={() => {
            setAddToInventoryModal({
              open: false,
              id: null,
            });
          }}
          onConfirm={() => {
            addProductToInventory();
          }}
        ></ConfirmationModal>
      )}
      {addToInventoryBulkModal && (
        <ConfirmationModal
          heading={"Add Products to Inventory"}
          message={
            "Are you sure you want to add selected products to inventory ?"
          }
          onCancel={() => {
            setAddToInventoryBulkModal(false);
          }}
          onConfirm={() => {
            addProductToInventoryBulk();
          }}
        ></ConfirmationModal>
      )}
      {addToInventoryLoading && (
        <Modal width={"w-1/4"}>
          <div className=" flex flex-col gap-2 justify-center items-center">
            <Ring speed={1} size={30} color="orange" />
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Adding Products to Inventory and Wants
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              Please wait while we add products to inventory and wants.
            </p>
          </div>
        </Modal>
      )}
      {showImportModal && (
        <ImportModal
          cancelImport={() => {
            setShowImportModal(false);
          }}
        ></ImportModal>
      )}
      {showInfoModal.status && (
        <div className="fixed inset-0 bg-black fade-in bg-opacity-25 backdrop-blur-sm z-[9999] min-h-screnn h-full flex justify-center items-center ">
          <div
            class="block rounded-lg bg-white text-center shadow-secondary-1 text-surface relative"
            style={{
              minWidth: "600px",
              maxWidth: "600px",
            }}
          >
            <div class="flex flex-row justify-between px-6 py-3">
              <div>{platformLogos[showInfoModal.product.platform]}</div>
              <div className="flex flex-row gap-3">
                <button
                  className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
                  onClick={() => {
                    setShowInfoModal({
                      status: false,
                      product: null,
                    });
                    setSelectedRows([]);
                    setAddToInventoryModal({
                      open: true,
                      id: showInfoModal.product.id,
                    });
                  }}
                >
                  Add To Inventory
                </button>
                <button
                  onClick={() => {
                    setShowInfoModal({
                      status: false,
                      product: null,
                    });
                    setSelectedRows([]);
                    setDeleteConfirmationModal({
                      open: true,
                      id: showInfoModal.product.id,
                    });
                  }}
                >
                  <Tippy content="Delete" arrow={true}>
                    <DeleteIcon
                      style={{
                        color: "#d11a2a",
                      }}
                    ></DeleteIcon>
                  </Tippy>
                </button>
                <button
                  onClick={() => {
                    setShowInfoModal({
                      status: false,
                      product: null,
                    });
                    setSelectedRows([]);
                  }}
                >
                  <Tippy content="close" arrow={true}>
                    <CloseIcon
                      style={{
                        color: "#d11a2a",
                        outline: "none",
                      }}
                    ></CloseIcon>
                  </Tippy>
                </button>
              </div>
            </div>
            <div class="flex flex-col bg-white border  md:flex-row  rounded-b-lg">
              <img
                class="object-cover w-full  md:w-48  h-[200px] rounded-bl-lg"
                src={showInfoModal.product.images[0].url}
                alt=""
              ></img>
              <div class="p-6 w-full">
                <div class="flex items-center justify-between mb-2 w-full">
                  <p class="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900">
                    {showInfoModal.product.data.title}
                  </p>
                  <p class="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900">
                    ${showInfoModal.product.data.price}
                  </p>
                </div>
                <p
                  class="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75 text-left "
                  style={{
                    maxWidth: "500px",
                  }}
                >
                  {showInfoModal.product.data.description &&
                  showInfoModal.product.data.description.length > 100
                    ? showInfoModal.product.data.description.slice(0, 100) +
                      "..."
                    : showInfoModal.product.data.description
                    ? showInfoModal.product.data.description
                    : "-"}
                </p>
                {showInfoModal.product.status === "listed" ? (
                  <Tippy content="listed" arrow={true}>
                    <div className="flex items-center bg-[#ECFDF3] py-1 px-2.5 rounded gap-x-1 w-[80px] absolute bottom-8">
                      <p className="bg-green-600 rounded-full !h-2 !w-2"></p>
                      <p className="cursor-pointer font-[500] font-Inter text-xs text-[#027A48]">
                        Listed
                      </p>
                    </div>
                  </Tippy>
                ) : (
                  <Tippy content="sold" arrow={true}>
                    <div className="flex items-center bg-[#FEF3F2] py-1 px-2.5 rounded gap-x-1 w-[80px] absolute bottom-8">
                      <p className="bg-red-600 rounded-full !h-2 !w-2"></p>
                      <p className="cursor-pointer font-[500] font-Inter text-xs text-[#D92D20]">
                        Sold
                      </p>
                    </div>
                  </Tippy>
                )}
              </div>
            </div>
            {/* <button
              onClick={() => {
                setShowInfoModal({
                  status: false,
                  product: null,
                });
                setSelectedRows([]);
              }}
            >
              cut
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Import;
