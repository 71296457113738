const initState = {
    loading: false,
    user: null,
    connectedPlatforms: [],
    isOtpRequired: null,
};

export default (state = initState, action) => {
    switch (action.type) {
        case "CHANGE_LOADING_STATE":
            return {
                ...state,
                loading: !state.loading,
            };
        case "SET_USER":
            return {
                ...state,
                user: action.payload,
            };
        case "SET_PLATFORMS":
            // console.log("Triggered", action.payload)
            return {
                ...state,
                connectedPlatforms: action.payload,
            };
        case "CONNECTION_OTP_REQUIREMENT":
            return {
                ...state,
                isOtpRequired: action.payload,
            };
        case "UPDATE_PLATFORM_STATUS":
            const newConnectedPlatforms = [...state.connectedPlatforms];
            for (let i = 0; i < newConnectedPlatforms.length; i++) {
                if (
                    newConnectedPlatforms[i].platform ===
                    action.payload.platform
                ) {
                    newConnectedPlatforms[i].status = action.payload.status;
                }
            }
            return {
                ...state,
                connectedPlatforms: newConnectedPlatforms,
            };
        default:
            return state;
    }
};
