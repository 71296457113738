import React from "react";
import Select from "react-select";
import {
  categories,
  conditions,
  subCategories,
  brands,
  SizeOptions,
} from "./KidizenDropdowns";
import { Ring } from "@uiball/loaders";

function kidizen({
  data,
  images,
  changeKidizenValueHandler,
  dataFetched,
  saveDataHandler,
}) {
  // console.log(data);
  const values = {
    title: "",
    category: "",
    subCategory: "",
    gender: "",
    size: "",
    brand: "",
    condition: "",
    description: "",
    price: "",
  };

  let GenderOptions = [
    { value: "Girl", label: "Girl" },
    { value: "Boy", label: "Boy" },
    { value: "All Genders", label: "All Genders" },
  ];

  const options = [
    {
      label: "Group 1",
      options: [
        { value: "value1", label: "Option 1" },
        { value: "value2", label: "Option 2" },
      ],
    },
    {
      label: "Group 2",
      options: [
        { value: "value3", label: "Option 3" },
        { value: "value4", label: "Option 4" },
      ],
    },
  ];

  let subCategoryOptions = [];
  let actualSizeOptions = [];
  let selectedSize = null;
  if (data && data.kidizen && data.generalInfo) {
    // console.log(data);
    values.title =
      "title" in data.kidizen ? data.kidizen.title : data.generalInfo.title;
    values.category = data.kidizen.category;
    values.subCategory = data.kidizen.subcategory;
    values.gender = data.kidizen.gender;
    values.size = data.kidizen.size;
    values.brand = data.kidizen.brand;

    values.condition = data.kidizen.condition;
    values.description =
      "description" in data.kidizen
        ? data.kidizen.description
        : data.generalInfo.description;
    values.price =
      "price" in data.kidizen ? data.kidizen.price : data.generalInfo.price;

    console.log("subCategoryOptions", subCategories[values.category]);

    if (
      subCategories[values.category] &&
      subCategories[values.category].length > 0
    ) {
      subCategoryOptions = subCategories[values.category];
    } else {
      subCategoryOptions = [];
    }

    if (
      SizeOptions[values.category] &&
      SizeOptions[values.category].length > 0
    ) {
      actualSizeOptions = SizeOptions[values.category];
    } else {
      actualSizeOptions = [];
    }

    if (
      ["Maternity", "Mama Clothing", "Mama Shoes", "Mama Accessories"].includes(
        values.category
      )
    ) {
      GenderOptions = [{ value: "Woman", label: "Woman" }];
    }

    for (let i = 0; i < actualSizeOptions.length; i++) {
      for (let j = 0; j < actualSizeOptions[i].options.length; j++) {
        if (actualSizeOptions[i].options[j].value === values.size) {
          selectedSize = actualSizeOptions[i].options[j];
          break;
        }
      }
    }
  }
  // console.log(values);

  return (
    <>
      <div className="grid grid-cols-12 gap-x-3">
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Title
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Name of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={(e) => {
                saveDataHandler();
              }}
              value={values.title}
              required
              onChange={(e) => {
                changeKidizenValueHandler("title", e.target.value);
              }}
            />
          </div>
          {values.title == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Description
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Describe your product in detail
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <textarea
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="description"
              value={values.description}
              onChange={(e) => {
                changeKidizenValueHandler("description", e.target.value);
              }}
              onBlur={saveDataHandler}
              placeholder="Enter description"
              // onBlur={onBlur}
              required
            />
          </div>
          {values.description == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={categories}
              placeholder="Select category"
              value={
                values.category &&
                categories.find((option) => {
                  return option.value === values.category;
                })
              }
              onChange={(selectedOption) => {
                changeKidizenValueHandler("category", selectedOption.value);
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.category == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Sub Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the sub category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={subCategoryOptions}
              placeholder="Select sub category"
              value={
                values.subCategory &&
                subCategoryOptions.find((option) => {
                  return option.value === values.subCategory;
                })
              }
              onChange={(selectedOption) => {
                changeKidizenValueHandler("subcategory", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.subCategory == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Gender
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the Gender of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={GenderOptions}
              placeholder="Select gender"
              value={
                values.gender &&
                GenderOptions.find((option) => {
                  return option.value === values.gender;
                })
              }
              onChange={(selectedOption) => {
                changeKidizenValueHandler("gender", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.gender == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Size
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the size of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={actualSizeOptions}
              placeholder="Select size"
              value={selectedSize}
              onChange={(selectedOption) => {
                changeKidizenValueHandler("size", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.size == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Brand
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Brand of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={brands}
              placeholder="Select brand"
              value={brands.find((option) => {
                return option.value === values.brand;
              })}
              onChange={(selectedOption) => {
                changeKidizenValueHandler("brand", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.brand == "" && dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Condition
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              condition of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={conditions}
              placeholder="Select condition"
              value={conditions.find((option) => {
                return option.value === values.condition;
              })}
              onChange={(selectedOption) => {
                changeKidizenValueHandler("condition", selectedOption.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.condition == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Price of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="price"
              onChange={(e) => {
                changeKidizenValueHandler("price", e.target.value);
              }}
              onBlur={saveDataHandler}
              placeholder="Enter price"
              // onBlur={onBlur}
              value={values.price}
              required
            />
          </div>
          {values.price == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default kidizen;
