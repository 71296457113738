const initState = {
    allWantData: [],
};

export default (state = initState, action) => {
    switch (action.type) {
        case "GET_ALL_WANTS":
            return {
                ...state,
                allWantData: action.payload,
            };
        case "CREATE_WANTS":
            console.log(action.payload, "action.payload");
            return {
                ...state,
                allWantData: [...state.allWantData, action.payload],
            };
        case "DELETE_WANTS":
            return {
                ...state,
                allWantData: state.allWantData.filter(
                    (item) => item.id !== action.payload.id
                ),
            };
        case "UPDATE_WANTS":
            return {
                ...state,
                allWantData: state.allWantData.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                }),
            };

        default:
            return state;
    }
};
