import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal } from "../Modal";
import { SaveCredentials } from "../../redux/actions/dashboard";
import { emitter } from "../utils";
import InfoIcon from "@mui/icons-material/Info";
import Tippy from "@tippyjs/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const ConnectionModal = (props) => {
  const {
    SaveCredentials,
    modalStatus,
    setModalStatus,
    connectingStatus,
    setConnectingStatus,
    connectedPlatforms,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_STAGING_DETAILS" });
    };
  }, []);
  const [credential, setCredential] = useState({
    email: "",
    password: "",
    username: "",
  });
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usernameErr, setUsernameErr] = useState(null);
  const [showPass, setShowPass] = useState(false);

  // handle Input
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    if (name === "email") setEmailError(null);
    if (name === "password") setPasswordError(null);
    if (name === "username") setUsernameErr(null);
    value = e.target.value;
    setCredential({ ...credential, [name]: value });
  };
  const updateConnectionState = (connectionStatus) => {
    console.log("updating connection status", connectionStatus);
    let platformsStatus = [...connectedPlatforms];
    console.log(platformsStatus, connectedPlatforms);

    if (
      !platformsStatus.find(
        (platform) => platform.platform === modalStatus.platform
      )
    ) {
      platformsStatus.push({
        platform: modalStatus.platform,
        status: connectionStatus,
      });
    } else {
      platformsStatus = platformsStatus.map((platform) => {
        console.log(platform);
        if (platform.platform === modalStatus.platform) {
          return {
            platform: platform.platform,
            status: connectionStatus,
          };
        }
        return platform;
      });
    }
    dispatch({
      type: "SET_PLATFORMS",
      payload: platformsStatus,
    });
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let isValid = true;
      const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      if (!credential.email || !emailRegex.test(credential.email)) {
        isValid = false;
        setEmailError("Please provide valid email");
      }
      if (!credential.password || credential.password === "") {
        isValid = false;
        setPasswordError("Please provide valid password");
      }
      if (!credential.username || credential.username === "") {
        isValid = false;
        setUsernameErr("Please provide valid username");
      }
      if (!isValid) return;
      // setLoading(true)
      // setConnectingStatus((prevStatus) => {
      //     return {
      //         ...prevStatus,
      //         [modalStatus.platform]: 'Connecting'
      //     }
      // });
      const tempCred = credential;

      setCredential({
        email: "",
        password: "",
        username: "",
      });
      setModalStatus(false);
      updateConnectionState("connecting");

      SaveCredentials(modalStatus.platform, tempCred);
      emitter.once(
        `connectionStatusFullfilled/${modalStatus.platform}`,
        (connectionStatus) => {
          console.log("triggering");
          updateConnectionState(connectionStatus);
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <div className="overflow-auto relative h-[100%]">
        <div className="sticky top-0 z-10 bg-white">
          <div className="flex item-center gap-x-5">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_101_2)">
                <path
                  d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z"
                  fill="#FFDCD0"
                />
                <path
                  d="M20.5 38V33M20.5 23V18M18 20.5H23M18 35.5H23M29 19L27.2658 23.5089C26.9838 24.2421 26.8428 24.6087 26.6235 24.9171C26.4292 25.1904 26.1904 25.4292 25.9171 25.6235C25.6087 25.8428 25.2421 25.9838 24.5089 26.2658L20 28L24.5089 29.7342C25.2421 30.0162 25.6087 30.1572 25.9171 30.3765C26.1904 30.5708 26.4292 30.8096 26.6235 31.0829C26.8428 31.3913 26.9838 31.7579 27.2658 32.4911L29 37L30.7342 32.4911C31.0162 31.7579 31.1572 31.3913 31.3765 31.0829C31.5708 30.8096 31.8096 30.5708 32.0829 30.3765C32.3913 30.1572 32.7579 30.0162 33.4911 29.7342L38 28L33.4911 26.2658C32.7579 25.9838 32.3913 25.8428 32.0829 25.6235C31.8096 25.4292 31.5708 25.1904 31.3765 24.9171C31.1572 24.6087 31.0162 24.2421 30.7342 23.5089L29 19Z"
                  stroke="#FF4405"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z"
                  stroke="#FFF4ED"
                  stroke-width="8"
                />
              </g>
              <defs>
                <clipPath id="clip0_101_2">
                  <rect width="56" height="56" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="mt-1">
              <p className="text-lg text-secondary font-semibold text-Inter capitalize">
                {`${modalStatus.platform} Credentials`}
              </p>
              <p className="text-sm leading-tight text-secondarySupport text-Inter">
                {`please provide ${modalStatus.platform} platform credentials here`}
              </p>
            </div>
          </div>
          <hr className="bg-gray-100 h-[1.15px] mt-3" />
        </div>

        <div>
          <div className="mt-5">
            <label
              htmlFor="email"
              className="text-[#4E5674] font-[500] font-Inter"
            >
              Email <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
              <input
                className="border-none outline-none bg-transparent w-full font-Inter"
                type="text"
                name="email"
                id="email"
                onChange={handleInputs}
                placeholder="Enter your email"
              />
            </div>
            {emailError && (
              <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {emailError}
              </span>
            )}
          </div>
          <div className="mt-5">
            <label
              htmlFor="email"
              className="text-[#4E5674] font-[500] font-Inter"
            >
              {modalStatus.platform === "mercari" ? "User Id" : "Username"}{" "}
              <span className="text-red-500">*</span>
              <Tippy
                content={`${
                  modalStatus.platform === "mercari" ? "userId" : "Username"
                } is available in profile section of ${modalStatus.platform}`}
                zIndex={10000000}
                arrow={true}
              >
                <InfoIcon
                  style={{
                    width: "18px",
                    height: "18px",
                    marginLeft: "5px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                ></InfoIcon>
              </Tippy>
            </label>

            <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
              <input
                className="border-none outline-none bg-transparent w-full font-Inter"
                type="text"
                name="username"
                id="username"
                onChange={handleInputs}
                placeholder={`Enter your ${
                  modalStatus.platform === "mercari" ? "User Id" : "Username"
                }`}
              />
            </div>
            {usernameErr && (
              <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {usernameErr}
              </span>
            )}
          </div>

          <div className="mt-4">
            <label
              htmlFor="password"
              className="text-[#4E5674] font-[500] font-Inter"
            >
              Password <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
              <input
                className="border-none outline-none bg-transparent w-full font-Inter"
                type={showPass ? "text" : "password"}
                name="password"
                id="password"
                onChange={handleInputs}
                placeholder="Password"
              />
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowPass((prv) => {
                    return !prv;
                  });
                }}
              >
                {showPass ? (
                  <VisibilityOffIcon
                    style={{ color: "#757575" }}
                  ></VisibilityOffIcon>
                ) : (
                  <VisibilityIcon style={{ color: "#757575" }}></VisibilityIcon>
                )}
              </span>
            </div>
            {passwordError && (
              <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {passwordError}
              </span>
            )}
          </div>
        </div>

        <div className="flex item-center justify-between mt-6 gap-x-5">
          <button
            onClick={() => {
              setModalStatus({
                status: false,
                platform: null,
              });
            }}
            className="btn-secondary flex item-center gap-x-1.5 text-Inter"
          >
            Cancel
          </button>
          {loading ? (
            <button
              disabled
              type="button"
              className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
            >
              <svg
                role="status"
                className="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  connectedPlatforms: state.auth.connectedPlatforms,
});

const mapDispatchToProps = { SaveCredentials };

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionModal);
