import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import platformList from "../Utils/platformList";
import { toast } from "sonner";
import { Modal } from "./Modal";
import { TiTick } from "react-icons/ti";
import { AiOutlineInfoCircle } from "react-icons/ai";
import AuthenticatedInstance from "../redux/actions/AxiosInstance/authenticated";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import InfoIcon from "@mui/icons-material/Info";

function ImportModal(props) {
  const { connectedPlatforms, cancelImport } = props;

  const [connectionStatus, setConnectionStatus] = useState({
    poshmark: false,
    shopify: false,
    ebay: false,
    mercari: false,
    etsy: false,
    kidizen: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (connectedPlatforms) {
      connectedPlatforms.forEach((item) => {
        setConnectionStatus((prev) => ({
          ...prev,
          [item.platform]: item.status,
        }));
      });
    }
  }, [connectedPlatforms]);

  const handleSubmit = async () => {
    try {
      if (selectedPlatforms.length === 0) {
        toast.error("Please select a platform to import from");
        return;
      }
      // console.log(selectedPlatforms);
      // return;
      setLoading(true);
      const response = await AuthenticatedInstance.post("/import/products", {
        platforms: selectedPlatforms,
      });
      setLoading(false);
      toast.success("product import started suucessfully");
      navigate("/tasks");
    } catch (e) {
      setLoading(false);
      let errMessage = e.response.data.message;
      toast.error(errMessage || "product import failed");
      console.log(e);
    }
  };

  return (
    <Modal width="lg:w-1/2">
      <div className="overflow-auto relative h-[100%]">
        <div className="flex item-center gap-x-5">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_101_2)">
              <path
                d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z"
                fill="#FFDCD0"
              />
              <path
                d="M20.5 38V33M20.5 23V18M18 20.5H23M18 35.5H23M29 19L27.2658 23.5089C26.9838 24.2421 26.8428 24.6087 26.6235 24.9171C26.4292 25.1904 26.1904 25.4292 25.9171 25.6235C25.6087 25.8428 25.2421 25.9838 24.5089 26.2658L20 28L24.5089 29.7342C25.2421 30.0162 25.6087 30.1572 25.9171 30.3765C26.1904 30.5708 26.4292 30.8096 26.6235 31.0829C26.8428 31.3913 26.9838 31.7579 27.2658 32.4911L29 37L30.7342 32.4911C31.0162 31.7579 31.1572 31.3913 31.3765 31.0829C31.5708 30.8096 31.8096 30.5708 32.0829 30.3765C32.3913 30.1572 32.7579 30.0162 33.4911 29.7342L38 28L33.4911 26.2658C32.7579 25.9838 32.3913 25.8428 32.0829 25.6235C31.8096 25.4292 31.5708 25.1904 31.3765 24.9171C31.1572 24.6087 31.0162 24.2421 30.7342 23.5089L29 19Z"
                stroke="#FF4405"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z"
                stroke="#FFF4ED"
                stroke-width="8"
              />
            </g>
            <defs>
              <clipPath id="clip0_101_2">
                <rect width="56" height="56" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div className="mt-1">
            <p className="text-lg text-secondary font-semibold text-Inter">
              Connected Platforms
            </p>
            <p className="text-sm leading-tight text-secondarySupport text-Inter">
              Please select the platform from which you want to import
            </p>
          </div>
        </div>
        <hr className="bg-gray-100 h-[1.15px] mt-3" />
      </div>
      <div className="mt-5 flex flex-wrap gap-y-4 justify-center item-center gap-x-4">
        {platformList.filter((item) => {
          if (connectionStatus[item.title]) {
            return true;
          }
          return false;
        }).length > 0 ? (
          platformList.map((item, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  if (
                    ![
                      "mercari",
                      "poshmark",
                      "kidizen",
                      "depop",
                      "shopify",
                      "alliwant",
                    ].includes(item.title)
                  ) {
                    toast.error("Currently we do not support " + item.title);
                    return;
                  }
                  if (
                    !connectionStatus[item.title] ||
                    connectionStatus[item.title] === "failed"
                  ) {
                    toast.error("Please connect " + item.title);
                    return;
                  }
                  if (selectedPlatforms.includes(item.title)) {
                    setSelectedPlatforms((prev) => {
                      return prev.filter((platform) => platform !== item.title);
                    });
                  }
                  if (!selectedPlatforms.includes(item.title)) {
                    setSelectedPlatforms((prev) => {
                      return [...prev, item.title];
                    });
                  }
                }}
                className={`w-fit py-2.5 px-5 border-[1px] cursor-pointer rounded-md shadow-sm 
                ${
                  selectedPlatforms.includes(item.title)
                    ? `border-green-200 bg-green-50`
                    : "border-slate-200"
                }
                 ${
                   !connectionStatus[item.title] ||
                   connectionStatus[item.title] === "failed"
                     ? "border-red-200 bg-red-50"
                     : ""
                 }`}
              >
                <div className="flex items-center gap-x-1">
                  {selectedPlatforms.includes(item.title) && (
                    <>
                      <TiTick className="h-5 w-5 text-green-600" />
                      <p className="text-xs font-[500] text-green-600">
                        Selected
                      </p>
                    </>
                  )}
                  {(!connectionStatus[item.title] ||
                    connectionStatus[item.title] === "failed") &&
                    !["etsy", "ebay"].includes(item.title) && (
                      <div className="w-[80px] flex gap-1 items-center">
                        <AiOutlineInfoCircle className="h-[16px] w-[16px] text-red-600" />
                        <p className="text-[10px] font-[500] text-red-600">
                          Not Connected
                        </p>
                      </div>
                    )}
                </div>
                {item.logo}
                {/* <p className="text-md text-gray-600 font-[500] mt-2 text-center capitalize">
                  {item.title}
                  <Tippy content={"hi"} arrow={true}>
                    <InfoIcon
                      style={{
                        fontSize: "1rem",
                        marginLeft: "0.5rem",
                        color: "#6B7280",
                        cursor: "pointer",
                      }}
                    ></InfoIcon>
                  </Tippy>
                </p> */}
                <p className="text-md text-gray-600 font-[500] mt-2 text-center capitalize">
                  {item.title}
                </p>
              </div>
            );
          })
        ) : (
          <p className="text-Inter text-md font-semibold text-gray-600">
            No connected platforms
          </p>
        )}
      </div>
      <div className="flex item-center justify-between mt-6 gap-x-5">
        <button
          onClick={() => {
            cancelImport();
          }}
          className="btn-secondary flex item-center gap-x-1.5 text-Inter"
        >
          Cancel
        </button>
        {loading ? (
          <button
            disabled
            type="button"
            className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
          >
            <svg
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Loading...
          </button>
        ) : (
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="btn-primary text-Inter flex item-center gap-x-1.5 text-Inter"
          >
            Import
          </button>
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  connectedPlatforms: state.auth.connectedPlatforms,
  listingId: state.staging.listingId,
});

export default connect(mapStateToProps)(ImportModal);
