import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { useDropzone } from "react-dropzone";
import { Ring } from "@uiball/loaders";
import { toast } from "sonner";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import Alliwant from "../staging/PlatformForms/Alliwant";
import CloseIcon from "@mui/icons-material/Close";

const CreateAllIWant = ({
  cancelHandler,
  modalType,
  viewingWant,
  newWantAddedHandler,
}) => {
  const [images, setImages] = React.useState(
    viewingWant ? viewingWant.images : []
  );
  const [wantsId, setWantsId] = React.useState(
    viewingWant ? viewingWant.id : null
  );
  const [dataFetched, setDataFetched] = React.useState(true);
  const [data, setData] = React.useState(viewingWant ? viewingWant.data : null);
  const [imageUploadingModal, setImageUploadingModal] = useState(false);
  const [autoFillImageId, setAutoFillImageId] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDrop = async (acceptedFiles) => {
    try {
      if (images.length + acceptedFiles.length > 4) {
        toast.error("You can only upload 3 images");
        return;
      }

      setImageUploadingModal(true);

      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      if (wantsId) {
        formData.append("wantsId", wantsId);
      }
      formData.append("type", "alliwant");

      const response = await authenticatedInstance.post(
        "/wants/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);

      setImageUploadingModal(false);

      setWantsId(response.data.wantsId);
      setAutoFillImageId(response.data.autoFillImageId);

      setImages([...images, ...response.data.images]);

      //   console.log(acceptedFiles);
      //   setImageUploadingModal(true);
    } catch (e) {
      console.log(e);
      setImageUploadingModal(false);
    }
  };

  const changeAlliwantValueHandler = (field, val) => {
    if (data && !viewingWant) {
      console.log(field, val);
      setData((prv) => {
        let newAlliwant = { ...prv.alliwant };
        newAlliwant[field] = val;
        if (field === "category") {
          newAlliwant["subcategory"] = null;
          newAlliwant["childsubcategory"] = null;
          newAlliwant["is_brand_filter"] = "0";
          newAlliwant["is_size_filter"] = "0";
          newAlliwant["is_color_filter"] = "0";
          newAlliwant["size"] = null;
          newAlliwant["sizegroup"] = null;
        }
        if (field === "subcategory") {
          newAlliwant["childsubcategory"] = null;
          newAlliwant["is_brand_filter"] = "0";
          newAlliwant["is_size_filter"] = "0";
          newAlliwant["is_color_filter"] = "0";
          newAlliwant["size"] = null;
          newAlliwant["sizegroup"] = null;
        }
        if (field === "deliveryMethod") {
          newAlliwant["pay_shipping_by"] = null;
          newAlliwant["shipping_type"] = null;
        }
        if (field === "shipping_type") {
          newAlliwant["packagesize"] = null;
          newAlliwant["shipping_carrier"] = null;
        }
        if (field === "packagesize") {
          newAlliwant["shipping_carrier"] = null;
        }
        if (field === "childsubcategory") {
          newAlliwant["size"] = null;
          newAlliwant["sizegroup"] = null;
        }
        console.log(newAlliwant);
        return {
          ...prv,
          alliwant: {
            ...newAlliwant,
          },
        };
      });
    }
  };

  const saveDataHandler = async () => {};

  const handleSubmit = async () => {
    try {
      // console.log(data);
      // return;

      let isAlliwantReady = true;

      if (!data.alliwant.quantity || parseInt(data.alliwant.quantity) < 1) {
        isAlliwantReady = false;
      }

      for (let key in data.alliwant) {
        if (
          key != "pay_shipping_by" &&
          key != "shipping_type" &&
          key != "packagesize" &&
          key != "shipping_carrier" &&
          key != "is_brand_filter" &&
          key != "is_size_filter" &&
          key != "is_color_filter" &&
          key != "sizegroup" &&
          key != "size" &&
          key != "brand" &&
          key != "color" &&
          key != "expiration_days" &&
          key != "is_accept_similar" &&
          key != "pickup_distance" &&
          key != "quantity"
        ) {
          if (!data.alliwant[key] || data.alliwant[key] === "") {
            console.log(key);
            isAlliwantReady = false;
          }
        }
      }
      if (data.alliwant["is_brand_filter"] == "1") {
        if (!data.alliwant["brand"]) {
          isAlliwantReady = false;
        }
      }

      if (data.alliwant["is_size_filter"] == "1") {
        if (!data.alliwant["size"]) {
          isAlliwantReady = false;
        }
      }

      if (data.alliwant["is_color_filter"] == "1") {
        if (!data.alliwant["color"]) {
          isAlliwantReady = false;
        }
      }

      if (
        data.alliwant["deliveryMethod"] != "1" &&
        data.alliwant["itemType"] == "2"
      ) {
        if (!data.alliwant["pay_shipping_by"]) {
          isAlliwantReady = false;
        }
        if (!data.alliwant["shipping_type"]) {
          isAlliwantReady = false;
        }
      }
      if (
        data.alliwant["deliveryMethod"] != "1" &&
        data.alliwant["shipping_type"] == "1" &&
        data.alliwant["itemType"] == "2"
      ) {
        if (!data.alliwant["packagesize"]) {
          isAlliwantReady = false;
        }
        if (!data.alliwant["shipping_carrier"]) {
          isAlliwantReady = false;
        }
      }
      let isGeneralReady = true;
      for (let key in data.generalInfo) {
        if (!data.generalInfo[key] || data.generalInfo[key] === "") {
          isGeneralReady = false;
        }
      }
      if (!isGeneralReady || !isAlliwantReady) {
        toast.error("Please fill all the required fields");
        return;
      }
      // return;
      // validate data
      setLoading(true);
      let response = await authenticatedInstance.post("/wants/saveAlliwant", {
        data: data,
        wantsId: wantsId,
        images: images,
      });
      // console.log(response.data);
      newWantAddedHandler(response.data);
      cancelHandler();
      setLoading(false);
      toast.success("Want created successfully");
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong while creating want");
      console.log(e);
      //   setLoading(false);
    }
  };

  useEffect(() => {
    let interval;

    if (wantsId) {
      setDataFetched(false);
      interval = setInterval(async () => {
        try {
          const response = await authenticatedInstance.get(
            `/wants/dataStatus/${wantsId}`
          );
          if (response.data.status === true) {
            setDataFetched(true);
            setData(response.data.data);
            clearInterval(interval);
          }
          console.log(response.data);
        } catch (e) {
          console.log(e);
        }
      }, 2000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [wantsId]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true, // Allow multiple file uploads
  });

  return (
    <>
      <Modal width={"w-3/5 h-[86%]"}>
        <div className="overflow-auto relative h-[100%]">
          <div className="sticky top-0 z-10 bg-white">
            <div className="flex justify-between align-center">
              <div className="flex item-center gap-x-5">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_101_2)">
                    <path
                      d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z"
                      fill="#FFDCD0"
                    />
                    <path
                      d="M20.5 38V33M20.5 23V18M18 20.5H23M18 35.5H23M29 19L27.2658 23.5089C26.9838 24.2421 26.8428 24.6087 26.6235 24.9171C26.4292 25.1904 26.1904 25.4292 25.9171 25.6235C25.6087 25.8428 25.2421 25.9838 24.5089 26.2658L20 28L24.5089 29.7342C25.2421 30.0162 25.6087 30.1572 25.9171 30.3765C26.1904 30.5708 26.4292 30.8096 26.6235 31.0829C26.8428 31.3913 26.9838 31.7579 27.2658 32.4911L29 37L30.7342 32.4911C31.0162 31.7579 31.1572 31.3913 31.3765 31.0829C31.5708 30.8096 31.8096 30.5708 32.0829 30.3765C32.3913 30.1572 32.7579 30.0162 33.4911 29.7342L38 28L33.4911 26.2658C32.7579 25.9838 32.3913 25.8428 32.0829 25.6235C31.8096 25.4292 31.5708 25.1904 31.3765 24.9171C31.1572 24.6087 31.0162 24.2421 30.7342 23.5089L29 19Z"
                      stroke="#FF4405"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52C41.2548 52 52 41.2548 52 28Z"
                      stroke="#FFF4ED"
                      stroke-width="8"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_101_2">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div className="mt-1">
                  <p className="text-lg text-secondary font-semibold text-Inter">
                    {!viewingWant ? "Basic Product Details" : "Want Details"}
                  </p>
                  <p className="text-sm leading-tight text-secondarySupport text-Inter">
                    {!viewingWant
                      ? "please provide your product photo and details here."
                      : "Here are the details of the want"}
                  </p>
                </div>
              </div>
              {viewingWant && (
                <div
                  className="cursor-pointer p-2 rounded-full hover:bg-gray-100 dark:hover:bg-bray-800"
                  onClick={() => {
                    cancelHandler();
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "red",
                      fontSize: "2rem",
                    }}
                  ></CloseIcon>
                </div>
              )}
            </div>
            <hr className="bg-gray-100 h-[1.15px] mt-3" />
          </div>
          <div className="overflow-y-auto h-fit scrollbar-none space-y-6 py-5 px-4">
            <div className="flex items-center w-full">
              <div className="w-2/5">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Image
                </p>
                <label className="text-Inter text-gray-600 text-sm leading-snug">
                  Upload image of the product.
                </label>
              </div>
              <div className="flex items-center justify-center w-3/5 py-3">
                {images.length === 0 ? (
                  <label
                    for="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-40 border-2 border-slate-400 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
                  >
                    <div
                      {...getRootProps()}
                      className="flex flex-col items-center justify-center pt-5 pb-6"
                    >
                      <svg
                        width="40"
                        height="40"
                        className="bg-[#F9FAFB] rounded-full p-2.5"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66666 12.3333L10 9M10 9L13.3333 12.3333M10 9V16.5M16.6667 12.9524C17.6846 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2184 2.73736 10.212 1.5 7.91666 1.5C4.46488 1.5 1.66666 4.29822 1.66666 7.75C1.66666 9.47175 2.36287 11.0309 3.48912 12.1613"
                          stroke="#475467"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold text-primary">
                          Click to upload
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        PNG, JPG (MAX. 5MB)
                      </p>
                    </div>
                  </label>
                ) : (
                  <div className="flex flex-wrap gap-3">
                    {images.map((image, index) => (
                      <div key={index} className="relative w-32 h-32">
                        <img
                          src={
                            image.url ? image.url : URL.createObjectURL(image)
                          }
                          alt=""
                          className="object-cover w-full h-full rounded-lg"
                        />
                        <div
                          className="absolute top-0 right-0 p-1 cursor-pointer bg-white rounded-bl-lg"
                          onClick={(e) => {
                            e.preventDefault();
                            if (viewingWant) return;
                            if (autoFillImageId === image.public_id) {
                              toast.error(
                                "You can't delete image used to auto fill data"
                              );
                              return;
                            }
                            setImages(
                              images.filter(
                                (img) => img.public_id !== image.public_id
                              )
                            );
                          }}
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.14412 6.4805C7.23218 6.56856 7.28166 6.688 7.28166 6.81253C7.28166 6.93707 7.23218 7.0565 7.14412 7.14456C7.05606 7.23262 6.93663 7.2821 6.81209 7.2821C6.68756 7.2821 6.56812 7.23262 6.48006 7.14456L3.99998 4.6637L1.51912 7.14378C1.43106 7.23184 1.31163 7.28131 1.18709 7.28131C1.06256 7.28131 0.943121 7.23184 0.855061 7.14378C0.767001 7.05572 0.717529 6.93629 0.717529 6.81175C0.717529 6.68721 0.767001 6.56778 0.855061 6.47972L3.33592 3.99964L0.855842 1.51878C0.767782 1.43072 0.718311 1.31129 0.718311 1.18675C0.718311 1.06221 0.767782 0.94278 0.855842 0.854719C0.943903 0.766659 1.06334 0.717187 1.18787 0.717187C1.31241 0.717187 1.43184 0.766659 1.51991 0.854719L3.99998 3.33558L6.48084 0.854329C6.5689 0.766268 6.68834 0.716797 6.81287 0.716797C6.93741 0.716797 7.05684 0.766268 7.14491 0.854329C7.23296 0.942389 7.28244 1.06182 7.28244 1.18636C7.28244 1.3109 7.23296 1.43033 7.14491 1.51839L4.66405 3.99964L7.14412 6.4805Z"
                              fill="#E31B54"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                    {images.length < 3 && !viewingWant && (
                      <label
                        {...getRootProps()}
                        for="dropzone-file"
                        className="flex items-center justify-center w-32 h-32 border-2 border-slate-400 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
                      >
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Add More
                        </p>
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <Alliwant
              data={data}
              dataFetched={dataFetched}
              changeAlliwantValueHandler={changeAlliwantValueHandler}
              saveDataHandler={saveDataHandler}
            ></Alliwant>
          </div>

          {!viewingWant && (
            <div className="mt-3 w-full px-1 space-y-2 sticky bottom-0 bg-white">
              <hr className="bg-gray-100 h-[1.15px] mt-3" />
              <div className="flex item-center justify-between ">
                <button
                  onClick={() => {
                    cancelHandler();
                  }}
                  className="btn-secondary text-Inter"
                >
                  Discard
                </button>
                {!viewingWant && loading ? (
                  <button
                    disabled
                    type="button"
                    className="btn-primary text-Inter"
                  >
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  !viewingWant && (
                    <button
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="btn-primary text-Inter"
                    >
                      Save
                    </button>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      {imageUploadingModal && (
        <Modal width={"w-1/4"}>
          <div className=" flex flex-col gap-2 justify-center items-center">
            <Ring speed={1} size={30} color="orange" />
            <p className="text-lg text-secondary font-semibold text-Inter mt-3">
              Uploading Images
            </p>
            <p className="text-sm text-secondarySupport text-Inter">
              Please wait while we upload your images.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateAllIWant;
