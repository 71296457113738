export const categories = [
  { value: "cat91543a4a44f111f3c8e7af0a5e384234", label: "Appliances" },
  { value: "cat74c2879f7d8354c9f006047b508a4c53", label: "Arts & Craft" },
  { value: "cat8e7928964d10faa07b43f22fa630522e", label: "Baby & Maternity" },
  { value: "catf184ae0b2a555b87fc5dd91b961761b7", label: "Books" },
  { value: "catfcfa96294c5b7da2b6bfc19c3ea3b6cb", label: "Cameras & Photos" },
  { value: "catd7b75687b7c14f980c14bcb6d841ee35", label: "Cell Phones" },
  { value: "catab4d97789ccc61217aa03659131e7aeb", label: "Cleaning" },
  {
    value: "cat0c61c27a020f68fe453a3b5e738a2108",
    label: "Clothing, Shoes & Accessories",
  },
  {
    value: "catb7979b7eabfaa962a8b76dfb0defd7b9",
    label: "Collectibles & Antiques",
  },
  { value: "catcc85d35ad17f25c9be67b0bbbbd8c3db", label: "Computers & IT" },
  {
    value: "cat40650858198bf1891d36d5567b336a60",
    label: "Construction & Improvements",
  },
  { value: "cat08ff8a09a27de3cdea474cc416d00997", label: "Electronics" },
  { value: "cat366b100f8154ceb45db6b581e79441d6", label: "Gift Cards & Sets" },
  { value: "cat38f3c102bc227de85fe4b5952bcb957a", label: "Health & Beauty" },
  { value: "cat0f8500c4b1b55e2177da5abeae7e700d", label: "Home & Garden" },
  { value: "catae7e3f6607579808aa4416656a8cc90c", label: "Jewelry & Watches" },
  {
    value: "catcf5c00333266524666421cca096504b1",
    label: "Musical Instruments & Gear",
  },
  {
    value: "cat3ebc6d3630e332e1610fc9a82f6cebcf",
    label: "Office & Commercial",
  },
  { value: "catf038388cd72edd7f03afb88f50dfda39", label: "Pet Supplies" },
  { value: "cat8b05846aa2eae4c2762d4ef888b8706e", label: "Sports & Outdoor" },
  { value: "cata22ccdf8594e524b12178b2c91d2697c", label: "Tickets & Travel" },
  { value: "cat080d83cb227e297dbcc5bf29994ccaed", label: "Toys & Hobbies" },
  {
    value: "cata2b678850b1e8551118fb71af72e0da4",
    label: "Videogames & Accessories",
  },
];
export const subcategories = {
  cat91543a4a44f111f3c8e7af0a5e384234: [
    {
      value: "subcat6cd967a32289d279a76e759a8353b6a0",
      label: "Major Appliances",
    },
    {
      value: "subcatd299e44717e9bc640a2d77cd55cb5f33",
      label: "Outdoor Appliances",
    },
    {
      value: "subcat8cd77253114b6c7123ca4d7597786a8c",
      label: "Small Appliances",
    },
  ],
  cat74c2879f7d8354c9f006047b508a4c53: [
    {
      value: "subcat967130a21e4a2d8dbfbc3a8623ad2f3c",
      label: "Arts",
    },
    {
      value: "subcat89cd7642eabb835c5db56cf781f4d269",
      label: "Craft",
    },
  ],
  cat8e7928964d10faa07b43f22fa630522e: [
    {
      value: "subcat154b026ad51cb4f732741fd36bf921b5",
      label: "Baby Announcements & Keepsakes",
    },
    {
      value: "subcat71b68bb1ac1d60a9fb07c11a950bf19b",
      label: "Car Seats & Accessories",
    },
    {
      value: "subcate99c209ccfed8101ea8aa99501192deb",
      label: "Diapering & Potty-Training",
    },
    {
      value: "subcatc48648de9c4effea038e5b9963a54baa",
      label: "Feeding",
    },
    {
      value: "subcat10f4e74c4bdab6839a4143e302f2a6d9",
      label: "Gear & Strollers",
    },
    {
      value: "subcat196ac0f034eb613d9948ff0ce04bcbc8",
      label: "Health & Baby Care",
    },
    {
      value: "subcat7bbf524e58ddc3318aafb3d1b24f0227",
      label: "Nursery Furniture & Decor",
    },
    {
      value: "subcat4586c8672c0e5ea221864a9a49fc2ecc",
      label: "Other - Baby, Kids & Toys",
    },
    {
      value: "subcat133dca40e4865e48a8d0bed0e0ac6b4d",
      label: "Pregnancy & Maternity",
    },
    {
      value: "subcat35640559e47824f1653fecbece5ce6d5",
      label: "Safety & Baby Proofing",
    },
    {
      value: "subcat51ce40c4b6f7d03506e8b513d52f9797",
      label: "Skin Care & Bathing",
    },
  ],
  catf184ae0b2a555b87fc5dd91b961761b7: [
    {
      value: "subcatecb1af51bbe0ecfba37b241a2f25dd57",
      label: "Administration & Management",
    },
    {
      value: "subcatf3cd1b9f7c1d0ce04c10af389ee5dfa7",
      label: "Books",
    },
    {
      value: "subcateb85abba93e94cc9418396f91b1adae9",
      label: "Books Accessories",
    },
    {
      value: "subcate7a65726035401b82105d0f6629b4a28",
      label: "Fiction",
    },
    {
      value: "subcat5dc68f5ded2d2c65af77c006953134c2",
      label: "General",
    },
    {
      value: "subcat2385e7adbb304fc536f7cc3e78be00ba",
      label: "History",
    },
    {
      value: "subcat018a87d3b60aa0a513a8e19344777e08",
      label: "Literary Criticism",
    },
    {
      value: "subcatfffa91d9c1a1721af2c1b56d3a5700be",
      label: "Movies",
    },
    {
      value: "subcatba802f772290f4e457acd18a45eca06a",
      label: "Music",
    },
    {
      value: "subcatb85c3a7289b661254b94f55accd4af31",
      label: "Poetry",
    },
    {
      value: "subcate538de37462ce5fb644ef56174947136",
      label: "Psychology",
    },
    {
      value: "subcat2906fcb0d8742b3322fc1277b7651907",
      label: "Religion",
    },
    {
      value: "subcat8a41ca23482536cb4db95c73913ba447",
      label: "Young Adult Fiction",
    },
  ],
  catfcfa96294c5b7da2b6bfc19c3ea3b6cb: [
    {
      value: "subcat87fd55795b7c5ab069c1c6c475bd5cf2",
      label: "Binocular & Telescopes",
    },
    {
      value: "subcate7b07534a984a25243ff45f691b8969b",
      label: "Cameras",
    },
    {
      value: "subcatb7302d7acd61ee162d67b5cd168f5199",
      label: "Lenses & Filters",
    },
    {
      value: "subcat864fee10d1d4d833766022f7c8376e43",
      label: "Lighting, Flashes & Studio",
    },
    {
      value: "subcat2a52a97f44e54b896fa3ea019ec9d52c",
      label: "Tripods & Supports",
    },
  ],
  catd7b75687b7c14f980c14bcb6d841ee35: [
    {
      value: "subcat1c9e66a32f726fd5744759db40453c53",
      label: "Accessories - Cell Phone",
    },
    {
      value: "subcate7abdbc4667f6e6b9a7483f70a64e597",
      label: "Devices",
    },
  ],
  catab4d97789ccc61217aa03659131e7aeb: [
    {
      value: "subcat0d8b41ba75a853f081b3360155a0e1af",
      label: "Cleaning Equipment",
    },
    {
      value: "subcat881ca5be40e779639ec477f87c3edc22",
      label: "Cleaning Supplies",
    },
    {
      value: "subcateaa4fd4dd4f74046e88978c6ae6006b8",
      label: "Pest Control",
    },
  ],
  cat0c61c27a020f68fe453a3b5e738a2108: [
    {
      value: "subcatdc9620efe3d31d4e513dcc39cf4bcadc",
      label: "Accessories",
    },
    {
      value: "subcate4c7a00e775900f48f57ebc9c5dae65f",
      label: "Baby & Toddler Clothing & Shoes",
    },
    {
      value: "subcat916f07b9cf17d647070f0381230050fb",
      label: "Boy's Clothes",
    },
    {
      value: "subcatc00a31f0b83398465ef68d944a8f1329",
      label: "Boy's Shoes",
    },
    {
      value: "subcatdf285558090143ef715cd36b4b9211cb",
      label: "Girl's Clothes",
    },
    {
      value: "subcat3bba12dc6f28155a6e620d67e0045511",
      label: "Girl's Shoes",
    },
    {
      value: "subcat20aeec77c9ff9bbe97b58c4d07b616c1",
      label: "Men's Clothing",
    },
    {
      value: "subcat8f7ecaee9c238071b20ced0c9267843b",
      label: "Men's Shoes",
    },
    {
      value: "subcat2002dd3fae01dd013a8842e928852913",
      label: "Women's Clothing",
    },
    {
      value: "subcat6fe20ae9df3802d4381a9e92dd97c2f1",
      label: "Women's Shoes",
    },
  ],
  catb7979b7eabfaa962a8b76dfb0defd7b9: [
    {
      value: "subcat1143b4b2a4fefeb0bbe01032ea23d533",
      label: "Antique",
    },
    {
      value: "subcat6bc89f45c20677ad361b2ed6e6dee0c4",
      label: "Collectibles",
    },
  ],
  catcc85d35ad17f25c9be67b0bbbbd8c3db: [
    {
      value: "subcat8ed3646d2bdaebb02966fe5736439e62",
      label: "Computers & Accessories",
    },
    {
      value: "subcat111dfbe259539899aca7b3c3ac7b055f",
      label: "Printers",
    },
    {
      value: "subcatbad978cd5aa505356aa946a66a51b437",
      label: "Scanners",
    },
  ],
  cat40650858198bf1891d36d5567b336a60: [
    {
      value: "subcatca63ffa9586918277817b1386ba4b804",
      label: "Agriculture & Farming",
    },
    {
      value: "subcat6121b5a3b9202915ef409e8330d2ba06",
      label: "Construction Material",
    },
    {
      value: "subcat0081b25a4340d23c6f169089271f2acd",
      label: "Heating, Vent & AC",
    },
  ],
  cat08ff8a09a27de3cdea474cc416d00997: [
    {
      value: "subcatfdd7be4cd8a5d2c82664aec7d86a58c1",
      label: "Audio",
    },
    {
      value: "subcat67ca668a5dbc6bad3c9bfb21c1eb830a",
      label: "Drones",
    },
    {
      value: "subcat810cbf1bdc8bcca7fb31b81566f62f73",
      label: "Home & Office Phones",
    },
    {
      value: "subcat769ab9c22e03f860683d57408511098f",
      label: "Smart Home",
    },
    {
      value: "subcatb1c04de880bc5f1366a92ac2ffafe0b6",
      label: "TV & Video",
    },
    {
      value: "subcat201dc60aea7852548349fb2357e7dbbf",
      label: "Wearables",
    },
  ],
  cat366b100f8154ceb45db6b581e79441d6: [
    {
      value: "subcat68451693fcedea1f6b909fc1f6517da9",
      label: "Corporate Gifts",
    },
    {
      value: "subcatff07cb3f53cab00bff6b4e19704c0eb9",
      label: "Gift Cards",
    },
  ],
  cat38f3c102bc227de85fe4b5952bcb957a: [
    {
      value: "subcatd017d256b3937fa54435b80f4d607892",
      label: "Bath & Body",
    },
    {
      value: "subcat0dada9d9307f8fc3121e548b8ba20f8d",
      label: "Body Art & Tattoos",
    },
    {
      value: "subcatb947cf81a27f9331e5d0b25799417022",
      label: "Fragrances",
    },
    {
      value: "subcat3b99c167fdf320b3dd1fb04676023235",
      label: "Hair Care",
    },
    {
      value: "subcat121c3d429260d78880f6b23394704203",
      label: "Health Care",
    },
    {
      value: "subcat8d72a9f733d7fa394b78f2283b53d4f8",
      label: "Makeup",
    },
    {
      value: "subcat0f27494dbfa86b0b356c9d04c4b5fa44",
      label: "Massage",
    },
    {
      value: "subcat78b1780c2cf72380981c97e0e837be7d",
      label: "Other - Health & Beauty",
    },
    {
      value: "subcat7ae4c0c39766d8b6c3b6c8e5bc78c715",
      label: "Salon Equipment",
    },
  ],
  cat0f8500c4b1b55e2177da5abeae7e700d: [
    {
      value: "subcat79be63769588c3d55652c08d42b84657",
      label: "Bedding & Bath",
    },
    {
      value: "subcat6bbc419bcc44207e9fbc57b41f62b6d8",
      label: "Furniture",
    },
    {
      value: "subcatf3de5f765fcd336d62903eae516ddb12",
      label: "Garden & Outdoor",
    },
    {
      value: "subcate05738f0b11970cd44beb79fdcf033c8",
      label: "Home Decor",
    },
    {
      value: "subcat4081ad88df8f386a1009d37f84b94768",
      label: "Kitchen & Dinning",
    },
    {
      value: "subcat0214d20804d01144e19b7a3d9ea0a25c",
      label: "Party & Seasonal",
    },
    {
      value: "subcatf0e0f5a16484304192e8fc9ee20e4f72",
      label: "Storage & Organizers",
    },
  ],
  catae7e3f6607579808aa4416656a8cc90c: [
    {
      value: "subcat49d639494f682638c4a643f22a6623c3",
      label: "Baby & Kids Jewelry",
    },
    {
      value: "subcat2e183cfd7bb98cc09748aa6d27951d34",
      label: "Jewelry Accessories",
    },
    {
      value: "subcat06ee29ec81c248464c13a2c56df6ef77",
      label: "Men's Jewelry",
    },
    {
      value: "subcat69ceb77438e8df15f1554f8cf24f15d1",
      label: "Watches",
    },
    {
      value: "subcatd6228f0026d146bd364a6780b50966fd",
      label: "Women's Jewelry",
    },
  ],
  catcf5c00333266524666421cca096504b1: [
    {
      value: "subcat240aa3263fcc0c0b1584e75813aa0598",
      label: "Musical Instruments",
    },
    {
      value: "subcat50717105fc7099fd10edd453699bc06f",
      label: "Parts & Accessories",
    },
  ],
  cat3ebc6d3630e332e1610fc9a82f6cebcf: [
    {
      value: "subcat955ad227c62389372b26b4b2e821808f",
      label: "Business For Sale",
    },
    {
      value: "subcatbe3d4fee014bf5ce0b7a9393f8829919",
      label: "Healthcare & Lab",
    },
    {
      value: "subcat386caf3c438c8ec131b0e4214af6ccb6",
      label: "Logistics & Handling",
    },
    {
      value: "subcat5567ee47f0a5cf045f174df3abc4ffe4",
      label: "Office Supplies & Equipment",
    },
  ],
  catf038388cd72edd7f03afb88f50dfda39: [
    {
      value: "subcatabaf5c4f5e404bdcf8c14d781960ddc3",
      label: "Bird Supplies",
    },
    {
      value: "subcata2a9b5af9e1845153a2a8b6b955d4f4f",
      label: "Cat Supplies",
    },
    {
      value: "subcatc4b6928dd0ba7f1f4ffc5a4588cea59e",
      label: "Dog Supplies",
    },
    {
      value: "subcataa581788260871cce0607c7137b06036",
      label: "Fish & Reptil Supplies",
    },
    {
      value: "subcat0132c5a6635b0a9d5c55278ebf226ad7",
      label: "Other - Pet Supplies",
    },
    {
      value: "subcat0cc28d586f90805b3a569178b16b1fc6",
      label: "Pet Memorials & Urns",
    },
    {
      value: "subcat96f05c8a49c61ee601800578d6d73175",
      label: "Small Animals Supplies",
    },
  ],
  cat8b05846aa2eae4c2762d4ef888b8706e: [
    {
      value: "subcatf74025634370844ab97e2257337b3868",
      label: "Fishing",
    },
    {
      value: "subcatc286a3cb9d96f893a2dff7f038f3460c",
      label: "General Sports Accessories",
    },
    {
      value: "subcatde1a06691f2a348aaccd0f919a179ba9",
      label: "Golf",
    },
    {
      value: "subcat312fb1b2873632f5228260b7625cd54b",
      label: "Gym & Exercise",
    },
    {
      value: "subcat7a60aaf56fb1a7ab2cbf1450cf558290",
      label: "Outdoor Sports",
    },
    {
      value: "subcate2e71e27924563dfc5fa0cd4403eab15",
      label: "Sports Fan",
    },
    {
      value: "subcat5b7a4d394e9da4e2b1d287be8ee6d26f",
      label: "Team Sports",
    },
  ],
  cata22ccdf8594e524b12178b2c91d2697c: [
    {
      value: "subcatd1dd8b828a65f4734fc71364c8e9a896",
      label: "Event Tickets",
    },
    {
      value: "subcatceefb4a946dc3a8f38221fd37c79f5cd",
      label: "Travel Accessories",
    },
    {
      value: "subcat4c8b13a644f5f5de32bca6909d7f4f64",
      label: "Travel Tickets",
    },
  ],
  cat080d83cb227e297dbcc5bf29994ccaed: [
    {
      value: "subcat9b35a9ef1469be03db203811634f5ffc",
      label: "Baby Toys (0 to 24 Months)",
    },
    {
      value: "subcat64da7893f215915fe30006dbcf2ddbbc",
      label: "Board Games",
    },
    {
      value: "subcatb1d32153b87101c6635a7d897683a57f",
      label: "Collectible Toys",
    },
    {
      value: "subcat2d8e10c3831d348882b9ff31e65bf879",
      label: "Hobby's Toys",
    },
    {
      value: "subcat6d350b93819247797548ce01d1325c0b",
      label: "Toys",
    },
  ],
  cata2b678850b1e8551118fb71af72e0da4: [
    {
      value: "subcat1fc6164b21d5ed74a44a70f262842a26",
      label: "Gaming Accessories",
    },
    {
      value: "subcatc15281cab63a8499ad1a29ca77b03f9f",
      label: "Videogames",
    },
    {
      value: "subcat36e8b571d3f27433f57b57232c562f7b",
      label: "Virtual Reality",
    },
  ],
};

export const childsubcategories = {
  cat91543a4a44f111f3c8e7af0a5e384234: {
    subcat6cd967a32289d279a76e759a8353b6a0: [
      {
        value: "childsubcatae9efaec2d6de23a685adc3441f71817",
        label: "Dishwasher",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat3d1975cbe459ac74b00d8f3f18c69bd9",
        label: "Freezer & Ice Makers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd665be40b489ec52002d9fc0ddc07610",
        label: "Microwave",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatc1f11545fcccc4cd2a2f578271a300e5",
        label: "Ovens, Ranges & Cooktops",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat88c1e9f62bf47a59bb0336f18e911b2e",
        label: "Refrigerator & Coolers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat517572248afae709249548d0d332b69b",
        label: "Washer & Dryers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatf85fd90503dc80cbd0c15454351e1e04",
        label: "Other - Major Appliances",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcatd299e44717e9bc640a2d77cd55cb5f33: [
      {
        value: "childsubcate1d78b2c28e6f2e11d0b41c5072e47e2",
        label: "Power Washers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc20ab0e2adebd0e628090a4d7ddfea41",
        label: "Parts & Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat806dabdde003c8182569608f28429f86",
        label: "Other - Outdoor Appliances",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat8cd77253114b6c7123ca4d7597786a8c: [
      {
        value: "childsubcat30f2100daa711f254a94142bf38e51bc",
        label: "Coffee Makers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcate97c994af91401cefb5d01d7174caa08",
        label: "Garbage Disposal",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat376c2923ffde2f9cd339831c0549f49f",
        label: "Heating, Cooling & Air Quality",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0181e59875e81ed47b1f2b212af28450",
        label: "Iron, Steamers & Boards",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat36194c9e5a19ff548d979746ce42bfa9",
        label: "Kitchen Appliances",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat26bd9bf3835af981d8998905e5737dd1",
        label: "Vacuum & Floor Care",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat42eeb44d918003083654805737ff1b81",
        label: "Other - Small Appliances",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
  },
  cat74c2879f7d8354c9f006047b508a4c53: {
    subcat967130a21e4a2d8dbfbc3a8623ad2f3c: [
      {
        value: "childsubcata4e74264ff8a061c29198e22a03f2933",
        label: "Art & Painting Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2d527a1f730d7e8cf8ad2733556a7b8c",
        label: "Drawings",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat94cae76cdabb6a5e6cfb2ae5fd4a8041",
        label: "Paintings",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat2425b7a38c089792d6f01952de2828a4",
        label: "Photographs",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat314cbb03b278ba78cca5eac3597e915d",
        label: "Prints & Posters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat701b6c7553c7003333f8a2cfc4aae8bf",
        label: "Sculptures",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat62cba61abffd5475937218ae42338eae",
        label: "Textile & Fiber Art",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat66bec9d5567e51cd37f8c83f916a2521",
        label: "Other - Arts",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat89cd7642eabb835c5db56cf781f4d269: [
      {
        value: "childsubcat5fc2f279669ed85370cdc7ed2c85468f",
        label: "General Craft Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat4a1bcdbfed6082398b70967139a3b936",
        label: "Decorating Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat12568536c2cc105b14e349bd146b3ff7",
        label: "Embossing Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate4494974a38071ababfbdd1cfdb17157",
        label: "Fabric Painting Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat7ccb859fb72d2f58ba5e22051b56445c",
        label: "Handcrafted Pieces",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate5b4e8c10b4f2c58045a7f104d5776bc",
        label: "Jewelry & Beads Making Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3bf0a420c049a3b012ce1d2804539fbe",
        label: "Leathercraft Tools & Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatea25b36ad1397b06b882fbaa324d6f7d",
        label: "Mosaics & Glass Craft",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatcb50d593fed7c1e8f91650aa44828d90",
        label: "Scrapbooking & Paper Craft Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat63bb2511708bd6a185007a7efe063335",
        label: "Sculpting, Molding & Ceramics Craft Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat23ca73115b2176ba8e5472175afa39f5",
        label: "Sewing Tools & Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat75b02de26dc9db4414baca1a73fda06e",
        label: "Knitting & Crouchet Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat67404757dd3d2ba4ec5f868d0b0fae9c",
        label: "Other - Craft",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  cat8e7928964d10faa07b43f22fa630522e: {
    subcat154b026ad51cb4f732741fd36bf921b5: [
      {
        value: "childsubcat23883d267e9af331d56c87c0824477c3",
        label: "Baby Books & Albums",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcate02471c25900bcac9bf3d384af6f4a3f",
        label: "Baby Handprint Kits",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat5be7589d325cf6d08732b04d7d8a6970",
        label: "Baby Keepsake Boxes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcata1d18d73a8ae284321032b71b2146f38",
        label: "Baby Picture Frames",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat5445f3a35fe252d42066c6ee1a1dcd11",
        label: "Birth Cards & Announcements",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatea5730e8c205d577af95ee4f573c128d",
        label: "Other - Baby Announcements & Keepsakes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat71b68bb1ac1d60a9fb07c11a950bf19b: [
      {
        value: "childsubcat53ddc439fe202a5717bf6f13cff387fa",
        label: "Car Seats",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcate27c595dc5b6db517826f92caeacb0a1",
        label: "Carriers, Slings, Backpack",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat6facfbbedff4844ab7f5b7a56b641494",
        label: "Head Support",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat2a138279b3120cdb9e9df00bcaa47b90",
        label: "Other - Car Seats & Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcate99c209ccfed8101ea8aa99501192deb: [
      {
        value: "childsubcat2b825d014f0fc7b8b5f1fe30cbac8e02",
        label: "Other - Diapering & Potty-Training",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcateb9388a1c0ed568f929a33454af0e9ac",
        label: "Diapers Bag",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat115c9a5454ee6e72b8a647a07a24b140",
        label: "Diaper Cover",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat9f6bb990f4a7ff75ac1405f2b9cc62c4",
        label: "Diaper Disposal",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat33049978742c2f5d6e9c02aeab51d100",
        label: "Potty-Training",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat599665b3789e1651a656b9ee70d2d0d4",
        label: "Swim Diapers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcate84ebb7f8e9d5c8a8d7144b7b9522915",
        label: "Wipe Warmers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatf30483b954ac47a2c94292ccae7828a0",
        label: "Wipes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatc48648de9c4effea038e5b9963a54baa: [
      {
        value: "childsubcat235b0abbffdf77351128ff37a61dffd0",
        label: "Bibs",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatec4a2649062d9215d24549f32126bc86",
        label: "Bottle & Food Warmer",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcate060750d65a7c4262c2d913c7adee13e",
        label: "Bottle Feeding",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat1276295efd238a0727332cf7ec79596b",
        label: "Breastfeeding",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat5cacc7816337aaccafb524810f276c35",
        label: "Chair",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat8f342d4c94f2aeecdeae88e2e248ffd2",
        label: "Cups & Dishes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat02f973e4b661e2d6227e54634389dace",
        label: "Baby Food Maker",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatfcaf5848aa40430b5bf71a27cc8037e0",
        label: "Other - Feeding",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat10f4e74c4bdab6839a4143e302f2a6d9: [
      {
        value: "childsubcat10afb0c25923aef033a05c567bb8f2f9",
        label: "Baby Jumping Exercisers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatf8ce4c3ac86528cdf93d496f20213bc3",
        label: "Play Yards",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatcd3d9f44077fe5691cda2da708f2cdbe",
        label: "Strollers Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcata7c13d700bfa7258af374b222ec70ef4",
        label: "Strollers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat3ba7d2ddfa5c7757fc21e9de75715139",
        label: "Swings",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatc5d7f2352b009a3c1eb52985d1ea9524",
        label: "Tents",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat3c95f8f174da7474a4551da772f69ef3",
        label: "Vibrating Chairs",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat54978565c64e7de5dc886795a5e4c692",
        label: "Walkers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat6121fc9eaf58c0407cbd576d7734af8e",
        label: "Other - Gear & Strollers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatfefdb03e2cda94c51c7c6b8a7d3d55c2",
        label: "Cart Covers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat196ac0f034eb613d9948ff0ce04bcbc8: [
      {
        value: "childsubcat0b030ddccaa9d7c1dfc57f3eb8753748",
        label: "Humidifiers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatf9e912bdab48c2b3db2e31a938f4cb02",
        label: "Nail Care",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat75e623800699a2063bc363d22984cb66",
        label: "Nasal Care",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat13bf88e1cacd7f688c6426ef8cd078bf",
        label: "Toothbrushes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd70047d32be573497697669fa9cad877",
        label: "Sun Protection",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat60151106b376f549e64d3312c9372428",
        label: "Other - Health & Baby Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat13148116ded8ffc05e8f2682e373f431",
        label: "Pacifiers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat1e14b47ea2979ff323065ba09997f84a",
        label: "Teethers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat44d96ba2bc1276f64ddf1ebde52ca772",
        label: "Scales",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcataa6e78b54cb735710a486062c8f88135",
        label: "Thermometers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat7bbf524e58ddc3318aafb3d1b24f0227: [
      {
        value: "childsubcat96a15b614613a224aaf6fb1676fd0fd4",
        label: "Nursery Bedding",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat19baa5f363fe12a4eda1c2d1c0ec84cd",
        label: "Nursery Decor",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat72c46f066b60892be7c720b97b9cd6bc",
        label: "Nursery Furniture",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatc3f1e189da8c3f3af178ecf74271e036",
        label: "Other - Nursery Furniture & Decor",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat4586c8672c0e5ea221864a9a49fc2ecc: [
      {
        value: "childsubcatfdb11a1eda0302a57239040b50e5929f",
        label: "Other - Baby, Kids & Toys",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat133dca40e4865e48a8d0bed0e0ac6b4d: [
      {
        value: "childsubcat295885b8eaa88187a7ead66dd92738bf",
        label: "Breastfeeding Stools",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatceb01e456b326bd06b1ccb97e475aec8",
        label: "Maternity Pillows",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat4109fe317817c18d90da36e2c11d0eda",
        label: "Planning Tests",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat17908aa300b106e3d07d8df82cc0726d",
        label: "Other - Pregnancy & Maternity",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat35640559e47824f1653fecbece5ce6d5: [
      {
        value: "childsubcat82630c90f5615a337a220d68bed03ccb",
        label: "Bed Rails",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatf9b30a8645c90c60347ca7afa0043a4d",
        label: "Car Shades & Decals",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd3eae845f28a078bd4139f00adb6ffec",
        label: "Monitoring Devices",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3df089a6ed1a51f06259ea7fda9fb1b0",
        label: "Safety Harnesses",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatae38a1783a567bbd02b2f5a6853036f2",
        label: "Other - Safety & Baby Proofing",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
    ],
    subcat51ce40c4b6f7d03506e8b513d52f9797: [
      {
        value: "childsubcat3fede0349a1cf8688707d2f6ed8b8a98",
        label: "Bathing Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc54921fb917a7f10cbdf3d0b34dda4dc",
        label: "Skin Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatec4192f2d6cc82ed3cb37ff942f279cb",
        label: "Tubs & Seats",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd49fef3f370ae0535458da348ecbbdf0",
        label: "Robes & Towels",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat26cea38a351d0aa58044250481719943",
        label: "Other - Skin Care & Bathing",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
  },
  catf184ae0b2a555b87fc5dd91b961761b7: {
    subcatecb1af51bbe0ecfba37b241a2f25dd57: [
      {
        value: "childsubcat3c9f5b23a40f826da3c39d933e3afbfd",
        label: "Language Arts & Disciplines",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatf3cd1b9f7c1d0ce04c10af389ee5dfa7: [
      {
        value: "childsubcataaed62be15240b139ec448ee0695910d",
        label: "Textbooks & Educational",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb9ad441d5d2224311f3d7ac93a17af5a",
        label: "Audiobooks",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate767b7a9adc37c5a99abcf241f48578c",
        label: "Blank Diaries & Journals",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfb84a6f33330cd1f9b128e662b0dc8a5",
        label: "Catalogs",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate16bd7efe27795cdc61c67a08a8bc53a",
        label: "Children's Literature",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5e8cb5326801c4d823cf565aa990f865",
        label: "Comic Books & Mangas",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat7ab1d13212a31a854ca8921a1fd4e3d6",
        label: "Fiction",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata7b60035ede2e10318ad50f5e9eeee2b",
        label: "Magazines",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc5bd291a1ae96babf93fce904620330f",
        label: "Nonfiction",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0259c5689f22ce7f54015c978277c0c7",
        label: "Poetry",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat302e8ce217b21de41176940d3043fe42",
        label: "Romance Novel",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0b332b5e877f403f7e3be0bea604ce0a",
        label: "Other - Books",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcateb85abba93e94cc9418396f91b1adae9: [
      {
        value: "childsubcate76f8c585001ff6d1b1ebdbfef874d8b",
        label: "Book Covers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatfcf505f9e9ddaa7a9b71c5c8bc79f546",
        label: "Book Holders / Bookends",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat891f5d455ba81b2e7f61e857f7bbb1cb",
        label: "Book Lights",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat7928d924ed73cc877dec90fea11b114b",
        label: "Book Plates",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat99502b1b3d53c2ec01aadda26ee8f0a7",
        label: "Bookmarks",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat6742b9514950f52dbf80aa10f4eface3",
        label: "Other - Books Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcate7a65726035401b82105d0f6629b4a28: [
      {
        value: "childsubcat4c17e3a8d74d3978e57159e8407f1976",
        label: "Classics",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat5dc68f5ded2d2c65af77c006953134c2: [
      {
        value: "childsubcatd87bdfe91e625e7509e2acf6cc4687b3",
        label: "Technology & Engineering",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat2385e7adbb304fc536f7cc3e78be00ba: [
      {
        value: "childsubcat6818b7de12df80b0c555c279b2d819cd",
        label: "Revolutionary Period (1775-1800)",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9df17613bf0978b897556dadf73a09b5",
        label: "General",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat018a87d3b60aa0a513a8e19344777e08: [
      {
        value: "childsubcate01d72326bf65b309a8d1b7dccd89cd8",
        label: "Ancient & Classical",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatfffa91d9c1a1721af2c1b56d3a5700be: [
      {
        value: "childsubcat14e24d87224d1997ed0e17e155b18431",
        label: "DVD's & BLU-Ray Discs",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatee0a1a5056b76ea1b14368a76e359c68",
        label: "Film Stock",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat42ddb7980e69232f19f9edbd01fa6181",
        label: "Other Medias",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatef281c33731dd7cedbee6b85055a8175",
        label: "VHS Tapes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat363355fe2646e34233935728d3243042",
        label: "Other - Movies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatba802f772290f4e457acd18a45eca06a: [
      {
        value: "childsubcat48b7270790a187748c852475c6d2cc0c",
        label: "CD's",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat56aa8769b30d7bf484cc260289fa787e",
        label: "Collection Set",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat7f38746475d264001e66c5b5e6479e4b",
        label: "Storage & Media Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat37a5d4d2c0d21d0366f8532ba6e2969e",
        label: "Vinyl Records & LP's",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat01680044510073117de5450371346b3c",
        label: "Other - Music",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat95751847cff1d01a804254a9f75ca775",
        label: "Cassetes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatb85c3a7289b661254b94f55accd4af31: [
      {
        value: "childsubcat1e74001871c79700d866815b195641f4",
        label: "Ancient & Classical",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcate538de37462ce5fb644ef56174947136: [
      {
        value: "childsubcatbac306845a287643f27657be698dd108",
        label: "Psychoanalysis",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat2906fcb0d8742b3322fc1277b7651907: [
      {
        value: "childsubcatf93fd70b953479d30f79eeb3009b1d7d",
        label: "General",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat8a41ca23482536cb4db95c73913ba447: [
      {
        value: "childsubcat24842fabeb2c3cad845f01e4564509c4",
        label: "Survival Stories",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  catfcfa96294c5b7da2b6bfc19c3ea3b6cb: {
    subcat87fd55795b7c5ab069c1c6c475bd5cf2: [
      {
        value: "childsubcatcd000c0549c9956cb5d81b82eb3793ea",
        label: "Binocular",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd80ed4e61d9fa4537e802def87df1f7c",
        label: "Binocular Accessories & Parts",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfaccc74177faecd9862df5e274769d5c",
        label: "Telescope Accessories & Parts",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatec057d01372ae64106f015fb692925a0",
        label: "Telescopes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat05b48de3b1a81f11d8a8215f0076fe34",
        label: "Other - Binocular & Telescopes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcate7b07534a984a25243ff45f691b8969b: [
      {
        value: "childsubcate012664f056104aa7af67dff6fa58ad1",
        label: "Accessories & Cables",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd1cb1bb2c1032d361d4d147097a3d30a",
        label: "Batteries",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate851d6141dd1c1cb77de782f12218644",
        label: "Camcorders",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2e82fdf4afb07eda0436944a5bfcd7e1",
        label: "Cases & Storage",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat55a31f70761a43a6cc4546f68b068213",
        label: "Digital & Sport Cameras",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9841eb87a733a796ac858b4d86fd5b72",
        label: "Film Cameras",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5f0e2569bb7ffeebe3a7aaede1dc3d17",
        label: "Polaroid Cameras & Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb04a38c86c7e7b1a64c36ee57e0d8122",
        label: "Professional High Resolution",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatebd0b8633ff2724917e598171e76d38a",
        label: "Other - Cameras",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatb7302d7acd61ee162d67b5cd168f5199: [
      {
        value: "childsubcatfdf9d1a2cf75939a378431b01e3b7488",
        label: "DSLR & SLR",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat3c8bbea15e05986e318225c2e6ce33e7",
        label: "Filters & Kits",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf7a9104e74ebbc5da756c113106ea418",
        label: "Filters Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfd976c4c8875eaa4693d0773c7d6a02e",
        label: "Mirrorless",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat414e819166eabfb955e103f2b74d3443",
        label: "Other - Lenses & Filters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat864fee10d1d4d833766022f7c8376e43: [
      {
        value: "childsubcatf93a8b3d543c741102b0760b64813e53",
        label: "Adapters & Brackets",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat54ca26ecf7fd891db44f081f12b9d83d",
        label: "Backgrounds & Tents",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat688c07ed08817ab411583295c531baed",
        label: "Continuous Lighting",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9033903f746e72ad57e2348fb2dc750a",
        label: "Diffusers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate40afa18e6c99c28315c625f0d86c8bf",
        label: "Flashes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5c034b7725e919daa83f431178acce8b",
        label: "Other - Lighting, Flashes & Studio",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat2a52a97f44e54b896fa3ea019ec9d52c: [
      {
        value: "childsubcatbec40fb18650bc6d41e0b00061d58523",
        label: "Camera Stabilizer",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat027f882747fdcabe95d41e509cc821eb",
        label: "Supports",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat12128705f2c2cab7c4795625c2dbdb6d",
        label: "Tripods & Monopods",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat8c369b3afcae50378db601f2baaad45b",
        label: "Other - Tripods & Supports",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  catd7b75687b7c14f980c14bcb6d841ee35: {
    subcat1c9e66a32f726fd5744759db40453c53: [
      {
        value: "childsubcat0fbeee3295022ddd668cf2596bfccef8",
        label: "Batteries & Powerbanks",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat4300f8e86bf61822c9bc3fc2cced3e74",
        label: "Cables & Adapters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat98e7e9d4367bafb92c0c5ca9ba4a24ea",
        label: "Cases, Covers & Skins",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb21ded1f5c8942dc340d80e151ff85de",
        label: "Chargers & Docks",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatbe4f59d79199e30446a9164644faab8e",
        label: "Headsets",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatbdf477613e072f9106eb60c788d06a41",
        label: "Mounts & Holders",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc197021557b7fa91f93ce25ec334358d",
        label: "Other Parts",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatbbb1faacd350b9dd9af38d2f57e259f7",
        label: "Screen Protector",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatcdbee2f2d758408dfeea139518312306",
        label: "Screens",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatde9a811c3276f77e727246b83ada5531",
        label: "Signal Boosters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1798c9841637edc5835392f1bd6d6120",
        label: "Sim & Phone Cards",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat24c0b559e9ae5e4a13f249d33ce241b5",
        label: "Other - Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcate7abdbc4667f6e6b9a7483f70a64e597: [
      {
        value: "childsubcatd63cfe39526aaddda2ca458b3922593e",
        label: "Smartphones",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat1ecfd07ce97ad1db5095212bd7112f45",
        label: "Basic Phones",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat31a1376f923c1f4586d9c721fddae4de",
        label: "Other - Devices",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
    ],
  },
  catab4d97789ccc61217aa03659131e7aeb: {
    subcat0d8b41ba75a853f081b3360155a0e1af: [
      {
        value: "childsubcatf7e5c7b4c3598090a56588fe82e47786",
        label: "Carts & Containers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0a3fe3ad348a8a3f3d532d5a17fb9914",
        label: "Trash Cans",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata215dea89c036b190ba29787f248c9f4",
        label: "Cleaning Tools",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatdd7bcffae185190b7c62e8f535621935",
        label: "Safety Gear & Gloves",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1babaf326f50673b9e9c6523edd0782b",
        label: "Other - Cleaning Equipment",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat881ca5be40e779639ec477f87c3edc22: [
      {
        value: "childsubcat3c33e851153f16eaf16372db8910d3fa",
        label: "Air Fresheners",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc553ccdc0defcac705e65a7dd6a0c7f3",
        label: "Household Products",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat5ed7f8c89b8898e7acaca2f618dec171",
        label: "Professional Products",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2816326c7fa1c2d6f75647f6963d7536",
        label: "Trash Bags",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat99bf5b8186dcbf1dbc2af7fa2a8aff7f",
        label: "Other - Cleaning Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcateaa4fd4dd4f74046e88978c6ae6006b8: [
      {
        value: "childsubcat99e9689afe8294cab05269c603c5901c",
        label: "Bugs & Insects",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb038da9aa77d8d97acbaad40b93331f9",
        label: "Rodents",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3878e0bc402e651b804cba7ba514fa59",
        label: "Other - Pest Control",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat0c61c27a020f68fe453a3b5e738a2108: {
    subcatdc9620efe3d31d4e513dcc39cf4bcadc: [
      {
        value: "childsubcat6372a9c42e093fc6d34876e8b6fb40f0",
        label: "Backpacks & Duffel Bags",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat3bc56c33478dbb1348e26170f3b5a5be",
        label: "Beanies",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat3ddf2b7d50d0ad8fd9020a58d885cbdf",
        label: "Briefcases",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat6ff80cfcacbf41722b5cbbd7cd9637ff",
        label: "Caps & Buckets",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatde7d909dba317ee096dcfbcbfbb2266c",
        label: "Gloves & Mittens",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat58d05389575682a2fb137903308fe95d",
        label: "Hair Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5a809f805b96d3a41ae1f698507dfd95",
        label: "Hats & Headpieces",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdba73a9d10582eac220e173a46fa6adf",
        label: "Masks & Gaiters",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb9c666050fa5a30d520e4d9c2ab55f98",
        label: "Purses & Handbags",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcata8a6f83c12343d27295a9b1e6ff50424",
        label: "Scarves & Wraps",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat866ffa6257281125892436f6cd91292c",
        label: "Sunglasses",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat19ddcfd7adf9ab7e81e2fc959018a364",
        label: "Ties & Suspenders",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatf1b97375e365a5a4a469364011a63b07",
        label: "Umbrellas",
        is_brand_filter: "",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat5f81f9244bc9c925cd1831d586af854c",
        label: "Wallets & Pouchs",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd675f101a816dcb085c272508cf756cc",
        label: "Other - Accessories Fashion",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcate4c7a00e775900f48f57ebc9c5dae65f: [
      {
        value: "childsubcat4d81b74e777886881976081ef247de1b",
        label: "Baby Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat082a6654d7bfd746a58f3b045e174bf8",
        label: "Baby & Toddler Clothing",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat79bc81850ef7697cea8051f1ca6a9762",
        label: "Baby & Toddler Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat95e14f17cf0c803cb68613c4c3b26ddd",
        label: "Baby & Toddler Costumes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat782473527a3e2eecd446ac72e52fabcc",
        label: "Other - Baby & Toddler",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat916f07b9cf17d647070f0381230050fb: [
      {
        value: "childsubcat34066e33507bd586216bb6731d065426",
        label: "Belts",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0579ed97c9fcc553dd63f59ba500065b",
        label: "Coats & Jackets",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat03452722b2cc5e96bbcc9772a6b07deb",
        label: "Costumes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatb014cd17122e9eb2c4f811b3033a1cd8",
        label: "Pants",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat216fc41c60f5d27cb4d1f02e5bd3a2dd",
        label: "Shirts & Polos",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat2a00356fdf0844c916c89a7168518faf",
        label: "Shorts & Swimwear",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat24aa04f85790cce498bd9d5f72f4096f",
        label: "Sleepwear & Robes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatb8be44590b6d757c7c169c8a4e1ed466",
        label: "Socks",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat67c7b35a475e9130a2bf7f29a0dd9e15",
        label: "Sports Pants",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat1171f899a036ed080535905bc950b698",
        label: "Suits & Blazers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat2ab3db5ff1e5202a0e0118e4c2150507",
        label: "Sweaters & Hoodies",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatf7251aeb3d5b26a987a502af1f23f8cf",
        label: "T-Shirts & Tanks",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat0fc44b68202596a9d389526ec2ca2aa9",
        label: "Underwear",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat3e14dc392d233720f29a91f3d8a909e0",
        label: "Uniforms",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat5f23af853adbe502d127270d4a9224b1",
        label: "Other - Boy's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcatc00a31f0b83398465ef68d944a8f1329: [
      {
        value: "childsubcat70f8bfa4e7f04a09148b057229dd620e",
        label: "Boots - Boy's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatfabd93344a5ed8cce7544f6e037887c6",
        label: "Casual Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat65d9d3ef233813ca9d01e9de967d9f10",
        label: "Formal Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat43cfd9b790c9e7fe67e13cd99149446d",
        label: "Sandals",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatf4edf09c23861c7d3aa6517c3cb6e2fa",
        label: "Sneakers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatab1b978cc301cea6842ffba12effcc77",
        label: "Sports & Cleats",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd17d2800c03139ee1c74502e4dc2e06e",
        label: "Other - Boy's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcatdf285558090143ef715cd36b4b9211cb: [
      {
        value: "childsubcat0e52ceecd3bb4f70c30017753f0f6ada",
        label: "Belts - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat37387f0258bffe052019899bb80b9d34",
        label: "Casual Dresses",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat1924c54463047fdfab1fb51056b3a6b5",
        label: "Coats & Jackets - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat840680c174f86938b83556038bd0eddc",
        label: "Costumes - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat5265dd4580caac4bc4c373702075c295",
        label: "Formal Wear & Gowns",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat21f794e071e2642602a2506657ca41be",
        label: "Jumpsuits & Rompers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatfe0f9adfd5735b2fe49fc322e8b92839",
        label: "Pants - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat988cf0e905817b669e1676e711cc9e4c",
        label: "Shirts & Polos - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatb450c0fe0d273f5e037bc68c83f62f20",
        label: "Skirts",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcate06d0da6bde68de7d8e5d92afcd85f02",
        label: "Sleepwear & Robes - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcata222cb1f722cb9d247b54c28dd349aa2",
        label: "Socks - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatf112a5f88169faddaf0735182e8f18a9",
        label: "Sports Pants & Leggings",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd50746b55ed783c558c74460b3a96d7e",
        label: "Sweaters & Hoodies - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcata37641a1423e357745bd3a7c22bcd569",
        label: "Tops & Blouses",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatda4200ac6cff97a4d557f8ff4d290bb7",
        label: "T-Shirts & Tanks - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatb1f04ac7e96a705ce6bca721b5857583",
        label: "Underwear - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatcb2ad2cb22d045c5f3b7f403a1d0b4d0",
        label: "Uniforms - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat8f76ede8e5eb9eb02ca79945134eee43",
        label: "Other - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcate2db4c64d7165f019fc95107266002d8",
        label: "Shorts & Swimwear - Girl's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcat3bba12dc6f28155a6e620d67e0045511: [
      {
        value: "childsubcate335235e81e4ec2db550f9e87f15e5f6",
        label: "Boots - Girl's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatb6fc20553cbf01b48f8d6adfee1dacef",
        label: "Casual Shoes - Girl's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat8c708a1aa6ebe40e06c64e079fcf41cb",
        label: "Dress Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat56ebede39ebdd9fb3f259d9f8003d7ac",
        label: "Sandals & Slippers",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat36c71a0010fd2878e263082fe07892cf",
        label: "Sneakers - Girl's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat4f3eaed29e0450e859b1d3741c25d921",
        label: "Sports & Cleats - Girl's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd120a5a11ed2867a21933010b2d1346a",
        label: "Other - Girl's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcat20aeec77c9ff9bbe97b58c4d07b616c1: [
      {
        value: "childsubcat27cc07e7f1f3920ecb56a2fc4647cb26",
        label: "Belts - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatb3a094b58b7230d04da5c4b72a6710cf",
        label: "Coats & Jackets - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat2b994fc5b6b11b145fd65ab190f5100d",
        label: "Costumes - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat127c54180f0fd98c8e806a9eb008f5c1",
        label: "Sleepwear & Robes- Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd9bae2f0d4a921b6bbd34d23def30424",
        label: "Pants - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat97fdf54e90a1e69c51a8e9fdfa72473d",
        label: "Shirts & Polos - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd9cf29476d5f6e0ad61bcb31bc4ed8db",
        label: "Shorts & Swimwear - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat644904a2520051968ce718ca61936716",
        label: "Socks - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatf9feffdd66ebf31d0029ebda25729dba",
        label: "Sports Pants - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat789823f8f3cc82da7e7c2531ce380d5e",
        label: "Suits & Blazers - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcata125402c07351b9348b8fa8f03d63b17",
        label: "Sweaters & Hoodies - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat94bc5af8f5fe45f940ea465e6f895d13",
        label: "T-Shirts & Tanks- Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatec7b79c641a70346886f30e3cfa701b5",
        label: "Underwear - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat3e620d1eaa1aa07f5a01bd727bfcf4e0",
        label: "Uniforms - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat89e71d7fe90863c7bbdec1407946a913",
        label: "Other - Men's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcat8f7ecaee9c238071b20ced0c9267843b: [
      {
        value: "childsubcate8508d7fb8f954c6c8c7f01afff356e0",
        label: "Boots",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat7ca7943b72931cf60f404a7664e70b6b",
        label: "Casual Shoes - Men's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd052e987a75417c7965e2b674cb39248",
        label: "Formal Shoes - Men's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcate9f62bfd6c92a4fdfa7193f038d7435a",
        label: "Sandals & Slippers - Men's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcate427e73631ce97b432dcf4899d4f069d",
        label: "Sneakers - Men's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd00a296e0c960d177046000bbf8db7d2",
        label: "Sports & Cleats - Men's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat626fcb19a9640823b5f13ec3572a99ac",
        label: "Other - Men's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcat2002dd3fae01dd013a8842e928852913: [
      {
        value: "childsubcat6e6f73ce53789221e93fda964f9ed8a4",
        label: "Casual Dresses - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatedf60d51a1e10f47c850df147cae13e8",
        label: "Coats & Jackets - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat5fb432372a112517d3947ab158e5ee2b",
        label: "Costumes - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat67eaab58ad09187f1720395dc798c708",
        label: "Formal Wear & Gowns - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatea2ca45061460dd31be7c7ecb3fa278c",
        label: "Jumpsuits & Rompers- Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat0bbe72d31fa74263c6d0ceea8beaa8cf",
        label: "Pants - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat5c4e97793e270edd50fc01bde1f9ee3b",
        label: "Shirts & Polos - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatc438f1797f1c49dcb3687bc541431fca",
        label: "Shorts - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat5348d58d6eecd53a58a8f7b3c663d3f9",
        label: "Skirts - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatdc0635f82c74f5070ca182a224778246",
        label: "Sleepwear & Robes - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat6f2655df1ede9861535af4bd400119c9",
        label: "Socks - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat2d59c58f7f526acda79c387700d96140",
        label: "Sports Pants & Leggings - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat04bde09d0e9737695789741c1afd6bce",
        label: "Suits & Blazers - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd23d4db94fbe44645dc80dd4c74c2f02",
        label: "Sweaters & Hoodies - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat9a2d2d386b0f20ccdbe75165c59b22f8",
        label: "Swimwear",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat6f4222f9cc732a65780b1d1f0578e5e7",
        label: "Tops & Blouses- Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat42583ee111478ea72a8cbd96ef05fdda",
        label: "T-Shirts & Tanks- Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat6ed53f103bde99312ba2421ca5ecebc4",
        label: "Other - Women's Clothes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatd35ec9e2a07fcd0fa6d30c0b697e04ea",
        label: "Panties",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat280f8e5278b0608eccfb0a9b732637f5",
        label: "Bras",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
    subcat6fe20ae9df3802d4381a9e92dd97c2f1: [
      {
        value: "childsubcat3a272a18cc2a14dbd2a1b3d9b8836a34",
        label: "Boots - Women's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat15cb98104ce9e3ae0d036d32b2633f54",
        label: "Casual Shoes - Women's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatf17c8454cdb0d9173270a43c19cb3d2d",
        label: "Dress Shoes & Heels",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatfc763454f520702cb52662ab49ca71af",
        label: "Sandals & Slippers - Women's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat8ce4a033d761fee1a84cb68ce8434c57",
        label: "Sneakers - Women's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat714c9e3c41c56a5521ae1f343d0088ab",
        label: "Sports & Cleats - Women's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat0ed3841f2349551ea3a61178d2b29135",
        label: "Other - Women's Shoes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
    ],
  },
  catb7979b7eabfaa962a8b76dfb0defd7b9: {
    subcat1143b4b2a4fefeb0bbe01032ea23d533: [
      {
        value: "childsubcat4447c26b4224e5edf7ae9847c9dfc74e",
        label: "Antique Barber Chairs",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2f6a1e898e0b897bc65d9fcf0c37fc2a",
        label: "Antique Binding, Embossing & Printing",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat18bf1a9b244ecf65574ea2c788afd96e",
        label: "Antique Cash Registers & Adding Machines",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9ab80ec8e3e80bfb768779569bbe81e6",
        label: "Antique Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat137439ed9b85171e5c374005072fe1b2",
        label: "Antique Furniture",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat6e175e55b2524ec0260b11869181a3e3",
        label: "Antique Industrial Molds",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat856107d6c9ae6abd832e170f331190c1",
        label: "Antique Safes & Still Banks",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatca6f875c136e0ef84086a78fe5957e97",
        label: "Antique Scientific Instruments",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat765a3a1ce296a2211f182e7f82958eb0",
        label: "Antique Typewriters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatef6c044281763c18e25ef0554d3c08ee",
        label: "Architectural & Garden Antiques",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat221e90bdcfc983636a124d69ce5fc13e",
        label: "Chandeliers, Sconces & Lighting Fixtures",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc95dddf67d9393c158be57cee400932a",
        label: "Dentistry Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb00e546a92ffbab6d81c44c738d56cc0",
        label: "Engineering Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat24d2cfd22aff0e79b71cac9a5970d828",
        label: "Maps, Atlases & Globes",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate948da639494b1ae8588b2a4e6c8d899",
        label: "Mining Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat7c25d081100eb2e161d7f8fa522c77ae",
        label: "Jukebox & Vending",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat628fb1b04eae364343f376b1ee699fdb",
        label: "Medical Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat05b27ea51177cf2b067d59e40dd12f1a",
        label: "Optical Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2a42fd4fa724e4bdbf59f1e13f2b44cf",
        label: "Other Science & Medicine Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc7ea455b809241ff98d3dd7ee0a7c077",
        label: "Sewing Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd9dbf9f1a09671d9fcf81b136251c8c4",
        label: "Sewing Buttons Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb4ef3397be371c8c04b6956a2f126039",
        label: "Silver Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat60a931963dc396c50f87f69bc2625aaa",
        label: "Other - Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat6bc89f45c20677ad361b2ed6e6dee0c4: [
      {
        value: "childsubcat7039c019d0cf39e04df63cf542501821",
        label: "Animals",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat24531b619feac39a794f339a29ef9a9c",
        label: "Arcade & Pinball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat8937aa70a81000c410480f21e7cbf41b",
        label: "Autographs",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatcdf0830c44e10c6bc7fd371590c4a2d9",
        label: "Barware",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat15897c9e006511e614ecc2c2f7cc16a8",
        label: "Beads",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat88b2ee95de90c78012c6e60a14c7a56a",
        label: "Bottles & Insulators",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc7407b22de74670a9399f8214670d7d8",
        label: "Casino",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd9e505ad632968841d3aef53a3811375",
        label: "Clocks",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2fd4dfc1bd287ae8d0d610481670c72b",
        label: "Coins & Paper Money",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat11527e5b544a36582701bec9e58ee4dd",
        label: "Cultures & Ethnicity",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat43227fa8436e5e1dc816e95c4514eb7c",
        label: "Decorative",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2ecff53adffa708f436b14c79a3a4b25",
        label: "Fossils & Minerals",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat7701553a290f9703d8725f1b290c45ae",
        label: "Holliday & Seasonal",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb13e22dbddae8a81b483e8ed577dd220",
        label: "Jukebox & Vending",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat458667222621a03ca47cd0a4af3f4dec",
        label: "Kitchen & Home",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdc5742c78fa8d3ea88ba518e8de7ebc4",
        label: "Militaria",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata260647af01a597c703b3d0937f7a082",
        label: "Mythical & Fantasy",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc837b7282eefe9d8b85b91516f6c14e4",
        label: "Pens & Writing Instruments",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatbd9584877c27585a7d02fc6eef96b256",
        label: "Photographic Images",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatffae5301a6b87631a2513dcaa01ee2e0",
        label: "Pins & Buttons",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc2e7bb103a4b71b6efe92fa938658605",
        label: "Postcards & Cards",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfb236110eed265e58edb75eb8b38575f",
        label: "Professions",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0bcd69e4f88fad0928aec9242305819c",
        label: "Props & Memorabilia",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf4cd7a813f7cba0523dd17b5831997bd",
        label: "Radio, TV & Phones",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb026e77cf9d5f034ac94e8f6588b73e3",
        label: "Religion",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5cf662791dea01f3260101585094650d",
        label: "Science & Medicine",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat74195c896b7d5280872cb521db5e8bde",
        label: "Sewing",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata1bc80445ca852677d7690db81aff825",
        label: "Signs & Plaques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat635bcbb9a230abf4c593ef1306e7be1a",
        label: "Sports Cards",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdc70e2a2b00e8c39c16af0375d12a58f",
        label: "Sports Memorabilia",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0407eb5aaa3335e39fdc15221f2af3ff",
        label: "Stamps",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatbf2cc0e7988ebac1aa3f0b74050211d2",
        label: "Vehicles & Transportation",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat4ad333c01e2946f1d8c73e61456e88df",
        label: "Other - Collectibles",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb8316943a0a64a4b89604ee87e848205",
        label: "Antique Mercantile Display Cases",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf8048985e1bb1e8f4df7dfb7a96c3261",
        label: "Antique Scale",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2fab0a28a060dc89a0d112e943b2ba70",
        label: "Apothecary & Pharmaceutical Antiques",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  catcc85d35ad17f25c9be67b0bbbbd8c3db: {
    subcat8ed3646d2bdaebb02966fe5736439e62: [
      {
        value: "childsubcatd8f7a838d60d0229f0a4c5eb793e4a0e",
        label: "Cleaning & Kits",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate50053a780d9aea1139950a2dc8d5af0",
        label: "Computer Speakers",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb95e215bbf1e59313ce21a3c61d15a67",
        label: "Desktops",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatac31337e87766041d60b5233f98b7e1e",
        label: "Drives",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcateb952d986c91d8d387669c359adbd2d2",
        label: "Firewall & Security",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat33473e892df28ae21c60f89f69fa5ee4",
        label: "Keyboard & Mouse",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat77af8ae2a916757330ce5454ea5db695",
        label: "Laptop Cases & Sleeves",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6986fc832927202dd7a7839b2060a95d",
        label: "Laptops",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata5fda66a0aac2775f8557e2b564ccdcb",
        label: "Monitors & Displays",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "1",
      },
      {
        value: "childsubcat7766cff0d03acb38db7cbb018cd1a096",
        label: "Networking Devices",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc7f656f1774e89a06f5e2c4c81415dad",
        label: "Other Parts & Hardware",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat877379e60ccf386bd19362910d3278ac",
        label: "Power Protection",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3a6cc54d11b8dbbf904c68e42a3a1a58",
        label: "Racks & Cabinets",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat8003d7db4987a129f7ee6472db213584",
        label: "Servers",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat53aeffb901219ce860d7a0785e962fa2",
        label: "Softwares",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3602d05e9a6a2ff3c4ff506b5b130034",
        label: "Storage Devices & Media",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3f60e6fc00d4da949270ba405eb5a8a0",
        label: "Webcams & Microphones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat157c6113a9d177d65a1e650e673716b4",
        label: "Tablets & Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatf7b43ef8f55c01b39b61dd2669ae0f73",
        label: "Other - Computers & Accessories",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat111dfbe259539899aca7b3c3ac7b055f: [
      {
        value: "childsubcatfb5cfef2b7d5884f4f5312f25ec23d5e",
        label: "3D Printer",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat93e06d15e0fe2bf4da968d8fe230a6b7",
        label: "Cutter",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf561b63ad44329078167edc2a53ed4bc",
        label: "Inkjet",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatcd640c861f8b42d4c2f822a7543d6bb2",
        label: "Laser",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatcc91e640c13d04da087c2bf19b2d5a9f",
        label: "Plotter",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata8b45cd9fbcf63db614cf747f1c2a926",
        label: "Thermal",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf9f5a3b8f3a13ecdf9e0019c29fc9738",
        label: "Other - Printers",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatbad978cd5aa505356aa946a66a51b437: [
      {
        value: "childsubcatadf22867db30511f40569ff50068b606",
        label: "3D Scanners",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatbe48a44da092ac0f84dab66827fb374e",
        label: "Handheld",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6f789ea68bee8a7ed50cbc6bd0932547",
        label: "Flatbed",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatdf2a4530a94e40b3b92beace83950675",
        label: "Other - Scanners",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat40650858198bf1891d36d5567b336a60: {
    subcatca63ffa9586918277817b1386ba4b804: [
      {
        value: "childsubcat61c1cdb62e6618b548f215b16f1ee7a1",
        label: "Forestry Equipment & Supplies",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat5acb6f9f4fe411d388a639d67597ab2e",
        label: "Livestock Supplies",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9937b92faa2c6c9d553b468d92f1f1aa",
        label: "Other - Agriculture & Farming",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat6121b5a3b9202915ef409e8330d2ba06: [
      {
        value: "childsubcat15a2540f926390d382f97cc65a71d24e",
        label: "Ceiling, Tiles, Flooring & Supplies",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdaa4584fd7149a052b455d94561651aa",
        label: "Cement, Concrete & Masonry",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat7f77c15f6bc53530095bda15393ae79a",
        label: "Doors & Windows",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc3fb3b4bbb36c86f2b14d1cb92724f0a",
        label: "Drywall & Insulation",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatacfbe3ba84ee69ec9d84dfcbd403b6ab",
        label: "Manufactured Homes",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0b84561e2b72bf79b66ca0080c8ce91b",
        label: "Electrical Equipment & Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfad945fe50ef264c246de3a9833b7285",
        label: "Lumber, Molding & Roofing",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat556a9f51ed9cf91fff9d0e02b77d7971",
        label: "Paint, Coating & Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat4e614ced8ab338ce3443edc6b8285782",
        label: "Tools",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat8abbd4aab24b45867865e6ff50caffa7",
        label: "Plumbing & Fixtures",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdfa9c0816b0d7c341db2418290295974",
        label: "Ladders & Stairways",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat14f165fac12e0de1e3af916b5997ab9f",
        label: "Other -Construction Material",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat0081b25a4340d23c6f169089271f2acd: [
      {
        value: "childsubcat57b2d956ece0b424b6ef1f9494c8b790",
        label: "Heating & Cooling Appliances",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata64bb8783322f59994673922eea27fa8",
        label: "Parts & Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat65f4d5c29fd3228d945c1bee0f95898c",
        label: "HVAC Systems",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb732a140d531b7508357400b615b7f01",
        label: "Refrigeration Equipment",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd60858d7a088738e3cdcb404c7e18187",
        label: "Water Heaters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9abbabacc518041278ac33a0e607fd04",
        label: "Other - Heating, Vent & AC",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  cat08ff8a09a27de3cdea474cc416d00997: {
    subcatfdd7be4cd8a5d2c82664aec7d86a58c1: [
      {
        value: "childsubcatb5ffefcd82343595997933ab9763043b",
        label: "Accessories & Cables",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7deaae0b4aeab3f642d220b3f4478e37",
        label: "Docking Stations",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf1196815d6d76da059a157ca1b7eea16",
        label: "Microphone & Studio Recording",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate62571e6b76c1f7a2f7f7c0322e5aca7",
        label: "Professional Audio",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat82abb6ba44367e1252fc965bd16fdebb",
        label: "Radios & Record Players",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatebac0bb73f19fab8c511316c729850b9",
        label: "Speakers & Systems",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat423b88cedeeaf526e69463c18220ac75",
        label: "Headphones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate2f149b055ef551b0f81bde3826e58fb",
        label: "Other - Audio",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat67ca668a5dbc6bad3c9bfb21c1eb830a: [
      {
        value: "childsubcata6715231544d3bfc4b449f17ffe237ca",
        label: "Accessories Parts",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat29e55e0fa7b069fe377bb449f9ff5451",
        label: "Drones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat06c540eba8223ba110b08538b7d84135",
        label: "Toy Drones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatcc40195233bf7a6eac4f292edcbbb4c5",
        label: "Other - Drones",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat810cbf1bdc8bcca7fb31b81566f62f73: [
      {
        value: "childsubcat863b3c61c4897575403b3762f3d11dec",
        label: "Corded Phones",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1a65cafe2adaf1d6ec38eab242ecea4d",
        label: "Cordless Phones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat4c8f48012c97892260d7b27b724cdc2d",
        label: "Accessories - Home & Office Phones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat924567479a39bf35ec366ca9363a9f59",
        label: "Other - Home & Office Phones",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat769ab9c22e03f860683d57408511098f: [
      {
        value: "childsubcat0eb0e22f9a3f750615ab5085507892c7",
        label: "Home Surveillance",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate20e88815abc64b3333b20edc1596586",
        label: "Smart Assistants",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat56ebe3dfa24b97903ec778e01d79c2af",
        label: "Smart Cleaning",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat207541063ea2750cafa8df1f5d070fdd",
        label: "Smart Lighting & Plugs",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat5849df77d989b3ee8a9890519f6f4d51",
        label: "Smart Locks",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc4afaa79587c4abd434e4519370febdc",
        label: "Thermostats",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatdad87e0c3b231c661d6ece0d4c7757c3",
        label: "Other - Smart Home",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatb1c04de880bc5f1366a92ac2ffafe0b6: [
      {
        value: "childsubcat3ff24b7bf84e39f762bdd11f213b2421",
        label: "TVs",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat9af4db9862d348cb9abe837a5ea05c48",
        label: "Accessories & Cables",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat0dadd062a16b31186a0e893fcb18cd7c",
        label: "DVD & Blu-Ray Players",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf7bc62f30a2f56f5bc14e30546e4fe01",
        label: "Mounts & Supports",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat096c600ed3ee1e58218749e54eb1a9ad",
        label: "Projectors & Screens",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat526e0c2ab6e877fcc41678afba052593",
        label: "Streaming Devices",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9c50bf8eab452fd56e80c4ad0a63e80c",
        label: "Other - TV's & Video",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat201dc60aea7852548349fb2357e7dbbf: [
      {
        value: "childsubcat77f8b79108a07b61c86e18d157ace78f",
        label: "Fitness Trackers & GPS",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat52c497f237160b3af83ba9555ebdf57f",
        label: "Smart Rings & Bracelets",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat52babea96a25f9397e7afcfe6fc6b569",
        label: "Other - Wearables",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat366b100f8154ceb45db6b581e79441d6: {
    subcat68451693fcedea1f6b909fc1f6517da9: [
      {
        value: "childsubcat71210661eb028c4ea9225a8e5f60ea0c",
        label: "Executive Sets",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatff07cb3f53cab00bff6b4e19704c0eb9: [
      {
        value: "childsubcatbff9e2ddb8f7a521fa869fbac7e22e49",
        label: "Amazon",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat02713e01082eb582e411b70aefd872f9",
        label: "Sephora",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb3e613e02bb78d9aa1f686b16be2a84d",
        label: "Wal-Mart",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata8c3d11556260051099d329f3c843d75",
        label: "Target",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc8ccd978f1ff086e10fd38f129926697",
        label: "Apple",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat2fc7facb1c3166f7583f711a312dcc3e",
        label: "Adidas",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat5f649c7d20f5cc289bf017cef45dc827",
        label: "Nike",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate66fe23b19d683b006cffc8c4a5ca9f2",
        label: "Google Play",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat8e332ebe5aa9467c0f3b4248a433f20f",
        label: "XBox",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat14129b1a08681ac817115821a459f670",
        label: "Starbucks",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6184fdd20c7e649ec700053b21ba5a1d",
        label: "Best Buy",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat5ffa72f36e966a6bee868c0fd0fe06c6",
        label: "Visa",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf717a23ac4fa61632a22eb681c7712ac",
        label: "Nordstrom",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatdb89880bbbcb10208f085e33e4d4d896",
        label: "Home Depot",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat2837e20ba2550fb238b5a1d9946713cb",
        label: "Gamestop",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9407eaead7e1cb8cb488a43b1c2361a8",
        label: "Victoria's Secret",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata4e0d5f498aecb315451d7edabb6cb13",
        label: "Macy's",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd9e3e9da90b7d271414bbeeeaf9f673b",
        label: "Disney",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatfed0584accb9b44e4c6bd51771a75299",
        label: "Costco",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7d3a28d52df99753a19393d899985e34",
        label: "Other - Gift Cards",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat21cf61346bebee7fff7657a62aa5bf2c",
        label: "Custom",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc2be31809015b0b1f06166f0ce53ca16",
        label: "Other - Corporate Gifts",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat38f3c102bc227de85fe4b5952bcb957a: {
    subcatd017d256b3937fa54435b80f4d607892: [
      {
        value: "childsubcat5d71ec4b9f51f058d68d157b3474b8c3",
        label: "Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "1",
      },
      {
        value: "childsubcata9c0324947a5cac05eeeaf708441fda6",
        label: "Bath & Body Care",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc47b621fd24eb6d0abd685ef6779ed68",
        label: "Other - Bath & Body Care",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat246068bdb1b79a25244151125f4566a2",
        label: "Other - Body Art & Tattoos",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat0dada9d9307f8fc3121e548b8ba20f8d: [
      {
        value: "childsubcat81aed25dc6b173459bd33716d25dc4d9",
        label: "Piercing Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatec6f6aef622907df98e4f9a7a048dc6e",
        label: "Tattoo Machine & Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatb947cf81a27f9331e5d0b25799417022: [
      {
        value: "childsubcat924e376d7f93492eea312269f9f7a720",
        label: "Kids",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat61e6d3df4d75c32a3e147a6b222ef208",
        label: "Men",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb58dc7ccca872091d81ab6e8627cbecf",
        label: "Women",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9e85d40881c55c85d0b927ecfe84c6cf",
        label: "Other - Fragrances",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat3b99c167fdf320b3dd1fb04676023235: [
      {
        value: "childsubcat1ac1820e8753f054d9ead578c98078bd",
        label: "Accessories - Hair Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat576b1e46aebbfb61406f85211e5cde84",
        label: "Brushes & Tools Set",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6647e8c22619889413f2b6fcd98a260d",
        label: "Buzzers & Scissors",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat663ff3ff349bc6bd0c2a056ebc56e26f",
        label: "Dryer & Steamers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatea33229b3d86520da6f19edc2758084c",
        label: "Hair Extensions & Wigs",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb1901f081b00fe9cf892504f825ceeb0",
        label: "Hair Loss",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd01ae563b76fffd280ad090b01956792",
        label: "Hair Styling & Color",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat52e00edba84fd401e686d02329e6c8aa",
        label: "Shampoos & Conditioners",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatfaa14b85d25af8ed9ad72a71b10a84f0",
        label: "Other - Hair Care",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat121c3d429260d78880f6b23394704203: [
      {
        value: "childsubcat753ccea59aee39f361e4863d40a796f1",
        label: "Eye Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb4b1859910f9f44288951f05e37b4b9f",
        label: "Foot Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5938d60a70e9da6b8e6fe021ef823dd6",
        label: "Mobility & Orthopedics",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat09953fd5efa2e328a2b5988235216aca",
        label: "Oral Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb7b61609e701eaf9795da2994da0c367",
        label: "Nail Care - Health Care",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat6c9443887705f119f8fd849592918381",
        label: "Skin Care & Sun Protection",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat54477565424ed068efe8a5f85ed7efb0",
        label: "Sleeping Aids",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata8149a282de5364fb27c17a6414ade70",
        label: "Vitamins & Supplements",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5aabfc35838e52475b42f4bbc1c536b6",
        label: "Other - Health Care",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat8d72a9f733d7fa394b78f2283b53d4f8: [
      {
        value: "childsubcat266f0cfb284b531f834cda4356beb601",
        label: "Eyes",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcata2fb7286d48a5454195efd4c7e06b3e6",
        label: "Face",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatcc9945a15e5e58ee0f82f615582b9201",
        label: "Lips",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat03fbcac9988c45c24eea5b1c96abc75c",
        label: "Tools, Accessories & Kits",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2bb14e4d0bff20ad391ea10471a19209",
        label: "Other - Makeup",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat0f27494dbfa86b0b356c9d04c4b5fa44: [
      {
        value: "childsubcat12f03cc42e42a19ebe835a827326f8ee",
        label: "Equipment",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb7b9c9ca8709858c0d18f9edcba573c0",
        label: "Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcateec20d61e423ac4825c7892749c06546",
        label: "Other - Massage",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat78b1780c2cf72380981c97e0e837be7d: [
      {
        value: "childsubcat61c3937684905b3ad2096e9c92733b69",
        label: "Other - Health & Beauty",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat7ae4c0c39766d8b6c3b6c8e5bc78c715: [
      {
        value: "childsubcat6fbcefedf42ac27c91430b39afd8da70",
        label: "Accessories - Salon Equipment",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat8cfc6cbb26bb919b8568ed2a81d30fd2",
        label: "Backwash Units",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat585643dde35bf42869771b55ae7b6db9",
        label: "Stations & Furniture",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
    ],
  },
  cat0f8500c4b1b55e2177da5abeae7e700d: {
    subcat79be63769588c3d55652c08d42b84657: [
      {
        value: "childsubcat73d6c0e2f7ece375859a7fb2a4fa9086",
        label: "Pillows & Cushions",
        is_brand_filter: "",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat5b3b2d240a80833bcf2b9f80ff0bedb0",
        label: "Skirts & Frames Drapperies",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat18c8bd87aa5474a81cad594e89b04655",
        label: "Blanquet & Throws",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcatba4e366358fcdc0602f69c2718b77ffe",
        label: "Towels & Sets",
        is_brand_filter: "",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd078ad2c78f4ace3aeefbbfdf141562f",
        label: "Duvets & Quilts",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcata1c4618fb3ec210da74a7b7c9eb356c8",
        label: "Bedding Sets",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat1292afc64ee2b995ef91eee0182cd3b8",
        label: "Mattress & Pads",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "1",
      },
      {
        value: "childsubcat9137577aaa1d1aae80b56bcf1ba79a85",
        label: "Sheets & Pillowcases",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat0960e9294a1c15921e83623d82c8e79c",
        label: "Sleeping Bags & Inflatable Beds",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcata573e82f6f9a1a847717af533e107b07",
        label: "Other - Bedding & Bath",
        is_brand_filter: "",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat6bbc419bcc44207e9fbc57b41f62b6d8: [
      {
        value: "childsubcat883bd4d23a1b6f5f4a547b21212b5841",
        label: "Bathroom",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat9cd3120f3223dc6f841024f66b593fb9",
        label: "Bedroom",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatcad38e0f68b612ec95baff41f9b01426",
        label: "Home Office",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatfa5d11c4d6d737bbd73618aee61de903",
        label: "Kitchen & Home Bar",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat4adefb982c332a007765f806ac75ebd1",
        label: "Dining Room",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatb691e62ef469358c18df9b3f63dd0895",
        label: "Living Room",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd2ab5929531e1610782319f99a7d2551",
        label: "Patio & Outdoor",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcate1721a3ff5665d4aa414c453cdc9dec8",
        label: "Other - Furniture",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcatf3de5f765fcd336d62903eae516ddb12: [
      {
        value: "childsubcat5bbab17b949e873872fe501899d3bec3",
        label: "Boxes & Containers",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata89e3e034ee09173c028e4116653658e",
        label: "Garden Decor",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7acb5f63087e671b7e25511ef30395e1",
        label: "Hand Tools & Equipment",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat837e802c0d5d057c13b2fab69a0bf931",
        label: "Structures & Umbrellas",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc479b9232a5a8c11022507aa19dd633f",
        label: "BBQ & Firepits",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat349899d0fd619d574902c961fbc9f016",
        label: "Pool Equipment",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat10cfb2723047b5ecb7a4490e211d8e5d",
        label: "Natural Plants & Flowers",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat935e39c2e619cb1b6b417c0ee4a03cdc",
        label: "Vases, Pots & Planters",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat8d7ba605b9ebdedccc318e3d457efead",
        label: "Power Equipment",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc1cb13faa2acb069da01160287027070",
        label: "Other - Garden & Outdoor",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcate05738f0b11970cd44beb79fdcf033c8: [
      {
        value: "childsubcata991bc099d6b59d06636669098a12951",
        label: "Accents",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatd908840b27561d31a122f00418859738",
        label: "Decorative Cushions",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatb002364848955045d73956f9105b81fc",
        label: "Fragrances & Aromatizes",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat18f391550ba979e6304d1333d894fd73",
        label: "Lamps & Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata10a3481c13c13b957b3559af8081350",
        label: "Mirrors, Photo Albums & Frames",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat92ebd6c511c91a9fe94996763ebf2bf6",
        label: "Artificial Plants & Flowers",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd13b37114c904a5df2b2d50584369b90",
        label: "Rugs, Doormats & Tapestries",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcatc75d679bcf58a157a50b793ab1961fc8",
        label: "Window Treatments",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat0e4fb8769e70527a9d851629973c6fa7",
        label: "Other - Home Decor",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
    ],
    subcat4081ad88df8f386a1009d37f84b94768: [
      {
        value: "childsubcatc625a58ca6299a2bb7adcba5f30f6fc4",
        label: "Coffee & Tea Accessories",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat8e2e8db437e7a89bcdcb8434ed7b8791",
        label: "Bakeware",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat472a207b84c942de83b5d5f2218838fb",
        label: "Cookware",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat157eed9470a3c827f2de16798fae2106",
        label: "Dinnerware & Serveware",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat17363abe8a2d9951880c164571ec6a33",
        label: "Silverware & Cutelary",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat90d331f8203a8be2ec6f3a635420289f",
        label: "Glassware & Drinkware",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb96122886abb2b5c4ed2585fa91ca0b3",
        label: "Other - Kitchen & Dining",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat0214d20804d01144e19b7a3d9ea0a25c: [
      {
        value: "childsubcat78fb6bc965c75f6d00d3d4cdc5219fe4",
        label: "Ballons & Piñatas",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd722ebd511055a0055e8cac4ff1ec2ff",
        label: "Birthday",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat902654b11a20c201684875a4b12002ec",
        label: "Christmas",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat45a5680511307ee689101a3e73dc9c84",
        label: "Easter",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat8fc48c463aa82462300d183a8102ada2",
        label: "Greeting Cards & Invitations",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc8d7882a673b8d17313b7a1a107f3c67",
        label: "Halloween & Dia De Los Muertos",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat724dc33cdc6c73df18d89f2ddc0f2824",
        label: "Hanukkah",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf4133a5a52380089a85c59d2469b0016",
        label: "New Year's",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata982ad1e6c2d3c3d245ee312e43af736",
        label: "Non-Seasonal Decorations",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb4adfc3491468a36f315a3769c416757",
        label: "Party Flavors",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf48bfa628bed085799eaf820652bd208",
        label: "Patriotic",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7e79f11f56bab890722b555752b0b88a",
        label: "St Patrick's Day",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat358ad654a5c0db228747f5e72ed25557",
        label: "Thanksgiving",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9b31f990a88bfe49e150c158903c893e",
        label: "Valentine's Day",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf9ecd76ab64ec48750b77859fa6f68ba",
        label: "Wedding",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat17349801ac500ac32217d7dd6817138b",
        label: "Wrapping Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcataabce3207de6c81cb827f13d5a4d872e",
        label: "Other - Party & Seasonal",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatf0e0f5a16484304192e8fc9ee20e4f72: [
      {
        value: "childsubcat97830d8d5e3e108ced4e297ad2dda7d1",
        label: "Baskets & Bins",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc298c5585f1142de5a8ec778a3322b75",
        label: "Clothing Storage",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat43b0f63376b84021ced37ce11cc4080a",
        label: "Kitchen Storage",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat4ada8d11991679e37aaa7c3d72a3159f",
        label: "Racks, Shelves & Drawers",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcataac31c1537f171777ec0fab9809b509b",
        label: "Other - Storage & Organizers",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  catae7e3f6607579808aa4416656a8cc90c: {
    subcat49d639494f682638c4a643f22a6623c3: [
      {
        value: "childsubcatc63c65abbcaddff51ec2ab55e30e0f02",
        label: "Birth Stones",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatbd4350ef00f49bf988cf460bddf75676",
        label: "Bracelets Baby & Kids",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1054ff3601d7d3b72abbc2a9e0f69074",
        label: "Earings Baby & Kids",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat89c0623f22ba5182fe00d9cf4469339b",
        label: "Necklace Baby & Kids",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat59e822f0408ab6694b294f4d25d4455b",
        label: "Charms Baby & Kids",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat4d981b993f9a6d223d16978eb3bb1abe",
        label: "Rings Baby & Kids",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdbe6a9778b327ee1dbfb5aa96fd5b16a",
        label: "Other - Baby & Kids Jewelry",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat2e183cfd7bb98cc09748aa6d27951d34: [
      {
        value: "childsubcat34613e843c8d67e331bfc6db32abf567",
        label: "Boxes & Organizers",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat06ee29ec81c248464c13a2c56df6ef77: [
      {
        value: "childsubcatdf2694090c6748ed926c077b6bdc28ff",
        label: "Bracelets",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcateb2d02325447a377ed889369026bef38",
        label: "Men's Earings",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat184ff75f058500c4b85b1de2c379a903",
        label: "Men's Rings",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat801365f817d5c1ea5283be2666fc2d38",
        label: "Cuff Links",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5173faf937c8869af38e3e87a64698da",
        label: "Chains & Necklace",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5c58eb2a183b8243430082568a14adb7",
        label: "Tie Clips",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd11f2da656e63b171a95dfd7c4a1b4b0",
        label: "Money Clips",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatbce61069cc94e6f8ad24cbcd9d3fc0ea",
        label: "Pendants",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc3c853a1232e4d60f400ca954b39a022",
        label: "Other - Men's Jewelry",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat69ceb77438e8df15f1554f8cf24f15d1: [
      {
        value: "childsubcat5b11585ec3d7b4270bf1b4957f73ce36",
        label: "Women's Watches",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2ea59a24fff4d44838a96f9bc0531e3b",
        label: "Kid's Watches",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1de92987d765aa60593c33e47d8b41b7",
        label: "Men's Watches",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb58c1c1daf106096181f4fc0ec9f0e89",
        label: "Smartwatches",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
    ],
    subcatd6228f0026d146bd364a6780b50966fd: [
      {
        value: "childsubcat274f8ef64776734cb3a6fb260489e9bc",
        label: "Bottons & Brooch",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb177d374fe359a764cc1b87f0c0dedcc",
        label: "Bracelets & Anklets",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5cbea88db5c8fa5ee1c541cba89c77f2",
        label: "Earings",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd7d725e9dfdb402bed7d52907e0b7584",
        label: "Necklace",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfb0ac539286f752691e8214d4b9168b8",
        label: "Charms",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfe1a70016b35009d095822443b758668",
        label: "Rings",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf007206f5b6a5e314de9a6f7fb2e7406",
        label: "Toe Rings",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc27c33cbb8d89a7aa21091f0e42934a1",
        label: "Other - Women's Jewelry",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  catcf5c00333266524666421cca096504b1: {
    subcat240aa3263fcc0c0b1584e75813aa0598: [
      {
        value: "childsubcat96acd48afa7141cae335d4f9a40a1736",
        label: "DJ Equipment",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat31ba879ded4eddf357f7209c597e50d6",
        label: "AMPS & Effects",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd61d24a34ee99b81bbbb2c7b66bb148b",
        label: "Basses",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat99616fb2ee2d3207cf52ec18649fbbb0",
        label: "Band & Orchestra",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc107f0fdf999d5c888d0e86f2f2856be",
        label: "Drums",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatbd47b4a8d690bd33e06cbf2b88638551",
        label: "Guitar",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf07853db3b540536feaa04b6a2ec89df",
        label: "Acoustic Piano",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat891b8f29e01a331711bc8adc126341f1",
        label: "Keyboards",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7ef8d25a01bd6312c74ab0b0070a28fe",
        label: "Percussion",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9dd7b200252d6f94c293daeb4461d7c7",
        label: "Karaoke Equipment",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat18bac636878fa713077eff4d113e759c",
        label: "Recording",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat856a5441efb6750e5f9c4c5dabc8c4a1",
        label: "Other Stringed",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd44650d3ae959db7bf4bbd8414ec51ac",
        label: "Wind & Woodwind",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6efb1dbd0df76a9b33e752d55bd77f3d",
        label: "Other - Musical Instruments",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat50717105fc7099fd10edd453699bc06f: [
      {
        value: "childsubcatc63037f294c3a96097af0dc582ebd39b",
        label: "Cases & Gig Bags",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatde30909254a3a99d5e744f7ef6bf8035",
        label: "Fog & Bubble Machines",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat76bc4cc31038c7cf5ba34cfc3a86bc12",
        label: "DJ Lights & Effects",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat4faf979cca46be696c89526a856136d2",
        label: "Strings & Picks",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatcc51f87d704176506d46573e913fafea",
        label: "Cables & Snakes",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat55317259580c552d1a3fd515d277c14c",
        label: "Other - Parts & Accessories",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat3ebc6d3630e332e1610fc9a82f6cebcf: {
    subcatbe3d4fee014bf5ce0b7a9393f8829919: [
      {
        value: "childsubcatafebf822dc7fef8284d5091ff2001d0a",
        label: "Handpieces & Instruments",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2fcf58a18069f1795da9f7e235c36e59",
        label: "Medical & Dental Furnitures",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0a38e8aff5d8ce6e86c894813eebde2c",
        label: "Medical, Lab & Dental Equipment",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9c44ad9574f8140a60181ca46d04caa4",
        label: "Medical, Lab & Dental Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatdd536b6274c619ab812bca042502e5d7",
        label: "Other - Healthcare & Lab",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat386caf3c438c8ec131b0e4214af6ccb6: [
      {
        value: "childsubcat0f6a55d5611ca87ee4bc822522593e43",
        label: "Carts & Trucks",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat8d2d29f9ec8779b419ea42486aecaacf",
        label: "Casters & Wheels",
        is_brand_filter: "0",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd233c57ae60b3f3c3fdaef02f7a37336",
        label: "Containers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat4ae1f91cd5c1476c980cbf1a5f6dcb50",
        label: "Packing & Shipping",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat639ad19ffe58529f21c9f2b32938f038",
        label: "Shelving & Storage",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0ebcdf599a16746b2192e68dda03e2c1",
        label: "Warehouse Loading & Unloading",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcataf617c75021dafeb05c3ba91409ec666",
        label: "Other - Logistics & Handling",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat5567ee47f0a5cf045f174df3abc4ffe4: [
      {
        value: "childsubcat697e936121422b2762aad9899bab7677",
        label: "Office Equipment",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat27ec0e51f79dd2b02abb51a42b8b8761",
        label: "Office Furniture",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat6d5994769f43791cfde799250c73d679",
        label: "Office Supplies",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat12fd6bc8d900ab63cac6ef0dc34af182",
        label: "Tapes, Adhesive & Sealants",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd0d94ce89eb389f627de70ee43902b8d",
        label: "Other - Office Supplies & Equipment",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  catf038388cd72edd7f03afb88f50dfda39: {
    subcatabaf5c4f5e404bdcf8c14d781960ddc3: [
      {
        value: "childsubcat1e7ee89014eee219844c090bf6d57d75",
        label: "Cages & Stands",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat198dc9eed2a0a22d133f4a18d56e69c3",
        label: "Feeders & Food",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat9c11bb88b244c5a25ba9998e80aa0d67",
        label: "Toys & Treats",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatba6cf146c5984ca19c68ccf0a323c0ad",
        label: "Other - Birds",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcata2a9b5af9e1845153a2a8b6b955d4f4f: [
      {
        value: "childsubcatdbc46edb6892cb652c3fbe63fd1f8689",
        label: "Collar & Tags",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat577d8116e815cd62c735ff4e740f30b0",
        label: "Clothing & Costumes",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat62fdd8d2f92e3416753fcd1354756946",
        label: "Cages & Stands - Cats",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata8bc45aaaa9f9389ac29512fc57958a9",
        label: "Feeders & Food - Cats",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat08e7cb7baf3cda1a43e3b2b844587f1e",
        label: "Beds & Furniture",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat680cb5d2da4890db5820a8a569bad5f9",
        label: "Toys & Treats - Cats",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf53c64fe18b2075cf2b98318d823d1ad",
        label: "Other - Cat Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatc4b6928dd0ba7f1f4ffc5a4588cea59e: [
      {
        value: "childsubcat23c986f0b264b231040910055783967f",
        label: "Beds & Furniture- Dogs",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate80574811b2b90596baadce158281da7",
        label: "Cages & Stands- Dogs",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf365212cb9607214accf7ba06d4e5536",
        label: "Clothing & Costumes - Dogs",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatdf86c6ca6219dd3afc6d5bd57d531827",
        label: "Leashes & Collar",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat43dcd5acdf052c99a284590131367991",
        label: "Feeders & Food- Dogs",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat22fb118a97668945a73e6b3adc1c420d",
        label: "Toys & Treats- Dogs",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat81ce4d50cf550861c91ce26bd56f4571",
        label: "Other - Dog Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcataa581788260871cce0607c7137b06036: [
      {
        value: "childsubcat999413df9d7a7c4dd282a76daa630dea",
        label: "Aquarium & Tanks",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd60d95b06b58c0c811a2012214028c04",
        label: "Cleaning & Maintenance",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat8d5a5f584d9079ca7b38e31d307415bc",
        label: "Feeders & Food - Fish",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate241e068957d458ab18d8784a0d946f9",
        label: "Other - Fish",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat0132c5a6635b0a9d5c55278ebf226ad7: [
      {
        value: "childsubcatda5add287f5fd7da748b2390a9c2131e",
        label: "Other - Pet Supplies",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat0cc28d586f90805b3a569178b16b1fc6: [
      {
        value: "childsubcat1f0bf4396f441f860cfa9534f23b29e2",
        label: "Pet Memorials",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcataf72fee2eb35bc102afba8636ffd98f4",
        label: "Pet Urn",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat16d42036a3dc673236a8334b66539394",
        label: "Other - Pet Memorials & Urns",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat96f05c8a49c61ee601800578d6d73175: [
      {
        value: "childsubcat1539e6829148c6f6feef6a41362f46fc",
        label: "Cages & Stands- Small Animals",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7be2ebc3b91768ee5e8eab3aef09ef73",
        label: "Feeders & Food- Small Animals",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatcbd50b70df7b49644b4e5ec4aad382c8",
        label: "Toys & Treats- Small Animals",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf6ff59b4ab72485856f0356a1b410c62",
        label: "Other - Small Animals",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat8b05846aa2eae4c2762d4ef888b8706e: {
    subcatf74025634370844ab97e2257337b3868: [
      {
        value: "childsubcata9f5506c1168ce7f076a3b2dc6075168",
        label: "Rods & Reels",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatce4384903c9e7e8dc2d708f3de95df9a",
        label: "Fishing Sets",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatda491aff5fa6f69f88eab42a032b93d4",
        label: "Tools & Accessories",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6fc21f153b37f877c8698a8b8169ed49",
        label: "Other - Fishing",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatc286a3cb9d96f893a2dff7f038f3460c: [
      {
        value: "childsubcat03a98d78e086215327b287c801a6773d",
        label: "Sports Bottle",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat666016188b4ec307a3c2bb3e4fb8490c",
        label: "Sport Bags & Packs",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9d98aa8786f837c6c06e99d0a10ce470",
        label: "Other Acessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatde1a06691f2a348aaccd0f919a179ba9: [
      {
        value: "childsubcat2cf5cc7bda5727431e2744ed6fb036f8",
        label: "Clubs & Sets",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat78cac9d44978123e3c217bc5869f695a",
        label: "Carts & Bags",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat3e810c2404480990c24274495c73acb3",
        label: "Balls & Tees",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1c022cceb775c18b845c6e032e5fbe7e",
        label: "Other - Golf",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat312fb1b2873632f5228260b7625cd54b: [
      {
        value: "childsubcat99b1083711b531e33863e7f78f9a9ff9",
        label: "Bowling",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcata1903c117c16631631ff3b8d97419b68",
        label: "Boxing",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9204c263bba743685398e6c8049c71ff",
        label: "Fitness",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "1",
      },
      {
        value: "childsubcat288a6b5aae709a27a488eaa5d8e43271",
        label: "Strength",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd199988fe0f098d875be6571093c43f5",
        label: "Track & Field",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcate7bf8f04bc7256b59780c59e3186563c",
        label: "Martial Arts",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat0f4e4f084d3a92a5f1e2e36c2fd26ac8",
        label: "Yoga & Pilates",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "1",
      },
      {
        value: "childsubcat5ce3e0d88c60302a458899c14b7335d6",
        label: "Other - Gym & Exercise",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat9c300dcebab411163b7112447e51d746",
        label: "Ballet & Dance",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat7a60aaf56fb1a7ab2cbf1450cf558290: [
      {
        value: "childsubcatcdb6244d43f4b9e78afff4b5760057a1",
        label: "Skating Electric",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "",
      },
      {
        value: "childsubcat7fa50602e5fe1be78ee15670365372f0",
        label: "Airsoft & Paintball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatae72260fd8341deb231d82323667b717",
        label: "Hunting & Archery",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat57379961f4eac78a74c605daed683cf9",
        label: "Biking Non Electric",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate1da02761761d8ba17ce39f462278f79",
        label: "Biking Electric",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb2534a9132824f2697d6302ffc94d29e",
        label: "Camping & Hiking",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat03a75401ba5dc8a05af469cd37351434",
        label: "Skating Non-Electric",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatee17c61077efc93bd71cf15bd6cdf463",
        label: "Water Sports & Diving",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf076fe954f07d5936ebc726cfec30338",
        label: "Scooters Electric",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat926972b6a1fc74f42f901e9ea0b35cae",
        label: "Scooters Non Electric",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcate1aad933caf82935dbaf18ec72f49eb4",
        label: "Ski & Snowboarding",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5b3e0f5f23ff3e9fd95a13d987cf7008",
        label: "Other - Outdoor Sports",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat45e5daac97984cfa715af9f36d08a478",
        label: "Badminton",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcate2e71e27924563dfc5fa0cd4403eab15: [
      {
        value: "childsubcat6d74f47aee562414b4ae221174b2fd00",
        label: "MLB",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd0496ab86bbf3b7b9646f78481db9e1f",
        label: "NBA",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb0c4e25fd3e516cf35a145782ca9e121",
        label: "NCAA",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatee7bbb5291f2c10770a4a10bc1d26cde",
        label: "NFL",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7856b12d41b71e1e858446bc04f8bb1e",
        label: "NHL",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat41128dff3b0929e238788b0b786ece9a",
        label: "Other - Sports Fan",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat5b7a4d394e9da4e2b1d287be8ee6d26f: [
      {
        value: "childsubcatc473babf7b61c1bfd9f93386878997ca",
        label: "Pickleball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata1d77167435b840255a5b154f2bf6168",
        label: "Table Tennis & Ping Pong",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat5c776096c074dd239475d988f8d9aa61",
        label: "Basketball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1aae5915c0265242eb132c8a89517fbf",
        label: "Football",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat531424ec424cc3b3ef89b6bf3b63defe",
        label: "Hockey",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5b3c8b4ef14078ea657cf3e75c6cd7d4",
        label: "Lacrosse",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat21ccd8aba04bece034244e6f366aea59",
        label: "Rugby",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat558abbbc10fa21061a812bc496142637",
        label: "Soccer",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatde4988095dc0aaf77f2da21b49e49896",
        label: "Softball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat3e8d8002114948824fe4a05c30da20e0",
        label: "Waterpolo",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfa767dfd47aca81e306ab2d2c8044231",
        label: "Tennis",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatd47038813f3850df770b36cd57897b5c",
        label: "Volleyball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatfca82debcfdd953250fb33866c78036a",
        label: "Other - Team Sports",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat00dc3978505e00a44b9018b1cf994a30",
        label: "Baseball",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  cata22ccdf8594e524b12178b2c91d2697c: {
    subcatd1dd8b828a65f4734fc71364c8e9a896: [
      {
        value: "childsubcat9490840e7bcf25d52a799279ae96212c",
        label: "Dining Experiences",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat69959283e3748eb34edf71a980778e1f",
        label: "Concerts",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat968fda88545b9c687299a3d90e91709a",
        label: "Sports",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat438e5d37709004911fe2e40858c49c47",
        label: "Theater",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat562f4e35c45ac1480fd9916651fa1688",
        label: "Seasonal",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatc45abb61a4867720295b1b33b316507f",
        label: "Theme Park",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata42590127e5d6f012de690977cfd6985",
        label: "Other - Event Tickets",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatceefb4a946dc3a8f38221fd37c79f5cd: [
      {
        value: "childsubcat901aad31f43c77f6b0e02690ae4abfa6",
        label: "Luggage",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb144e1ee09eed8557ff40de95f047bff",
        label: "Adapters & Converters",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatadade6904200ba3398fc8fd0c40033d8",
        label: "Accessories - Travel Accessories",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat4c8b13a644f5f5de32bca6909d7f4f64: [
      {
        value: "childsubcatf7f7560073964f9bf88a47d59d8b2367",
        label: "Airline",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcata4962e68ef3d69416cc556dcb242a500",
        label: "Campground & RV Parks",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb4fa620d1605f35774865d26749ffa89",
        label: "Car Rental",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcataf9f5278920decc65341d0084b3ec3fe",
        label: "Cruises",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat6c0c88c22040cb1466540cb10bbddd19",
        label: "Trains & Rail",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb3df869dc405117f969cdf5c98600c58",
        label: "Vacation Packages",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7511095dff705a9cf07fa5e15c667720",
        label: "Other - Travel Tickets",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
  cat080d83cb227e297dbcc5bf29994ccaed: {
    subcat9b35a9ef1469be03db203811634f5ffc: [
      {
        value: "childsubcat33b62656d34d58389f3d4ecc4988ba28",
        label: "Blocks & Sorters",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat5fae499c11a3ea9199823c862ee7a221",
        label: "Crib Toys & Playmats",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat7ded96dd089181a5ee2cd0cd0723bb3d",
        label: "Plush Toys",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatd6ba18ab351dca2f35b21bfde498dd69",
        label: "Other - Baby Toys (0 to 24 Months)",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat64da7893f215915fe30006dbcf2ddbbc: [
      {
        value: "childsubcate9199047e13e018bc8e5a686cdf0ed7a",
        label: "Card Games",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatb5a7e642d4f425420ad89b14b319d4ba",
        label: "Checkers",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat949e62a39700451b59a73dcd17f2ac94",
        label: "Chess",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat03e993ce3d9d65b0cefe06b45ad1a0d6",
        label: "Monopoly",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate46498be6fd11477b6a875ca4a710ce3",
        label: "Poker",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat386f2c4bd09af12c349945eace1a627c",
        label: "Scrabble",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc38b3b21c5d5dd240f51229e6d579bcd",
        label: "Other Games",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcatb1d32153b87101c6635a7d897683a57f: [
      {
        value: "childsubcat63739a6c077fe82e6250eed26c473c11",
        label: "Premium Action Figure",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat8082ea8ef78a1aa7ceecb7721fceffd3",
        label: "Premium Dolls",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat682a40fc43404d421f8c913a6a4bb63f",
        label: "Vinyl Figures",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc13a1d2b6e73355fe01ab69851586b25",
        label: "Figure & Statues",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatc4a8c08422575e010146c1c69d7ed930",
        label: "Other - Collectible Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat2d8e10c3831d348882b9ff31e65bf879: [
      {
        value: "childsubcat17a91432daa9a88fb2f8f2b2ebde9f1c",
        label: "Airbrushes & Compressors",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat1f53fb98af8398824de37bdfff34d5fd",
        label: "Model Building Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat6f7b857ae0e16724352d4959d1f08bd4",
        label: "Model Building Kits",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata0167e3f4ab971f4878ea0dbedf2f92a",
        label: "Premium Radio Control Vehicles",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf7c604fd1a3c4bc232d06da2d248f1bb",
        label: "Model Train & Sets",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat15a4e5888e56205f935226bc52400003",
        label: "Slot Cars",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2d8bc72b2f10b90c6bfb25254c82ac46",
        label: "Other - Hobby's Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
    subcat6d350b93819247797548ce01d1325c0b: [
      {
        value: "childsubcat1babc782a088c6c5fe985249eae9ed52",
        label: "Building Toys & Blocks",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat55d7aed7fa663bfa3fc496538cd8213d",
        label: "Die Cast Vehicles & Friction",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcataf311c09eb5d813256f99b93a0167ade",
        label: "Dolls & Accessories",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat3f1a42fa2900a048b62b60fb725bc7f9",
        label: "Dress Up & Pretend",
        is_brand_filter: "1",
        is_color_filter: "1",
        is_size_filter: "0",
      },
      {
        value: "childsubcat48d34afdeb79ec7bb7e8d89a37a69358",
        label: "Interactive & Robots",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatcb2a5117e086d8f9ccd0f1713240ed06",
        label: "Kids Action Figure",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2c9f2654e39ff4c98ff67c644a1e6778",
        label: "Magician Kits",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat2dfb2a916d83e1de034292bc04ad1b2d",
        label: "Outdoor Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatca7e6ece7482b36bdf340cc9c9aae309",
        label: "Play-Doh & Modeling Clay",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata8efbca01ce28f9ae30e1cb1a8e98f86",
        label: "Plush Toys - Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcata17e1da94510512e018048b52e1564a7",
        label: "Puzzles",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat6c95fce660919211b03d440355ec457d",
        label: "Radio Control Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat23ff1eeb6d0d1fc6c4ddb801d7f0c9e6",
        label: "Stem Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf4fe259fd3119d26be8cfc3500bf693e",
        label: "Tricycles & Ride On",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcatf0303c759304104143bfedc673cb23c7",
        label: "Wooden Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
      {
        value: "childsubcat910ccf83b4bcfcc2f9fbe051764d89ba",
        label: "Other - Toys",
        is_brand_filter: "1",
        is_color_filter: "0",
        is_size_filter: "0",
      },
    ],
  },
  cata2b678850b1e8551118fb71af72e0da4: {
    subcat1fc6164b21d5ed74a44a70f262842a26: [
      {
        value: "childsubcat376a176195886768aee633b2e7483106",
        label: "Batteries - Gaming Accessories",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatf3fc1f908df481bc592ec5c42b17ced2",
        label: "Cases & Boxes",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat69f18a95780ef2326e2f0d7bfde647a3",
        label: "Cables & Adapters",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatea07b4daaec1421ee81febdc95b21d9f",
        label: "Chargers & Docks",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb853c316d09c29dca75130ba0689d7d5",
        label: "Controllers",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat3b0a3b5c160606837e7dbd9c6b97c085",
        label: "Replacement Parts & Tools",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat86d08fa96282d2fb93b7ddca57635fda",
        label: "Other - Gaming Accessories",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcatc15281cab63a8499ad1a29ca77b03f9f: [
      {
        value: "childsubcat24276bc182c5f040e99ff1fc391aeec9",
        label: "Consoles",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat36b3594735dfc3d301341a7b42686c6e",
        label: "Games",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat965ce737885cbc2184585bafda3717a8",
        label: "Gaming PC's",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcatb890e89d5841a7899b5eab8c8d74f27e",
        label: "Handheld Consoles",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat1c987afaea314d2747e4bcd98ce58f74",
        label: "Prepaid Game Card",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat41a3492edbc422ddcbec552f000736bb",
        label: "Other - Videogames",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
    subcat36e8b571d3f27433f57b57232c562f7b: [
      {
        value: "childsubcata1d721ddff43a8a7f6afd26bfe6e2f6a",
        label: "VR Accessories",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcate609762442bb6bb73b5c66a6c2e280eb",
        label: "VR Headsets",
        is_brand_filter: "1",
        is_color_filter: "",
        is_size_filter: "",
      },
      {
        value: "childsubcat2b8c46ff85d788b3aa85562934b14099",
        label: "Other - Virtual Reality",
        is_brand_filter: "",
        is_color_filter: "",
        is_size_filter: "",
      },
    ],
  },
};

export const colorOptions = [
  {
    value: "color687bc4a52a56a14dfe749fb3ed575131",
    label: "White",
  },
  {
    value: "color0141ba991723103a37ac5dc45bdcdc85",
    label: "Silver",
  },
  {
    value: "colorebd66c25b299162b76a2581e92406d0a",
    label: "Gold",
  },
  {
    value: "color4771e27e11c3dc5b78d6d2d01b9361ad",
    label: "Black",
  },
  {
    value: "color3ec204f58016a2d2b5fd613e92593b49",
    label: "Yellow",
  },
  {
    value: "colorf4f546f9c28b5e863fb52aaefb01fc48",
    label: "Orange",
  },
  {
    value: "colorf784737fad278572178706eee7d05dbf",
    label: "Red",
  },
  {
    value: "color941e2a7570c5cf15fbd1a7c7acf4dd98",
    label: "Pink",
  },
  {
    value: "colore81e77576207cadcd54bc3d5da43346a",
    label: "Green",
  },
  {
    value: "colored44036bda62362395934fbea617f5b2",
    label: "Cyan",
  },
  {
    value: "color3a199721269b08fa7a8ad41b1781eee8",
    label: "Blue",
  },
  {
    value: "color3175fe5580fcccd55bfe51fdb9e2e317",
    label: "Purple",
  },
  {
    value: "color74b96a09105b8a0ef160fd0a73a47ac8",
    label: "Gray",
  },
  {
    value: "color5ca3434258cddfe8e8163a433dd1d8a3",
    label: "Beige",
  },
  {
    value: "colorca074910975845b362f263b7937eddab",
    label: "Brown",
  },
  {
    value: "color98df3dd4db53fc2aa241e0d352fee823",
    label: "Burgundy",
  },
];

export const itemTypes = [
  { value: "1", label: "Wants" },
  { value: "2", label: "Sale" },
];

export const conditionOptions = [
  { value: "1", label: "New" },
  { value: "2", label: "Used" },
];

export const deliveryMethods = [
  {
    value: "1",
    label: "Pickup",
  },
  {
    value: "2",
    label: "Delivery Only",
  },
  {
    value: "3",
    label: "Pickup & Delivery",
  },
];
