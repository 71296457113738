import { Outlet, Navigate } from 'react-router-dom'
import { isExpired } from "react-jwt";
const PrivateRoutes = () => {
    let auth = localStorage.getItem('accessToken')
    const isMyTokenExpired = isExpired(auth);
    if(isMyTokenExpired) localStorage.clear();
    return(
        !isMyTokenExpired ? <Outlet/> : <Navigate to="/signin"/>
    )
}

export default PrivateRoutes