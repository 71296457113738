import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const handleSignIn = async ({ email, password }) => {
  try {
    const { data, status } = await instance.post("/user/signin", {
      email: email,
      password: password,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Logged in successfully!");
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signin");
    toast.error(error.response.data.message);
    return false;
  }
};

export const handleSignUp = async ({ email, password, name }) => {
  try {
    const { data, status } = await instance.post("/user/signup", {
      email: email,
      password: password,
      name: name,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Signed up successfully!");
      return true;
    }
  } catch (error) {
    console.log(error, "Error in signup");
    toast.error(error.response.data.message);
    return false;
  }
};

export const getUserInfo = () => async (dispatch) => {
  try {
    const { data, status } = await authenticatedInstance.get(
      "/user/get-user-info"
    );
    // console.log(data, "data");
    if (status === 200) {
      dispatch({
        type: "SET_USER",
        payload: data.user,
      });
      dispatch({
        type: "SET_PLATFORMS",
        payload: data.platforms,
      });
      return true;
    } else {
      window.location.href = "/signin";
    }
  } catch (error) {
    console.log(error, "Error in signup");
    // toast.error("Something went wrong!")
    return false;
  }
};
