import React from 'react'

const Settings = () => {

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')

  const handleSubmit = async () => {
    alert('Functionality not implemented yet!')
    return

    if (name.trim() === '') {
      alert('Name cannot be empty!')
      return
    }
    // const resp = await instance.post('/api/user/update', {
    //   name: name,
    //   email: email
    // })
    // if (resp.status === 200) {
    //   alert('Settings saved!')
    // }
  }

  React.useEffect(() => {
    setName(localStorage.getItem('name'))
    setEmail(localStorage.getItem('email'))
  }, [])

  return (
    <>
      <div className="flex-1 px-8 flex items-center justify-center sm:items-stretch sm:justify-start">
        <p className="text-2xl font-Inter font-[600] text-[#101828] ease-in duration-300 fade-in pt-1.5">Settings</p>
      </div>
      <hr className="bg-[#EAECF0] h-[1.15px] mt-2" />
      <div className='pt-3 ml-8'>
        <div className='flex items-center mt-3'>
          <label className="text-[#344054] font-Inter font-[500] text-sm min-w-[100px] w-max">Your Name</label>
          <input value={name} className="border border-[#D0D5DD] rounded-lg ml-4 py-2 px-3" type="text" placeholder="John Doe" onChange={(e) => {
            setName(e.target.value)
          }} />
        </div>
        <div className='flex items-center mt-3'>
          <label className="text-[#344054] font-Inter font-[500] text-sm min-w-[100px] w-max">Your Email</label>
          <div>
            <input value={email} className="border border-[#D0D5DD] rounded-lg ml-4 py-2 px-3" type="text" placeholder="john@email.com" disabled />
          </div>
        </div>
        <button onClick={handleSubmit} className="bg-[#FF4405] text-white font-Inter font-[500] rounded-lg text-sm py-2 px-5 mt-4">💾 Save</button>
      </div>
    </>
  )
}

export default Settings
