import PrivateRoutes from "./components/PrivateRoutes";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Authentication,
  ForgotPassword,
  ResetPassword,
} from "./pages/Authentication";
import Index from "./pages/index.js";
import React, { useEffect } from "react";
import PrivateRoute from "./components/PrivateRoutes";
import { Toaster, toast } from "sonner";

const App = () => {
  return (
    <>
      <Toaster position="top-right" richColors closeButton />
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/*" element={<Index />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
