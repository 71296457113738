const sizes = {
  Men: {
    Footwear: {
      UK: [
        {
          id: "5.1",
          name: "One size",
        },
        {
          id: "5.2",
          name: "UK 6",
        },
        {
          id: "5.3",
          name: "UK 6.5",
        },
        {
          id: "5.4",
          name: "UK 7",
        },
        {
          id: "5.5",
          name: "UK 7.5",
        },
        {
          id: "5.6",
          name: "UK 8",
        },
        {
          id: "5.7",
          name: "UK 8.5",
        },
        {
          id: "5.8",
          name: "UK 9",
        },
        {
          id: "5.9",
          name: "UK 9.5",
        },
        {
          id: "5.10",
          name: "UK 10",
        },
        {
          id: "5.11",
          name: "UK 10.5",
        },
        {
          id: "5.12",
          name: "UK 11",
        },
        {
          id: "5.13",
          name: "UK 11.5",
        },
        {
          id: "5.14",
          name: "UK 12",
        },
        {
          id: "5.15",
          name: "UK 12.5",
        },
        {
          id: "5.16",
          name: "UK 13",
        },
        {
          id: "5.17",
          name: "UK 13.5",
        },
        {
          id: "5.18",
          name: "UK 14",
        },
        {
          id: "5.19",
          name: "Other",
        },
      ],
      US: [
        {
          id: "77.1",
          name: "One size",
        },
        {
          id: "77.2",
          name: "US 7",
        },
        {
          id: "77.3",
          name: "US 7.5",
        },
        {
          id: "77.4",
          name: "US 8",
        },
        {
          id: "77.5",
          name: "US 8.5",
        },
        {
          id: "77.6",
          name: "US 9",
        },
        {
          id: "77.7",
          name: "US 9.5",
        },
        {
          id: "77.8",
          name: "US 10",
        },
        {
          id: "77.9",
          name: "US 10.5",
        },
        {
          id: "77.10",
          name: "US 11",
        },
        {
          id: "77.11",
          name: "US 11.5",
        },
        {
          id: "77.12",
          name: "US 12",
        },
        {
          id: "77.13",
          name: "US 12.5",
        },
        {
          id: "77.14",
          name: "US 13",
        },
        {
          id: "77.15",
          name: "US 13.5",
        },
        {
          id: "77.16",
          name: "US 14",
        },
        {
          id: "77.17",
          name: "US 14.5",
        },
        {
          id: "77.18",
          name: "US 15",
        },
        {
          id: "77.19",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "79.1",
          name: "One size",
        },
        {
          id: "79.16",
          name: "EUR 37",
        },
        {
          id: "79.17",
          name: "EUR 37.5",
        },
        {
          id: "79.18",
          name: "EUR 38",
        },
        {
          id: "79.19",
          name: "EUR 38.5",
        },
        {
          id: "79.2",
          name: "EUR 39",
        },
        {
          id: "79.3",
          name: "EUR 39.5",
        },
        {
          id: "79.4",
          name: "EUR 40",
        },
        {
          id: "79.5",
          name: "EUR 40.5",
        },
        {
          id: "79.6",
          name: "EUR 41",
        },
        {
          id: "79.7",
          name: "EUR 41.5",
        },
        {
          id: "79.8",
          name: "EUR 42",
        },
        {
          id: "79.9",
          name: "EUR 42.5",
        },
        {
          id: "79.10",
          name: "EUR 43",
        },
        {
          id: "79.11",
          name: "EUR 43.5",
        },
        {
          id: "79.12",
          name: "EUR 44",
        },
        {
          id: "79.13",
          name: "EUR 44.5",
        },
        {
          id: "79.14",
          name: "EUR 45",
        },
        {
          id: "79.15",
          name: "Other",
        },
      ],
    },
    Tops: {
      UK: [
        {
          id: "52.1",
          name: "One size",
        },
        {
          id: "52.9",
          name: "XXS",
        },
        {
          id: "52.2",
          name: "XS",
        },
        {
          id: "52.3",
          name: "S",
        },
        {
          id: "52.4",
          name: "M",
        },
        {
          id: "52.5",
          name: "L",
        },
        {
          id: "52.6",
          name: "XL",
        },
        {
          id: "52.7",
          name: "XXL",
        },
        {
          id: "52.10",
          name: "XXXL",
        },
        {
          id: "52.11",
          name: "XXXXL",
        },
        {
          id: "52.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "54.1",
          name: "One size",
        },
        {
          id: "54.10",
          name: "XXS",
        },
        {
          id: "54.2",
          name: "XS",
        },
        {
          id: "54.3",
          name: "S",
        },
        {
          id: "54.4",
          name: "M",
        },
        {
          id: "54.5",
          name: "L",
        },
        {
          id: "54.6",
          name: "XL",
        },
        {
          id: "54.7",
          name: "XXL",
        },
        {
          id: "54.8",
          name: "3XL",
        },
        {
          id: "54.11",
          name: "4XL",
        },
        {
          id: "54.9",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "56.1",
          name: "One size",
        },
        {
          id: "56.2",
          name: "XS",
        },
        {
          id: "56.3",
          name: "S",
        },
        {
          id: "56.4",
          name: "M",
        },
        {
          id: "56.5",
          name: "L",
        },
        {
          id: "56.6",
          name: "XL",
        },
        {
          id: "56.7",
          name: "XXL",
        },
        {
          id: "56.8",
          name: "XXXL",
        },
        {
          id: "56.10",
          name: "36",
        },
        {
          id: "56.11",
          name: "37",
        },
        {
          id: "56.12",
          name: "38",
        },
        {
          id: "56.13",
          name: "39",
        },
        {
          id: "56.14",
          name: "40",
        },
        {
          id: "56.15",
          name: "41",
        },
        {
          id: "56.16",
          name: "42",
        },
        {
          id: "56.17",
          name: "43",
        },
        {
          id: "56.18",
          name: "44",
        },
        {
          id: "56.19",
          name: "45",
        },
        {
          id: "56.20",
          name: "46",
        },
        {
          id: "56.21",
          name: "47",
        },
        {
          id: "56.22",
          name: "48",
        },
        {
          id: "56.9",
          name: "Other",
        },
      ],
    },
    Bottoms: {
      UK: [
        {
          id: "58.1",
          name: "One size",
        },
        {
          id: "58.26",
          name: "XXS",
        },
        {
          id: "58.2",
          name: "XS",
        },
        {
          id: "58.3",
          name: "S",
        },
        {
          id: "58.4",
          name: "M",
        },
        {
          id: "58.5",
          name: "L",
        },
        {
          id: "58.6",
          name: "XL",
        },
        {
          id: "58.7",
          name: "XXL",
        },
        {
          id: "58.27",
          name: "XXXL",
        },
        {
          id: "58.28",
          name: "XXXXL",
        },
        {
          id: "58.8",
          name: "24",
        },
        {
          id: "58.9",
          name: "25",
        },
        {
          id: "58.10",
          name: "26",
        },
        {
          id: "58.11",
          name: "27",
        },
        {
          id: "58.12",
          name: "28",
        },
        {
          id: "58.13",
          name: "29",
        },
        {
          id: "58.14",
          name: "30",
        },
        {
          id: "58.15",
          name: "31",
        },
        {
          id: "58.16",
          name: "32",
        },
        {
          id: "58.17",
          name: "33",
        },
        {
          id: "58.18",
          name: "34",
        },
        {
          id: "58.19",
          name: "35",
        },
        {
          id: "58.20",
          name: "36",
        },
        {
          id: "58.21",
          name: "37",
        },
        {
          id: "58.22",
          name: "38",
        },
        {
          id: "58.23",
          name: "39",
        },
        {
          id: "58.24",
          name: "40",
        },
        {
          id: "58.29",
          name: "42",
        },
        {
          id: "58.30",
          name: "44",
        },
        {
          id: "58.31",
          name: "46",
        },
        {
          id: "58.25",
          name: "Other",
        },
      ],
      US: [
        {
          id: "60.1",
          name: "One size",
        },
        {
          id: "60.27",
          name: "XXS",
        },
        {
          id: "60.2",
          name: "XS",
        },
        {
          id: "60.3",
          name: "S",
        },
        {
          id: "60.4",
          name: "M",
        },
        {
          id: "60.5",
          name: "L",
        },
        {
          id: "60.6",
          name: "XL",
        },
        {
          id: "60.7",
          name: "XXL",
        },
        {
          id: "60.8",
          name: "XXXL",
        },
        {
          id: "60.28",
          name: "XXXXL",
        },
        {
          id: "60.9",
          name: "26",
        },
        {
          id: "60.10",
          name: "27",
        },
        {
          id: "60.11",
          name: "28",
        },
        {
          id: "60.12",
          name: "29",
        },
        {
          id: "60.13",
          name: "30",
        },
        {
          id: "60.14",
          name: "31",
        },
        {
          id: "60.15",
          name: "32",
        },
        {
          id: "60.16",
          name: "33",
        },
        {
          id: "60.17",
          name: "34",
        },
        {
          id: "60.18",
          name: "35",
        },
        {
          id: "60.19",
          name: "36",
        },
        {
          id: "60.20",
          name: "37",
        },
        {
          id: "60.21",
          name: "38",
        },
        {
          id: "60.22",
          name: "39",
        },
        {
          id: "60.23",
          name: "40",
        },
        {
          id: "60.24",
          name: "41",
        },
        {
          id: "60.25",
          name: "42",
        },
        {
          id: "60.29",
          name: "44",
        },
        {
          id: "60.30",
          name: "46",
        },
        {
          id: "60.26",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "62.1",
          name: "One size",
        },
        {
          id: "62.2",
          name: "XS",
        },
        {
          id: "62.3",
          name: "S",
        },
        {
          id: "62.4",
          name: "M",
        },
        {
          id: "62.5",
          name: "L",
        },
        {
          id: "62.6",
          name: "XL",
        },
        {
          id: "62.7",
          name: "XXL",
        },
        {
          id: "62.8",
          name: "XXXL",
        },
        {
          id: "62.9",
          name: "24",
        },
        {
          id: "62.10",
          name: "25",
        },
        {
          id: "62.11",
          name: "26",
        },
        {
          id: "62.12",
          name: "27",
        },
        {
          id: "62.13",
          name: "28",
        },
        {
          id: "62.14",
          name: "29",
        },
        {
          id: "62.15",
          name: "30",
        },
        {
          id: "62.16",
          name: "31",
        },
        {
          id: "62.17",
          name: "32",
        },
        {
          id: "62.18",
          name: "33",
        },
        {
          id: "62.19",
          name: "34",
        },
        {
          id: "62.20",
          name: "35",
        },
        {
          id: "62.21",
          name: "36",
        },
        {
          id: "62.22",
          name: "37",
        },
        {
          id: "62.23",
          name: "38",
        },
        {
          id: "62.24",
          name: "39",
        },
        {
          id: "62.25",
          name: "40",
        },
        {
          id: "62.33",
          name: "42",
        },
        {
          id: "62.34",
          name: "44",
        },
        {
          id: "62.27",
          name: "46",
        },
        {
          id: "62.28",
          name: "48",
        },
        {
          id: "62.29",
          name: "50",
        },
        {
          id: "62.30",
          name: "52",
        },
        {
          id: "62.31",
          name: "54",
        },
        {
          id: "62.32",
          name: "56",
        },
        {
          id: "62.26",
          name: "Other",
        },
      ],
    },
    Underwear: {
      UK: [
        {
          id: "87.1",
          name: "One size",
        },
        {
          id: "87.9",
          name: "XXS",
        },
        {
          id: "87.2",
          name: "XS",
        },
        {
          id: "87.3",
          name: "S",
        },
        {
          id: "87.4",
          name: "M",
        },
        {
          id: "87.5",
          name: "L",
        },
        {
          id: "87.6",
          name: "XL",
        },
        {
          id: "87.7",
          name: "XXL",
        },
        {
          id: "87.10",
          name: "XXXL",
        },
        {
          id: "87.11",
          name: "XXXXL",
        },
        {
          id: "87.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "89.1",
          name: "One size",
        },
        {
          id: "89.10",
          name: "XXS",
        },
        {
          id: "89.2",
          name: "XS",
        },
        {
          id: "89.3",
          name: "S",
        },
        {
          id: "89.4",
          name: "M",
        },
        {
          id: "89.5",
          name: "L",
        },
        {
          id: "89.6",
          name: "XL",
        },
        {
          id: "89.7",
          name: "XXL",
        },
        {
          id: "89.8",
          name: "XXXL",
        },
        {
          id: "89.11",
          name: "XXXXL",
        },
        {
          id: "89.9",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "92.1",
          name: "One size",
        },
        {
          id: "92.2",
          name: "XS",
        },
        {
          id: "92.3",
          name: "S",
        },
        {
          id: "92.4",
          name: "M",
        },
        {
          id: "92.5",
          name: "L",
        },
        {
          id: "92.6",
          name: "XL",
        },
        {
          id: "92.7",
          name: "XXL",
        },
        {
          id: "92.8",
          name: "XXXL",
        },
        {
          id: "92.10",
          name: "3",
        },
        {
          id: "92.11",
          name: "4",
        },
        {
          id: "92.12",
          name: "5",
        },
        {
          id: "92.13",
          name: "6",
        },
        {
          id: "92.14",
          name: "7",
        },
        {
          id: "92.9",
          name: "Other",
        },
      ],
    },
    "Coats and jackets": {
      UK: [
        {
          id: "52.1",
          name: "One size",
        },
        {
          id: "52.9",
          name: "XXS",
        },
        {
          id: "52.2",
          name: "XS",
        },
        {
          id: "52.3",
          name: "S",
        },
        {
          id: "52.4",
          name: "M",
        },
        {
          id: "52.5",
          name: "L",
        },
        {
          id: "52.6",
          name: "XL",
        },
        {
          id: "52.7",
          name: "XXL",
        },
        {
          id: "52.10",
          name: "XXXL",
        },
        {
          id: "52.11",
          name: "XXXXL",
        },
        {
          id: "52.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "54.1",
          name: "One size",
        },
        {
          id: "54.10",
          name: "XXS",
        },
        {
          id: "54.2",
          name: "XS",
        },
        {
          id: "54.3",
          name: "S",
        },
        {
          id: "54.4",
          name: "M",
        },
        {
          id: "54.5",
          name: "L",
        },
        {
          id: "54.6",
          name: "XL",
        },
        {
          id: "54.7",
          name: "XXL",
        },
        {
          id: "54.8",
          name: "XXXL",
        },
        {
          id: "54.11",
          name: "XXXXL",
        },
        {
          id: "54.9",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "56.1",
          name: "One size",
        },
        {
          id: "56.2",
          name: "XS",
        },
        {
          id: "56.3",
          name: "S",
        },
        {
          id: "56.4",
          name: "M",
        },
        {
          id: "56.5",
          name: "L",
        },
        {
          id: "56.6",
          name: "XL",
        },
        {
          id: "56.7",
          name: "XXL",
        },
        {
          id: "56.8",
          name: "XXXL",
        },
        {
          id: "56.10",
          name: "36",
        },
        {
          id: "56.11",
          name: "37",
        },
        {
          id: "56.12",
          name: "38",
        },
        {
          id: "56.13",
          name: "39",
        },
        {
          id: "56.14",
          name: "40",
        },
        {
          id: "56.15",
          name: "41",
        },
        {
          id: "56.16",
          name: "42",
        },
        {
          id: "56.17",
          name: "43",
        },
        {
          id: "56.18",
          name: "44",
        },
        {
          id: "56.19",
          name: "45",
        },
        {
          id: "56.20",
          name: "46",
        },
        {
          id: "56.21",
          name: "47",
        },
        {
          id: "56.22",
          name: "48",
        },
        {
          id: "56.9",
          name: "Other",
        },
      ],
    },
    "Jumpsuits and rompers": {
      UK: [
        {
          id: "58.1",
          name: "One size",
        },
        {
          id: "58.26",
          name: "XXS",
        },
        {
          id: "58.2",
          name: "XS",
        },
        {
          id: "58.3",
          name: "S",
        },
        {
          id: "58.4",
          name: "M",
        },
        {
          id: "58.5",
          name: "L",
        },
        {
          id: "58.6",
          name: "XL",
        },
        {
          id: "58.7",
          name: "XXL",
        },
        {
          id: "58.27",
          name: "XXXL",
        },
        {
          id: "58.28",
          name: "XXXXL",
        },
        {
          id: "58.8",
          name: "24",
        },
        {
          id: "58.9",
          name: "25",
        },
        {
          id: "58.10",
          name: "26",
        },
        {
          id: "58.11",
          name: "27",
        },
        {
          id: "58.12",
          name: "28",
        },
        {
          id: "58.13",
          name: "29",
        },
        {
          id: "58.14",
          name: "30",
        },
        {
          id: "58.15",
          name: "31",
        },
        {
          id: "58.16",
          name: "32",
        },
        {
          id: "58.17",
          name: "33",
        },
        {
          id: "58.18",
          name: "34",
        },
        {
          id: "58.19",
          name: "35",
        },
        {
          id: "58.20",
          name: "36",
        },
        {
          id: "58.21",
          name: "37",
        },
        {
          id: "58.22",
          name: "38",
        },
        {
          id: "58.23",
          name: "39",
        },
        {
          id: "58.24",
          name: "40",
        },
        {
          id: "58.29",
          name: "42",
        },
        {
          id: "58.30",
          name: "44",
        },
        {
          id: "58.31",
          name: "46",
        },
        {
          id: "58.25",
          name: "Other",
        },
      ],
      US: [
        {
          id: "60.1",
          name: "One size",
        },
        {
          id: "60.27",
          name: "XXS",
        },
        {
          id: "60.2",
          name: "XS",
        },
        {
          id: "60.3",
          name: "S",
        },
        {
          id: "60.4",
          name: "M",
        },
        {
          id: "60.5",
          name: "L",
        },
        {
          id: "60.6",
          name: "XL",
        },
        {
          id: "60.7",
          name: "XXL",
        },
        {
          id: "60.8",
          name: "XXXL",
        },
        {
          id: "60.28",
          name: "XXXXL",
        },
        {
          id: "60.9",
          name: "26",
        },
        {
          id: "60.10",
          name: "27",
        },
        {
          id: "60.11",
          name: "28",
        },
        {
          id: "60.12",
          name: "29",
        },
        {
          id: "60.13",
          name: "30",
        },
        {
          id: "60.14",
          name: "31",
        },
        {
          id: "60.15",
          name: "32",
        },
        {
          id: "60.16",
          name: "33",
        },
        {
          id: "60.17",
          name: "34",
        },
        {
          id: "60.18",
          name: "35",
        },
        {
          id: "60.19",
          name: "36",
        },
        {
          id: "60.20",
          name: "37",
        },
        {
          id: "60.21",
          name: "38",
        },
        {
          id: "60.22",
          name: "39",
        },
        {
          id: "60.23",
          name: "40",
        },
        {
          id: "60.24",
          name: "41",
        },
        {
          id: "60.25",
          name: "42",
        },
        {
          id: "60.29",
          name: "44",
        },
        {
          id: "60.30",
          name: "46",
        },
        {
          id: "60.26",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "62.1",
          name: "One size",
        },
        {
          id: "62.2",
          name: "XS",
        },
        {
          id: "62.3",
          name: "S",
        },
        {
          id: "62.4",
          name: "M",
        },
        {
          id: "62.5",
          name: "L",
        },
        {
          id: "62.6",
          name: "XL",
        },
        {
          id: "62.7",
          name: "XXL",
        },
        {
          id: "62.8",
          name: "XXXL",
        },
        {
          id: "62.9",
          name: "24",
        },
        {
          id: "62.10",
          name: "25",
        },
        {
          id: "62.11",
          name: "26",
        },
        {
          id: "62.12",
          name: "27",
        },
        {
          id: "62.13",
          name: "28",
        },
        {
          id: "62.14",
          name: "29",
        },
        {
          id: "62.15",
          name: "30",
        },
        {
          id: "62.16",
          name: "31",
        },
        {
          id: "62.17",
          name: "32",
        },
        {
          id: "62.18",
          name: "33",
        },
        {
          id: "62.19",
          name: "34",
        },
        {
          id: "62.20",
          name: "35",
        },
        {
          id: "62.21",
          name: "36",
        },
        {
          id: "62.22",
          name: "37",
        },
        {
          id: "62.23",
          name: "38",
        },
        {
          id: "62.24",
          name: "39",
        },
        {
          id: "62.25",
          name: "40",
        },
        {
          id: "62.33",
          name: "42",
        },
        {
          id: "62.34",
          name: "44",
        },
        {
          id: "62.27",
          name: "46",
        },
        {
          id: "62.28",
          name: "48",
        },
        {
          id: "62.29",
          name: "50",
        },
        {
          id: "62.30",
          name: "52",
        },
        {
          id: "62.31",
          name: "54",
        },
        {
          id: "62.32",
          name: "56",
        },
        {
          id: "62.26",
          name: "Other",
        },
      ],
    },
    Suits: {
      UK: [
        {
          id: "52.1",
          name: "One size",
        },
        {
          id: "52.9",
          name: "XXS",
        },
        {
          id: "52.2",
          name: "XS",
        },
        {
          id: "52.3",
          name: "S",
        },
        {
          id: "52.4",
          name: "M",
        },
        {
          id: "52.5",
          name: "L",
        },
        {
          id: "52.6",
          name: "XL",
        },
        {
          id: "52.7",
          name: "XXL",
        },
        {
          id: "52.10",
          name: "XXXL",
        },
        {
          id: "52.11",
          name: "XXXXL",
        },
        {
          id: "52.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "54.1",
          name: "One size",
        },
        {
          id: "54.10",
          name: "XXS",
        },
        {
          id: "54.2",
          name: "XS",
        },
        {
          id: "54.3",
          name: "S",
        },
        {
          id: "54.4",
          name: "M",
        },
        {
          id: "54.5",
          name: "L",
        },
        {
          id: "54.6",
          name: "XL",
        },
        {
          id: "54.7",
          name: "XXL",
        },
        {
          id: "54.8",
          name: "XXXL",
        },
        {
          id: "54.11",
          name: "XXXXL",
        },
        {
          id: "54.9",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "56.1",
          name: "One size",
        },
        {
          id: "56.2",
          name: "XS",
        },
        {
          id: "56.3",
          name: "S",
        },
        {
          id: "56.4",
          name: "M",
        },
        {
          id: "56.5",
          name: "L",
        },
        {
          id: "56.6",
          name: "XL",
        },
        {
          id: "56.7",
          name: "XXL",
        },
        {
          id: "56.8",
          name: "XXXL",
        },
        {
          id: "56.10",
          name: "36",
        },
        {
          id: "56.11",
          name: "37",
        },
        {
          id: "56.12",
          name: "38",
        },
        {
          id: "56.13",
          name: "39",
        },
        {
          id: "56.14",
          name: "40",
        },
        {
          id: "56.15",
          name: "41",
        },
        {
          id: "56.16",
          name: "42",
        },
        {
          id: "56.17",
          name: "43",
        },
        {
          id: "56.18",
          name: "44",
        },
        {
          id: "56.19",
          name: "45",
        },
        {
          id: "56.20",
          name: "46",
        },
        {
          id: "56.21",
          name: "47",
        },
        {
          id: "56.22",
          name: "48",
        },
        {
          id: "56.9",
          name: "Other",
        },
      ],
    },
    Accessories: {
      UK: [{ name: "One size" }],
      US: [{ name: "One size" }],
      EUR: [{ name: "One size" }],
    },
    Swimwear: {
      UK: [
        {
          id: "87.1",
          name: "One size",
        },
        {
          id: "87.9",
          name: "XXS",
        },
        {
          id: "87.2",
          name: "XS",
        },
        {
          id: "87.3",
          name: "S",
        },
        {
          id: "87.4",
          name: "M",
        },
        {
          id: "87.5",
          name: "L",
        },
        {
          id: "87.6",
          name: "XL",
        },
        {
          id: "87.7",
          name: "XXL",
        },
        {
          id: "87.10",
          name: "XXXL",
        },
        {
          id: "87.11",
          name: "XXXXL",
        },
        {
          id: "87.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "89.1",
          name: "One size",
        },
        {
          id: "89.10",
          name: "XXS",
        },
        {
          id: "89.2",
          name: "XS",
        },
        {
          id: "89.3",
          name: "S",
        },
        {
          id: "89.4",
          name: "M",
        },
        {
          id: "89.5",
          name: "L",
        },
        {
          id: "89.6",
          name: "XL",
        },
        {
          id: "89.7",
          name: "XXL",
        },
        {
          id: "89.8",
          name: "XXXL",
        },
        {
          id: "89.11",
          name: "XXXXL",
        },
        {
          id: "89.9",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "92.1",
          name: "One size",
        },
        {
          id: "92.2",
          name: "XS",
        },
        {
          id: "92.3",
          name: "S",
        },
        {
          id: "92.4",
          name: "M",
        },
        {
          id: "92.5",
          name: "L",
        },
        {
          id: "92.6",
          name: "XL",
        },
        {
          id: "92.7",
          name: "XXL",
        },
        {
          id: "92.8",
          name: "XXXL",
        },
        {
          id: "92.10",
          name: "3",
        },
        {
          id: "92.11",
          name: "4",
        },
        {
          id: "92.12",
          name: "5",
        },
        {
          id: "92.13",
          name: "6",
        },
        {
          id: "92.14",
          name: "7",
        },
        {
          id: "92.9",
          name: "Other",
        },
      ],
    },
    Costume: {
      UK: [{ name: "One size" }],
      US: [{ name: "One size" }],
      EUR: [{ name: "One size" }],
    },
    Sleepwear: {
      UK: [
        {
          id: "58.1",
          name: "One size",
        },
        {
          id: "58.26",
          name: "XXS",
        },
        {
          id: "58.2",
          name: "XS",
        },
        {
          id: "58.3",
          name: "S",
        },
        {
          id: "58.4",
          name: "M",
        },
        {
          id: "58.5",
          name: "L",
        },
        {
          id: "58.6",
          name: "XL",
        },
        {
          id: "58.7",
          name: "XXL",
        },
        {
          id: "58.27",
          name: "XXXL",
        },
        {
          id: "58.28",
          name: "XXXXL",
        },
        {
          id: "58.8",
          name: "24",
        },
        {
          id: "58.9",
          name: "25",
        },
        {
          id: "58.10",
          name: "26",
        },
        {
          id: "58.11",
          name: "27",
        },
        {
          id: "58.12",
          name: "28",
        },
        {
          id: "58.13",
          name: "29",
        },
        {
          id: "58.14",
          name: "30",
        },
        {
          id: "58.15",
          name: "31",
        },
        {
          id: "58.16",
          name: "32",
        },
        {
          id: "58.17",
          name: "33",
        },
        {
          id: "58.18",
          name: "34",
        },
        {
          id: "58.19",
          name: "35",
        },
        {
          id: "58.20",
          name: "36",
        },
        {
          id: "58.21",
          name: "37",
        },
        {
          id: "58.22",
          name: "38",
        },
        {
          id: "58.23",
          name: "39",
        },
        {
          id: "58.24",
          name: "40",
        },
        {
          id: "58.29",
          name: "42",
        },
        {
          id: "58.30",
          name: "44",
        },
        {
          id: "58.31",
          name: "46",
        },
        {
          id: "58.25",
          name: "Other",
        },
      ],
      US: [
        {
          id: "60.1",
          name: "One size",
        },
        {
          id: "60.27",
          name: "XXS",
        },
        {
          id: "60.2",
          name: "XS",
        },
        {
          id: "60.3",
          name: "S",
        },
        {
          id: "60.4",
          name: "M",
        },
        {
          id: "60.5",
          name: "L",
        },
        {
          id: "60.6",
          name: "XL",
        },
        {
          id: "60.7",
          name: "XXL",
        },
        {
          id: "60.8",
          name: "XXXL",
        },
        {
          id: "60.28",
          name: "XXXXL",
        },
        {
          id: "60.9",
          name: "26",
        },
        {
          id: "60.10",
          name: "27",
        },
        {
          id: "60.11",
          name: "28",
        },
        {
          id: "60.12",
          name: "29",
        },
        {
          id: "60.13",
          name: "30",
        },
        {
          id: "60.14",
          name: "31",
        },
        {
          id: "60.15",
          name: "32",
        },
        {
          id: "60.16",
          name: "33",
        },
        {
          id: "60.17",
          name: "34",
        },
        {
          id: "60.18",
          name: "35",
        },
        {
          id: "60.19",
          name: "36",
        },
        {
          id: "60.20",
          name: "37",
        },
        {
          id: "60.21",
          name: "38",
        },
        {
          id: "60.22",
          name: "39",
        },
        {
          id: "60.23",
          name: "40",
        },
        {
          id: "60.24",
          name: "41",
        },
        {
          id: "60.25",
          name: "42",
        },
        {
          id: "60.29",
          name: "44",
        },
        {
          id: "60.30",
          name: "46",
        },
        {
          id: "60.26",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "62.1",
          name: "One size",
        },
        {
          id: "62.2",
          name: "XS",
        },
        {
          id: "62.3",
          name: "S",
        },
        {
          id: "62.4",
          name: "M",
        },
        {
          id: "62.5",
          name: "L",
        },
        {
          id: "62.6",
          name: "XL",
        },
        {
          id: "62.7",
          name: "XXL",
        },
        {
          id: "62.8",
          name: "XXXL",
        },
        {
          id: "62.9",
          name: "24",
        },
        {
          id: "62.10",
          name: "25",
        },
        {
          id: "62.11",
          name: "26",
        },
        {
          id: "62.12",
          name: "27",
        },
        {
          id: "62.13",
          name: "28",
        },
        {
          id: "62.14",
          name: "29",
        },
        {
          id: "62.15",
          name: "30",
        },
        {
          id: "62.16",
          name: "31",
        },
        {
          id: "62.17",
          name: "32",
        },
        {
          id: "62.18",
          name: "33",
        },
        {
          id: "62.19",
          name: "34",
        },
        {
          id: "62.20",
          name: "35",
        },
        {
          id: "62.21",
          name: "36",
        },
        {
          id: "62.22",
          name: "37",
        },
        {
          id: "62.23",
          name: "38",
        },
        {
          id: "62.24",
          name: "39",
        },
        {
          id: "62.25",
          name: "40",
        },
        {
          id: "62.33",
          name: "42",
        },
        {
          id: "62.34",
          name: "44",
        },
        {
          id: "62.27",
          name: "46",
        },
        {
          id: "62.28",
          name: "48",
        },
        {
          id: "62.29",
          name: "50",
        },
        {
          id: "62.30",
          name: "52",
        },
        {
          id: "62.31",
          name: "54",
        },
        {
          id: "62.32",
          name: "56",
        },
        {
          id: "62.26",
          name: "Other",
        },
      ],
    },
  },
  Women: {
    Tops: {
      UK: [
        {
          id: "2.1",
          name: "One size",
        },
        {
          id: "2.25",
          name: "4",
        },
        {
          id: "2.2",
          name: "6",
        },
        {
          id: "2.3",
          name: "8",
        },
        {
          id: "2.4",
          name: "10",
        },
        {
          id: "2.5",
          name: "12",
        },
        {
          id: "2.6",
          name: "14",
        },
        {
          id: "2.7",
          name: "16",
        },
        {
          id: "2.15",
          name: "18",
        },
        {
          id: "2.16",
          name: "20",
        },
        {
          id: "2.17",
          name: "22",
        },
        {
          id: "2.18",
          name: "24",
        },
        {
          id: "2.19",
          name: "26",
        },
        {
          id: "2.20",
          name: "28",
        },
        {
          id: "2.21",
          name: "30",
        },
        {
          id: "2.22",
          name: "32",
        },
        {
          id: "2.26",
          name: "XXS",
        },
        {
          id: "2.9",
          name: "XS",
        },
        {
          id: "2.10",
          name: "S",
        },
        {
          id: "2.11",
          name: "M",
        },
        {
          id: "2.12",
          name: "L",
        },
        {
          id: "2.13",
          name: "XL",
        },
        {
          id: "2.14",
          name: "XXL",
        },
        {
          id: "2.23",
          name: "XXXL",
        },
        {
          id: "2.24",
          name: "XXXXL",
        },
        {
          id: "2.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "4.1",
          name: "One size",
        },
        {
          id: "4.21",
          name: "00",
        },
        {
          id: "4.2",
          name: "0",
        },
        {
          id: "4.3",
          name: "1",
        },
        {
          id: "4.4",
          name: "2",
        },
        {
          id: "4.5",
          name: "3",
        },
        {
          id: "4.6",
          name: "4",
        },
        {
          id: "4.7",
          name: "5",
        },
        {
          id: "4.8",
          name: "6",
        },
        {
          id: "4.9",
          name: "7",
        },
        {
          id: "4.10",
          name: "8",
        },
        {
          id: "4.11",
          name: "9",
        },
        {
          id: "4.12",
          name: "10",
        },
        {
          id: "4.13",
          name: "11",
        },
        {
          id: "4.22",
          name: "12",
        },
        {
          id: "4.23",
          name: "14",
        },
        {
          id: "4.24",
          name: "16",
        },
        {
          id: "4.25",
          name: "18",
        },
        {
          id: "4.26",
          name: "20",
        },
        {
          id: "4.27",
          name: "22",
        },
        {
          id: "4.28",
          name: "24",
        },
        {
          id: "4.29",
          name: "26",
        },
        {
          id: "4.30",
          name: "28",
        },
        {
          id: "4.31",
          name: "30",
        },
        {
          id: "4.32",
          name: "XXS",
        },
        {
          id: "4.15",
          name: "XS",
        },
        {
          id: "4.16",
          name: "S",
        },
        {
          id: "4.17",
          name: "M",
        },
        {
          id: "4.18",
          name: "L",
        },
        {
          id: "4.19",
          name: "XL",
        },
        {
          id: "4.20",
          name: "XXL",
        },
        {
          id: "4.33",
          name: "XXXL",
        },
        {
          id: "4.34",
          name: "XXXXL",
        },
        {
          id: "4.14",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "9.1",
          name: "One size",
        },
        {
          id: "9.2",
          name: "XS",
        },
        {
          id: "9.3",
          name: "S",
        },
        {
          id: "9.4",
          name: "M",
        },
        {
          id: "9.5",
          name: "L",
        },
        {
          id: "9.6",
          name: "XL",
        },
        {
          id: "9.7",
          name: "XXL",
        },
        {
          id: "9.8",
          name: "36",
        },
        {
          id: "9.9",
          name: "38",
        },
        {
          id: "9.10",
          name: "40",
        },
        {
          id: "9.11",
          name: "42",
        },
        {
          id: "9.12",
          name: "44",
        },
        {
          id: "9.13",
          name: "46",
        },
        {
          id: "9.14",
          name: "48",
        },
        {
          id: "9.15",
          name: "Other",
        },
      ],
    },
    Bottoms: {
      UK: [
        {
          id: "20.1",
          name: "One size",
        },
        {
          id: "20.36",
          name: "UK 4",
        },
        {
          id: "20.2",
          name: "UK 6",
        },
        {
          id: "20.3",
          name: "UK 8",
        },
        {
          id: "20.4",
          name: "UK 10",
        },
        {
          id: "20.5",
          name: "UK 12",
        },
        {
          id: "20.6",
          name: "UK 14",
        },
        {
          id: "20.7",
          name: "UK 16",
        },
        {
          id: "20.8",
          name: "UK 18",
        },
        {
          id: "20.27",
          name: "UK 20",
        },
        {
          id: "20.28",
          name: "UK 22",
        },
        {
          id: "20.29",
          name: "UK 24",
        },
        {
          id: "20.30",
          name: "UK 26",
        },
        {
          id: "20.31",
          name: "UK 28",
        },
        {
          id: "20.32",
          name: "UK 30",
        },
        {
          id: "20.33",
          name: "UK 32",
        },
        {
          id: "20.38",
          name: "21",
        },
        {
          id: "20.39",
          name: "22",
        },
        {
          id: "20.40",
          name: "23",
        },
        {
          id: "20.9",
          name: "24",
        },
        {
          id: "20.10",
          name: "25",
        },
        {
          id: "20.11",
          name: "26",
        },
        {
          id: "20.12",
          name: "27",
        },
        {
          id: "20.13",
          name: "28",
        },
        {
          id: "20.14",
          name: "29",
        },
        {
          id: "20.15",
          name: "30",
        },
        {
          id: "20.16",
          name: "31",
        },
        {
          id: "20.17",
          name: "32",
        },
        {
          id: "20.18",
          name: "33",
        },
        {
          id: "20.19",
          name: "34",
        },
        {
          id: "20.41",
          name: "35",
        },
        {
          id: "20.42",
          name: "36",
        },
        {
          id: "20.43",
          name: "37",
        },
        {
          id: "20.44",
          name: "38",
        },
        {
          id: "20.45",
          name: "39",
        },
        {
          id: "20.46",
          name: "40",
        },
        {
          id: "20.47",
          name: "42",
        },
        {
          id: "20.48",
          name: "44",
        },
        {
          id: "20.49",
          name: "46",
        },
        {
          id: "20.50",
          name: "48",
        },
        {
          id: "20.51",
          name: "50",
        },
        {
          id: "20.37",
          name: "XXS",
        },
        {
          id: "20.21",
          name: "XS",
        },
        {
          id: "20.22",
          name: "S",
        },
        {
          id: "20.23",
          name: "M",
        },
        {
          id: "20.24",
          name: "L",
        },
        {
          id: "20.25",
          name: "XL",
        },
        {
          id: "20.26",
          name: "XXL",
        },
        {
          id: "20.34",
          name: "XXXL",
        },
        {
          id: "20.35",
          name: "XXXXL",
        },
        {
          id: "20.20",
          name: "Other",
        },
      ],
      US: [
        {
          id: "22.1",
          name: "One size",
        },
        {
          id: "22.2",
          name: "0",
        },
        {
          id: "22.3",
          name: "1",
        },
        {
          id: "22.4",
          name: "2",
        },
        {
          id: "22.5",
          name: "3",
        },
        {
          id: "22.6",
          name: "4",
        },
        {
          id: "22.7",
          name: "5",
        },
        {
          id: "22.8",
          name: "6",
        },
        {
          id: "22.9",
          name: "7",
        },
        {
          id: "22.10",
          name: "8",
        },
        {
          id: "22.11",
          name: "9",
        },
        {
          id: "22.12",
          name: "10",
        },
        {
          id: "22.13",
          name: "11",
        },
        {
          id: "22.14",
          name: "12",
        },
        {
          id: "22.37",
          name: "14",
        },
        {
          id: "22.38",
          name: "16",
        },
        {
          id: "22.39",
          name: "18",
        },
        {
          id: "22.40",
          name: "20",
        },
        {
          id: "22.41",
          name: "22",
        },
        {
          id: "22.42",
          name: "24",
        },
        {
          id: "22.43",
          name: "26",
        },
        {
          id: "22.44",
          name: "28",
        },
        {
          id: "22.45",
          name: "30",
        },
        {
          id: "22.46",
          name: "32",
        },
        {
          id: "22.48",
          name: "21",
        },
        {
          id: "22.49",
          name: "22",
        },
        {
          id: "22.33",
          name: "23",
        },
        {
          id: "22.15",
          name: "24",
        },
        {
          id: "22.16",
          name: "25",
        },
        {
          id: "22.17",
          name: "26",
        },
        {
          id: "22.18",
          name: "27",
        },
        {
          id: "22.19",
          name: "28",
        },
        {
          id: "22.20",
          name: "29",
        },
        {
          id: "22.21",
          name: "30",
        },
        {
          id: "22.22",
          name: "31",
        },
        {
          id: "22.23",
          name: "32",
        },
        {
          id: "22.24",
          name: "33",
        },
        {
          id: "22.25",
          name: "34",
        },
        {
          id: "22.34",
          name: "35",
        },
        {
          id: "22.50",
          name: "36",
        },
        {
          id: "22.51",
          name: "37",
        },
        {
          id: "22.52",
          name: "38",
        },
        {
          id: "22.53",
          name: "39",
        },
        {
          id: "22.54",
          name: "40",
        },
        {
          id: "22.55",
          name: "42",
        },
        {
          id: "22.56",
          name: "44",
        },
        {
          id: "22.57",
          name: "46",
        },
        {
          id: "22.58",
          name: "48",
        },
        {
          id: "22.59",
          name: "50",
        },
        {
          id: "22.35",
          name: "XXS",
        },
        {
          id: "22.27",
          name: "XS",
        },
        {
          id: "22.28",
          name: "S",
        },
        {
          id: "22.29",
          name: "M",
        },
        {
          id: "22.30",
          name: "L",
        },
        {
          id: "22.31",
          name: "XL",
        },
        {
          id: "22.32",
          name: "XXL",
        },
        {
          id: "22.36",
          name: "XXXL",
        },
        {
          id: "22.47",
          name: "XXXXL",
        },
        {
          id: "22.26",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "24.1",
          name: "One size",
        },
        {
          id: "24.2",
          name: "XS",
        },
        {
          id: "24.3",
          name: "S",
        },
        {
          id: "24.4",
          name: "M",
        },
        {
          id: "24.5",
          name: "L",
        },
        {
          id: "24.6",
          name: "XL",
        },
        {
          id: "24.7",
          name: "XXL",
        },
        {
          id: "24.16",
          name: "24",
        },
        {
          id: "24.17",
          name: "25",
        },
        {
          id: "24.18",
          name: "26",
        },
        {
          id: "24.19",
          name: "27",
        },
        {
          id: "24.20",
          name: "28",
        },
        {
          id: "24.21",
          name: "29",
        },
        {
          id: "24.22",
          name: "30",
        },
        {
          id: "24.23",
          name: "31",
        },
        {
          id: "24.24",
          name: "32",
        },
        {
          id: "24.25",
          name: "33",
        },
        {
          id: "24.26",
          name: "34",
        },
        {
          id: "24.8",
          name: "36",
        },
        {
          id: "24.9",
          name: "38",
        },
        {
          id: "24.10",
          name: "40",
        },
        {
          id: "24.11",
          name: "42",
        },
        {
          id: "24.12",
          name: "44",
        },
        {
          id: "24.13",
          name: "46",
        },
        {
          id: "24.14",
          name: "48",
        },
        {
          id: "24.15",
          name: "Other",
        },
      ],
    },
    Underwear: {
      UK: [
        {
          id: "28.1",
          name: "One size",
        },
        {
          id: "28.60",
          name: "UK 4",
        },
        {
          id: "28.2",
          name: "UK 6",
        },
        {
          id: "28.3",
          name: "UK 8",
        },
        {
          id: "28.4",
          name: "UK 10",
        },
        {
          id: "28.5",
          name: "UK 12",
        },
        {
          id: "28.6",
          name: "UK 14",
        },
        {
          id: "28.7",
          name: "UK 16",
        },
        {
          id: "28.8",
          name: "UK 18",
        },
        {
          id: "28.9",
          name: "28D",
        },
        {
          id: "28.10",
          name: "30A",
        },
        {
          id: "28.11",
          name: "30B",
        },
        {
          id: "28.12",
          name: "30C",
        },
        {
          id: "28.13",
          name: "30D",
        },
        {
          id: "28.14",
          name: "30DD",
        },
        {
          id: "28.15",
          name: "30E",
        },
        {
          id: "28.16",
          name: "32A",
        },
        {
          id: "28.17",
          name: "32B",
        },
        {
          id: "28.18",
          name: "32C",
        },
        {
          id: "28.19",
          name: "32D",
        },
        {
          id: "28.20",
          name: "32DD",
        },
        {
          id: "28.21",
          name: "32E",
        },
        {
          id: "28.22",
          name: "34A",
        },
        {
          id: "28.23",
          name: "34B",
        },
        {
          id: "28.24",
          name: "34C",
        },
        {
          id: "28.25",
          name: "34D",
        },
        {
          id: "28.26",
          name: "34DD",
        },
        {
          id: "28.27",
          name: "34E",
        },
        {
          id: "28.58",
          name: "34F",
        },
        {
          id: "28.59",
          name: "34G",
        },
        {
          id: "28.28",
          name: "36A",
        },
        {
          id: "28.29",
          name: "36B",
        },
        {
          id: "28.30",
          name: "36C",
        },
        {
          id: "28.31",
          name: "36D",
        },
        {
          id: "28.32",
          name: "36DD",
        },
        {
          id: "28.33",
          name: "36E",
        },
        {
          id: "28.34",
          name: "36F",
        },
        {
          id: "28.35",
          name: "36G",
        },
        {
          id: "28.36",
          name: "38A",
        },
        {
          id: "28.37",
          name: "38B",
        },
        {
          id: "28.38",
          name: "38C",
        },
        {
          id: "28.39",
          name: "38D",
        },
        {
          id: "28.40",
          name: "38DD",
        },
        {
          id: "28.41",
          name: "38E",
        },
        {
          id: "28.42",
          name: "38F",
        },
        {
          id: "28.43",
          name: "38G",
        },
        {
          id: "28.44",
          name: "A cup",
        },
        {
          id: "28.45",
          name: "B cup",
        },
        {
          id: "28.46",
          name: "C cup",
        },
        {
          id: "28.47",
          name: "D cup",
        },
        {
          id: "28.48",
          name: "DD cup",
        },
        {
          id: "28.49",
          name: "E cup",
        },
        {
          id: "28.50",
          name: "F cup",
        },
        {
          id: "28.61",
          name: "XXS",
        },
        {
          id: "28.52",
          name: "XS",
        },
        {
          id: "28.53",
          name: "S",
        },
        {
          id: "28.54",
          name: "M",
        },
        {
          id: "28.55",
          name: "L",
        },
        {
          id: "28.56",
          name: "XL",
        },
        {
          id: "28.57",
          name: "XXL",
        },
        {
          id: "28.62",
          name: "XXXL",
        },
        {
          id: "28.63",
          name: "XXXXL",
        },
        {
          id: "28.51",
          name: "Other",
        },
      ],
      US: [
        {
          id: "30.1",
          name: "One size",
        },
        {
          id: "30.2",
          name: "0",
        },
        {
          id: "30.3",
          name: "1",
        },
        {
          id: "30.4",
          name: "2",
        },
        {
          id: "30.5",
          name: "3",
        },
        {
          id: "30.6",
          name: "4",
        },
        {
          id: "30.7",
          name: "5",
        },
        {
          id: "30.8",
          name: "6",
        },
        {
          id: "30.9",
          name: "7",
        },
        {
          id: "30.10",
          name: "8",
        },
        {
          id: "30.11",
          name: "9",
        },
        {
          id: "30.12",
          name: "10",
        },
        {
          id: "30.13",
          name: "11",
        },
        {
          id: "30.14",
          name: "12",
        },
        {
          id: "30.15",
          name: "28D",
        },
        {
          id: "30.16",
          name: "30A",
        },
        {
          id: "30.17",
          name: "30B",
        },
        {
          id: "30.18",
          name: "30C",
        },
        {
          id: "30.19",
          name: "30D",
        },
        {
          id: "30.20",
          name: "30DD",
        },
        {
          id: "30.21",
          name: "30E",
        },
        {
          id: "30.22",
          name: "32A",
        },
        {
          id: "30.23",
          name: "32B",
        },
        {
          id: "30.24",
          name: "32C",
        },
        {
          id: "30.25",
          name: "32D",
        },
        {
          id: "30.26",
          name: "32DD",
        },
        {
          id: "30.27",
          name: "32E",
        },
        {
          id: "30.28",
          name: "34A",
        },
        {
          id: "30.29",
          name: "34B",
        },
        {
          id: "30.30",
          name: "34C",
        },
        {
          id: "30.31",
          name: "34D",
        },
        {
          id: "30.32",
          name: "34DD",
        },
        {
          id: "30.33",
          name: "34E",
        },
        {
          id: "30.34",
          name: "36A",
        },
        {
          id: "30.35",
          name: "36B",
        },
        {
          id: "30.36",
          name: "36C",
        },
        {
          id: "30.37",
          name: "36D",
        },
        {
          id: "30.38",
          name: "36DD",
        },
        {
          id: "30.39",
          name: "36E",
        },
        {
          id: "30.40",
          name: "36F",
        },
        {
          id: "30.41",
          name: "36G",
        },
        {
          id: "30.42",
          name: "38A",
        },
        {
          id: "30.43",
          name: "38B",
        },
        {
          id: "30.44",
          name: "38C",
        },
        {
          id: "30.45",
          name: "38D",
        },
        {
          id: "30.46",
          name: "38DD",
        },
        {
          id: "30.47",
          name: "38E",
        },
        {
          id: "30.48",
          name: "38F",
        },
        {
          id: "30.49",
          name: "38G",
        },
        {
          id: "30.50",
          name: "A cup",
        },
        {
          id: "30.51",
          name: "B cup",
        },
        {
          id: "30.52",
          name: "C cup",
        },
        {
          id: "30.53",
          name: "D cup",
        },
        {
          id: "30.54",
          name: "DD cup",
        },
        {
          id: "30.62",
          name: "XXS",
        },
        {
          id: "30.56",
          name: "XS",
        },
        {
          id: "30.57",
          name: "S",
        },
        {
          id: "30.58",
          name: "M",
        },
        {
          id: "30.59",
          name: "L",
        },
        {
          id: "30.60",
          name: "XL",
        },
        {
          id: "30.61",
          name: "XXL",
        },
        {
          id: "30.63",
          name: "XXXL",
        },
        {
          id: "30.64",
          name: "XXXXL",
        },
        {
          id: "30.55",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "32.1",
          name: "One size",
        },
        {
          id: "32.2",
          name: "XS",
        },
        {
          id: "32.3",
          name: "S",
        },
        {
          id: "32.4",
          name: "M",
        },
        {
          id: "32.5",
          name: "L",
        },
        {
          id: "32.6",
          name: "XL",
        },
        {
          id: "32.7",
          name: "XXL",
        },
        {
          id: "32.8",
          name: "36",
        },
        {
          id: "32.9",
          name: "38",
        },
        {
          id: "32.10",
          name: "40",
        },
        {
          id: "32.11",
          name: "42",
        },
        {
          id: "32.12",
          name: "44",
        },
        {
          id: "32.13",
          name: "46",
        },
        {
          id: "32.14",
          name: "48",
        },
        {
          id: "32.15",
          name: "1A",
        },
        {
          id: "32.16",
          name: "1B",
        },
        {
          id: "32.17",
          name: "1C",
        },
        {
          id: "32.18",
          name: "1D",
        },
        {
          id: "32.19",
          name: "1DD",
        },
        {
          id: "32.20",
          name: "1E",
        },
        {
          id: "32.21",
          name: "1F",
        },
        {
          id: "32.22",
          name: "1G",
        },
        {
          id: "32.23",
          name: "2A",
        },
        {
          id: "32.24",
          name: "2B",
        },
        {
          id: "32.25",
          name: "2C",
        },
        {
          id: "32.26",
          name: "2D",
        },
        {
          id: "32.27",
          name: "2DD",
        },
        {
          id: "32.28",
          name: "2E",
        },
        {
          id: "32.29",
          name: "2F",
        },
        {
          id: "32.30",
          name: "2G",
        },
        {
          id: "32.31",
          name: "3A",
        },
        {
          id: "32.32",
          name: "3B",
        },
        {
          id: "32.33",
          name: "3C",
        },
        {
          id: "32.34",
          name: "3D",
        },
        {
          id: "32.35",
          name: "3DD",
        },
        {
          id: "32.36",
          name: "3E",
        },
        {
          id: "32.37",
          name: "3F",
        },
        {
          id: "32.38",
          name: "3G",
        },
        {
          id: "32.39",
          name: "4A",
        },
        {
          id: "32.40",
          name: "4B",
        },
        {
          id: "32.41",
          name: "4C",
        },
        {
          id: "32.42",
          name: "4D",
        },
        {
          id: "32.48",
          name: "4DD",
        },
        {
          id: "32.43",
          name: "4E",
        },
        {
          id: "32.44",
          name: "4F",
        },
        {
          id: "32.45",
          name: "4G",
        },
        {
          id: "32.46",
          name: "4H",
        },
        {
          id: "32.47",
          name: "Other",
        },
      ],
    },
    Outerwear: {
      UK: [
        {
          id: "36.1",
          name: "One size",
        },
        {
          id: "36.16",
          name: "UK 4",
        },
        {
          id: "36.2",
          name: "UK 6",
        },
        {
          id: "36.3",
          name: "UK 8",
        },
        {
          id: "36.4",
          name: "UK 10",
        },
        {
          id: "36.5",
          name: "UK 12",
        },
        {
          id: "36.6",
          name: "UK 14",
        },
        {
          id: "36.7",
          name: "UK 16",
        },
        {
          id: "36.8",
          name: "UK 18",
        },
        {
          id: "36.17",
          name: "XXS",
        },
        {
          id: "36.9",
          name: "XS",
        },
        {
          id: "36.10",
          name: "S",
        },
        {
          id: "36.11",
          name: "M",
        },
        {
          id: "36.12",
          name: "L",
        },
        {
          id: "36.13",
          name: "XL",
        },
        {
          id: "36.14",
          name: "XXL",
        },
        {
          id: "36.18",
          name: "XXXL",
        },
        {
          id: "36.19",
          name: "XXXXL",
        },
        {
          id: "36.15",
          name: "Other",
        },
      ],
      US: [
        {
          id: "38.1",
          name: "One size",
        },
        {
          id: "38.2",
          name: "0",
        },
        {
          id: "38.3",
          name: "1",
        },
        {
          id: "38.4",
          name: "2",
        },
        {
          id: "38.5",
          name: "3",
        },
        {
          id: "38.6",
          name: "4",
        },
        {
          id: "38.7",
          name: "5",
        },
        {
          id: "38.8",
          name: "6",
        },
        {
          id: "38.9",
          name: "7",
        },
        {
          id: "38.10",
          name: "8",
        },
        {
          id: "38.11",
          name: "9",
        },
        {
          id: "38.12",
          name: "10",
        },
        {
          id: "38.13",
          name: "11",
        },
        {
          id: "38.14",
          name: "12",
        },
        {
          id: "38.22",
          name: "14",
        },
        {
          id: "38.23",
          name: "16",
        },
        {
          id: "38.24",
          name: "18",
        },
        {
          id: "38.25",
          name: "20",
        },
        {
          id: "38.26",
          name: "22",
        },
        {
          id: "38.27",
          name: "24",
        },
        {
          id: "38.30",
          name: "XXS",
        },
        {
          id: "38.15",
          name: "XS",
        },
        {
          id: "38.16",
          name: "S",
        },
        {
          id: "38.17",
          name: "M",
        },
        {
          id: "38.18",
          name: "L",
        },
        {
          id: "38.19",
          name: "XL",
        },
        {
          id: "38.20",
          name: "XXL",
        },
        {
          id: "38.28",
          name: "XXXL",
        },
        {
          id: "38.29",
          name: "XXXXL",
        },
        {
          id: "38.21",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "40.1",
          name: "One size",
        },
        {
          id: "40.2",
          name: "36",
        },
        {
          id: "40.3",
          name: "38",
        },
        {
          id: "40.4",
          name: "40",
        },
        {
          id: "40.5",
          name: "42",
        },
        {
          id: "40.6",
          name: "44",
        },
        {
          id: "40.7",
          name: "46",
        },
        {
          id: "40.8",
          name: "48",
        },
        {
          id: "40.9",
          name: "XS",
        },
        {
          id: "40.10",
          name: "S",
        },
        {
          id: "40.11",
          name: "M",
        },
        {
          id: "40.12",
          name: "L",
        },
        {
          id: "40.13",
          name: "XL",
        },
        {
          id: "40.14",
          name: "XXL",
        },
        {
          id: "40.15",
          name: "Other",
        },
      ],
    },
    Footwear: {
      UK: [
        {
          id: "44.1",
          name: "One size",
        },
        {
          id: "44.2",
          name: "UK 3",
        },
        {
          id: "44.3",
          name: "UK 3.5",
        },
        {
          id: "44.4",
          name: "UK 4",
        },
        {
          id: "44.5",
          name: "UK 4.5",
        },
        {
          id: "44.6",
          name: "UK 5",
        },
        {
          id: "44.7",
          name: "UK 5.5",
        },
        {
          id: "44.8",
          name: "UK 6",
        },
        {
          id: "44.9",
          name: "UK 6.5",
        },
        {
          id: "44.10",
          name: "UK 7",
        },
        {
          id: "44.11",
          name: "UK 7.5",
        },
        {
          id: "44.12",
          name: "UK 8",
        },
        {
          id: "44.13",
          name: "UK 8.5",
        },
        {
          id: "44.14",
          name: "UK 9",
        },
        {
          id: "44.15",
          name: "Other",
        },
      ],
      US: [
        {
          id: "46.1",
          name: "One size",
        },
        {
          id: "46.2",
          name: "US 5",
        },
        {
          id: "46.3",
          name: "US 5.5",
        },
        {
          id: "46.4",
          name: "US 6",
        },
        {
          id: "46.5",
          name: "US 6.5",
        },
        {
          id: "46.6",
          name: "US 7",
        },
        {
          id: "46.7",
          name: "US 7.5",
        },
        {
          id: "46.8",
          name: "US 8",
        },
        {
          id: "46.9",
          name: "US 8.5",
        },
        {
          id: "46.10",
          name: "US 9",
        },
        {
          id: "46.11",
          name: "US 9.5",
        },
        {
          id: "46.12",
          name: "US 10",
        },
        {
          id: "46.13",
          name: "US 10.5",
        },
        {
          id: "46.14",
          name: "US 11",
        },
        {
          id: "46.15",
          name: "US 11.5",
        },
        {
          id: "46.16",
          name: "US 12",
        },
        {
          id: "46.17",
          name: "US 12.5",
        },
        {
          id: "46.18",
          name: "US 13",
        },
        {
          id: "46.19",
          name: "US 13.5",
        },
        {
          id: "46.20",
          name: "US 14",
        },
        {
          id: "46.21",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "48.1",
          name: "One size",
        },
        {
          id: "48.2",
          name: "EUR 35",
        },
        {
          id: "48.3",
          name: "EUR 35.5",
        },
        {
          id: "48.4",
          name: "EUR 36",
        },
        {
          id: "48.5",
          name: "EUR 36.5",
        },
        {
          id: "48.6",
          name: "EUR 37",
        },
        {
          id: "48.7",
          name: "EUR 37.5",
        },
        {
          id: "48.8",
          name: "EUR 38",
        },
        {
          id: "48.9",
          name: "EUR 38.5",
        },
        {
          id: "48.10",
          name: "EUR 39",
        },
        {
          id: "48.11",
          name: "EUR 39.5",
        },
        {
          id: "48.12",
          name: "EUR 40",
        },
        {
          id: "48.13",
          name: "EUR 40.5",
        },
        {
          id: "48.14",
          name: "EUR 41",
        },
        {
          id: "48.16",
          name: "EUR 41.5",
        },
        {
          id: "48.17",
          name: "EUR 42",
        },
        {
          id: "48.18",
          name: "EUR 42.5",
        },
        {
          id: "48.19",
          name: "EUR 43",
        },
        {
          id: "48.15",
          name: "Other",
        },
      ],
    },
    Dresses: {
      EUR: [
        {
          id: "81.1",
          name: "One size",
        },
        {
          id: "81.2",
          name: "XS",
        },
        {
          id: "81.3",
          name: "S",
        },
        {
          id: "81.4",
          name: "M",
        },
        {
          id: "81.5",
          name: "L",
        },
        {
          id: "81.6",
          name: "XL",
        },
        {
          id: "81.7",
          name: "XXL",
        },
        {
          id: "81.8",
          name: "36",
        },
        {
          id: "81.9",
          name: "38",
        },
        {
          id: "81.10",
          name: "40",
        },
        {
          id: "81.11",
          name: "42",
        },
        {
          id: "81.12",
          name: "44",
        },
        {
          id: "81.13",
          name: "46",
        },
        {
          id: "81.14",
          name: "48",
        },
        {
          id: "81.15",
          name: "Other",
        },
      ],
      US: [
        {
          id: "84.1",
          name: "One size",
        },
        {
          id: "84.21",
          name: "00",
        },
        {
          id: "84.2",
          name: "0",
        },
        {
          id: "84.3",
          name: "1",
        },
        {
          id: "84.4",
          name: "2",
        },
        {
          id: "84.5",
          name: "3",
        },
        {
          id: "84.6",
          name: "4",
        },
        {
          id: "84.7",
          name: "5",
        },
        {
          id: "84.8",
          name: "6",
        },
        {
          id: "84.9",
          name: "7",
        },
        {
          id: "84.10",
          name: "8",
        },
        {
          id: "84.11",
          name: "9",
        },
        {
          id: "84.12",
          name: "10",
        },
        {
          id: "84.13",
          name: "11",
        },
        {
          id: "84.22",
          name: "12",
        },
        {
          id: "84.23",
          name: "14",
        },
        {
          id: "84.24",
          name: "16",
        },
        {
          id: "84.25",
          name: "18",
        },
        {
          id: "84.26",
          name: "20",
        },
        {
          id: "84.27",
          name: "22",
        },
        {
          id: "84.28",
          name: "24",
        },
        {
          id: "84.29",
          name: "26",
        },
        {
          id: "84.30",
          name: "28",
        },
        {
          id: "84.31",
          name: "30",
        },
        {
          id: "84.32",
          name: "XXS",
        },
        {
          id: "84.15",
          name: "XS",
        },
        {
          id: "84.16",
          name: "S",
        },
        {
          id: "84.17",
          name: "M",
        },
        {
          id: "84.18",
          name: "L",
        },
        {
          id: "84.19",
          name: "XL",
        },
        {
          id: "84.20",
          name: "XXL",
        },
        {
          id: "84.33",
          name: "XXXL",
        },
        {
          id: "84.34",
          name: "XXXXL",
        },
        {
          id: "84.14",
          name: "Other",
        },
      ],
      UK: [
        {
          id: "86.1",
          name: "One size",
        },
        {
          id: "86.25",
          name: "4",
        },
        {
          id: "86.2",
          name: "6",
        },
        {
          id: "86.3",
          name: "8",
        },
        {
          id: "86.4",
          name: "10",
        },
        {
          id: "86.5",
          name: "12",
        },
        {
          id: "86.6",
          name: "14",
        },
        {
          id: "86.7",
          name: "16",
        },
        {
          id: "86.15",
          name: "18",
        },
        {
          id: "86.16",
          name: "20",
        },
        {
          id: "86.17",
          name: "22",
        },
        {
          id: "86.18",
          name: "24",
        },
        {
          id: "86.19",
          name: "26",
        },
        {
          id: "86.20",
          name: "28",
        },
        {
          id: "86.21",
          name: "30",
        },
        {
          id: "86.22",
          name: "32",
        },
        {
          id: "86.26",
          name: "XXS",
        },
        {
          id: "86.9",
          name: "XS",
        },
        {
          id: "86.10",
          name: "S",
        },
        {
          id: "86.11",
          name: "M",
        },
        {
          id: "86.12",
          name: "L",
        },
        {
          id: "86.13",
          name: "XL",
        },
        {
          id: "86.14",
          name: "XXL",
        },
        {
          id: "86.23",
          name: "XXXL",
        },
        {
          id: "86.24",
          name: "XXXXL",
        },
        {
          id: "86.8",
          name: "Other",
        },
      ],
    },
    "Coats and jackets": {
      UK: [
        {
          id: "36.1",
          name: "One size",
        },
        {
          id: "36.16",
          name: "UK 4",
        },
        {
          id: "36.2",
          name: "UK 6",
        },
        {
          id: "36.3",
          name: "UK 8",
        },
        {
          id: "36.4",
          name: "UK 10",
        },
        {
          id: "36.5",
          name: "UK 12",
        },
        {
          id: "36.6",
          name: "UK 14",
        },
        {
          id: "36.7",
          name: "UK 16",
        },
        {
          id: "36.8",
          name: "UK 18",
        },
        {
          id: "36.17",
          name: "XXS",
        },
        {
          id: "36.9",
          name: "XS",
        },
        {
          id: "36.10",
          name: "S",
        },
        {
          id: "36.11",
          name: "M",
        },
        {
          id: "36.12",
          name: "L",
        },
        {
          id: "36.13",
          name: "XL",
        },
        {
          id: "36.14",
          name: "XXL",
        },
        {
          id: "36.18",
          name: "XXXL",
        },
        {
          id: "36.19",
          name: "XXXXL",
        },
        {
          id: "36.15",
          name: "Other",
        },
      ],
      US: [
        {
          id: "38.1",
          name: "One size",
        },
        {
          id: "38.2",
          name: "0",
        },
        {
          id: "38.3",
          name: "1",
        },
        {
          id: "38.4",
          name: "2",
        },
        {
          id: "38.5",
          name: "3",
        },
        {
          id: "38.6",
          name: "4",
        },
        {
          id: "38.7",
          name: "5",
        },
        {
          id: "38.8",
          name: "6",
        },
        {
          id: "38.9",
          name: "7",
        },
        {
          id: "38.10",
          name: "8",
        },
        {
          id: "38.11",
          name: "9",
        },
        {
          id: "38.12",
          name: "10",
        },
        {
          id: "38.13",
          name: "11",
        },
        {
          id: "38.14",
          name: "12",
        },
        {
          id: "38.22",
          name: "14",
        },
        {
          id: "38.23",
          name: "16",
        },
        {
          id: "38.24",
          name: "18",
        },
        {
          id: "38.25",
          name: "20",
        },
        {
          id: "38.26",
          name: "22",
        },
        {
          id: "38.27",
          name: "24",
        },
        {
          id: "38.30",
          name: "XXS",
        },
        {
          id: "38.15",
          name: "XS",
        },
        {
          id: "38.16",
          name: "S",
        },
        {
          id: "38.17",
          name: "M",
        },
        {
          id: "38.18",
          name: "L",
        },
        {
          id: "38.19",
          name: "XL",
        },
        {
          id: "38.20",
          name: "XXL",
        },
        {
          id: "38.28",
          name: "XXXL",
        },
        {
          id: "38.29",
          name: "XXXXL",
        },
        {
          id: "38.21",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "40.1",
          name: "One size",
        },
        {
          id: "40.2",
          name: "36",
        },
        {
          id: "40.3",
          name: "38",
        },
        {
          id: "40.4",
          name: "40",
        },
        {
          id: "40.5",
          name: "42",
        },
        {
          id: "40.6",
          name: "44",
        },
        {
          id: "40.7",
          name: "46",
        },
        {
          id: "40.8",
          name: "48",
        },
        {
          id: "40.9",
          name: "XS",
        },
        {
          id: "40.10",
          name: "S",
        },
        {
          id: "40.11",
          name: "M",
        },
        {
          id: "40.12",
          name: "L",
        },
        {
          id: "40.13",
          name: "XL",
        },
        {
          id: "40.14",
          name: "XXL",
        },
        {
          id: "40.15",
          name: "Other",
        },
      ],
    },
    "Jumpsuits and rompers": {
      UK: [
        {
          id: "20.1",
          name: "One size",
        },
        {
          id: "20.36",
          name: "UK 4",
        },
        {
          id: "20.2",
          name: "UK 6",
        },
        {
          id: "20.3",
          name: "UK 8",
        },
        {
          id: "20.4",
          name: "UK 10",
        },
        {
          id: "20.5",
          name: "UK 12",
        },
        {
          id: "20.6",
          name: "UK 14",
        },
        {
          id: "20.7",
          name: "UK 16",
        },
        {
          id: "20.8",
          name: "UK 18",
        },
        {
          id: "20.27",
          name: "UK 20",
        },
        {
          id: "20.28",
          name: "UK 22",
        },
        {
          id: "20.29",
          name: "UK 24",
        },
        {
          id: "20.30",
          name: "UK 26",
        },
        {
          id: "20.31",
          name: "UK 28",
        },
        {
          id: "20.32",
          name: "UK 30",
        },
        {
          id: "20.33",
          name: "UK 32",
        },
        {
          id: "20.38",
          name: "21",
        },
        {
          id: "20.39",
          name: "22",
        },
        {
          id: "20.40",
          name: "23",
        },
        {
          id: "20.9",
          name: "24",
        },
        {
          id: "20.10",
          name: "25",
        },
        {
          id: "20.11",
          name: "26",
        },
        {
          id: "20.12",
          name: "27",
        },
        {
          id: "20.13",
          name: "28",
        },
        {
          id: "20.14",
          name: "29",
        },
        {
          id: "20.15",
          name: "30",
        },
        {
          id: "20.16",
          name: "31",
        },
        {
          id: "20.17",
          name: "32",
        },
        {
          id: "20.18",
          name: "33",
        },
        {
          id: "20.19",
          name: "34",
        },
        {
          id: "20.41",
          name: "35",
        },
        {
          id: "20.42",
          name: "36",
        },
        {
          id: "20.43",
          name: "37",
        },
        {
          id: "20.44",
          name: "38",
        },
        {
          id: "20.45",
          name: "39",
        },
        {
          id: "20.46",
          name: "40",
        },
        {
          id: "20.47",
          name: "42",
        },
        {
          id: "20.48",
          name: "44",
        },
        {
          id: "20.49",
          name: "46",
        },
        {
          id: "20.50",
          name: "48",
        },
        {
          id: "20.51",
          name: "50",
        },
        {
          id: "20.37",
          name: "XXS",
        },
        {
          id: "20.21",
          name: "XS",
        },
        {
          id: "20.22",
          name: "S",
        },
        {
          id: "20.23",
          name: "M",
        },
        {
          id: "20.24",
          name: "L",
        },
        {
          id: "20.25",
          name: "XL",
        },
        {
          id: "20.26",
          name: "XXL",
        },
        {
          id: "20.34",
          name: "XXXL",
        },
        {
          id: "20.35",
          name: "XXXXL",
        },
        {
          id: "20.20",
          name: "Other",
        },
      ],
      US: [
        {
          id: "22.1",
          name: "One size",
        },
        {
          id: "22.2",
          name: "0",
        },
        {
          id: "22.3",
          name: "1",
        },
        {
          id: "22.4",
          name: "2",
        },
        {
          id: "22.5",
          name: "3",
        },
        {
          id: "22.6",
          name: "4",
        },
        {
          id: "22.7",
          name: "5",
        },
        {
          id: "22.8",
          name: "6",
        },
        {
          id: "22.9",
          name: "7",
        },
        {
          id: "22.10",
          name: "8",
        },
        {
          id: "22.11",
          name: "9",
        },
        {
          id: "22.12",
          name: "10",
        },
        {
          id: "22.13",
          name: "11",
        },
        {
          id: "22.14",
          name: "12",
        },
        {
          id: "22.37",
          name: "14",
        },
        {
          id: "22.38",
          name: "16",
        },
        {
          id: "22.39",
          name: "18",
        },
        {
          id: "22.40",
          name: "20",
        },
        {
          id: "22.41",
          name: "22",
        },
        {
          id: "22.42",
          name: "24",
        },
        {
          id: "22.43",
          name: "26",
        },
        {
          id: "22.44",
          name: "28",
        },
        {
          id: "22.45",
          name: "30",
        },
        {
          id: "22.46",
          name: "32",
        },
        {
          id: "22.48",
          name: "21",
        },
        {
          id: "22.49",
          name: "22",
        },
        {
          id: "22.33",
          name: "23",
        },
        {
          id: "22.15",
          name: "24",
        },
        {
          id: "22.16",
          name: "25",
        },
        {
          id: "22.17",
          name: "26",
        },
        {
          id: "22.18",
          name: "27",
        },
        {
          id: "22.19",
          name: "28",
        },
        {
          id: "22.20",
          name: "29",
        },
        {
          id: "22.21",
          name: "30",
        },
        {
          id: "22.22",
          name: "31",
        },
        {
          id: "22.23",
          name: "32",
        },
        {
          id: "22.24",
          name: "33",
        },
        {
          id: "22.25",
          name: "34",
        },
        {
          id: "22.34",
          name: "35",
        },
        {
          id: "22.50",
          name: "36",
        },
        {
          id: "22.51",
          name: "37",
        },
        {
          id: "22.52",
          name: "38",
        },
        {
          id: "22.53",
          name: "39",
        },
        {
          id: "22.54",
          name: "40",
        },
        {
          id: "22.55",
          name: "42",
        },
        {
          id: "22.56",
          name: "44",
        },
        {
          id: "22.57",
          name: "46",
        },
        {
          id: "22.58",
          name: "48",
        },
        {
          id: "22.59",
          name: "50",
        },
        {
          id: "22.35",
          name: "XXS",
        },
        {
          id: "22.27",
          name: "XS",
        },
        {
          id: "22.28",
          name: "S",
        },
        {
          id: "22.29",
          name: "M",
        },
        {
          id: "22.30",
          name: "L",
        },
        {
          id: "22.31",
          name: "XL",
        },
        {
          id: "22.32",
          name: "XXL",
        },
        {
          id: "22.36",
          name: "XXXL",
        },
        {
          id: "22.47",
          name: "XXXXL",
        },
        {
          id: "22.26",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "24.1",
          name: "One size",
        },
        {
          id: "24.2",
          name: "XS",
        },
        {
          id: "24.3",
          name: "S",
        },
        {
          id: "24.4",
          name: "M",
        },
        {
          id: "24.5",
          name: "L",
        },
        {
          id: "24.6",
          name: "XL",
        },
        {
          id: "24.7",
          name: "XXL",
        },
        {
          id: "24.16",
          name: "24",
        },
        {
          id: "24.17",
          name: "25",
        },
        {
          id: "24.18",
          name: "26",
        },
        {
          id: "24.19",
          name: "27",
        },
        {
          id: "24.20",
          name: "28",
        },
        {
          id: "24.21",
          name: "29",
        },
        {
          id: "24.22",
          name: "30",
        },
        {
          id: "24.23",
          name: "31",
        },
        {
          id: "24.24",
          name: "32",
        },
        {
          id: "24.25",
          name: "33",
        },
        {
          id: "24.26",
          name: "34",
        },
        {
          id: "24.8",
          name: "36",
        },
        {
          id: "24.9",
          name: "38",
        },
        {
          id: "24.10",
          name: "40",
        },
        {
          id: "24.11",
          name: "42",
        },
        {
          id: "24.12",
          name: "44",
        },
        {
          id: "24.13",
          name: "46",
        },
        {
          id: "24.14",
          name: "48",
        },
        {
          id: "24.15",
          name: "Other",
        },
      ],
    },
    Suits: {
      UK: [
        {
          id: "2.1",
          name: "One size",
        },
        {
          id: "2.25",
          name: "4",
        },
        {
          id: "2.2",
          name: "6",
        },
        {
          id: "2.3",
          name: "8",
        },
        {
          id: "2.4",
          name: "10",
        },
        {
          id: "2.5",
          name: "12",
        },
        {
          id: "2.6",
          name: "14",
        },
        {
          id: "2.7",
          name: "16",
        },
        {
          id: "2.15",
          name: "18",
        },
        {
          id: "2.16",
          name: "20",
        },
        {
          id: "2.17",
          name: "22",
        },
        {
          id: "2.18",
          name: "24",
        },
        {
          id: "2.19",
          name: "26",
        },
        {
          id: "2.20",
          name: "28",
        },
        {
          id: "2.21",
          name: "30",
        },
        {
          id: "2.22",
          name: "32",
        },
        {
          id: "2.26",
          name: "XXS",
        },
        {
          id: "2.9",
          name: "XS",
        },
        {
          id: "2.10",
          name: "S",
        },
        {
          id: "2.11",
          name: "M",
        },
        {
          id: "2.12",
          name: "L",
        },
        {
          id: "2.13",
          name: "XL",
        },
        {
          id: "2.14",
          name: "XXL",
        },
        {
          id: "2.23",
          name: "XXXL",
        },
        {
          id: "2.24",
          name: "XXXXL",
        },
        {
          id: "2.8",
          name: "Other",
        },
      ],
      US: [
        {
          id: "4.1",
          name: "One size",
        },
        {
          id: "4.21",
          name: "00",
        },
        {
          id: "4.2",
          name: "0",
        },
        {
          id: "4.3",
          name: "1",
        },
        {
          id: "4.4",
          name: "2",
        },
        {
          id: "4.5",
          name: "3",
        },
        {
          id: "4.6",
          name: "4",
        },
        {
          id: "4.7",
          name: "5",
        },
        {
          id: "4.8",
          name: "6",
        },
        {
          id: "4.9",
          name: "7",
        },
        {
          id: "4.10",
          name: "8",
        },
        {
          id: "4.11",
          name: "9",
        },
        {
          id: "4.12",
          name: "10",
        },
        {
          id: "4.13",
          name: "11",
        },
        {
          id: "4.22",
          name: "12",
        },
        {
          id: "4.23",
          name: "14",
        },
        {
          id: "4.24",
          name: "16",
        },
        {
          id: "4.25",
          name: "18",
        },
        {
          id: "4.26",
          name: "20",
        },
        {
          id: "4.27",
          name: "22",
        },
        {
          id: "4.28",
          name: "24",
        },
        {
          id: "4.29",
          name: "26",
        },
        {
          id: "4.30",
          name: "28",
        },
        {
          id: "4.31",
          name: "30",
        },
        {
          id: "4.32",
          name: "XXS",
        },
        {
          id: "4.15",
          name: "XS",
        },
        {
          id: "4.16",
          name: "S",
        },
        {
          id: "4.17",
          name: "M",
        },
        {
          id: "4.18",
          name: "L",
        },
        {
          id: "4.19",
          name: "XL",
        },
        {
          id: "4.20",
          name: "XXL",
        },
        {
          id: "4.33",
          name: "XXXL",
        },
        {
          id: "4.34",
          name: "XXXXL",
        },
        {
          id: "4.14",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "9.1",
          name: "One size",
        },
        {
          id: "9.2",
          name: "XS",
        },
        {
          id: "9.3",
          name: "S",
        },
        {
          id: "9.4",
          name: "M",
        },
        {
          id: "9.5",
          name: "L",
        },
        {
          id: "9.6",
          name: "XL",
        },
        {
          id: "9.7",
          name: "XXL",
        },
        {
          id: "9.8",
          name: "36",
        },
        {
          id: "9.9",
          name: "38",
        },
        {
          id: "9.10",
          name: "40",
        },
        {
          id: "9.11",
          name: "42",
        },
        {
          id: "9.12",
          name: "44",
        },
        {
          id: "9.13",
          name: "46",
        },
        {
          id: "9.14",
          name: "48",
        },
        {
          id: "9.15",
          name: "Other",
        },
      ],
    },
    Costume: {
      UK: [{ name: "One size" }],
      US: [{ name: "One size" }],
      EUR: [{ name: "One size" }],
    },
    Accessories: {
      UK: [{ name: "One size" }],
      US: [{ name: "One size" }],
      EUR: [{ name: "One size" }],
    },
    Sleepwear: {
      UK: [
        {
          id: "20.1",
          name: "One size",
        },
        {
          id: "20.36",
          name: "UK 4",
        },
        {
          id: "20.2",
          name: "UK 6",
        },
        {
          id: "20.3",
          name: "UK 8",
        },
        {
          id: "20.4",
          name: "UK 10",
        },
        {
          id: "20.5",
          name: "UK 12",
        },
        {
          id: "20.6",
          name: "UK 14",
        },
        {
          id: "20.7",
          name: "UK 16",
        },
        {
          id: "20.8",
          name: "UK 18",
        },
        {
          id: "20.27",
          name: "UK 20",
        },
        {
          id: "20.28",
          name: "UK 22",
        },
        {
          id: "20.29",
          name: "UK 24",
        },
        {
          id: "20.30",
          name: "UK 26",
        },
        {
          id: "20.31",
          name: "UK 28",
        },
        {
          id: "20.32",
          name: "UK 30",
        },
        {
          id: "20.33",
          name: "UK 32",
        },
        {
          id: "20.38",
          name: "21",
        },
        {
          id: "20.39",
          name: "22",
        },
        {
          id: "20.40",
          name: "23",
        },
        {
          id: "20.9",
          name: "24",
        },
        {
          id: "20.10",
          name: "25",
        },
        {
          id: "20.11",
          name: "26",
        },
        {
          id: "20.12",
          name: "27",
        },
        {
          id: "20.13",
          name: "28",
        },
        {
          id: "20.14",
          name: "29",
        },
        {
          id: "20.15",
          name: "30",
        },
        {
          id: "20.16",
          name: "31",
        },
        {
          id: "20.17",
          name: "32",
        },
        {
          id: "20.18",
          name: "33",
        },
        {
          id: "20.19",
          name: "34",
        },
        {
          id: "20.41",
          name: "35",
        },
        {
          id: "20.42",
          name: "36",
        },
        {
          id: "20.43",
          name: "37",
        },
        {
          id: "20.44",
          name: "38",
        },
        {
          id: "20.45",
          name: "39",
        },
        {
          id: "20.46",
          name: "40",
        },
        {
          id: "20.47",
          name: "42",
        },
        {
          id: "20.48",
          name: "44",
        },
        {
          id: "20.49",
          name: "46",
        },
        {
          id: "20.50",
          name: "48",
        },
        {
          id: "20.51",
          name: "50",
        },
        {
          id: "20.37",
          name: "XXS",
        },
        {
          id: "20.21",
          name: "XS",
        },
        {
          id: "20.22",
          name: "S",
        },
        {
          id: "20.23",
          name: "M",
        },
        {
          id: "20.24",
          name: "L",
        },
        {
          id: "20.25",
          name: "XL",
        },
        {
          id: "20.26",
          name: "XXL",
        },
        {
          id: "20.34",
          name: "XXXL",
        },
        {
          id: "20.35",
          name: "XXXXL",
        },
        {
          id: "20.20",
          name: "Other",
        },
      ],
      US: [
        {
          id: "22.1",
          name: "One size",
        },
        {
          id: "22.2",
          name: "0",
        },
        {
          id: "22.3",
          name: "1",
        },
        {
          id: "22.4",
          name: "2",
        },
        {
          id: "22.5",
          name: "3",
        },
        {
          id: "22.6",
          name: "4",
        },
        {
          id: "22.7",
          name: "5",
        },
        {
          id: "22.8",
          name: "6",
        },
        {
          id: "22.9",
          name: "7",
        },
        {
          id: "22.10",
          name: "8",
        },
        {
          id: "22.11",
          name: "9",
        },
        {
          id: "22.12",
          name: "10",
        },
        {
          id: "22.13",
          name: "11",
        },
        {
          id: "22.14",
          name: "12",
        },
        {
          id: "22.37",
          name: "14",
        },
        {
          id: "22.38",
          name: "16",
        },
        {
          id: "22.39",
          name: "18",
        },
        {
          id: "22.40",
          name: "20",
        },
        {
          id: "22.41",
          name: "22",
        },
        {
          id: "22.42",
          name: "24",
        },
        {
          id: "22.43",
          name: "26",
        },
        {
          id: "22.44",
          name: "28",
        },
        {
          id: "22.45",
          name: "30",
        },
        {
          id: "22.46",
          name: "32",
        },
        {
          id: "22.48",
          name: "21",
        },
        {
          id: "22.49",
          name: "22",
        },
        {
          id: "22.33",
          name: "23",
        },
        {
          id: "22.15",
          name: "24",
        },
        {
          id: "22.16",
          name: "25",
        },
        {
          id: "22.17",
          name: "26",
        },
        {
          id: "22.18",
          name: "27",
        },
        {
          id: "22.19",
          name: "28",
        },
        {
          id: "22.20",
          name: "29",
        },
        {
          id: "22.21",
          name: "30",
        },
        {
          id: "22.22",
          name: "31",
        },
        {
          id: "22.23",
          name: "32",
        },
        {
          id: "22.24",
          name: "33",
        },
        {
          id: "22.25",
          name: "34",
        },
        {
          id: "22.34",
          name: "35",
        },
        {
          id: "22.50",
          name: "36",
        },
        {
          id: "22.51",
          name: "37",
        },
        {
          id: "22.52",
          name: "38",
        },
        {
          id: "22.53",
          name: "39",
        },
        {
          id: "22.54",
          name: "40",
        },
        {
          id: "22.55",
          name: "42",
        },
        {
          id: "22.56",
          name: "44",
        },
        {
          id: "22.57",
          name: "46",
        },
        {
          id: "22.58",
          name: "48",
        },
        {
          id: "22.59",
          name: "50",
        },
        {
          id: "22.35",
          name: "XXS",
        },
        {
          id: "22.27",
          name: "XS",
        },
        {
          id: "22.28",
          name: "S",
        },
        {
          id: "22.29",
          name: "M",
        },
        {
          id: "22.30",
          name: "L",
        },
        {
          id: "22.31",
          name: "XL",
        },
        {
          id: "22.32",
          name: "XXL",
        },
        {
          id: "22.36",
          name: "XXXL",
        },
        {
          id: "22.47",
          name: "XXXXL",
        },
        {
          id: "22.26",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "24.1",
          name: "One size",
        },
        {
          id: "24.2",
          name: "XS",
        },
        {
          id: "24.3",
          name: "S",
        },
        {
          id: "24.4",
          name: "M",
        },
        {
          id: "24.5",
          name: "L",
        },
        {
          id: "24.6",
          name: "XL",
        },
        {
          id: "24.7",
          name: "XXL",
        },
        {
          id: "24.16",
          name: "24",
        },
        {
          id: "24.17",
          name: "25",
        },
        {
          id: "24.18",
          name: "26",
        },
        {
          id: "24.19",
          name: "27",
        },
        {
          id: "24.20",
          name: "28",
        },
        {
          id: "24.21",
          name: "29",
        },
        {
          id: "24.22",
          name: "30",
        },
        {
          id: "24.23",
          name: "31",
        },
        {
          id: "24.24",
          name: "32",
        },
        {
          id: "24.25",
          name: "33",
        },
        {
          id: "24.26",
          name: "34",
        },
        {
          id: "24.8",
          name: "36",
        },
        {
          id: "24.9",
          name: "38",
        },
        {
          id: "24.10",
          name: "40",
        },
        {
          id: "24.11",
          name: "42",
        },
        {
          id: "24.12",
          name: "44",
        },
        {
          id: "24.13",
          name: "46",
        },
        {
          id: "24.14",
          name: "48",
        },
        {
          id: "24.15",
          name: "Other",
        },
      ],
    },
    Swimwear: {
      UK: [
        {
          id: "28.1",
          name: "One size",
        },
        {
          id: "28.60",
          name: "UK 4",
        },
        {
          id: "28.2",
          name: "UK 6",
        },
        {
          id: "28.3",
          name: "UK 8",
        },
        {
          id: "28.4",
          name: "UK 10",
        },
        {
          id: "28.5",
          name: "UK 12",
        },
        {
          id: "28.6",
          name: "UK 14",
        },
        {
          id: "28.7",
          name: "UK 16",
        },
        {
          id: "28.8",
          name: "UK 18",
        },
        {
          id: "28.9",
          name: "28D",
        },
        {
          id: "28.10",
          name: "30A",
        },
        {
          id: "28.11",
          name: "30B",
        },
        {
          id: "28.12",
          name: "30C",
        },
        {
          id: "28.13",
          name: "30D",
        },
        {
          id: "28.14",
          name: "30DD",
        },
        {
          id: "28.15",
          name: "30E",
        },
        {
          id: "28.16",
          name: "32A",
        },
        {
          id: "28.17",
          name: "32B",
        },
        {
          id: "28.18",
          name: "32C",
        },
        {
          id: "28.19",
          name: "32D",
        },
        {
          id: "28.20",
          name: "32DD",
        },
        {
          id: "28.21",
          name: "32E",
        },
        {
          id: "28.22",
          name: "34A",
        },
        {
          id: "28.23",
          name: "34B",
        },
        {
          id: "28.24",
          name: "34C",
        },
        {
          id: "28.25",
          name: "34D",
        },
        {
          id: "28.26",
          name: "34DD",
        },
        {
          id: "28.27",
          name: "34E",
        },
        {
          id: "28.58",
          name: "34F",
        },
        {
          id: "28.59",
          name: "34G",
        },
        {
          id: "28.28",
          name: "36A",
        },
        {
          id: "28.29",
          name: "36B",
        },
        {
          id: "28.30",
          name: "36C",
        },
        {
          id: "28.31",
          name: "36D",
        },
        {
          id: "28.32",
          name: "36DD",
        },
        {
          id: "28.33",
          name: "36E",
        },
        {
          id: "28.34",
          name: "36F",
        },
        {
          id: "28.35",
          name: "36G",
        },
        {
          id: "28.36",
          name: "38A",
        },
        {
          id: "28.37",
          name: "38B",
        },
        {
          id: "28.38",
          name: "38C",
        },
        {
          id: "28.39",
          name: "38D",
        },
        {
          id: "28.40",
          name: "38DD",
        },
        {
          id: "28.41",
          name: "38E",
        },
        {
          id: "28.42",
          name: "38F",
        },
        {
          id: "28.43",
          name: "38G",
        },
        {
          id: "28.44",
          name: "A cup",
        },
        {
          id: "28.45",
          name: "B cup",
        },
        {
          id: "28.46",
          name: "C cup",
        },
        {
          id: "28.47",
          name: "D cup",
        },
        {
          id: "28.48",
          name: "DD cup",
        },
        {
          id: "28.49",
          name: "E cup",
        },
        {
          id: "28.50",
          name: "F cup",
        },
        {
          id: "28.61",
          name: "XXS",
        },
        {
          id: "28.52",
          name: "XS",
        },
        {
          id: "28.53",
          name: "S",
        },
        {
          id: "28.54",
          name: "M",
        },
        {
          id: "28.55",
          name: "L",
        },
        {
          id: "28.56",
          name: "XL",
        },
        {
          id: "28.57",
          name: "XXL",
        },
        {
          id: "28.62",
          name: "XXXL",
        },
        {
          id: "28.63",
          name: "XXXXL",
        },
        {
          id: "28.51",
          name: "Other",
        },
      ],
      US: [
        {
          id: "30.1",
          name: "One size",
        },
        {
          id: "30.2",
          name: "0",
        },
        {
          id: "30.3",
          name: "1",
        },
        {
          id: "30.4",
          name: "2",
        },
        {
          id: "30.5",
          name: "3",
        },
        {
          id: "30.6",
          name: "4",
        },
        {
          id: "30.7",
          name: "5",
        },
        {
          id: "30.8",
          name: "6",
        },
        {
          id: "30.9",
          name: "7",
        },
        {
          id: "30.10",
          name: "8",
        },
        {
          id: "30.11",
          name: "9",
        },
        {
          id: "30.12",
          name: "10",
        },
        {
          id: "30.13",
          name: "11",
        },
        {
          id: "30.14",
          name: "12",
        },
        {
          id: "30.15",
          name: "28D",
        },
        {
          id: "30.16",
          name: "30A",
        },
        {
          id: "30.17",
          name: "30B",
        },
        {
          id: "30.18",
          name: "30C",
        },
        {
          id: "30.19",
          name: "30D",
        },
        {
          id: "30.20",
          name: "30DD",
        },
        {
          id: "30.21",
          name: "30E",
        },
        {
          id: "30.22",
          name: "32A",
        },
        {
          id: "30.23",
          name: "32B",
        },
        {
          id: "30.24",
          name: "32C",
        },
        {
          id: "30.25",
          name: "32D",
        },
        {
          id: "30.26",
          name: "32DD",
        },
        {
          id: "30.27",
          name: "32E",
        },
        {
          id: "30.28",
          name: "34A",
        },
        {
          id: "30.29",
          name: "34B",
        },
        {
          id: "30.30",
          name: "34C",
        },
        {
          id: "30.31",
          name: "34D",
        },
        {
          id: "30.32",
          name: "34DD",
        },
        {
          id: "30.33",
          name: "34E",
        },
        {
          id: "30.34",
          name: "36A",
        },
        {
          id: "30.35",
          name: "36B",
        },
        {
          id: "30.36",
          name: "36C",
        },
        {
          id: "30.37",
          name: "36D",
        },
        {
          id: "30.38",
          name: "36DD",
        },
        {
          id: "30.39",
          name: "36E",
        },
        {
          id: "30.40",
          name: "36F",
        },
        {
          id: "30.41",
          name: "36G",
        },
        {
          id: "30.42",
          name: "38A",
        },
        {
          id: "30.43",
          name: "38B",
        },
        {
          id: "30.44",
          name: "38C",
        },
        {
          id: "30.45",
          name: "38D",
        },
        {
          id: "30.46",
          name: "38DD",
        },
        {
          id: "30.47",
          name: "38E",
        },
        {
          id: "30.48",
          name: "38F",
        },
        {
          id: "30.49",
          name: "38G",
        },
        {
          id: "30.50",
          name: "A cup",
        },
        {
          id: "30.51",
          name: "B cup",
        },
        {
          id: "30.52",
          name: "C cup",
        },
        {
          id: "30.53",
          name: "D cup",
        },
        {
          id: "30.54",
          name: "DD cup",
        },
        {
          id: "30.62",
          name: "XXS",
        },
        {
          id: "30.56",
          name: "XS",
        },
        {
          id: "30.57",
          name: "S",
        },
        {
          id: "30.58",
          name: "M",
        },
        {
          id: "30.59",
          name: "L",
        },
        {
          id: "30.60",
          name: "XL",
        },
        {
          id: "30.61",
          name: "XXL",
        },
        {
          id: "30.63",
          name: "XXXL",
        },
        {
          id: "30.64",
          name: "XXXXL",
        },
        {
          id: "30.55",
          name: "Other",
        },
      ],
      EUR: [
        {
          id: "32.1",
          name: "One size",
        },
        {
          id: "32.2",
          name: "XS",
        },
        {
          id: "32.3",
          name: "S",
        },
        {
          id: "32.4",
          name: "M",
        },
        {
          id: "32.5",
          name: "L",
        },
        {
          id: "32.6",
          name: "XL",
        },
        {
          id: "32.7",
          name: "XXL",
        },
        {
          id: "32.8",
          name: "36",
        },
        {
          id: "32.9",
          name: "38",
        },
        {
          id: "32.10",
          name: "40",
        },
        {
          id: "32.11",
          name: "42",
        },
        {
          id: "32.12",
          name: "44",
        },
        {
          id: "32.13",
          name: "46",
        },
        {
          id: "32.14",
          name: "48",
        },
        {
          id: "32.15",
          name: "1A",
        },
        {
          id: "32.16",
          name: "1B",
        },
        {
          id: "32.17",
          name: "1C",
        },
        {
          id: "32.18",
          name: "1D",
        },
        {
          id: "32.19",
          name: "1DD",
        },
        {
          id: "32.20",
          name: "1E",
        },
        {
          id: "32.21",
          name: "1F",
        },
        {
          id: "32.22",
          name: "1G",
        },
        {
          id: "32.23",
          name: "2A",
        },
        {
          id: "32.24",
          name: "2B",
        },
        {
          id: "32.25",
          name: "2C",
        },
        {
          id: "32.26",
          name: "2D",
        },
        {
          id: "32.27",
          name: "2DD",
        },
        {
          id: "32.28",
          name: "2E",
        },
        {
          id: "32.29",
          name: "2F",
        },
        {
          id: "32.30",
          name: "2G",
        },
        {
          id: "32.31",
          name: "3A",
        },
        {
          id: "32.32",
          name: "3B",
        },
        {
          id: "32.33",
          name: "3C",
        },
        {
          id: "32.34",
          name: "3D",
        },
        {
          id: "32.35",
          name: "3DD",
        },
        {
          id: "32.36",
          name: "3E",
        },
        {
          id: "32.37",
          name: "3F",
        },
        {
          id: "32.38",
          name: "3G",
        },
        {
          id: "32.39",
          name: "4A",
        },
        {
          id: "32.40",
          name: "4B",
        },
        {
          id: "32.41",
          name: "4C",
        },
        {
          id: "32.42",
          name: "4D",
        },
        {
          id: "32.48",
          name: "4DD",
        },
        {
          id: "32.43",
          name: "4E",
        },
        {
          id: "32.44",
          name: "4F",
        },
        {
          id: "32.45",
          name: "4G",
        },
        {
          id: "32.46",
          name: "4H",
        },
        {
          id: "32.47",
          name: "Other",
        },
      ],
    },
  },
};

for (let key in sizes) {
  for (let key2 in sizes[key]) {
    for (let key3 in sizes[key][key2]) {
      for (let i = 0; i < sizes[key][key2][key3].length; i++) {
        sizes[key][key2][key3][i] = {
          label: sizes[key][key2][key3][i].name,
          value: sizes[key][key2][key3][i].name,
        };
      }
    }
  }
}

export default sizes;
