const initState = {
    mercari: { info: {}, images: [] },
    facebook: { info: {}, images: [] },
    shopify: { info: {}, images: [] },
    ebay: { info: {}, images: [] },
    etsy: { info: {}, images: [] },
    poshmark: { info: {}, images: [] },
    kidizen: { info: {}, images: [] },
    depop: { info: {}, images: [] },
    stagingDetails: { info: {}, images: [] },
    inventoryId: null,
    listingId: null,
    imageMetaData: null,
    imageMetaDataDetectorStatus: "pending",
};

const stagingReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_INVENTORY_ID":
            return {
                ...state,
                inventoryId: action.payload,
            };
        case "SAVE_IMAGE_META_DATA":
            const metaData =
                action.payload?.googleLensResults || action.payload;
            console.log(metaData);
            const genderMap = {
                Male: ["Male", "Men", "men", "male"],
                Female: ["Female", "Women", "women", "female"],
                Others: ["Others"],
            };
            const getGender = (gender) =>
                Object.keys(genderMap).find((category) =>
                    genderMap[category].includes(gender)
                ) || null;

            const propertiesToUpdate = [
                { key: "title", value: metaData?.title },
                { key: "description", value: metaData?.description },
                { key: "price", value: metaData?.price },
                { key: "tags", value: metaData?.tags },
                { key: "color", value: metaData?.color },
                { key: "brand", value: metaData?.brand },
                { key: "size", value: metaData?.size },
                { key: "gender", value: getGender(metaData?.gender) },
            ];

            const updatedInfo = { ...state.stagingDetails?.info };
            propertiesToUpdate.forEach(({ key, value }) => {
                if (value && !updatedInfo[key]) {
                    updatedInfo[key] = value;
                }
            });

            const updatedStagingDetails = {
                ...state.stagingDetails,
                info: { ...state.stagingDetails.info, ...updatedInfo },
            };

            const kidizenUpdatedInfo = { ...state.kidizen?.info };
            console.log(kidizenUpdatedInfo);
            const kidizenPropertiesToUpdate = [
                { key: "title", value: updatedStagingDetails.info?.title },
                {
                    key: "description",
                    value: updatedStagingDetails.info?.description,
                },
                { key: "category", value: metaData?.kidizen?.category },
                { key: "subcategory", value: metaData?.kidizen?.subcategory },
                { key: "age_group", value: metaData?.kidizen?.age_group },
                { key: "condition", value: metaData?.kidizen?.condition },
                { key: "brand", value: metaData?.kidizen?.brand },
                { key: "gender", value: metaData?.kidizen?.gender },
                { key: "price", value: updatedStagingDetails.info?.price },
            ];
            kidizenPropertiesToUpdate.forEach(({ key, value }) => {
                if (value && !kidizenUpdatedInfo[key]) {
                    kidizenUpdatedInfo[key] = value;
                }
            });
            console.log(kidizenUpdatedInfo);
            const updatedKidizenDetails = {
                ...state.stagingDetails,
                info: { ...state.kidizen.info, ...kidizenUpdatedInfo },
            };

            const poshmarkPropertiesToUpdate = [
                { key: "title", value: updatedStagingDetails.info?.title },
                {
                    key: "description",
                    value: updatedStagingDetails.info?.description,
                },
                {
                    key: "tags",
                    value: metaData?.tags,
                },
                { key: "department", value: metaData?.poshmark?.department },
                { key: "category", value: metaData?.poshmark?.category },
                { key: "brand", value: metaData?.poshmark?.brand },
                { key: "size", value: metaData?.poshmark?.size },
                { key: "color", value: metaData?.poshmark?.color },
                { key: "price", value: updatedStagingDetails.info?.price },
                {
                    key: "lisitingprice",
                    value: updatedStagingDetails.info?.price,
                },
            ];

            const poshmarkUpdatedInfo = { ...state.poshmark?.info };
            poshmarkPropertiesToUpdate.forEach(({ key, value }) => {
                if (value && !poshmarkUpdatedInfo[key]) {
                    poshmarkUpdatedInfo[key] = value;
                }
            });

            const updatedPoskmarkDetails = {
                ...state.stagingDetails,
                info: { ...state.poshmark.info, ...poshmarkUpdatedInfo },
            };

            const mercariPropertiesToUpdate = [
                { key: "title", value: updatedStagingDetails.info?.title },
                {
                    key: "description",
                    value: updatedStagingDetails.info?.description,
                },
                { key: "tags", value: updatedStagingDetails.info?.tags },
                { key: "brand", value: metaData?.mercari?.brand },
                { key: "condition", value: metaData?.mercari?.condition },
                { key: "price", value: updatedStagingDetails.info?.price },
                { key: "color", value: metaData?.mercari?.color },
                { key: "age_group", value: metaData?.mercari?.age_group },
            ];

            const mercariUpdatedInfo = { ...state.mercari?.info };
            mercariPropertiesToUpdate.forEach(({ key, value }) => {
                if (value && !mercariUpdatedInfo[key]) {
                    mercariUpdatedInfo[key] = value;
                }
            });

            const updatedMercariDetails = {
                ...state.stagingDetails,
                info: { ...state.mercari.info, ...mercariUpdatedInfo },
            };

            const depopPropertiesToUpdate = [
                {
                    key: "description",
                    value: updatedStagingDetails.info?.description,
                },
                { key: "category", value: metaData?.depop?.category },
                { key: "department", value: metaData?.depop?.department },
                { key: "subcategory", value: metaData?.depop?.subcategory },
                { key: "brand", value: metaData?.depop?.brand },
                { key: "color", value: metaData?.depop?.color },
                { key: "source", value: metaData?.depop?.source },
                { key: "condition", value: metaData?.depop?.condition },
                { key: "age", value: metaData?.depop?.age },
                { key: "style", value: metaData?.depop?.style },
                { key: "size", value: metaData?.depop?.size },
                { key: "country", value: metaData?.depop?.country },
                { key: "price", value: updatedStagingDetails.info?.price },
            ];

            const depopUpdatedInfo = { ...state.depop?.info };
            depopPropertiesToUpdate.forEach(({ key, value }) => {
                if (value && !depopUpdatedInfo[key]) {
                    depopUpdatedInfo[key] = value;
                }
            });

            const updatedDepopDetails = {
                ...state.stagingDetails,
                info: { ...state.depop.info, ...depopUpdatedInfo },
            };

            const shopifyPropertiesToUpdate = [
                { key: "title", value: updatedStagingDetails.info?.title },
                {
                    key: "description",
                    value: updatedStagingDetails.info?.description,
                },
                { key: "brand", value: metaData?.shopify?.brand },
                { key: "category", value: metaData?.shopify?.category },
                { key: "size", value: metaData?.shopify?.size },
                { key: "price", value: updatedStagingDetails.info?.price },
                { key: "color", value: metaData?.shopify?.color },
                { key: "tags", value: updatedStagingDetails.info?.tags },
            ];

            const shopifyUpdatedInfo = { ...state.shopify?.info };
            shopifyPropertiesToUpdate.forEach(({ key, value }) => {
                if (value && !shopifyUpdatedInfo[key]) {
                    shopifyUpdatedInfo[key] = value;
                }
            });

            const updatedShopifyDetails = {
                ...state.stagingDetails,
                info: { ...state.shopify.info, ...shopifyUpdatedInfo },
            };

            return {
                ...state,
                imageMetaData: action.payload,
                stagingDetails: updatedStagingDetails,
                kidizen: updatedKidizenDetails,
                poshmark: updatedPoskmarkDetails,
                mercari: updatedMercariDetails,
                depop: updatedDepopDetails,
                shopify: updatedShopifyDetails,
            };
        case "SET_IMAGE_META_DATA_DETECTOR_STATUS":
            return {
                ...state,
                imageMetaDataDetectorStatus: action.payload,
            };
        case "SAVE_TO_LISTING":
            return {
                ...state,
                listingId: action.payload,
            };
        case "SET_MERCARI_DATA":
            return {
                ...state,
                mercari: action.payload,
            };
        case "SET_FACEBOOK_DATA":
            return {
                ...state,
                facebook: action.payload,
            };
        case "SET_SHOPIFY_DATA":
            return {
                ...state,
                shopify: action.payload,
            };
        case "SET_EBAY_DATA":
            return {
                ...state,
                ebay: action.payload,
            };
        case "SET_ETSY_DATA":
            return {
                ...state,
                etsy: action.payload,
            };
        case "SET_POSHMARK_DATA":
            return {
                ...state,
                poshmark: action.payload,
            };
        case "SET_KIDIZEN_DATA":
            return {
                ...state,
                kidizen: action.payload,
            };
        case "SET_DEPOP_DATA":
            return {
                ...state,
                depop: action.payload,
            };
        case "SET_SHOPIFY_DATA":
            return {
                ...state,
                shopify: action.payload,
            };
        case "UPDATE_INPUT_FIELD":
            const { platform, key, value } = action.payload;
            const platformData = state[platform];
            const updatedPlatformData = {
                ...platformData,
                info: {
                    ...platformData.info,
                    [key]: value,
                },
            };
            return {
                ...state,
                [platform]: updatedPlatformData,
            };
        case "SET_STAGING_DETAILS":
            return {
                ...state,
                stagingDetails: action.payload,
            };
        case "RESET_STAGING_DETAILS":
            return {
                mercari: { info: {}, images: [] },
                facebook: { info: {}, images: [] },
                shopify: { info: {}, images: [] },
                ebay: { info: {}, images: [] },
                etsy: { info: {}, images: [] },
                poshmark: { info: {}, images: [] },
                kidizen: { info: {}, images: [] },
                depop: { info: {}, images: [] },
                stagingDetails: { info: {}, images: [] },
                inventoryId: null,
                listingId: null,
                imageMetaData: null,
                imageMetaDataDetectorStatus: "pending",
            };
        default:
            return state;
    }
};

export default stagingReducer;
