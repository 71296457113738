import { useEffect } from "react";
import React from "react";

export function Modal(props) {
  const { handleClose } = props;

  //animation
  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div
      className="fixed inset-0 bg-black fade-in bg-opacity-25 backdrop-blur-sm z-[999] min-h-screnn h-full flex justify-center items-center "
      style={{
        marginTop: "0px",
      }}
    >
      <div
        className={`bg-[#fff] rounded-md px-7 py-5 relative ${
          props.width ? props.width : `w-1/3`
        }`}
        style={{
          minWidth: "400px",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
