import Tippy from "@tippyjs/react";
import { SiShopify, SiEbay, SiEtsy } from "react-icons/si";

let platformLogos = {
  poshmark: (
    <div className="">
      <svg
        version="1.2"
        className="h-5 w-16 mx-auto text-gray-600 mt-2"
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1446 1621"
      >
        <path
          id="Layer"
          class="shp0"
          d="M976.02 0.37C1230.96 -4.26 1441.08 199.42 1445.72 452.48C1451.9 708.62 1249.5 920.01 993.02 926.18L993.02 754.91C1147.52 745.65 1268.04 617.58 1268.04 463.28C1268.04 302.8 1138.25 171.65 976.02 171.65C815.34 171.65 684.01 301.26 684.01 463.28L684.01 1191.58L512.5 1191.58L512.5 463.28C512.5 208.68 719.54 0.37 976.02 0.37L976.02 0.37ZM756.62 429.33L928.13 429.33L928.13 1157.64C928.13 1406.06 730.36 1611.28 481.6 1620.54C225.12 1629.8 10.36 1430.75 1.09 1174.61C-8.18 918.47 191.13 703.99 447.61 694.73L447.61 866.01C293.11 875.26 172.59 1003.33 172.59 1157.64C172.59 1318.11 302.38 1449.27 464.61 1449.27C625.29 1449.27 756.62 1319.65 756.62 1157.64L756.62 429.33Z"
        />
      </svg>
    </div>
  ),
  shopify: (
    <div>
      <SiShopify className="h-8 w-8 text-gray-600" />
    </div>
  ),
  mercari: (
    <div className="">
      <svg
        className="h-8 w-8 mx-auto text-gray-600 mt-2"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.79492 36.25V12.4925L18.6733 36.25L30.5524 12.4925V36.25M27.7499 8.0525L28.9033 3.75L33.2066 4.9025L32.0533 9.20583L27.7499 8.0525Z"
          stroke="#3F3F46"
          stroke-width="0.9375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  ),
  kidizen: (
    <div className="">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-auto p-2.5 text-gray-600"
        viewBox="0 0 481.000000 105.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,105.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M930 1033 c-39 -20 -70 -72 -70 -119 0 -71 86 -143 155 -130 78 15
129 97 105 170 -24 74 -121 114 -190 79z"
          />
          <path
            d="M2192 1034 c-78 -40 -90 -160 -22 -218 45 -37 90 -43 144 -17 71 35
96 113 56 178 -38 63 -116 88 -178 57z"
          />
          <path d="M0 485 l0 -455 120 0 120 0 0 455 0 455 -120 0 -120 0 0 -455z" />
          <path
            d="M1780 786 l0 -155 -51 34 c-66 45 -118 58 -204 53 -106 -7 -190 -55
-250 -142 -95 -140 -81 -344 33 -465 68 -72 144 -105 247 -105 70 -1 87 3 134
29 30 16 59 38 65 47 17 28 26 21 26 -22 l0 -40 115 0 115 0 0 460 0 460 -115
0 -115 0 0 -154z m-80 -281 c94 -49 114 -179 38 -252 -55 -53 -117 -66 -182
-39 -121 51 -134 214 -23 285 39 25 124 28 167 6z"
          />
          <path d="M418 823 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
          <path d="M344 788 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
          <path
            d="M710 765 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25 -28
25 -3 0 5 -11 18 -25z"
          />
          <path d="M311 724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path
            d="M3527 719 c-80 -12 -138 -39 -196 -94 -69 -64 -94 -126 -99 -245 -3
-79 0 -104 18 -152 27 -73 93 -147 163 -181 138 -67 341 -55 448 27 38 29 99
116 99 142 0 5 -50 8 -110 7 -102 -1 -113 -3 -141 -27 -96 -81 -249 -14 -249
109 l0 25 260 0 261 0 -6 53 c-26 213 -132 323 -330 340 -33 2 -86 1 -118 -4z
m161 -159 c29 -18 72 -73 72 -92 0 -5 -65 -8 -145 -8 -95 0 -145 4 -145 10 0
25 33 72 63 90 42 26 112 26 155 0z"
          />
          <path
            d="M4489 720 c-59 -10 -85 -23 -131 -68 l-38 -36 0 47 0 47 -115 0 -115
0 0 -340 0 -340 115 0 115 0 0 205 c0 223 4 243 56 284 30 23 97 28 137 10 13
-6 32 -25 43 -42 17 -29 19 -54 19 -244 l0 -213 118 0 117 0 0 235 c0 133 -5
256 -11 286 -26 121 -160 194 -310 169z"
          />
          <path d="M890 365 l0 -335 120 0 120 0 0 335 0 335 -120 0 -120 0 0 -335z" />
          <path d="M2150 365 l0 -335 120 0 120 0 0 335 0 335 -120 0 -120 0 0 -335z" />
          <path
            d="M2510 605 l0 -94 146 -3 145 -3 -160 -234 c-89 -129 -161 -236 -161
-238 0 -2 155 -3 345 -3 l345 0 0 95 0 94 -149 3 -149 3 159 233 c88 128 159
235 159 237 0 3 -153 5 -340 5 l-340 0 0 -95z"
          />
          <path d="M311 674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M736 623 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
          <path d="M350 596 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
          <path
            d="M709 583 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"
          />
          <path
            d="M659 543 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"
          />
          <path d="M390 546 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
          <path d="M609 503 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
          <path d="M420 506 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
          <path d="M559 463 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
          <path d="M460 456 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21 13z" />
          <path
            d="M519 433 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"
          />
          <path
            d="M404 330 c-49 -40 -93 -75 -98 -80 -4 -4 25 -56 65 -114 l73 -106
153 0 153 0 -116 172 c-65 95 -122 179 -129 186 -9 10 -29 -2 -101 -58z"
          />
        </g>
      </svg>
    </div>
  ),
  depop: (
    <svg
      className="h-15 w-20 mt-2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-1 -1 1296 402"
    >
      <path d="M-1-1h582v402H-1z" fill="none" />
      <path
        d="M0 167.9c0-54.5 38.884-88 101.06-88h64.373V0H246v251H100.76C38.883 251 0 218.8 0 167.9zm85-2.7c0 20.9 12.653 31.8 36.96 31.8H165v-64h-42.64C98.05 133 85 144 85 165.2zm682-.1c0 54.5-38.884 88-101.06 88h-64.373V333H521V82h145.24C728.117 82 767 114.2 767 165.1zm-85 2.7c0-20.9-12.668-31.8-37.007-31.8H602v64h42.693c24.34 0 37.307-11 37.307-32.2zm613-2.7c0 54.5-38.884 88-101.06 88h-64.373V333H1049V82h145.24c61.876 0 100.76 32.2 100.76 83.1zm-85 2.7c0-20.9-12.668-31.8-37.007-31.8H1130v64h42.693c24.34 0 37.307-11 37.307-32.2zm-419-1.2c0-60.996 48.8-93.6 116-93.6s116 32.604 116 93.6c0 60.993-48.8 94.4-116 94.4-67.2-.1-116-33.407-116-94.4zm153-.95c0-29.733-14.9-44.65-37.5-44.65S869 135.917 869 165.65c0 29.332 14.9 45.35 37.5 45.35 22.7-.1 37.5-16.018 37.5-45.35zm-450 4.262C494 108.918 454.77 72 383.898 72 315.82 72 272 108.918 272 166.702 272 225.19 315.422 260 387.79 260c45.52 0 82.253-17.054 101.618-44.743l-57.696-21.067c-9.982 10.935-25.055 16.954-40.926 16.954-23.857 0-40.427-9.63-44.72-25.983-.3-1.102-.5-2.106-.698-3.31H494v-11.938zm-148-25.913c1.902-19.814 15.62-32 39.45-32 23.832 0 37.55 12.88 37.55 32z"
        fill="#ff2300"
      />
    </svg>
  ),
  alliwant: (
    <img
      width="40"
      style={{
        marginTop: "7px",
        marginLeft: "10px",
        // marginRight: "auto",
      }}
      src={require("../Assets/alliwantlogo.png")}
    ></img>
  ),
};

export default platformLogos;
