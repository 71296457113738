import React from "react";
import Select from "react-select";
import { Ring } from "@uiball/loaders";
import { conditions } from "./mercariDropdowns";
import { ColorOptions, colorOptions } from "./DepopDropdowns";

const deliveryOptions = [
  {
    value: "MERCARI",
    label:
      "Standard shipping: Best for sending small items across the U.S. We'll email you a label and you'll ship the item. Save an average of 25% off retail. Includes delivery protection.",
  },
  {
    value: "LOCAL",
    label:
      "Local Delivery: Expose your item to more shoppers in your area. A driver will pick up and deliver the item - no box needed. Includes shipping protection.",
  },
  {
    value: "MERCARI_AND_LOCAL",
    label:
      "Shipping and local delivery: Make your item available to more people. You'll let buyers choose from both options. Includes shipping protection.",
  },
  {
    value: "SOYO",
    label:
      "Ship on your own: You provide your own label and ship the item. It’s not covered by shipping protection.",
  },
];

const deliveryPayByOptions = [
  { value: "Buyer", label: "The Buyer" },
  { value: "Seller", label: "I'll pay" },
];

const smartPricingOptions = [
  { value: "off", label: "Off" },
  { value: "on", label: "On" },
];

function mercari({
  data,
  changeMercaiValueHandler,
  dataFetched,
  saveDataHandler,
}) {
  const values = {
    title: "",
    description: "",
    department: "",
    category: "",
    quantity: "",
    size: "",
    newWithTags: "",
    brand: "",
    color: "",
    tags: "",
    delivery: "",
    deliveryPayBy: "",
    smartPricing: "off",
    minimumPrice: "",
  };
  if (data && data.mercari && data.generalInfo) {
    values.title =
      "title" in data.mercari ? data.mercari.title : data.generalInfo.title;
    values.description =
      "description" in data.mercari
        ? data.mercari.description
        : data.generalInfo.description;
    values.tags =
      "tags" in data.mercari ? data.mercari.tags : data.generalInfo.tags;
    values.condition = data.mercari.condition;
    values.brand =
      "brand" in data.mercari ? data.mercari.brand : data.generalInfo.brand;
    values.color =
      "color" in data.mercari ? data.mercari.color : data.generalInfo.color;
    values.price =
      "price" in data.mercari ? data.mercari.price : data.generalInfo.price;
    values.delivery = data.mercari.delivery || "SOYO";
    values.deliveryPayBy = data.mercari.deliveryPayBy || "Seller";
    values.smartPricing = data.mercari.smartPricing || "off";
    values.minimumPrice = data.mercari.minimumPrice;
  }
  return (
    <>
      <div className="grid grid-cols-12 gap-x-3">
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Title
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Name of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              onBlur={saveDataHandler}
              value={values.title}
              required
              onChange={(e) => {
                changeMercaiValueHandler("title", e.target.value);
              }}
            />
          </div>
          {values.title === "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Description
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Descriptionof the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <textarea
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="description"
              value={values.description}
              onChange={(e) => {
                changeMercaiValueHandler("description", e.target.value);
              }}
              placeholder="Enter description"
              onBlur={saveDataHandler}
              required
            />
          </div>
          {values.description === "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Tags
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Tags for the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="tags"
              value={values.tags}
              onChange={(e) => {
                changeMercaiValueHandler("tags", e.target.value);
              }}
              placeholder="Enter tags"
              onBlur={saveDataHandler}
              required
            />
          </div>
          {values.tags === "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Condition
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the condition of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={conditions}
              placeholder="Select category"
              value={
                values.condition &&
                conditions.find((option) => {
                  return option.value === values.condition;
                })
              }
              onChange={(selectedOption) => {
                changeMercaiValueHandler("condition", selectedOption.value);
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.condition == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Brand
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Brand of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="tags"
              value={values.brand}
              onChange={(e) => {
                changeMercaiValueHandler("brand", e.target.value);
              }}
              placeholder="Enter brand"
              onBlur={saveDataHandler}
              required
            />
          </div>
          {values.brand === "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Color
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the color of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={colorOptions}
              placeholder="Select color"
              value={
                values.color &&
                colorOptions.find((option) => {
                  return option.value === values.color;
                })
              }
              onChange={(selectedOption) => {
                changeMercaiValueHandler("color", selectedOption.value);
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.color == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Delivery
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select Delivery of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={deliveryOptions}
              placeholder="Select delivery method"
              value={
                values.delivery &&
                deliveryOptions.find((option) => {
                  return option.value === values.delivery;
                })
              }
              onChange={(selectedOption) => {
                changeMercaiValueHandler("delivery", selectedOption.value);
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.condition == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.delivery != "SOYO" && (
          <div
            className={`flex items-center w-full col-span-11 gap-5 space-y-7`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Delivery Pay By
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Who will pay for the delivery?
              </label>
            </div>
            <div className="w-3/5 my-auto relative">
              <Select
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
                options={deliveryPayByOptions}
                placeholder="select who will pay for the delivery?"
                value={
                  values.delivery &&
                  deliveryPayByOptions.find((option) => {
                    return option.value === values.deliveryPayBy;
                  })
                }
                onChange={(selectedOption) => {
                  changeMercaiValueHandler(
                    "deliveryPayBy",
                    selectedOption.value
                  );
                }}
                onBlur={(e) => {
                  saveDataHandler();
                }}
                // onChange={onChange}
              />
            </div>
            {values.condition == "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Price of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product price"
              onBlur={saveDataHandler}
              value={values.price}
              required
              onChange={(e) => {
                changeMercaiValueHandler("price", e.target.value);
              }}
            />
          </div>
          {values.price === "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Smart Pricing
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Mercari will Adjusts price automatically.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={smartPricingOptions}
              placeholder="Select delivery method"
              value={
                values.smartPricing &&
                smartPricingOptions.find((option) => {
                  return option.value === values.smartPricing;
                })
              }
              onChange={(selectedOption) => {
                changeMercaiValueHandler("smartPricing", selectedOption.value);
              }}
              onBlur={(e) => {
                saveDataHandler();
              }}
              // onChange={onChange}
            />
          </div>
          {values.smartPricing == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        {values.smartPricing == "on" && (
          <div
            className={`flex items-center w-full col-span-11 gap-5 space-y-5`}
          >
            <div className="w-2/5">
              <div className="flex gap-1">
                <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                  Minimum
                </p>
                <p className="font-bold text-red-500">*</p>
              </div>
              <label className="text-Inter text-gray-600 text-sm leading-snug">
                Minimum price of the product
              </label>
            </div>
            <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
              <input
                className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
                type="text"
                name="title"
                // onChange={onChange}
                placeholder="Enter product minimum price"
                onBlur={saveDataHandler}
                value={values.minimumPrice}
                onChange={(e) => {
                  changeMercaiValueHandler("minimumPrice", e.target.value);
                }}
              />
            </div>
            {values.minimumPrice === "" && !dataFetched && (
              <Ring
                color="orange"
                size={20}
                speed={1}
                className="h-[15px] w-[15px] col-span-1"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default mercari;
