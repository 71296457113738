import React from "react";
import Select from "react-select";
import {
  departmentOptions,
  categories,
  quantityOptions,
} from "./PoshmarkDropdowns";
import { colorOptions } from "./DepopDropdowns";
import { Ring } from "@uiball/loaders";
import SizeOptions from "./PoshmarkSizes";

const discountShippingOptions = [
  { value: "No Discount", label: "No Discount" },
  { value: "$5.95", label: "$5.95 ($2.02 dedcuted from earnings)" },
  { value: "$4.99", label: "$4.99 ($2.98 dedcuted from earnings)" },
  { value: "FREE", label: "FREE ($7.97 deducted from earnings)" },
];

function poshmark({
  data,
  changePoshmarkValueHandler,
  dataFetched,
  saveDataHandler,
}) {
  const sizingTypeOptions = [
    { value: "us", label: "us" },
    { value: "eu", label: "eu" },
    { value: "uk", label: "uk" },
    { value: "au", label: "au" },
  ];

  const values = {
    title: "",
    description: "",
    department: "",
    category: "",
    quantity: "",
    size: "",
    newWithTags: "",
    brand: "",
    color: "",
    tags: "",
    price: "",
    listingPrice: "",
    discountShipping: "",
    sizeGroup: "",
  };
  let categoryOptions = [];
  let actualSizeOptions = [];
  let selectedSize = null;
  if (data && data.poshmark && data.generalInfo) {
    values.title =
      "title" in data.poshmark ? data.poshmark.title : data.generalInfo.title;
    values.description =
      "description" in data.poshmark
        ? data.poshmark.description
        : data.generalInfo.description;
    values.department = data.poshmark.department;
    values.category = data.poshmark.category;
    values.quantity = data.poshmark.quantity;
    values.size = data.poshmark.size;
    values.sizeGroup = data.poshmark.sizeGroup;
    values.newWithTags = data.poshmark.newWithTags;
    values.brand = data.poshmark.brand;
    values.color =
      "color" in data.poshmark ? data.poshmark.color : data.generalInfo.color;
    values.tags =
      "tags" in data.poshmark ? data.poshmark.tags : data.generalInfo.tags;
    values.price =
      "price" in data.poshmark ? data.poshmark.price : data.generalInfo.price;
    values.listingPrice =
      "listingPrice" in data.poshmark
        ? data.poshmark.listingPrice
        : data.generalInfo.price;

    categoryOptions = categories[values.department];

    console.log(data.poshmark.size);

    if ("quantity" in data.poshmark) {
      values.quantity = data.poshmark.quantity;
    } else {
      values.quantity = "Single Item";
    }
    values.sizingType = data.poshmark.sizingType;
    if (!values.sizingType || values.sizingType == "") {
      values.sizingType = "us";
    }

    values.discountShipping = data.poshmark.discountShipping || "No Discount";

    if (
      !SizeOptions[values.department] ||
      !SizeOptions[values.department][values.category] ||
      !SizeOptions[values.department][values.category][values.sizingType]
    ) {
      actualSizeOptions = [];
    } else {
      actualSizeOptions =
        SizeOptions[values.department][values.category][values.sizingType];
      console.log("actualSizeOptions", actualSizeOptions);
    }

    // let OneSizePresent = false;
    // for (let sizeOption of actualSizeOptions) {
    //   let newOptions = [];
    //   for (let option of sizeOption.options) {
    //     option.group = sizeOption.label;
    //   }
    // }

    for (let i = 0; i < actualSizeOptions.length; i++) {
      let found = false;
      for (let j = 0; j < actualSizeOptions[i].options.length; j++) {
        if (
          actualSizeOptions[i].options[j].value === values.size &&
          (!values.sizeGroup ||
            values.sizeGroup == actualSizeOptions[i].options[j].group)
        ) {
          selectedSize = actualSizeOptions[i].options[j];
          found = true;
          // changePoshmarkValueHandler("sizeGroup", selectedSize.group);
          break;
        }
      }
      if (found) {
        break;
      }
    }
    console.log(values.sizeGroup, "size group");
    if (!values.sizeGroup) {
      changePoshmarkValueHandler("sizeGroup", selectedSize.group);
    }
    console.log("selectedSize", selectedSize);
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-x-3">
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Title
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Name of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              // onBlur={onBlur}
              value={values.title}
              required
              onChange={(e) => {
                changePoshmarkValueHandler("title", e.target.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.title == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Description
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Descriptionof the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <textarea
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full h-[auto]"
              name="description"
              value={values.description}
              onChange={(e) => {
                changePoshmarkValueHandler("description", e.target.value);
              }}
              onBlur={saveDataHandler}
              placeholder="Enter description"
              // onBlur={onBlur}
              required
            />
          </div>
          {values.description == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Department
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the department of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={departmentOptions}
              placeholder="Select category"
              value={
                values.department &&
                departmentOptions.find((option) => {
                  return option.value === values.department;
                })
              }
              onChange={(selectedOption) => {
                changePoshmarkValueHandler("department", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.department == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Category
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the category of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={categoryOptions}
              placeholder="Select category"
              value={
                values.category &&
                categoryOptions.find((option) => {
                  return option.value === values.category;
                })
              }
              onChange={(selectedOption) => {
                changePoshmarkValueHandler("category", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.category == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Sizing Type
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the sizing type of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={sizingTypeOptions}
              placeholder="Select sizing type"
              value={
                values.sizingType &&
                sizingTypeOptions.find((option) => {
                  return option.value === values.sizingType;
                })
              }
              onChange={(selectedOption) => {
                changePoshmarkValueHandler("sizingType", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.sizingType == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Size
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the size of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={actualSizeOptions}
              placeholder="Select size"
              value={selectedSize}
              onChange={(selectedOption) => {
                console.log("selectedOption", selectedOption);
                changePoshmarkValueHandler("size", selectedOption.value);
                changePoshmarkValueHandler("sizeGroup", selectedOption.group);
              }}
              onBlur={saveDataHandler}
              isOptionSelected={(option, selectValue) =>
                selectValue.some((i) => i === option)
              }
              // onChange={onChange}
            />
          </div>
          {values.sizingType == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Tags
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Tags of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product tags"
              // onBlur={onBlur}
              value={values.tags}
              required
              onChange={(e) => {
                changePoshmarkValueHandler("tags", e.target.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.tags == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Brand
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Brand of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product brand"
              // onBlur={onBlur}
              value={values.brand}
              required
              onChange={(e) => {
                changePoshmarkValueHandler("brand", e.target.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.brand == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Color
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select the color of the product.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={colorOptions}
              placeholder="Select color"
              value={
                values.color &&
                colorOptions.find((option) => {
                  return option.value === values.color;
                })
              }
              onChange={(selectedOption) => {
                changePoshmarkValueHandler("color", selectedOption.value);
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.color == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Price of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              // onBlur={onBlur}
              value={values.price}
              required
              onChange={(e) => {
                changePoshmarkValueHandler("price", e.target.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.price == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-5`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Listing Price
              </p>
              <p className="font-bold text-red-500">*</p>
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Listing Price of the product
            </label>
          </div>
          <div className="w-3/5 my-auto flex items-center rounded-lg border-2 py-1.5 px-3">
            <input
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full "
              type="text"
              name="title"
              // onChange={onChange}
              placeholder="Enter product title"
              // onBlur={onBlur}
              value={values.listingPrice}
              required
              onChange={(e) => {
                changePoshmarkValueHandler("listingPrice", e.target.value);
              }}
              onBlur={saveDataHandler}
            />
          </div>
          {values.listingPrice == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
        <div className={`flex items-center w-full col-span-11 gap-5 space-y-7`}>
          <div className="w-2/5">
            <div className="flex gap-1">
              <p className="text-Inter text-gray-700 tracking-wide font-[500] text-sm leading-snug">
                Shipping Discount
              </p>
              {/* <p className="font-bold text-red-500">*</p> */}
            </div>
            <label className="text-Inter text-gray-600 text-sm leading-snug">
              Select how much discount you want to give on shipping.
            </label>
          </div>
          <div className="w-3/5 my-auto relative">
            <Select
              className="border-none pl-0.5 placeholder:text-sm rounded-lg outline-none w-full"
              options={discountShippingOptions}
              placeholder="Select shipping discount"
              value={
                values.discountShipping &&
                discountShippingOptions.find((option) => {
                  return option.value === values.discountShipping;
                })
              }
              onChange={(selectedOption) => {
                changePoshmarkValueHandler(
                  "discountShipping",
                  selectedOption.value
                );
              }}
              onBlur={saveDataHandler}
              // onChange={onChange}
            />
          </div>
          {values.color == "" && !dataFetched && (
            <Ring
              color="orange"
              size={20}
              speed={1}
              className="h-[15px] w-[15px] col-span-1"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default poshmark;
