export const departmentOptions = [
  { value: "Women", label: "Women" },
  { value: "Men", label: "Men" },
  { value: "Kids", label: "Kids" },
  { value: "Home", label: "Home" },
  { value: "Pets", label: "Pets" },
  { value: "Electronics", label: "Electronics" },
];

export const categories = {
  Women: [
    { value: "Accessories", label: "Accessories" },
    { value: "Bags", label: "Bags" },
    { value: "Dresses", label: "Dresses" },
    { value: "Intimates & Sleepwear", label: "Intimates & Sleepwear" },
    { value: "Jackets & Coats", label: "Jackets & Coats" },
    { value: "Jeans", label: "Jeans" },
    { value: "Jewelry", label: "Jewelry" },
    { value: "Makeup", label: "Makeup" },
    { value: "Pants & Jumpsuits", label: "Pants & Jumpsuits" },
    { value: "Shoes", label: "Shoes" },
    { value: "Shorts", label: "Shorts" },
    { value: "Skirts", label: "Skirts" },
    { value: "Sweaters", label: "Sweaters" },
    { value: "Swim", label: "Swim" },
    { value: "Tops", label: "Tops" },
    { value: "Skincare", label: "Skincare" },
    { value: "Hair", label: "Hair" },
    { value: "Bath & Body", label: "Bath & Body" },
    {
      value: "Global & Traditional Wear",
      label: "Global & Traditional Wear",
    },
    { value: "Other", label: "Other" },
  ],
  Men: [
    { value: "Accessories", label: "Accessories" },
    { value: "Bags", label: "Bags" },
    { value: "Jackets & Coats", label: "Jackets & Coats" },
    { value: "Jeans", label: "Jeans" },
    { value: "Pants", label: "Pants" },
    { value: "Shirts", label: "Shirts" },
    { value: "Shoes", label: "Shoes" },
    { value: "Shorts", label: "Shorts" },
    { value: "Suits & Blazers", label: "Suits & Blazers" },
    { value: "Sweaters", label: "Sweaters" },
    { value: "Swim", label: "Swim" },
    { value: "Underwear & Socks", label: "Underwear & Socks" },
    { value: "Grooming", label: "Grooming" },
    {
      value: "Global & Traditional Wear",
      label: "Global & Traditional Wear",
    },
    { value: "Other", label: "Other" },
  ],
  Kids: [
    { value: "Accessories", label: "Accessories" },
    { value: "Bottoms", label: "Bottoms" },
    { value: "Dresses", label: "Dresses" },
    { value: "Jackets & Coats", label: "Jackets & Coats" },
    { value: "Matching Sets", label: "Matching Sets" },
    { value: "One Pieces", label: "One Pieces" },
    { value: "Pajamas", label: "Pajamas" },
    { value: "Shirts & Tops", label: "Shirts & Tops" },
    { value: "Shoes", label: "Shoes" },
    { value: "Swim", label: "Swim" },
    { value: "Costumes", label: "Costumes" },
    { value: "Bath, Skin & Hair", label: "Bath, Skin & Hair" },
    { value: "Toys", label: "Toys" },
    { value: "Other", label: "Other" },
  ],
  Home: [
    { value: "Accents", label: "Accents" },
    { value: "Art", label: "Art" },
    { value: "Bath", label: "Bath" },
    { value: "Bedding", label: "Bedding" },
    { value: "Design", label: "Design" },
    { value: "Dining", label: "Dining" },
    { value: "Games", label: "Games" },
    { value: "Holiday", label: "Holiday" },
    { value: "Kitchen", label: "Kitchen" },
    { value: "Office", label: "Office" },
    { value: "Party Supplies", label: "Party Supplies" },
    { value: "Storage & Organization", label: "Storage & Organization" },
    { value: "Wall Decor", label: "Wall Decor" },
    { value: "Other", label: "Other" },
  ],
  Pets: [
    { value: "Dog", label: "Dog" },
    { value: "Cat", label: "Cat" },
    { value: "Bird", label: "Bird" },
    { value: "Fish", label: "Fish" },
    { value: "Reptile", label: "Reptile" },
    { value: "Small Pets", label: "Small Pets" },
    { value: "Other", label: "Other" },
  ],
  Electronics: [
    { value: "Cameras, Photo & Video", label: "Cameras, Photo & Video" },
    {
      value: "Computers, Laptops & Parts",
      label: "Computers, Laptops & Parts",
    },
    {
      value: "Cell Phones & Accessories",
      label: "Cell Phones & Accessories",
    },
    { value: "Car Audio, Video & GPS", label: "Car Audio, Video & GPS" },
    { value: "Wearables", label: "Wearables" },
    { value: "Tablets & Accessories", label: "Tablets & Accessories" },
    { value: "Video Games & Consoles", label: "Video Games & Consoles" },
    { value: "VR, AR & Accessories", label: "VR, AR & Accessories" },
    { value: "Media", label: "Media" },
    { value: "Networking", label: "Networking" },
    { value: "Headphones", label: "Headphones" },
    { value: "Portable Audio & Video", label: "Portable Audio & Video" },
    { value: "Other", label: "Other" },
  ],
};

export const colorOptions = [
  { value: "Black", label: "Black" },
  { value: "Grey", label: "Grey" },
  { value: "White", label: "White" },
  { value: "Brown", label: "Brown" },
  { value: "Tan", label: "Tan" },
  { value: "Cream", label: "Cream" },
  { value: "Yellow", label: "Yellow" },
  { value: "Red", label: "Red" },
  { value: "Orange", label: "Orange" },
  { value: "Pink", label: "Pink" },
  { value: "Purple", label: "Purple" },
  { value: "Blue", label: "Blue" },
  { value: "Green", label: "Green" },
  { value: "Gold", label: "Gold" },
  { value: "Silver", label: "Silver" },
];

export const quantityOptions = [
  {
    value: "Single Item",
    label: "Single Item",
  },
  {
    value: "Multi Item",
    label: "Multi Item",
  },
];
